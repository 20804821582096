import {OPENING_DELETING_CLASSIFICATION_MODAL, CLOSING_DELETING_CLASSIFICATION_MODAL, DELETING_CLASSIFICATION} from '../../actions/classifications/ClassificationListActions'

const INITIAL_STATE = {
    open: false,
    classificationId: null,
    isProcessing: false
}

export default function DeletingCLASSIFICATIONModal(state = INITIAL_STATE, action){
    switch(action.type){
        case OPENING_DELETING_CLASSIFICATION_MODAL:
            return {...state, open: true, classificationId: action.payload, isProcessing: false};
        case CLOSING_DELETING_CLASSIFICATION_MODAL:
            return {...state, open: false, classificationId: null, isProcessing: false};
        case `${DELETING_CLASSIFICATION}_PENDING`:
            return {...state, isProcessing: true};
        case `${DELETING_CLASSIFICATION}_FULFILLED`:
            return {...state, open: false, classificationId: null};
        case `${DELETING_CLASSIFICATION}_REJECTED`:
            return {...state, open: false, classificationId: null, isProcessing: false};
        default:
            return state;
    }
}