import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import MyFile from '../fileUploader/fileUploader';

import {uploadNewInvoice} from '../../actions/bills/billActions';

import { connect } from 'react-redux';

const validate = values => {
    const errors = {};
    let requiredFields = [
        
    ];
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });
    if( values.document == undefined && values.dutyDocument == undefined) {
        errors.document = "Al menos un documento es requerido para resubir";
    }
    /*if(values.user && emailRegex.test(values.email)){
    errors.email = 'Usuario inválido';
    }*/
    return errors;
}

class UploadNewInvoiceDialog extends Component {
    constructor(props){
        super(props);
    }

    handleFormSubmit = values => {
        const fd = new FormData();
        if (values.document !== undefined && values.document !== null) {
            
            fd.append("document", values.document[0]);
        }

        if (values.dutyDocument !== undefined && values.dutyDocument !== null) {
            
            fd.append("documentDuty", values.dutyDocument[0]);
        }

        this.props.uploadNewInvoice(fd);
    }

    render() {
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                disableBackdropClick={this.props.isProcessing}
                disableEscapeKeyDown={this.props.isProcessing}
                >
                    <DialogTitle id="form-dialog-title">Resubir factura</DialogTitle>
                    <form onSubmit={handleSubmit(this.handleFormSubmit)} noValidate autoComplete='off'>
                    <DialogContent>
                        <DialogContentText>
                            Se procederá a subir una nueva versión de factura seleccionada.
                        </DialogContentText>
                        <div>
                            <h4>Documento de factura a subir</h4>                            
                            <Field label={"Documento de honorarios a subir"} name="document" component={MyFile} type="file"
                            dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                        </div>
                        <div>
                            
                        <h4>Documento de aranceles a subir</h4>                            
                            <Field label={"Documento de aranceles a subir"} name="dutyDocument" component={MyFile} type="file"
                            dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                        </div>                                               
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" disabled={submitting||this.props.isProcessing}>
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary" disabled={pristine||submitting||this.props.isProcessing}>
                            Guardar
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>       
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {uploadNewInvoiceModal} = state;
    return {uploadNewInvoiceModal, isProcessing: uploadNewInvoiceModal.isProcessing};
}

const mapDispatchToProps = {
    uploadNewInvoice
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'UploadNewInvoiceBill', validate})(UploadNewInvoiceDialog));
