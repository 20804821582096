import {OPENING_DELETE_CASE, CLOSING_DELETE_CASE, DELETE_CASE} from '../../actions/legalCases/legalCaseListAction';

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    legalCaseId: null
}

export default function DeleteLegalCaseModal(state=INITIAL_STATE, action){
    switch(action.type){
        case OPENING_DELETE_CASE:
            return {...state, open: true, legalCaseId: action.payload};
        case CLOSING_DELETE_CASE:
            return {...state, open: false, legalCaseId: null};
        case `${DELETE_CASE}_PENDING`:
            return {...state, isProcessing: true};
        case `${DELETE_CASE}_FULFILLED`:
            return {...state, isProcessing: false, open: false, legalCaseId: null};
        case `${DELETE_CASE}_REJECTED`:
            return {...state, isProcessing: false}
        default:
            return state;
    }
}