import {OPEN_PAYMENT_DETAIL_MODAL, CLOSE_PAYMENT_DETAIL_MODAL} from '../../actions/bills/billActions';

const INITIAL_STATE = {
    open: false,
    isFetching: false,
    payments: null,
    canPay: false
}

export default function PaymentsDetailModal(state=INITIAL_STATE,action){
    switch(action.type){
        case `${OPEN_PAYMENT_DETAIL_MODAL}_PENDING`:
            return {...state, isFetching: true};
        case `${OPEN_PAYMENT_DETAIL_MODAL}_FULFILLED`:
            return {...state, isFetching: false, payments: action.payload.data, canPay: action.payload.canPay, open: true};
        case `${OPEN_PAYMENT_DETAIL_MODAL}_REJECTED`:
            return {...state, isFetching: false, payments: null, open: false, canPay: false};
        case CLOSE_PAYMENT_DETAIL_MODAL:
            return {...state, isFetching: false, payments: null, open: false, canPay: false};
        default:
            return state;            
    }
}