import {FINISHING_CASE, OPENING_FINISHING_CASE_MODAL, CLOSING_FINISHING_CASE_MODAL} from '../../actions/legalCases/legalCaseListAction';

const INITIAL_STATE = {
    open: false,
    legalCaseId: null,
    isProcessing: false
}

export default function FinishLegalCaseModalReducer(state=INITIAL_STATE,action){
    switch(action.type){
        case OPENING_FINISHING_CASE_MODAL:
            return {...state, open: true, legalCaseId: action.payload};
        case CLOSING_FINISHING_CASE_MODAL:
            return {...state, open: false, legalCaseId: null, isProcessing: false};
        case `${FINISHING_CASE}_PENDING`:
            return {...state, isProcessing: true};
        case `${FINISHING_CASE}_FULFILLED`:
            return {...state, isProcessing: false, open: false, legalCaseId: null};
        case `${FINISHING_CASE}_REJECTED`:
            return {...state, isProcessing: false, open: false, legalCaseId: null};
        default:
            return state;
    }
}