import authAxios, {reports} from '../../constants/api';
import FileDownload from 'js-file-download';
export const FETCHING_REPORT = 'FETCHING_REPORT';
export const EXPORT_REPORT = 'EXPORT_REPORT';

export function getReport(info){
    return (dispatch, getState) => {
        dispatch({type: `${FETCHING_REPORT}_PENDING`});
        const {auth} = getState();
        let url = '';
        if(info.userId === undefined || info.userId === null){
            url = `${reports}?StartDate=${info.startDate}&EndDate=${info.endDate}&Type=${info.type}`
        }
        else{
            url = `${reports}?StartDate=${info.startDate}&EndDate=${info.endDate}&UserId=${info.userId}&Type=${info.type}`
        }
        authAxios.get(url, {headers: auth.headers})
        .then(response => {
            const labels = response.data.records.map(data => data.label);
            const pieData = response.data.records.map(c => c.percentage);
            return dispatch({
                type: `${FETCHING_REPORT}_SUCCESS`,
                payload: {
                    data: response.data,
                    newColumn: [{key: 'label', label: 'Etiqueta'},
                                {key: 'rawData', label: response.data.rawDataColumnName},
                                {key: 'percentage', label: 'Porcentaje'}],
                    labels,
                    pieData
                }
            });
        })
        .catch(e => {
            console.log(e);
            return dispatch({type: `${FETCHING_REPORT}_REJECTED`, payload: e});
        });
    }
}

export function exportReport(info){
    return(dispatch, getState) => {
        dispatch({type: `${EXPORT_REPORT}_PENDING`});
        const {auth} = getState();
        const reportState = getState().reports;
        authAxios.post(`${reports}`, JSON.stringify(info), {headers: auth.headers, responseType: 'blob'})
        .then(response => {
            dispatch({type: `${EXPORT_REPORT}_SUCCESS`});
            FileDownload(response.data, `${reportState.data.reportName}.xlsx`)
        })
        .catch(e => {
            return dispatch({type: `${EXPORT_REPORT}_REJECTED`, payload: e})
        })
    }
}