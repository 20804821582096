import {CHANGE_OWNER_LEGAL_CASE, OPEN_CHANGE_OWNER_CASE, CLOSE_CHANGE_OWNER_CASE} from '../../actions/legalCases/legalCaseListAction';

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    legalCaseId: null,
    parentClientId: null
}

export default function ChangeOwnerCaseModal(state=INITIAL_STATE,action){
    switch(action.type){
        case OPEN_CHANGE_OWNER_CASE:
            return {...state, open: true, legalCaseId: action.payload.legalCaseId, parentClientId: action.payload.parentClientId};
        case CLOSE_CHANGE_OWNER_CASE:
            return {...state, open: false, legalCaseId: null, parentClientId: null};
        case `${CHANGE_OWNER_LEGAL_CASE}_PENDING`:
            return {...state, isProcessing: true};
        case `${CHANGE_OWNER_LEGAL_CASE}_FULFILLED`:
            return {...state, isProcessing: false, open: false, legalCaseId: null, parentClientId: null};
        case `${CHANGE_OWNER_LEGAL_CASE}_REJECTED`:
            return {...state, isProcessing: false, open: false, legalCaseId: null, parentClientId: null};
        default:
            return state;
    }
}