import React, { Fragment, Component } from 'react';
import { 
  Route,
  Redirect
 } from 'react-router-dom';

 import TopScreen from './topScreen/topScreen';

 import {connect} from 'react-redux';

 import {withStyles} from '@material-ui/core/styles';
import { hasModulePermission } from './../constants/api';
const authenticatedLayout = (props) => (
  <Fragment>
    <TopScreen/>
    <div style={{paddingTop: '85px'}}></div>
    <Component {...props} style={{marginTop: '85px'}}/>
  </Fragment>
)

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
});

const PrivateRoute = ({component: Component, isAuthenticated, userData, onExit, moduleId, classes, ...rest}) => (
    <Route
      {...rest}
      render = {(props) => isAuthenticated === true
        ? hasModulePermission(userData, moduleId)?  (<Fragment>
            <TopScreen userData={userData} onExit={onExit}/>
            <div className={classes.toolbar}/>
            <Component {...props}/>
          </Fragment>) : (<Redirect to={{pathname: '/'}}/>)
        : (<Redirect to={{pathname: '/login', state: {from: props.location}}} />)}
    />
);

// const mapStateToProps = state => ({
//   isAuthenticated: state.auth.isAuthenticated,
// })

export default withStyles(styles)(PrivateRoute);