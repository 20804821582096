import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { ResponsiveTable } from 'material-ui-next-responsive-table';
import { green } from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import { Tooltip } from '@material-ui/core';

import {renderTextField, renderFromHelper, renderCheckbox, renderSelectField, ColorButtonBlue} from '../../constants/customElementsMUI';
import { connect } from 'react-redux';
import { isRootUser } from './../../constants/api';

import {createClient, editingClient, openingClientUserModal, closingClientUserModal,
openingDeletingClientUserModal, closingDeletingClientUserModal, deletingClientUser,
openingUpdatingPasswordModal, closingUpdatingPasswordModal} from '../../actions/clients/ClientListActions';

import {getClients} from '../../actions/catalog/catalogActions';

import ClientUserFormDialog from './clientUserFormDialog';
import DeletingClientUserDialog from './deletingClientUserDialog';
import UpdateClientPasswordDialog from './updateClientPasswordDialog';

const validate = (values, props) => {
    const errors = {};
    let requiredFields = [
        'name',
    ];
    if(values.parentClientId===undefined || values.parentClientId===null){
        requiredFields = requiredFields.concat([
            'contactName',
            'contactEmail'
        ]);
        if (props.editing === false && values.username) {
            requiredFields = requiredFields.concat([
            'password',
            'confirmPassword',
            ]);
        }
    }
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });
    if (values.username && values.password && values.confirmPassword && values.password != values.confirmPassword) {
        errors.confirmPassword = 'Las contraseñas no coinciden*';
    }
    return errors;
}

const styles = theme => ({
    root: {
      width: '100%',
      overflowX: 'auto',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    tableWrapper: {
      width: 'auto',
    },
    button: {
      margin: theme.spacing(3, 0, 2, 3),
      color: 'white'
    },
    horizontalForm: {
      width: '100%',
      overflowY: 'auto',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row'
    },
    gridInput: {
      margin: theme.spacing(1)
    }
});

const required = (value, allValues) => {
    return allValues.parentClientId === undefined && value === undefined ? 'Obligatorio*' : undefined;
}

class FormClientDialog extends Component {

    constructor(props){
        super(props);
    }
    componentDidMount() {
        this.props.getClients();
    }

    
    
    render() {
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;

        const handleClientUserModalOpen = (clientId, userInfo, isEditing) => {
            this.props.openingClientUserModal(clientId, userInfo, isEditing);
        }

        const handleClientUserModalClose = () => {
            this.props.closingClientUserModal();
        }

        const handleClientUserChangePasswordOpen = (clientId, userId) => {
            this.props.openingUpdatingPasswordModal(clientId, userId);
        }

        const handleClientUserChangePasswordClose = () => {
            this.props.closingUpdatingPasswordModal();
        }

        const handleClientUserDeleteOpen = (clientId, userId) => {
            this.props.openingDeletingClientUserModal(clientId, userId);
        }

        const handleClientUserDeleteClose = () => {
            this.props.closingDeletingClientUserModal();
        }

        const handleDeleteConfirmation = () => {
            this.props.deletingClientUser();
        }

        const { classes } = this.props;
        let listItemsClasses = Object.assign({}, classes);

        const newColumns = [
            { key: 'username', label: 'Nombre de Usuario'},
            { key: 'canAccessBills', label: 'Acceso a Bills', render: (value) => value === true ? 'Sí' : 'No'},
            { key: 'canAccessDocuments', label: 'Acceso a documentos', render: (value) => value === true ? 'Sí' : 'No' },
            { key: 'loginEnabled', label: 'Inicio de sesión habilitado', render: (value) => value === true ? 'Sí' : 'No'},
            { key: 'id', label: 'Acciones', render: (value, column) => {
                return value == null || !this.props.isAdmin ? '-' : (
                    <div>
                        <Tooltip title="Editar Usuario">
                          <IconButton onClick={() => handleClientUserModalOpen(column.clientId,{
                              username: column.username,
                              clientId: column.clientId,
                              canAccessBills: column.canAccessBills,
                              canAccessDocuments: column.canAccessDocuments,
                              id: column.id
                          }, true)}>
                            <EditIcon style={{ color: green[500] }}/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cambiar contraseña">
                            <IconButton onClick={() => handleClientUserChangePasswordOpen(column.clientId, column.id)}>
                                <VpnKeyIcon style={{ color: green[500] }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Borrar Usuario">
                          <IconButton onClick={() => handleClientUserDeleteOpen(column.clientId, column.id)}>
                            <DeleteIcon style={{ color: green[500] }} />
                          </IconButton>
                        </Tooltip>                        
                    </div>
                )
            }}
        ];

        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                    disableBackdropClick={this.props.isProcessing}
                    disableEscapeKeyDown={this.props.isProcessing}
                    maxWidth='xl'
                    fullWidth={true}
                >
                    <DialogTitle id="form-dialog-title">{this.props.editing === false ? "Nuevo" : this.props.readOnly ? "Ver" : "Editar"} Cliente</DialogTitle>
                    <form onSubmit={handleSubmit(this.props.editing === false ? this.props.createClient : this.props.editingClient)} init noValidate autoComplete='off'>
                    <DialogContent>
                        <DialogContentText>
                            Para crear un nuevo cliente, favor llenar los siguientes datos:
                        </DialogContentText>
                        <div>
                            <Field
                                label="Nombre"
                                name="name"
                                type="text"
                                component={renderTextField}
                                disabled={this.props.readOnly}
                            />
                        </div>
                        <div>
                            <Field
                                label="Teléfono"
                                name="phoneNumber"
                                type="text"
                                component={renderTextField}
                                disabled={this.props.readOnly}
                            />
                        </div>
                        <div>
                            <Field
                                label="Correo"
                                name="email"
                                type="email"
                                component={renderTextField}
                                disabled={this.props.readOnly}
                            />
                        </div>
                        <div>
                            <Field
                                label="Dirección"
                                name="address"
                                type="text"
                                component={renderTextField}
                                disabled={this.props.readOnly}
                            />
                        </div>
                        <div>
                            <Field
                                label="Nombre del contacto"
                                name="contactName"
                                type="text"
                                component={renderTextField}
                                disabled={this.props.readOnly}
                            />
                        </div>
                        <div>
                            <Field
                                label="Teléfono del contacto"
                                name="contactPhoneNumber"
                                type="text"
                                component={renderTextField}
                                disabled={this.props.readOnly}
                            />
                        </div>
                        <div>
                            <Field
                                label="RUC / No. tributario"
                                name="tributaryNumber"
                                type="text"
                                component={renderTextField}
                                disabled={this.props.readOnly}
                            />
                        </div>
                        <div>
                            <Field
                                label="Correo del contacto"
                                name="contactEmail"
                                type="email"
                                component={renderTextField}
                                disabled={this.props.readOnly}
                            />
                        </div>
                        {/*{((this.props.isClientParentSelected === undefined ||this.props.isClientParentSelected === null)|| (this.props.client != null && this.props.client.parentClientId == null)) && (
                            <div>
                            <Field
                                label="Usuario"
                                name="username"
                                type="text"
                                component={renderTextField}
                                disabled={this.props.readOnly}
                            />
                        </div>
                        )}
                        {(this.props.isClientParentSelected === undefined ||this.props.isClientParentSelected === null) && this.props.readOnly == false && this.props.editing === false
                         && 
                            (
                            <div>
                                
                                <div>
                                    <Field
                                        label="Clave"
                                        name="password"
                                        type="password"
                                        component={renderTextField}
                                        disabled={this.props.readOnly}
                                    />
                                </div>
                                <div>
                                    <Field
                                        label="Confirmar clave"
                                        name="confirmPassword"
                                        type="password"
                                        component={renderTextField}
                                        disabled={this.props.readOnly}
                                    />
                                </div>
                            </div>)
                        }*/}
                        {((this.props.editing == false && this.props.readOnly == false) || (this.props.client != null && this.props.client.parentClientId != null)) && (
                            <div>
                            <Field
                                name="parentClientId"
                                component={renderSelectField}
                                label="Cliente Padre"
                                disabled={this.props.readOnly || this.props.editing == true}
                            >
                                <option key={0} value={null}></option>
                                {this.props.clients !== undefined ? this.props.clients.map(client => {
                                    return(
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    )
                                }) : undefined}
                            </Field>    
                        </div>
                        )}
                        {/*
                        {((this.props.isClientParentSelected === undefined ||this.props.isClientParentSelected === null) ||(this.props.client != null && this.props.client.parentClientId == null)) &&(
                            <div>
                                <div>
                                    <Field name="canAccessBills" component={renderCheckbox} label="Acceso a sus Bills" disabled={this.props.readOnly}/>
                                </div>
                                <div>
                                    <Field name="canAccessDocuments" component={renderCheckbox} label="Acceso a sus documentos" disabled={this.props.readOnly}/>
                                </div> 
                            </div>
                        )}*/}
                        {(this.props.client && this.props.client.id !== undefined && this.props.client.id !== null) && (this.props.isClientParentSelected === undefined ||this.props.isClientParentSelected === null) && (<div>
                            {this.props.isAdmin && (
                                <ColorButtonBlue variant="contained" className={classes.button} onClick={() => handleClientUserModalOpen(this.props.client.id, null, false)}>Nuevo Usuario de Cliente</ColorButtonBlue>
                            )}
                            <Paper>
                                <ResponsiveTable
                                    excludePrimaryFromDetails={true}
                                    columns={newColumns}
                                    data={this.props.client.users || []}
                                    noContentText={'No hay usuarios creados'}
                                    TableProps={{dense: true, table: "true", size: "small"}}
                                    TableHeadCellProps= {{align: 'center', style: {"fontWeight": "bold", "backgroundColor": "#D3D3D3", color: "#60b044"}}}
                                    TableBodyCellProps={{align: 'center'}}
                                    
                                    ExpansionPanelSummaryTypographyProps={{style: {"fontWeight": "bold"}}}
                                    ExpansionPanelDetailsTypographyProps={{ classes:listItemsClasses}}
                                />
                            </Paper>
                         </div>)}                                               
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" disabled={submitting || this.props.isProcessing}>
                            Cancelar
                        </Button>
                        {!this.props.readOnly && (
                            <Button type="submit" color="primary" disabled={pristine||submitting || this.props.isProcessing}>
                                Guardar
                            </Button>
                        )}
                    </DialogActions>
                    </form>
                </Dialog>       
            </div>
        )
    }
}

const selector = formValueSelector('ClientForm');

const mapStateToProps = (state, props) => {
    const {clients} = state.catalog;
    const {user} = state.auth;
    let isAdmin = isRootUser(user);
    const isClientParentSelected = selector(state, 'parentClientId');
    const initialValues = state.clientModal.client;
    const {readOnly, isProcessing} = state.clientModal;
    const {clientUserFormModal, deletingClientUserModal, updatingClientPasswordModal} = state;
    return {clients, isAdmin, isClientParentSelected, initialValues, readOnly, isProcessing, client: state.clientModal.client,
    clientUserFormModal, deletingClientUserModal, updatingClientPasswordModal};
}

const mapDispatchToProps = {
    createClient,
    getClients,
    editingClient,
    openingClientUserModal,
    closingClientUserModal,
    openingUpdatingPasswordModal,
    closingUpdatingPasswordModal,
    openingDeletingClientUserModal,
    closingDeletingClientUserModal,
    deletingClientUser
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(reduxForm({form:'ClientForm', validate, enableReinitialize: true})(FormClientDialog)));