import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {green} from '@material-ui/core/colors';
import {Field, reduxForm} from 'redux-form';

import Logo from '../assets/logo.png'
import {ColorButton, GreenLink, BorderedGrid, renderTextField, renderCheckbox} from '../constants/customElementsMUI';
import { Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';

import {login, storeToken, initAuth} from '../actions/LoginActions';


const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  typography: {
      color: green[500],
  },
});

const validate = values => {
  const errors = {};
  const requiredFields = [
    'user',
    'password',
  ];
  requiredFields.forEach(field => {
    if(!values[field]){
      errors[field] = 'Obligatorio*';
    }
  });
  /*if(values.user && emailRegex.test(values.email)){
    errors.email = 'Usuario inválido';
  }*/
  return errors;
}

class Login extends Component {
  constructor(props){
    super(props);
    this.state = {
      user: '',
      password: '',
      formValid: false,
      loginError: false
    };
  }

  componentDidMount() {
    this.props.initAuth();
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isAuthenticated){
      let routeTo;
      if(this.props.location.state && this.props.location.state.from){
        routeTo = this.props.location.state.from.pathname;
      }
      else{
        routeTo = '/';
      }
      return this.props.history.push(routeTo);
    }
  }

  setFormInvalid = () => {
    this.setState({
      formValid: false,
    });
  }

  setFormValid = () => {
    this.setState({
      formValid: true,
    });
  }

  handleChange = (e, {name, value}) => {
    let field = {};
    field[name] = value;
    this.setState(field);
  }

  doLogin = (formValues) => {
    return this.props.login(formValues)
  }
  


  render() {
    const {handleSubmit, pristine, reset, submitting, classes} = this.props;
    return(
      <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh'}}
      >
          <BorderedGrid item xs={12} sm={12} md={4} lg={4}>
            <div className={classes.paper}>
                <img src={Logo}/>
                <Typography component="h1" variant="h5" className={classes.typography}>
                Inicio de sesión
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit(this.props.login)} noValidate autoComplete="off">
                <div>
                  <Field
                      label="Usuario"
                      name="username"
                      type="text"
                      component={renderTextField}
                  />
                </div>
                <div>
                  <Field
                    label="Contraseña"
                    name="password"
                    type="password"
                    component={renderTextField}
                  />
                </div>
                <div>
                  <Field name="isClient" component={renderCheckbox} label="Soy un Cliente"/>
                </div>
                {/*
                <CssTextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                />
                */}
                {/*
                <FormControlLabel
                    color='primary'
                    control={<GreenCheckbox checked={this.state.isClient} onChange={this.handleChange}/>}
                    label="Soy cliente"
                />
                */}
                <div>
                  <ColorButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={{color: "white"}}
                      className={classes.submit}
                      disabled={pristine || submitting}
                  >
                      Iniciar sesión
                  </ColorButton>
                </div>
                <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={this.props.loginError}
                autoHideDuration={3000}
                ContentProps={{
                  'aria-describedby': 'message-id'
                }}
                message={<span id="message-id">Usuario o contraseña incorrectos</span>}/>
                <Grid container >
                    <Grid item xs style={{textAlign: "center"}}>
                        <GreenLink href="/passwordReset" variant="body2">
                            Olvidé mi contraseña
                        </GreenLink>
                    </Grid>
                </Grid>
                </form>
            </div>
          </BorderedGrid>
      </Grid>
    )     
  };
}
const mapStateToProps = state => {
  const {loginError, isAuthenticated, user} = state.auth;
  return {user, isAuthenticated, loginError};
}

const mapDispatchToProps = {
  login,
  storeToken,
  initAuth
};
export default withStyles(styles)(reduxForm({form:'LoginForm', validate, login})(connect(mapStateToProps, mapDispatchToProps)(Login)));