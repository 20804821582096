import {decode} from 'jsonwebtoken';
import axios from 'axios';
import {authentication, SESSION_NAME} from '../constants/api';

export const RESET_LOGIN = 'RESET_LOGIN';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_REJECTED = 'LOGIN_REQUEST_REJECTED';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const STORING_TOKEN = 'STORING_TOKEN';
export const TOKEN_STORED = 'TOKEN_STORED';
export const CHECKING_SESSION = 'CHECKING_SESSION';
export const REINITIALIZING_SESSION = 'REINITIALIZING_SESSION';
export const NO_SESSION_FOUND = 'NO_SESSION_FOUND';
export const SETTING_HEADERS = 'SETTING_HEADERS';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const DISABLE_MUST_CHANGE_PWD = 'DISABLE_MUST_CHANGE_PWD';
export const SET_IS_ACCOUNT_LOCKED = 'SET_IS_ACCOUNT_LOCKED';


export function resetLogin(){
    return {type: RESET_LOGIN}
}
export function disableMustChangePwd(){
    return {type: DISABLE_MUST_CHANGE_PWD}
}

const headers = {
  headers:{
    'Content-Type':'application/json'
  }
};

export function login(info){
    return dispatch => {
        if(!info.isClient){
          info.isClient = false;
        }
        axios.post(authentication, JSON.stringify(info), headers)
        .then((response) => {
          if(response.data.isValid === true){
            dispatch({
              type: `${LOGIN_REQUEST}_FULFILLED`,
              payload: {
                  user: decode(response.data.token),
                  token: response.data.token,
                  headers: setHeaders(response.data.token),
                  headersMultipart: setMultipartHeaders(response.data.token)
              }
            });
            return dispatch(storeToken());
          }
          else{
            return dispatch({
              type: `${LOGIN_REQUEST}_REJECTED`,
          });
          }
        })
        .catch(e => {
            return dispatch({
                type: `${LOGIN_REQUEST}_REJECTED`,
                payload: e
            });
        });
    }
}

function setHeaders(token){
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
}

function setMultipartHeaders(token){
  return {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${token}`
  }
}

function requestLogout(){
    return {
      type: LOGOUT_REQUEST,
      isFetetching: true,
      isAuthenticated: true
    } 
}
  
function receiveLogout(){
    return {
      type: LOGOUT_SUCCESS,
      isFetching: false,
      isAuthenticated: false
    }
}

export function storeToken(){
    return (dispatch, getState) => {
      dispatch({ type: STORING_TOKEN })
      let { token } = getState().auth;
      window.sessionStorage.setItem(SESSION_NAME, token)
      dispatch({ type: TOKEN_STORED })
    }
}

export function initAuth(){
    return dispatch => {
      dispatch({ type: CHECKING_SESSION })
      let token = window.sessionStorage.getItem(SESSION_NAME);
      if(token) {
        return dispatch({ 
          type: REINITIALIZING_SESSION,
          token: token,
          user: decode(token),
          headers: setHeaders(token),
          headersMultipart: setMultipartHeaders(token)
        })
      }
      return dispatch({ type: NO_SESSION_FOUND })
    }
  }

export function logoutUser(){
    return dispatch => {
      dispatch(requestLogout());
      window.sessionStorage.removeItem(SESSION_NAME)
      dispatch(receiveLogout())
    }
}