import axios from 'axios';
// const apiURL = 'https://api.lexnica.com/LexNicaApiTesting/api/';
//const apiURL = 'https://localhost:44355/api/';
const apiURL = 'https://api.lexnica.com/LexNicaApi/api/';
export const SESSION_NAME = 'lexnica';
//Authentication
export const authentication = `${apiURL}Authentication`;
export const recoverPassword = `${authentication}/passwordReset`;
//Classifications
export const classifications = `/Classifications`;
//ClientDocuments
export const clientDocuments = `/ClientDocuments`;
//Clients
export const clients = `/Clients`;
//DocumentTypes
export const documentTypes = `/DocumentTypes`;
//SpecialAccesses
export const specialAccesses = `/SpecialAccesses`;
//Users
export const users = `/Users`;
//UserTypes
export const userTypes = `/UserTypes`;
//LegalCases
export const legalCases = '/LegalCases'
//CaseTypes
export const caseTypes = '/CaseTypes';
//Bills
export const bills = '/Bills';
//PaymentTypes
export const paymentTypes = '/PaymentTypes/catalogue'
//PaymentWay
export const paymentWays = '/PaymentWay/catalogue';
//Reports
export const reports = '/Reports/filtered';

//the common modules are clients, documents only for users and cases
export const modules = {
  clients: 1,
  cases: 2,
  documentTypes: 3,
  documents: 4,
  classifications: 5,
  bills: 6,
  reports: 7,
  users: 8

};

export const specialAccessCodes = {
  bills: 0,
  users: 1,
  reports: 2,
  documentClassifications: 3,
  documentTypes: 4
};

export const isRootUser = (userData) => {
  return userData != null && userData.IsClient == "false" && userData.RoleCode == "0";
}
export const isNonClient = (userData) => {
  return userData != null && userData.IsClient == "false";
}

export const hasModulePermission = (userData, moduleId) => {
  let hasPermission = false;
  switch(moduleId) {
    case modules.clients:
      hasPermission = true;
      break;
    case modules.cases:
      hasPermission = true;
      break;
    case modules.documents:
      hasPermission = userData.RoleCode == "0" || userData.IsClient == "false" || userData.ClientCanAccessDocuments == "true";
      break;
    case modules.classifications:
      hasPermission = userData.RoleCode == "0" || (userData.IsClient == "false" && userData.SpecialAccesses != undefined && userData.SpecialAccesses.indexOf(specialAccessCodes.documentClassifications.toString()) != -1 );
      break;

    case modules.bills:
      hasPermission = userData.RoleCode == "0" 
        || (userData.IsClient == "false" && userData.SpecialAccesses != undefined && userData.SpecialAccesses.indexOf(specialAccessCodes.bills.toString()) != -1 )
        || (userData.IsClient == "true" && userData.ClientCanAccessBills == "true");
      break;
    case modules.reports:
      hasPermission = userData.RoleCode == "0" 
        || (userData.IsClient == "false" && userData.SpecialAccesses != undefined && userData.SpecialAccesses.indexOf(specialAccessCodes.reports.toString()) != -1 );
      break;
    case modules.documentTypes:
      hasPermission = userData.RoleCode == "0" 
        || (userData.IsClient == "false" && userData.SpecialAccesses != undefined && userData.SpecialAccesses.indexOf(specialAccessCodes.documentTypes.toString()) != -1 );
      break;
      case modules.users:
      hasPermission = userData.RoleCode == "0" 
        || (userData.IsClient == "false" && userData.SpecialAccesses != undefined && userData.SpecialAccesses.indexOf(specialAccessCodes.users.toString()) != -1 );
      break;

    
    default: 
    hasPermission = true;
    break;
  }
  return hasPermission;
}

// Set config defaults when creating the instance
const authAxios = axios.create({
  baseURL: apiURL
});

// Add a request interceptor
authAxios.interceptors.request.use(function (config) {
  const token = window.sessionStorage.getItem(SESSION_NAME)

  if ( token != null ) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export default authAxios;

