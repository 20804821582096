import authAxios, {legalCases} from './../../constants/api';
import {reset} from 'redux-form';
import moment from 'moment';

export const GET_COMMENTS_LIST = 'GET_COMMENTS_LIST';
export const OPEN_COMMENT_MODAL = 'OPEN_COMMENT_MODAL';
export const CLOSE_COMMENT_MODAL = 'CLOSE_COMMENT_MODAL';
export const CREATE_NEW_COMMENT = 'CREATE_NEW_COMMENT';
export const CHANGE_WILL_NOTIFY = 'CHANGE_WILL_NOTIFY';

export function getComments(id){
    return(dispatch, getState) => {
        const {auth} = getState();
        dispatch({type: `${GET_COMMENTS_LIST}_PENDING`});
        authAxios.get(`${legalCases}/${id}/commentsHistory`, {headers: auth.headers})
        .then(response => {
            response.data.comments.map(comment => comment.createdAt = moment.utc(comment.createdAt).local().format('DD/MM/YYYY'));
            return dispatch({type: `${GET_COMMENTS_LIST}_FULFILLED`, payload: response.data});
        })
        .catch(e => {
            return dispatch({type: `${GET_COMMENTS_LIST}_REJECTED`, payload: e});
        });
    }
}

export function openCommentModal(){
    return dispatch => {
        return dispatch({type: OPEN_COMMENT_MODAL});
    }
}

export function closeCommentModal(){
    return dispatch => {
        return dispatch({type: CLOSE_COMMENT_MODAL});
    }
}

export function createNewComment(info){
    return (dispatch, getState) => {
        const {auth, comments} = getState();
        dispatch({type: `${CREATE_NEW_COMMENT}_PENDING`});
        authAxios.post(`${legalCases}/${comments.legalCaseId}/comments`, info, {headers: auth.headersMultipart})
        .then(response => {
            dispatch({type: `${CREATE_NEW_COMMENT}_FULFILLED`});
            dispatch(reset('NewCommentForm'));
            return dispatch(getComments(comments.legalCaseId));
        })
        .catch(e => {
            return dispatch({type: `${CREATE_NEW_COMMENT}_REJECTED`, payload: e});
        });
    }
}

export function willNotify(){
    return(dispatch, getState) => {
      const {newCommentModal} = getState();
      return dispatch({type: CHANGE_WILL_NOTIFY, payload: !newCommentModal.shouldNotify});
    }
  }