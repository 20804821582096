import {CREATING_NEW_DOCUMENT, OPENING_DOCUMENT_MODAL, CLOSING_DOCUMENT_MODAL, GETTING_CHILD_CLIENTS, RESETTING_CHILD_CLIENTS} from './../../actions/documents/DocumentListActions'

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    childClients: []
}

export default function DocumentModal(state=INITIAL_STATE, action){
    switch(action.type){
        
        case `${CREATING_NEW_DOCUMENT}_REQUESTED`:
            return {...state, isProcessing: true};
        case `${CREATING_NEW_DOCUMENT}_FULFILLED`:
            return {...state, open: false, isProcessing: false};
            
        case `${CREATING_NEW_DOCUMENT}_REJECTED`:
        return {...state, open: false, isProcessing: false};
        case OPENING_DOCUMENT_MODAL:
            return {...state, open: true};
        case CLOSING_DOCUMENT_MODAL:
            return {...state, open: false, childClients: [], isProcessing: false};
        case `${GETTING_CHILD_CLIENTS}_FULFILLED`:
            return {...state, childClients: action.payload};
        case RESETTING_CHILD_CLIENTS:
            return {...state, childClients: []};
        default:
            return state;
    }
}