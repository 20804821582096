import {
    FETCH_CLASSIFICATIONS_START,
    FETCH_CLASSIFICATIONS_SUCCESS,
    FETCH_CLASSIFICATIONS_FAILURE,
    SET_CLASSIFICATIONS_CRITERIA,
    SET_CLASSIFICATIONS_CURRENT_PAGE,
    SET_CLASSIFICATIONS_PAGE_SIZE
  }
  
    from './../../actions/classifications/ClassificationListActions';

const INITIAL_STATE = {
    fetchError: false,
    isFetchingList: false,
    criteria: '',
    data: [],
    totalCount: 0,
    pageSize: 25,
    totalPages: 0,
    currentPage: 0
};


export default function ClassificationsList(state = INITIAL_STATE, action) {
    switch (action.type) {
      case SET_CLASSIFICATIONS_CRITERIA:
        return { ...state, criteria: action.payload, currentPage: 0 };
      case SET_CLASSIFICATIONS_CURRENT_PAGE:
        return { ...state, currentPage: action.payload };
      case SET_CLASSIFICATIONS_PAGE_SIZE:
        return { ...state, pageSize: action.payload, currentPage: 0 };
      case FETCH_CLASSIFICATIONS_START:
        return { ...state, isFetchingList: true, fetchError: false };
      case FETCH_CLASSIFICATIONS_SUCCESS:
        return {
          ...state,
          isFetchingList: false,
          fetchError: false,
          data: action.payload.records,
          totalCount: action.payload.totalCount,
          totalPages: action.payload.totalPages,
        };
      case FETCH_CLASSIFICATIONS_FAILURE:
        return { ...state, isFetchingList: false, fetchError: true, data: [], totalCount: 0, currentPage: 0, totalPages: 0 };
  
      default:
        return state;
    }
  }