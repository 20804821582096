import {OPEN_DELETE_PAYMENT, CLOSE_DELETE_PAYMENT, DELETE_PAYMENT} from '../../actions/bills/billActions';

const INITIAL_STATE = {
    open: false,
    paymentId: null,
    isProcessing: false
}

export default function DeletePaymentModal(state=INITIAL_STATE, action){
    switch(action.type){
        case OPEN_DELETE_PAYMENT:
            return {...state, open: true, paymentId: action.payload};
        case CLOSE_DELETE_PAYMENT:
            return {...state, open: false, paymentId: null, isProcessing: false};
        case `${DELETE_PAYMENT}_PENDING`:
            return {...state, isProcessing: true};
        case `${DELETE_PAYMENT}_FULFILLED`:
            return {...state, isProcessing: false, open: false, paymentId: null};
        case `${DELETE_PAYMENT}_REJECTED`:
            return {...state, isProcessing: false};
        default:
            return state;
    }
}