import {CREATE_NEW_PAYMENT, OPEN_NEW_PAYMENT_MODAL, CLOSE_NEW_PAYMENT_MODAL} from '../../actions/bills/billActions';

const INITIAL_STATE = {
    open: false,
    isFetching: false,
    isProcessing: false,
    billId: null,
    payments: null
}

export default function NewPaymentModal(state=INITIAL_STATE,action){
    switch(action.type){
        case `${OPEN_NEW_PAYMENT_MODAL}_PENDING`:
            return {...state, isFetching: true};
        case `${OPEN_NEW_PAYMENT_MODAL}_FULFILLED`:
            return {...state, isFetching: false, open: true, billId: action.payload.id, payments: action.payload.data};
        case `${OPEN_NEW_PAYMENT_MODAL}_REJECTED`:
            return {...state, isFetching: false, open: false, billId: null, payments: null};
        case CLOSE_NEW_PAYMENT_MODAL:
            return {...state, open: false, billId: null, isProcessing: false, payments: null};
        case `${CREATE_NEW_PAYMENT}_PENDING`:
            return {...state, isProcessing: true};
        case `${CREATE_NEW_PAYMENT}_FULFILLED`:
            return {...state, isProcessing: false, open: false, billId: null};
        case `${CREATE_NEW_PAYMENT}_REJECTED`:
            return {...state, isProcessing: false, billId: null};
        default:
            return state;
    }
}