import React, {  Component } from "react"
import {withRouter} from "react-router-dom";
import FileViewer from 'react-file-viewer';
import queryString from 'query-string';
import Grid from '@material-ui/core/Grid';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {green} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { PDFReader, MobilePDFReader  } from 'reactjs-pdf-reader';

const FilePreview = (props) => {
    let queryValues = queryString.parse(props.location.search);
    
    const handleDownload = (url) => {
        window.open(url);
    }
    return (
      <div id='fileViewerContainer'>
          <div style={{display: "inline-flex"}}>
            <h2 className="alt-header">{queryValues && queryValues.name}</h2>
            
            <IconButton onClick={() => handleDownload(atob(queryValues.url))}>
                            <CloudDownloadIcon style={{ color: green[500] }}/>
                          </IconButton>
            
          </div>
        {['pdf'].indexOf(queryValues.fileType && queryValues.fileType.toLowerCase())!== -1 && (
          <PDFReader  url={atob(queryValues.url)} showAllPage={true}></PDFReader >
        )}

        {[ 'csv', 'xslx', 'docx'].indexOf(queryValues.fileType && queryValues.fileType.toLowerCase()) !== -1 && (
            <FileViewer
            fileType={queryValues.fileType.toLowerCase()}
            filePath={atob(queryValues.url)}
            />
        )}
        {['png', 'jpeg', 'gif', 'bmp'].indexOf(queryValues.fileType && queryValues.fileType.toLowerCase()) !== -1 && (
            <Grid container>
            <Grid item xs={12}>
                
                    <img style={{width: "100%"}} src={atob(queryValues.url)}></img>
                    
            </Grid>
            </Grid>
                
        )}
        
      </div>
    );
  };
  
  export default withRouter(FilePreview);