import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { Divider } from '@material-ui/core';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import FormLabel from '@material-ui/core/FormLabel';
import {renderTextField, renderFromHelper, renderCheckbox, renderSelectField} from '../../constants/customElementsMUI';
import { connect } from 'react-redux';
import {createUser, editingUser, addClientToBanList, removeClientFromBanList} from '../../actions/users/UserListActions';
import { getUserTypes, getSpecialAccesses, getClients } from './../../actions/catalog/catalogActions';
import { isRootUser, isNonClient } from './../../constants/api';
const validate = (values, props) => {
    const errors = {};
    let requiredFields = [
        'firstName',
        'surname',
        'email',
        'typeId',
        'username',
    ];
    if(props.editing === false){
        requiredFields = requiredFields.concat([
            'password',
            'confirmPassword',
        ]);
    }
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });

    if (values.password && values.confirmPassword && values.password != values.confirmPassword) {
        errors.confirmPassword = 'Las contraseñas no coinciden*';
    }
    /*if(values.user && emailRegex.test(values.email)){
    errors.email = 'Usuario inválido';
    }*/
    return errors;
}



class FormUserDialog extends Component {

    constructor(props){
        super(props);
    }
    componentDidMount() {
        this.props.getClients();
        this.props.getUserTypes();
        this.props.getSpecialAccesses();
    }
    
    render() {
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;
        
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}
                scroll={'paper'}
                    maxWidth={'md'}>
                    <DialogTitle id="form-dialog-title">{this.props.editing === false ? "Nuevo" : "Editar"} Usuario</DialogTitle>
                    <form onSubmit={handleSubmit(this.props.editing === false ? this.props.createUser : this.props.editingUser)} init noValidate autoComplete='off'>
                    <DialogContent>
                        <DialogContentText>
                            Para {this.props.editing === false ? "crear" : "editar"} {this.props.editing === false ? "un nuevo" : "al"} usuario, favor llenar los siguientes datos:
                        </DialogContentText>
                        <div>
                            <Field
                                label="Primer Nombre"
                                name="firstName"
                                type="text"
                                disabled={this.props.isReadOnly}
                                component={renderTextField}
                            />
                        </div>
                        <div>
                            <Field
                                label="Segundo Nombre"
                                name="secondName"
                                type="text"
                                disabled={this.props.isReadOnly}
                                component={renderTextField}
                            />
                        </div>
                        <div>
                            <Field
                                label="Primer Apellido"
                                name="surname"
                                type="text"
                                disabled={this.props.isReadOnly}
                                component={renderTextField}
                            />
                        </div>
                        <div>
                            <Field
                                label="Segundo Apellido"
                                name="secondSurname"
                                type="text"
                                disabled={this.props.isReadOnly}
                                component={renderTextField}
                            />
                        </div>
                        <div>
                            <Field
                                label="Correo"
                                name="email"
                                type="email"
                                disabled={this.props.isReadOnly}
                                component={renderTextField}
                            />
                        </div>
                        <Field
                                name="typeId"
                                component={renderSelectField}
                                label="Tipo de usuario"
                                disabled={this.props.isReadOnly}
                            >
                                <option key={0} value={null}></option>
                                {this.props.userTypes !== undefined ? this.props.userTypes.map(user => {
                                    return(
                                        <option key={user.id} value={user.id}>{user.name}</option>
                                    )
                                }) : undefined}
                        </Field> 
                        <div>
                                    <Field
                                        label="Usuario"
                                        name="username"
                                        type="text"
                                        disabled={this.props.isReadOnly}
                                        component={renderTextField}
                                    />
                                </div>
                        {this.props.editing === false && 
                            (
                            <div>
                                <div>
                                    <Field
                                        label="Clave"
                                        name="password"
                                        type="password"
                                        disabled={this.props.isReadOnly}
                                        component={renderTextField}

                                    />
                                </div>
                                <div>
                                    <Field
                                        label="Confirmar clave"
                                        name="confirmPassword"
                                        type="password"
                                        disabled={this.props.isReadOnly}
                                        component={renderTextField}
                                    />
                                </div>
                            </div>)
                        }
                        <div>
                            <Field
                                label="Observación"
                                name="observation"
                                type="text"
                                disabled={this.props.isReadOnly}
                                component={renderTextField}
                            />
                        </div>
                        
                        <div>
                            {this.props.isReadOnly ? (
                                <Field name="loginEnabled" component={renderCheckbox} label="Login habilitado" 
                                disabled
                                />
                            ) : (
                                <Field name="loginEnabled" component={renderCheckbox} label="Login habilitado" 
                            
                            />
                            )}
                            
                        </div>
                        {!this.props.isAdminSelected && (
                            <div>
                                <div>
                                <FormLabel component="legend">Accesos especiales</FormLabel>
                                </div>
                                <div>
                                    <Field name="canAccessBills" 
                                        disabled={this.props.isReadOnly}
                                        component={renderCheckbox} label="Bills"/>
                                </div>
                                <div>
                                    <Field name="canAccessClassifications"
                                        disabled={this.props.isReadOnly} component={renderCheckbox} label="Clasificaciones" />
                                </div>
                                <div>
                                    <Field name="canAccessReports" 
                                        disabled={this.props.isReadOnly} component={renderCheckbox} label="Reportes" />
                                </div>
                                <div>
                                    <Field name="canAccessDocumentTypes" 
                                        disabled={this.props.isReadOnly} component={renderCheckbox} label="Tipos de documentos" />
                                </div>
                                <div>
                                    <Field name="canAccessUsers" 
                                        disabled={this.props.isReadOnly} component={renderCheckbox} label="Usuarios" />
                                </div>
                                
                            </div> 

                        )}
                        {this.props.isAdmin && !this.props.isReadOnly && (
                            <div>
                                <Field
                                    name="bannedClientId"
                                    component={renderSelectField}
                                    label="Cliente a no visualizar"
                                >
                                    <option key={0} value={null}></option>
                                    {this.props.clients !== undefined ? this.props.clients
                                    .filter(client => ((this.props.user && this.props.user.bannedClients) || []).some(v => v.id == client.id) == false)
                                    .map(client => {
                                        return(
                                            <option key={client.id} value={client.id}>{client.name}</option>
                                        )
                                    }) : undefined}
                                </Field> 
                                <Button onClick={() => {this.props.addClientToBanList(this.props.selectedClientIdToBan)}} color="primary">
                                    Agregar a la lista
                                </Button>
                            </div>
                        )}
                        <div>
                            <h2>Clientes no visibles</h2>
                            <List>
                            <Divider />
                            {((this.props.user && this.props.user.bannedClients) || []).length > 0 && ((this.props.user && this.props.user.bannedClients) || [])
                            .sort( (a, b) => a.name.localeCompare(b.name, 'es', {'sensitivity': 'base'}))
                            .map(value => (
                                <div>
                                    <ListItem>
                                        <ListItemText
                                            primary={value.name}
                                        />
                                        {this.props.isAdmin && !this.props.isReadOnly &&
                                            (
                                                <ListItemSecondaryAction onClick ={() => {this.props.removeClientFromBanList(value.id)}}>
                                                    <IconButton edge="end" aria-label="delete">
                                                    <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            )
                                        }
                                        
                                    </ListItem>
                                    <Divider />
                                </div>
                                

                            ))}
                            </List>
                        </div>                                             
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        {!this.props.isReadOnly && (
                            <Button type="submit" color="primary" disabled={submitting}>
                            Guardar
                            </Button>
                        )}
                    </DialogActions>
                    </form>
                </Dialog>       
            </div>
        )
    }
}

const selector = formValueSelector('UserForm');

const mapStateToProps = (state, props) => {
    const {userTypes, specialAccesses, clients} = state.catalog;
    const initialValues = state.userModal.user;
    const isReadOnly = state.userModal.readOnly;
    const isAdminSelected = selector(state, 'typeId') == 1;
    const selectedClientIdToBan = selector(state, 'bannedClientId');
    let isAdmin = isRootUser(state.auth.user);
    return {userTypes, clients, specialAccesses, selectedClientIdToBan, initialValues, isAdminSelected, isReadOnly, user: state.userModal.user, isAdmin};
}

const mapDispatchToProps = {
    createUser,
    editingUser,
    getUserTypes,
    getClients,
    getSpecialAccesses,
    removeClientFromBanList,
    addClientToBanList
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form:'UserForm', validate, enableReinitialize: false})(FormUserDialog));