import {OPENING_DELETE_DOCUMENT_TYPE, CLOSING_DELETE_DOCUMENT_TYPE, DELETE_DOCUMENT_TYPE} from '../../actions/documentTypes/DocumentTypesListActions';

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    documentTypeId: null
}

export default function(state=INITIAL_STATE, action){
    switch(action.type){
        case OPENING_DELETE_DOCUMENT_TYPE:
            return {...state, open: true, documentTypeId: action.payload}
        case CLOSING_DELETE_DOCUMENT_TYPE:
            return {...state, open: true, documentTypeId: null};
        case `${DELETE_DOCUMENT_TYPE}_PENDING`:
            return {...state, isProcessing: true};
        case `${DELETE_DOCUMENT_TYPE}_FULFILLED`:
            return {...state, isProcessing: false, open: false, documentTypeId: null};
        case `${DELETE_DOCUMENT_TYPE}_REJECTED`:
            return {...state, isProcessing: false};
        default:
            return state;
    }
}