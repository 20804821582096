import React, { Component } from 'react'
import debounce from 'lodash/debounce';

import Container from '@material-ui/core/Container';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';

import { renderTextField, renderSelectField } from './../../constants/customElementsMUI';
import { Field, reduxForm } from 'redux-form';

import {ColorButtonBlue} from '../../constants/customElementsMUI';

import { getPagedBills, initPagedBills, handleChangePage, handleChangeRowsPerPage, handleSearch, handleParentClient, 
    handleChildClient, handleYear, handleMonth, openingBillModal, closingBillModal, openEditBillModal,
    openBillStatusModal, closeBillStatusModal, updateBillStatus, openUploadNewInvoice, closeUploadNewInvoice,
    openInvoiceNotification, closeInvoiceNotification, invoiceNotification, openNewPaymentModal, closeNewPaymentModal,
    openPaymentDetailModal, closePaymentDetailModal} from './../../actions/bills/billActions';

import {getClients, getChildClients, clearChildClients} from '../../actions/catalog/catalogActions';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import EditIcon from '@material-ui/icons/Edit';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import IconButton from '@material-ui/core/IconButton';

import BillDialog from './billDialog';
import ChangeBillStatusDialog from './changeBillStatusDialog';
import UploadNewInvoiceDialog from './uploadNewInvoiceDialog';
import InvoiceNotifyDialog from './invoiceNotifyDialog';
import NewPaymentDialog from './newPaymentDialog';
import PaymentDetailDialog from './paymentDetailDialog';

import Grid from '@material-ui/core/Grid';
import { green } from '@material-ui/core/colors';
import { isRootUser, isNonClient, hasModulePermission, modules } from './../../constants/api';
import { ResponsiveTable } from 'material-ui-next-responsive-table';
import  Tooltip  from '@material-ui/core/Tooltip';

const columns = [
    { id: 'year', label: 'Año', minWidth: 120, align: 'center' },
    { id: 'name', label: 'Nombre del documento', minWidth: 120, align: 'center' },
    { id: 'ownerName', label: 'Cliente', minWidth: 120, align: 'center' },
    { id: 'classificationName', label: 'Clasificación', minWidth: 80, align: 'center' },
];

const styles = theme => ({
    root: {
      width: '100%',
      overflowX: 'auto',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    button: {
      margin: theme.spacing(3, 0, 2, 3),
      color: 'white'
    },
});

class BillsLanding extends Component {

    debouncedOnSearchChange = debounce(this.props.handleSearch, 1000);

    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.props.getClients();
        this.props.initPagedBills();
    }

    render() {
        const { classes } = this.props;
        let listItemsClasses = Object.assign({}, classes);

        const handleClickOpen = () => {
            this.props.openingBillModal()
        };
        const handleClose = () => {
            this.props.closingBillModal()
        };

        const handleEdit = (id) => {
            this.props.openEditBillModal(id, true);
        }
        
        const handleView = (id, canPay) => {
          this.props.openEditBillModal(id, false, canPay);
        }

        const handleNewPaymentFromView = () => {
          this.props.openNewPaymentModal(this.props.billModal.bill.id);
          this.props.closingBillModal();
        }

        const handleChangeStatusOpen = (id) => {
          this.props.openBillStatusModal(id);
        }

        const handleChangeStatusClose = () => {
          this.props.closeBillStatusModal();
        }

        const handleChangeStatusConfirmation = () => {
          this.props.updateBillStatus();
        }

        const handleUploadNewInvoiceOpen = (id) => {
          this.props.openUploadNewInvoice(id);
        }

        const handleUploadNewInvoiceClose = () => {
          this.props.closeUploadNewInvoice();
        }

        const handleNotifyOpen = (id) => {
          this.props.openInvoiceNotification(id);
        }

        const handleDownload = (url) => {
          window.open(url);
        }

        const handleNotifyClose = () => {
          this.props.closeInvoiceNotification();
        }

        const handleNotifyConfirmation = () => {
          this.props.invoiceNotification();
        }

        const handleNewPaymentOpen = (id) => {
          this.props.openNewPaymentModal(id);
        }

        const handleNewPaymentClose = () => {
          this.props.closeNewPaymentModal();
        }

        const handlePaymentDetailOpen = (id, canPay) => {
          this.props.openPaymentDetailModal(id, canPay);
        }

        const handlePaymentDetailClose = () => {
          this.props.closePaymentDetailModal();
        }

        const handleNewPaymentFromDetail = () => {
          this.props.openNewPaymentModal(this.props.paymentDetailModal.payments.id);
          this.props.closePaymentDetailModal();
        }

        const newColumns = [
          { key: 'referenceDate', label: 'Fecha' },
          { key: 'caseName', label: 'Caso', primary: true},
          { key: 'clientName', label: 'Cliente', primary: true },
          { key: 'feeUSD', label: 'Honorarios USD' },
          { key: 'status', label: 'Estado' },
          { key: 'id', label: 'Acciones', render: (value, column) => {
            return (
              <div>
                {this.props.isNotClient === true ? (
                              <Tooltip title="Ver" aria-label="ver">  
                                <IconButton onClick={() => handleView(column.id, column.canPay)}>
                                  <VisibilityIcon style={{ color: green[500] }}/>
                                </IconButton>
                              </Tooltip>
                            
                          ) : undefined}
                      {column.downloadUrl != null  ? (
                            <Tooltip title="Descargar honorarios" aria-label="descargar honorarios">
                            <IconButton onClick={() => handleDownload(column.downloadUrl)}>
                              <CloudDownloadIcon style={{ color: green[500] }}/>
                            </IconButton>
                            </Tooltip>

                          ) : undefined}
                          
                          {column.dutyDownloadUrl != null  ? (
                            <Tooltip title="Descargar aranceles" aria-label="descargar aranceles">
                            <IconButton onClick={() => handleDownload(column.dutyDownloadUrl)}>
                              <CloudDownloadIcon style={{ color: green[500] }}/>
                            </IconButton>
                            </Tooltip>

                          ) : undefined}
                          
                          {(column.isEditable === true && this.props.isNotClient === true) ? (
                            <Tooltip title="Editar" aria-label="editar">
                              <IconButton onClick={() => handleEdit(column.id)}>
                                <EditIcon style={{ color: green[500] }}/>
                              </IconButton>
                            </Tooltip>
                            
                          ): undefined}
                          {(column.isEditable === true && this.props.isNotClient === true) ? (
                            <Tooltip title="Finalizar edición" aria-label="finalizar edición">
                              <IconButton onClick={() => handleChangeStatusOpen(column.id)} >
                                <CheckCircleIcon style={{color: green[500]}} />
                              </IconButton>
                            </Tooltip>
                            
                          ): undefined}
                          {(column.isEditable === true && this.props.isNotClient === true) ? (
                            <Tooltip title="Resubir" aria-label="resubir">
                              <IconButton onClick={() => handleUploadNewInvoiceOpen(column.id)} >
                                <CloudUploadIcon style={{color: green[500]}} />
                              </IconButton>
                            </Tooltip>
                           
                          ): undefined}
                          {(this.props.isNotClient === true) ? (
                            <Tooltip title="Nuevo pago" aria-label="Nuevo pago">
                              <IconButton onClick={() => handleNewPaymentOpen(column.id)} >
                                <AttachMoneyIcon style={{color: green[500]}} />
                              </IconButton>
                            </Tooltip>
                            
                          ): undefined} 
                          {(this.props.isNotClient === true ) ?(
                          <Tooltip title="Ver pagos" >
                            <IconButton aria-label="ver pagos" onClick={() => handlePaymentDetailOpen(column.id, this.props.isNotClient === true ? column.canPay : false)} >
                              <AccountBalanceIcon style={{color: green[500]}} />
                            </IconButton>
                          </Tooltip>) : undefined }
                          {(column.canNotify === true && this.props.isNotClient === true) ? (
                            
                            <Tooltip title="Notificar" aria-label="notificar">
                              <IconButton onClick={() => handleNotifyOpen(column.id)}>
                                <NotificationsIcon style={{color: green[500]}} />
                              </IconButton>
                            </Tooltip>
                            
                          ): undefined}
                          {column.downloadUrl != null && 
                            (<a style={{marginRight: "10px"}} target='_blank' className={'link'} href={`/filePreview?name=${column.displayFileName}&fileType=${column.documentExtension}&url=${btoa(column.downloadUrl)}`}>Ver preview de honorarios</a>        
                            )
                          }
                          {column.dutyDownloadUrl != null && 
                            (<a target='_blank' className={'link'} href={`/filePreview?name=${column.dutyDisplayFileName}&fileType=${column.dutyDocumentExtension}&url=${btoa(column.dutyDownloadUrl)}`}>Ver preview de aranceles</a>        
                            )
                          }
                          
              </div>
            );
          }}
        ];

        return (
            <React.Fragment>
            <h2>Bills</h2>
            
            <form noValidate autoComplete="off">
            <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                  <div><Field
                              label="Buscar"
                              name="criteria"
                              type="text"
                              onChange={this.debouncedOnSearchChange}
                              component={renderTextField}
                          />
                  </div>
                  </Grid>
                  <Grid item  item xs={12} sm={6} md={4} lg={3}>
                  <div style={{height: "16px"}}></div>
                    <Field
                      label="Cliente"
                      name="parentClientId"
                      onChange={this.props.handleParentClient}
                      component={renderSelectField}
                    >
                      <option key={0} value={null}>Todos</option>
                                {this.props.clients !== undefined ? this.props.clients.map(client => {
                                    return(
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    )
                                }) : undefined}
                    </Field>
                  </Grid>
                 
                  {this.props.isNotClient && (
                     <Grid item  item xs={12} sm={6} md={4} lg={3}>
                    <div>
                      <div style={{height: "16px"}}></div>
                    <Field
                      label="Subcliente"
                      name="subClientId"
                      onChange={this.props.handleChildClient}
                      component={renderSelectField}
                    >
                      <option key={0} value={null}>Todos</option>
                                {this.props.childClients !== undefined ? this.props.childClients.map(client => {
                                    return(
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    )
                                }) : undefined}
                    </Field>
                    </div>
                    </Grid>
                  )}
                  
                 
                  <Grid item  item xs={12} sm={6} md={4} lg={3}>
                        <Field
                              label="Año"
                              name="year"
                              type="number"
                              onChange={this.props.handleYear}
                              component={renderTextField}
                          />
                  </Grid>
                  <Grid item  item xs={12} sm={6} md={4} lg={3}>
                        <Field
                              label="Mes"
                              name="month"
                              type="number"
                              InputProps={{inputProps:{min:1, max:12}}}
                              onChange={this.props.handleYear}
                              component={renderTextField}
                          />
                  </Grid>

              <Grid item  item xs={12} sm={6} md={2} lg={2}>
                {this.props.isNotClient == true ? (
                  <ColorButtonBlue variant="contained" className={classes.button} onClick={handleClickOpen}>Nueva Bill</ColorButtonBlue>
                ): undefined}
                <BillDialog open={this.props.billModal.open} handleClose={handleClose} handleNewPaymentFromView={handleNewPaymentFromView} />
                <ChangeBillStatusDialog open={this.props.updateBillStatusModal.open} handleClose={handleChangeStatusClose} 
                 isProcessing={this.props.updateBillStatusModal.isProcessing} handleConfirmation={handleChangeStatusConfirmation} />
                <UploadNewInvoiceDialog open={this.props.uploadNewInvoiceModal.open} handleClose={handleUploadNewInvoiceClose}
                 isProcessing={this.props.uploadNewInvoiceModal.isProcessing} />
                <InvoiceNotifyDialog open={this.props.invoiceNotificationModal.open} handleClose={handleNotifyClose}
                 isProcessing={this.props.invoiceNotificationModal.isProcessing} handleConfirmation={handleNotifyConfirmation} />
                <NewPaymentDialog open={this.props.newPaymentModal.open} handleClose={handleNewPaymentClose} />
                <PaymentDetailDialog open={this.props.paymentDetailModal.open} handleClose={handlePaymentDetailClose}
                  handleNewPaymentFromDetail={handleNewPaymentFromDetail} />
              </Grid>
            </Grid>
            
            </form>
            <Paper>
              <ResponsiveTable
                excludePrimaryFromDetails={true}
                enableShouldComponentUpdate={true}
                columns={newColumns}
                data={this.props.data}
                showPagination={true}
                count={this.props.totalCount}
                rowsPerPage={this.props.pageSize}
                page={this.props.currentPage}
                noContentText={'No hay registros'}
                onChangePage={this.props.handleChangePage}
                TableProps={{dense: true, table: "true", size: "small"}}
                TableHeadCellProps= {{align: 'center', style: {"fontWeight": "bold", "background-color": "#D3D3D3", color: "#60b044"}}}
                TableBodyCellProps={{align: 'center'}}
                TablePaginationProps={{
                  labelRowsPerPage: "Cantidad de Filas",
                  rowsPerPageOptions: [10, 25, 50, 100],
                  component: "div",
                  onChangeRowsPerPage: this.props.handleChangeRowsPerPage
                }}
                ExpansionPanelSummaryTypographyProps={{style: {"fontWeight": "bold"}}}
                ExpansionPanelDetailsTypographyProps={{className: "test", classes:listItemsClasses}}
              />
            </Paper>
        <br></br>
          <br></br>
      </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {pagedBills, catalog, billModal, updateBillStatusModal, uploadNewInvoiceModal, invoiceNotificationModal, newPaymentModal,
    paymentDetailModal} = state;
    const {clients, childClients} = catalog;
    const {user} = state.auth;
    let isAdmin = isRootUser(user);
    let isNotClient = isNonClient(user);
    return {...pagedBills, clients, isNotClient, isAdmin, childClients, isAdmin, isNotClient, billModal, updateBillStatusModal, uploadNewInvoiceModal, invoiceNotificationModal,
    newPaymentModal, paymentDetailModal};
}

const mapDispatchToProps = {
    getClients,
    getChildClients,
    clearChildClients,
    getPagedBills, 
    initPagedBills, 
    handleChangePage, 
    handleChangeRowsPerPage, 
    handleSearch, 
    handleParentClient, 
    handleChildClient, 
    handleYear, 
    handleMonth,
    openingBillModal,
    closingBillModal,
    openEditBillModal,
    openBillStatusModal,
    closeBillStatusModal,
    updateBillStatus,
    openUploadNewInvoice,
    closeUploadNewInvoice,
    openInvoiceNotification,
    closeInvoiceNotification,
    invoiceNotification,
    openNewPaymentModal,
    closeNewPaymentModal,
    openPaymentDetailModal,
    closePaymentDetailModal
};

export default withStyles(styles)(reduxForm({form:'BillsListForm'})(connect(mapStateToProps, mapDispatchToProps)(BillsLanding)));