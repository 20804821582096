import React, { Component } from 'react';
import debounce from 'lodash/debounce';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';

import { renderTextField, renderSelectField, renderDatePicker } from './../../constants/customElementsMUI';
import { Field, reduxForm } from 'redux-form';

import {ColorButtonBlue} from '../../constants/customElementsMUI';

import {getReport, exportReport} from '../../actions/reports/reportActions';

import {getAllAssignableLawyers} from '../../actions/catalog/catalogActions';

import {FAST_VS_NORMAL_CASES, PENDING_VS_FINISHED_CASES, FREE_VS_PAYABLE_BILLS, PAID_VS_REMAINING_BILLS} from '../../constants/reportTypes';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import { green } from '@material-ui/core/colors';
import { ResponsiveTable } from 'material-ui-next-responsive-table';

import {Pie} from 'react-chartjs-2';

const styles = theme => ({
    root: {
      width: '100%',
      overflowX: 'auto',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    button: {
      margin: theme.spacing(1.2, 0, 2, 3),
      color: 'white'
    },
});

const validate = values => {
    const errors = {};
    let requiredFields = [
        'startDate',
        'endDate',
        'type'
    ];
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });
    /*if(values.user && emailRegex.test(values.email)){
    errors.email = 'Usuario inválido';
    }*/
    return errors;
}

class ReportsLanding extends Component {
    componentDidMount() {
        this.props.getAllAssignableLawyers();
    }

    handleFormSubmit = values => {
        const info = values;
        if(info.startDate !== undefined && info.startDate !== null){
            info.startDate = moment(info.startDate).format('YYYY-MM-DD');
        }
        if(info.endDate !== undefined && info.endDate !== null){
            info.endDate = moment(info.endDate).format('YYYY-MM-DD');
        }
        if(values.userId === 'Todos' || values.userId === undefined){
            info.userId = null;
        }
        this.props.getReport(info);
    }

    handleExport = values => {
        console.log('handle export')
        const info = values;
        if(info.startDate !== undefined && info.startDate !== null){
            info.startDate = moment(info.startDate).format('YYYY-MM-DD');
        }
        if(info.endDate !== undefined && info.endDate !== null){
            info.endDate = moment(info.endDate).format('YYYY-MM-DD');
        }
        if(values.userId === 'Todos' || values.userId === undefined){
            info.userId = null;
        }
        this.props.exportReport(info);
    }

    render() {
        const { classes } = this.props;
        const {handleSubmit, pristine, reset, submitting} = this.props;

        let listItemsClasses = Object.assign({}, classes);
        
        return (
            <React.Fragment>
                <h2>Reportes</h2>
                <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Field
                              label="Fecha de inicio"
                              name="startDate"
                              component={renderDatePicker}
                          />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Field
                              label="Fecha de fin"
                              name="endDate"
                              component={renderDatePicker}
                          />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Field
                      label="Abogado"
                      name="userId"
                      component={renderSelectField}
                    >
                      <option key={0} value={null}>Todos</option>
                                {this.props.assignableLawyers !== undefined ? this.props.assignableLawyers.map(lawyer => {
                                    return(
                                        <option key={lawyer.id} value={lawyer.id}>{lawyer.name}</option>
                                    )
                                }) : undefined}
                    </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Field
                      label="Tipo de reporte"
                      name="type"
                      component={renderSelectField}
                    >
                      <option key={FAST_VS_NORMAL_CASES} value={FAST_VS_NORMAL_CASES}>Casos rápidos vs normales</option>
                      <option key={PENDING_VS_FINISHED_CASES} value={PENDING_VS_FINISHED_CASES}>Casos pendientes vs finalizados</option>
                      <option key={FREE_VS_PAYABLE_BILLS} value={FREE_VS_PAYABLE_BILLS}>Probono vs pagados</option>
                      <option key={PAID_VS_REMAINING_BILLS} value={PAID_VS_REMAINING_BILLS}>Bills pagadas vs pendientes</option>
                    </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <ColorButtonBlue variant="contained" className={classes.button} onClick={handleSubmit(this.handleFormSubmit)}>Generar reporte</ColorButtonBlue>
              </Grid>
              {this.props.data !== null && (
                <Grid item xs={12} sm={6} md={2} lg={2}>
                    <ColorButtonBlue variant="contained" className={classes.button} onClick={handleSubmit(this.handleExport)}>Exportar reporte</ColorButtonBlue>
                </Grid>
              )}
            </Grid>
            </form>
            {this.props.data !== null && (
                <div>
                <Paper>
                    <ResponsiveTable
                        excludePrimaryFromDetails={true}
                        enableShouldComponentUpdate={true}
                        columns={this.props.columns}
                        data={this.props.data.records}
                        noContentText={'No hay registros'}
                        TableProps={{dense: true, table: "true", size: "small"}}
                        TableHeadCellProps= {{align: 'center', style: {"fontWeight": "bold", "background-color": "#D3D3D3", color: "#60b044"}}}
                        TableBodyCellProps={{align: 'center'}}
                        ExpansionPanelSummaryTypographyProps={{style: {"fontWeight": "bold"}}}
                        ExpansionPanelDetailsTypographyProps={{className: "test", classes:listItemsClasses}}
                    />
                </Paper>
                <Paper>
                    <Pie 
                        data={this.props.pieData}
                        height='75%'
                        options={{ maintainAspectRatio: true }}
                    />
                </Paper>
                </div>
            )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {data, columns, isFetching, pieData} = state.reports;
    const {assignableLawyers} = state.catalog;
    const initialValues = {
        type: '1'
    };
    return {data, columns, isFetching, assignableLawyers, initialValues, pieData};
}

const mapDispatchToProps = {
    getReport,
    getAllAssignableLawyers,
    exportReport
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'ReportForm', validate, enableReinitialize: true})(ReportsLanding)));
