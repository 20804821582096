import authAxios, {clientDocuments, clients, isNonClient} from './../../constants/api';

import {reset} from 'redux-form';
export const FETCH_DOCUMENTS_START = 'FETCH_DOCUMENTS_START';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';
export const SET_DOCUMENTS_CURRENT_PAGE = 'SET_DOCUMENTS_CURRENT_PAGE';
export const SET_DOCUMENTS_CRITERIA = 'SET_DOCUMENTS_CRITERIA';
export const SET_DOCUMENTS_PAGE_SIZE = 'SET_DOCUMENTS_PAGE_SIZE';
export const SET_DOCUMENTS_PARENT_CLIENT = 'SET_DOCUMENTS_PARENT_CLIENT';
export const SET_DOCUMENTS_CHILD_CLIENT = 'SET_DOCUMENTS_CHILD_CLIENT';
export const SET_DOCUMENTS_YEAR = 'SET_DOCUMENTS_YEAR';
export const SET_DOCUMENTS_CLASSIFICATION = 'SET_DOCUMENTS_CLASSIFICATION';
export const CREATING_NEW_DOCUMENT = 'CREATING_NEW_DOCUMENT';
export const OPENING_DOCUMENT_MODAL = 'OPENING_DOCUMENT_MODAL';
export const CLOSING_DOCUMENT_MODAL = 'CLOSING_DOCUMENT_MODAL';

export const GETTING_CHILD_CLIENTS = 'GETTING_CHILD_CLIENTS';
export const RESETTING_CHILD_CLIENTS = 'RESETTING_CHILD_CLIENTS';

export const RESUBMITTING_DOCUMENT = 'RESUBMITTING_DOCUMENT';
export const OPENING_RESUBMIT_DOCUMENT_MODAL = 'OPENING_RESUBMIT_DOCUMENT_MODAL';
export const CLOSING_RESUBMIT_DOCUMENT_MODAL = 'CLOSING_RESUBMIT_DOCUMENT_MODAL';

export const CHANGE_DOCUMENT_ONWER = 'CHANGE_DOCUMENT_OWNER';
export const OPENING_CHANGE_DOCUMENT_OWNER_MODAL = 'OPENING_CHANGE_DOCUMENT_OWNER_MODAL';
export const CLOSING_CHANGE_DOCUMENT_OWNER_MODAL = 'CLOSING_CHANGE_DOCUMENT_OWNER_MODAL';
export const GETTING_SIBLING_CLIENTS = 'GETTING_SIBLING_CLIENTS';

export const DELETING_DOCUMENT = 'DELETING_DOCUMENT';
export const OPENING_DELETE_DOCUMENT_MODAL = 'OPENING_DELETE_DOCUMENT_MODAL';
export const CLOSING_DELETE_DOCUMENT_MODAL = 'CLOSING_DELETE_DOCUMENT_MODAL';

import {getChildClients as getChildClientsCatalog, clearChildClients as clearChildClientsCatalog} from '../catalog/catalogActions';

export function handleSearch(event, newValue, previousValue, name) {
    return dispatch => {
      dispatch(setCriteria(newValue));
      return dispatch(getPagedDocuments());
    }
  }

export function handleParentClient(event, newValue, previousValue, name){
  return (dispatch, getState) => {
    let isNotClient = isNonClient(getState().auth.user);
      
    if(newValue === 'Todos'){
      newValue = null;
      dispatch(clearChildClientsCatalog());
      dispatch(setChildClient(newValue));
    }
    else if(isNotClient){
      dispatch(getChildClientsCatalog(newValue));
    }
    dispatch(setParentClient(newValue));
    if (!isNotClient) {
      dispatch(setChildClient(newValue))
    }
    return dispatch(getPagedDocuments());
  }
}

export function handleChildClient(event, newValue, previousValue, name){
  return dispatch => {
    if(newValue === 'Todos'){
      newValue = null;
    }
    dispatch(setChildClient(newValue));
    return dispatch(getPagedDocuments());
  }
}

export function handleYear(event, newValue, previousValue, name){
  return dispatch => {
    dispatch(setYear(newValue));
    return dispatch(getPagedDocuments());
  }
}

export function handleClassification(event, newValue, previousValue, name){
  return dispatch => {
    if(newValue === 'Todos'){
      newValue = null;
    }
    dispatch(setClassification(newValue));
    return dispatch(getPagedDocuments());
  }
}
  
  export function handleChangePage(event, newPage){
    return dispatch => {
      dispatch(setPage(newPage));
      return dispatch(getPagedDocuments());
    }
  }
  
  export function handleChangeRowsPerPage(event) {
    
    return dispatch => {
      dispatch(setPageSize(event.target.value));
      return dispatch(getPagedDocuments());
    }
  }
  
  export function startFetchDocumentList() {
    return {
      type: FETCH_DOCUMENTS_START
    };
  }
  
  export function setPage(page) {
    return {
      type: SET_DOCUMENTS_CURRENT_PAGE,
      payload: page
    };
  }
  
  
  export function setPageSize(pageSize) {
    return {
      type: SET_DOCUMENTS_PAGE_SIZE,
      payload: pageSize
    };
  }
  
  export function setCriteria(criteria) {
    return {
      type: SET_DOCUMENTS_CRITERIA,
      payload: criteria
    };
  }
  
export function setParentClient(parentClient){
  return{
    type: SET_DOCUMENTS_PARENT_CLIENT,
    payload: parentClient
  }
}

export function setChildClient(childClient){
  return{
    type: SET_DOCUMENTS_CHILD_CLIENT,
    payload: childClient
  }
}

export function setYear(year){
  return{
    type: SET_DOCUMENTS_YEAR,
    payload: year
  }
}

export function setClassification(classification){
  return {
    type: SET_DOCUMENTS_CLASSIFICATION,
    payload: classification
  }
}
  
  export function processDocumentList(data) {
    return {
      type: FETCH_DOCUMENTS_SUCCESS,
      payload: data
    };
  }
  
  export function processDocumentListFailure() {
    return {
      type: FETCH_DOCUMENTS_FAILURE
    }
  }
  export function initPagedDocuments() {
    return (dispatch) => {
      dispatch(setCriteria(''));
      dispatch(setPage(0));
      dispatch(setPageSize(25));
      return dispatch(getPagedDocuments());
    };
  }
  export function getPagedDocuments() {
    return (dispatch, getState) => {

      const { auth, pagedDocuments } = getState();
      

      dispatch(startFetchDocumentList());
      let pagedPayload = {
        page: pagedDocuments.currentPage + 1,
        pageSize: pagedDocuments.pageSize,
        criteria: pagedDocuments.criteria,
        parentClientId: pagedDocuments.parentClientId,
        specificClientId: pagedDocuments.childClientId,
        year: pagedDocuments.year,
        classificationId: pagedDocuments.classificationId
      }
      
      authAxios.get(clientDocuments, {params: pagedPayload}, {headers: auth.headers})
        .then((response) => {
          return dispatch(processDocumentList(response.data));
          
        })
        .catch(e => {
          return dispatch(processDocumentListFailure());
        });
    }
  }

  export function openingDocumentModal(){
    return dispatch => {
      dispatch({type: OPENING_DOCUMENT_MODAL});
    }
  }
  
  export function closingDocumentModal(){
    return dispatch => {
      dispatch({type: CLOSING_DOCUMENT_MODAL});
      dispatch(reset('CreateDocumentForm'));
    }
  }

  export function createDocument(info){
    return(dispatch, getState) => {
      const {auth} = getState();
      dispatch({
        type: `${CREATING_NEW_DOCUMENT}_REQUESTED`
      });
      authAxios.post(clientDocuments, info, {headers: auth.headersMultipart})
      .then(response => {
        dispatch({
          type: `${CREATING_NEW_DOCUMENT}_FULFILLED`
        });
        dispatch(reset('CreateDocumentForm'));
        return dispatch(getPagedDocuments());
      })
      .catch(e => {
        dispatch(reset('CreateDocumentForm'));
        dispatch({
          type: `${CREATING_NEW_DOCUMENT}_REJECTED`,
          payload: e
        })
      })
    }
  }

export function getChildClients(parentId){
    return (dispatch, getState) => {
        dispatch({type: `${GETTING_CHILD_CLIENTS}_PENDING`})
        const {auth} = getState();
        let pagedPayload = {
          parentClientId: parentId
        }
        authAxios.get(`${clients}/catalogue`, {params: pagedPayload}, {headers: auth.headers})
        .then(response => {
            return dispatch({
                type: `${GETTING_CHILD_CLIENTS}_FULFILLED`,
                payload: response.data
            });
        })
        .catch(e => {
            return dispatch({
                type: `${GETTING_CHILD_CLIENTS}_REJECTED`,
                payload: e
            });
        })
    }
}

export function clearChildClients(){
  return dispatch => {
    return dispatch({type: `${RESETTING_CHILD_CLIENTS}`});
  }
}

export function resubmitDocument(id, info){
  return (dispatch, getState) => {
    const {auth} = getState();
    dispatch({
      type: `${RESUBMITTING_DOCUMENT}_REQUESTED`
    });
    authAxios.put(`${clientDocuments}/${id}`, info, {headers: auth.headersMultipart})
    .then(response => {
      dispatch({
        type: `${RESUBMITTING_DOCUMENT}_FULFILLED`
      });
      dispatch(reset('ResubmitDocumentForm'));
      return dispatch(getPagedDocuments());
    })
    .catch(e => {
      dispatch(reset('ResubmitDocumentForm'));
      return dispatch({
        type: `${RESUBMITTING_DOCUMENT}_REJECTED`,
        payload: e
      })
    });
  }
}

export function changeOwner(id, info) {
  return (dispatch, getState) => {
    const {auth} = getState();
    dispatch({
      type: `${CHANGE_DOCUMENT_ONWER}_REQUESTED`
    })
    authAxios.put(`${clientDocuments}/${id}/owner`, JSON.stringify(info), {headers: auth.headers})
    .then(response => {
      dispatch({
        type: `${CHANGE_DOCUMENT_ONWER}_FULFILLED`
      })
      dispatch(reset('ChangeDocumentOwnerForm'));
      return dispatch(getPagedDocuments());
    })
    .catch(e=>{
      dispatch(reset('ChangeDocumentOwnerForm'));
      return dispatch({
        type: `${CHANGE_DOCUMENT_ONWER}_REJECTED`,
        payload: e
      })
    });
  }
}

export function openingResubmitDocumentModal(id){
  return dispatch => {
    dispatch({type: OPENING_RESUBMIT_DOCUMENT_MODAL, payload: id});
  }
}

export function closingResubmitDocumentModal(){
  return dispatch => {
    dispatch({type: CLOSING_RESUBMIT_DOCUMENT_MODAL});
    dispatch(reset('ResubmitDocumentForm'));
  }
}

export function openingChangeOwnerModal(id, parentClientId){
  return dispatch => {
    dispatch(getSiblingClients(parentClientId));
    dispatch({type: OPENING_CHANGE_DOCUMENT_OWNER_MODAL, payload: id});
  }
}

export function closingChangeOwnerModal(){
  return dispatch => {
    dispatch({type: CLOSING_CHANGE_DOCUMENT_OWNER_MODAL})
    dispatch(reset('ChangeDocumentOwnerForm'));
  }
}

export function openingDeletingDocumentModal(id){
  return dispatch => {
    dispatch({
      type: OPENING_DELETE_DOCUMENT_MODAL,
      payload: id
    })
  }
}

export function closdingDeletingDocumentModal(){
  return dispatch => {
    dispatch({type: CLOSING_DELETE_DOCUMENT_MODAL});
  }
}

export function getSiblingClients(parentId){
  return (dispatch, getState) => {
      dispatch({type: `${GETTING_SIBLING_CLIENTS}_PENDING`})
      const {auth} = getState();
      let pagedPayload = {
        parentClientId: parentId,
        includeParent: true
      }
      authAxios.get(`${clients}/catalogue`, {params: pagedPayload}, {headers: auth.headers})
      .then(response => {
          return dispatch({
              type: `${GETTING_SIBLING_CLIENTS}_FULFILLED`,
              payload: response.data
          });
      })
      .catch(e => {
          return dispatch({
              type: `${GETTING_SIBLING_CLIENTS}_REJECTED`,
              payload: e
          });
      })
  }
}

export function deleteDocument() {
  return (dispatch, getState) => {
    const {auth, deleteDocumentModal} = getState();
    dispatch({type:`${DELETING_DOCUMENT}_PENDING`});
    authAxios.delete(`${clientDocuments}/${deleteDocumentModal.documentId}`,{headers:auth.headers})
    .then(response => {
      dispatch({type: `${DELETING_DOCUMENT}_FULFILLED`});
      dispatch(getPagedDocuments());
    })
    .catch(e => {
      dispatch({
        type: `${DELETING_DOCUMENT}_REJECTED`,
        payload: e
      })
    });
  }
}