import {CREATING_NEW_LEGAL_CASE, OPENING_LEGAL_CASE_MODAL, CLOSING_LEGAL_CASE_MODAL} from '../../actions/legalCases/legalCaseListAction';

const INITIAL_STATE = {
    open: false,
    isProcessing: false
}

export default function LegalCaseModal(state=INITIAL_STATE, action){
    switch(action.type){
        case OPENING_LEGAL_CASE_MODAL:
            return {...state, open: true};
        case CLOSING_LEGAL_CASE_MODAL:
            return {...state, open: false, isProcessing: false};
        case `${CREATING_NEW_LEGAL_CASE}_REQUESTED`:
            return {...state, isProcessing: true};
        case `${CREATING_NEW_LEGAL_CASE}_FULFILLED`:
            return {...state, open: false, isProcessing: false};
        case `${CREATING_NEW_LEGAL_CASE}_REJECTED`:
            return {...state, open: false, isProcessing: false};
        default:
            return state;
    }
}