import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import logo from '../../assets/logo.png';
import ToolbarMenu from './toolbarMenu';
import {Button, MenuItem, Menu} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { green } from '@material-ui/core/colors';
import { modules, hasModulePermission	 } from './../../constants/api';
const styles = {
  root:{
    flexGrow: 1
  },
  grow:{
    flexGrow: 1
  },
  homeIcon: {
    marginTop: 10,
    marginBottom: 10
  },
  menuButton:{
    marginLeft: -12,
    marginRight: 20,
    color: green[500]
  }
};

function ButtonAppBar(props){
  const{classes, userData, onExit} = props;
  let canClients = hasModulePermission(userData, modules.clients);
  let canDocuments = hasModulePermission(userData, modules.documents);
  let canUsers = hasModulePermission(userData, modules.users);
  let canClassifications = hasModulePermission(userData, modules.classifications);
  let canDocumentTypes = hasModulePermission(userData, modules.documentTypes);
  let canCases = hasModulePermission(userData, modules.cases);
  let canReports = hasModulePermission(userData, modules.reports);
  let canBills = hasModulePermission(userData, modules.bills);
  let menuItems = [];
  let respMenuItems = [];
  if (canClients) {
    menuItems.push(<MenuItem key="clients" onClick={onClients} autoClose={true}>
        Clientes
        </MenuItem>);

    respMenuItems.push(<Button
                  key='clients'
                  color='inherit'
                  onClick={onClients}
                  className={classes.menuButton}>
                  Clientes
                </Button>);
  }
  if(canCases){
    menuItems.push(
      <MenuItem key="cases" onClick={onCases} autoClose={true}>Casos</MenuItem>
    );
    respMenuItems.push(
      <Button key="cases" color="inherit" onClick={onCases} className={classes.menuButton}>Casos</Button>
    )
  }
  if(canBills){
    menuItems.push(
      <MenuItem key="bills" onClick={onBills} autoClose={true}>Bills</MenuItem>
    );
    respMenuItems.push(
      <Button key="bills" color="inherit" onClick={onBills} className={classes.menuButton}>Bills</Button>
    );
  }
  if (canDocuments) {
    menuItems.push(<MenuItem key="documents" onClick={onDocuments}>
    Documentos
  </MenuItem>);
  
  respMenuItems.push(<Button
    key='documents'
    color='inherit'
    onClick={onDocuments}
    className={classes.menuButton}>
    Documentos
  </Button>);
  }
  if(canReports){
    menuItems.push(<MenuItem key="reports" onClick={onReports}>Reportes</MenuItem>);

    respMenuItems.push(<Button
      key="reports"
      color="inherit"
      onClick={onReports}
      className={classes.menuButton}>
      Reportes
    </Button>)
  }
  if (canUsers) {
    menuItems.push(<MenuItem key="users" onClick={onUsers}>
    Usuarios
  </MenuItem>);
  
  respMenuItems.push(<Button
    key='users'
    color='inherit'
    onClick={onUsers}
    className={classes.menuButton}>
    Usuarios
  </Button>);
  }
  
  //now proceed to add logout option
  menuItems.push(<MenuItem key="logout" onClick={onExit}>
  Cerrar sesión
</MenuItem>);

respMenuItems.push(<Button
  key='logout'
  color='inherit'
  onClick={onExit}
  className={classes.menuButton}>
  <ExitToAppIcon color={green[500]}/>
</Button>);



  function onClients(){
    window.location = '/clients'
  }
  function onDocuments(){
    window.location = '/documents'
  }
function onClassifications(){
    window.location = '/classifications'
  }
  
  function onReports(){
    window.location = '/reports'
  }
      
  function onUsers(){
    window.location = '/users'
  }

  function onCases(){
    window.location = '/legalCases'
  }

  function onBills(){
    window.location = '/bills'
  }
  return(
    <AppBar position='fixed' style={{ background: '#D3D3D3' }}>
      <Toolbar>
        <img src={logo} height='60px' className={classes.homeIcon}/>
        <ToolbarMenu
          render={collapsed => {
            return collapsed

              ? menuItems
              : respMenuItems

          }}
          />
      </Toolbar>
    </AppBar>
  )
}

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ButtonAppBar);