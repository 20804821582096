import authAxios, {users} from './../../constants/api';
import {reset, change, initialize} from 'redux-form';
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const SET_USERS_CURRENT_PAGE = 'SET_USERS_CURRENT_PAGE';
export const SET_USERS_CRITERIA = 'SET_USERS_CRITERIA';
export const SET_USERS_TYPE_ID = 'SET_USERS_TYPE_ID';
export const SET_USERS_LOGIN_STATUS_FILTER = 'SET_USERS_LOGIN_STATUS_FILTER';
export const SET_USERS_PAGE_SIZE = 'SET_USERS_PAGE_SIZE';
export const CREATING_NEW_USER = 'CREATING_NEW_USER';
export const OPENING_USER_MODAL = 'OPENING_USER_MODAL';
export const CLOSING_USER_MODAL = 'CLOSING_USER_MODAL';
export const OPENING_EDITING_MODAL = 'OPENING_EDITING_MODAL';
export const OPENING_VIEW_MODAL = 'OPENING_VIEW_MODAL';
export const OPENING_USER_LOGIN_STATUS_MODAL = 'OPENING_USER_LOGIN_STATUS_MODAL';
export const CLOSING_USER_LOGIN_STATUS_MODAL = 'CLOSING_USER_LOGIN_STATUS_MODAL';
export const EDITING_USER = 'EDITING_USER';
export const UPDATING_USER_LOGIN_STATUS = 'UPDATING_USER_LOGIN_STATUS';
export const BAN_CLIENT_TO_USER = 'BAN_CLIENT_TO_USER';
export const REMOVE_CLIENT_FROM_BAN_LIST = 'REMOVE_CLIENT_FROM_BAN_LIST';
//For change password
export const OPENING_USER_CHANGE_PASSWORD = 'OPENING_USER_CHANGE_PASSWORD';
export const CLOSING_USER_CHANGE_PASSWORD = 'CLOSING_USER_CHANGE_PASSWORD';
export const UPDATING_USER_CHANGE_PASSWORD = 'UPDATING_USER_CHANGE_PASSWORD';

export function addClientToBanList(clientId){
  return (dispatch, getState) => {
    const {catalog, userModal} = getState();
    const clients = catalog.clients;
    const userBannedClients = (userModal.user && userModal.user.bannedClients) || [];
    const selectedClient = clients.find(value => value.id == clientId);
    if (selectedClient != null && selectedClient != undefined
        && !userBannedClients.some(value => value.id == clientId)){
          
      dispatch(change('UserForm', 'bannedClientId', null));
      return dispatch({
        type: BAN_CLIENT_TO_USER,
        payload: {
          client: selectedClient
        }
      });
    }
  };
}

export function removeClientFromBanList(clientId){
  return (dispatch) => {
    return dispatch({
      type: REMOVE_CLIENT_FROM_BAN_LIST,
      payload: {
        clientId: clientId
      }
    });
  };
}

export function handleSearch(event, newValue, previousValue, name) {
  return dispatch => {
    dispatch(setCriteria(newValue));
    return dispatch(getPagedUsers());
  }
}

export function handleUserType(event, newValue, previousValue, name) {
  return dispatch => {
    dispatch(setUserType(newValue));
    return dispatch(getPagedUsers());
  }
}

export function handleLoginStatus(event, newValue, previousValue, name) {
  return dispatch => {
    dispatch(setLoginStatus(newValue));
    return dispatch(getPagedUsers());
  }
}


export function handleChangePage(event, newPage){
  return dispatch => {
    dispatch(setPage(newPage));
    return dispatch(getPagedUsers());
  }
}

export function handleChangeRowsPerPage(event) {
  
  return dispatch => {
    dispatch(setPageSize(event.target.value));
    return dispatch(getPagedUsers());
  }
}

export function startFetchUserList() {
  return {
    type: FETCH_USERS_START
  };
}

export function setPage(page) {
  return {
    type: SET_USERS_CURRENT_PAGE,
    payload: page
  };
}


export function setPageSize(pageSize) {
  return {
    type: SET_USERS_PAGE_SIZE,
    payload: pageSize
  };
}

export function setCriteria(criteria) {
  return {
    type: SET_USERS_CRITERIA,
    payload: criteria
  };
}

export function setUserType(userType) {
  return {
    type: SET_USERS_TYPE_ID,
    payload: userType
  };
}

export function setLoginStatus(loginEnabled) {
  return {
    type: SET_USERS_LOGIN_STATUS_FILTER,
    payload: loginEnabled
  };
}


export function processUserList(data) {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: data
  };
}

export function processUserListFailure() {
  return {
    type: FETCH_USERS_FAILURE
  }
}
export function initPagedUsers() {
  return (dispatch) => {
    dispatch(setCriteria(''));
    dispatch(setPage(0));
    dispatch(setPageSize(25));
    return dispatch(getPagedUsers());
  };
}
export function getPagedUsers() {
  return (dispatch, getState) => {
    
    const { auth, pagedUsers } = getState();
    dispatch(startFetchUserList());
    let pagedPayload = {
      page: pagedUsers.currentPage + 1,
      pageSize: pagedUsers.pageSize,
      criteria: pagedUsers.criteria,
      typeId: pagedUsers.typeId,
      loginEnabled: pagedUsers.loginEnabled
    }
    authAxios.get(users, {params: pagedPayload}, {headers: auth.headers})
      .then((response) => {
        return dispatch(processUserList(response.data));
        
      })
      .catch(e => {
        return dispatch(processUserListFailure());
      });
  }
}

export function createUser(info){
  return (dispatch, getState) => {
    let specialAccessesToAdd = [];
    if (!info.loginEnabled) {
      info.loginEnabled = false;
    }
    if(info.canAccessBills){
      specialAccessesToAdd.push(1);
    }
    if(info.canAccessClassifications){
      specialAccessesToAdd.push(4);
    }
    if(info.canAccessReports){
      specialAccessesToAdd.push(3);
    }
    if(info.canAccessDocumentTypes){
      specialAccessesToAdd.push(5);
    }
    if(info.canAccessUsers){
      specialAccessesToAdd.push(2);
    }
    info.specialAccesses = specialAccessesToAdd;

    const {auth, userModal} = getState();
    info.bannedClients = ((userModal.user && userModal.user.bannedClients) || []).map(value => value.id);
    authAxios.post(users, JSON.stringify(info), {headers: auth.headers})
    .then((response) => {
      dispatch({
        type: `${CREATING_NEW_USER}_FULFILLED`,
      });
      return dispatch(getPagedUsers());
    })
    .catch(e => {
      return dispatch({
        type: `${CREATING_NEW_USER}_REJECTED`,
        payload: e
      })
    })
  }
}

export function openingUserModal(){
  return (dispatch, getState) => {
    dispatch({type: OPENING_USER_MODAL});
    const {userModal} = getState();
    return dispatch(initialize('UserForm', userModal.user, false));
  }
}

export function closingUserModal(){
  return dispatch => {
    dispatch({type: CLOSING_USER_MODAL});
    dispatch(reset('UserForm'));
  }
}

export function openingUserLoginStatusModal(userId, loginEnabled){
  return dispatch => {
    dispatch({type: OPENING_USER_LOGIN_STATUS_MODAL, 
      payload: {
        userId,
        loginEnabled
      }
    });
  }
}
export function closingUserLoginStatusModal(){
  return dispatch => {
    dispatch({type: CLOSING_USER_LOGIN_STATUS_MODAL});
  }
}


export function openingUserChangePasswordModal(userId){
  return dispatch => {
    dispatch({type: OPENING_USER_CHANGE_PASSWORD, 
      payload: {
        userId
      }
    });
  }
}
export function closingUserChangePasswordModal(){
  return dispatch => {
    dispatch({type: CLOSING_USER_CHANGE_PASSWORD});
  }
}



export function openingEditingModal(id, readOnly){
  return (dispatch, getState) => {
    const {auth} = getState();
    authAxios.get(`${users}/${id}`, {headers: auth.headers})
    .then(response => {
      let userData = response.data;
      userData.canAccessBills = userData.specialAccesses.indexOf(1) != -1;
      userData.canAccessClassifications = userData.specialAccesses.indexOf(4) != -1;
      userData.canAccessReports = userData.specialAccesses.indexOf(3) != -1;
      userData.canAccessDocumentTypes = userData.specialAccesses.indexOf(5) != -1;
      userData.canAccessUsers = userData.specialAccesses.indexOf(2) != -1;
      dispatch({
        type: readOnly ? OPENING_VIEW_MODAL : OPENING_EDITING_MODAL,
        payload: {
          user: userData
        }
      });
      return dispatch(initialize('UserForm', userData, false));
    })
  }
}

export function editingUser(info){
  return (dispatch, getState) => {
    const {auth} = getState();
    const {userModal} = getState();
    const {id} = userModal.user;
    if (!info.loginEnabled) {
      info.loginEnabled = false;
    }
    let specialAccessesToAdd = [];
    if(info.canAccessBills){
      specialAccessesToAdd.push(1);
    }
    if(info.canAccessClassifications){
      specialAccessesToAdd.push(4);
    }
    if(info.canAccessReports){
      specialAccessesToAdd.push(3);
    }
    if(info.canAccessDocumentTypes){
      specialAccessesToAdd.push(5);
    }
    if(info.canAccessUsers){
      specialAccessesToAdd.push(2);
    }
    info.specialAccesses = specialAccessesToAdd;
    info.bannedClients = ((userModal.user && userModal.user.bannedClients) || []).map(value => value.id);
    authAxios.put(`${users}/${id}`, JSON.stringify(info), {headers: auth.headers})
    .then(response => {
      dispatch({
        type: `${EDITING_USER}_FULFILLED`
      });
      dispatch(reset('UserForm'));
      return dispatch(getPagedUsers());
    })
    .catch(e => {
      dispatch(reset('UserForm'));
      dispatch({
        type: `${EDITING_USER}_REJECTED`,
        payload: e
      });
    });
  }
}

export function updateLoginStatus(){
  return (dispatch, getState) => {
    const {auth} = getState();
    const {userLoginStatusModal} = getState();
    const {userId, loginEnabled} = userLoginStatusModal;
    
    let payload = {
      loginEnabled: !loginEnabled
    }
    dispatch({
      type: `${UPDATING_USER_LOGIN_STATUS}_REQUESTED`
    });
    authAxios.put(`${users}/${userId}/loginStatus`, JSON.stringify(payload), {headers: auth.headers})
    .then(response => {
      dispatch({
        type: `${UPDATING_USER_LOGIN_STATUS}_FULFILLED`
      });
      return dispatch(getPagedUsers());
    })
    .catch(e => {
      dispatch({
        type: `${UPDATING_USER_LOGIN_STATUS}_REJECTED`,
        payload: e
      });
    });
  }
}

export function updateUserPassword(info){
  return (dispatch, getState) => {
    const {auth} = getState();
    const {userChangePasswordModal} = getState();
    const {userId} = userChangePasswordModal;
    dispatch({
      type: `${UPDATING_USER_CHANGE_PASSWORD}_REQUESTED`
    });
    authAxios.put(`${users}/${userId}/password`, JSON.stringify(info), {headers: auth.headers})
    .then(response => {
      dispatch(reset('UserChangePasswordForm'));
      dispatch({
        type: `${UPDATING_USER_CHANGE_PASSWORD}_FULFILLED`
      });
      return dispatch(getPagedUsers());
    })
    .catch(e => {
      dispatch(reset('UserChangePasswordForm'));
      dispatch({
        type: `${UPDATING_USER_CHANGE_PASSWORD}_REJECTED`,
        payload: e
      });
    });
  }
}