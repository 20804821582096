import {
OPENING_DOCUMENT_TYPE_STATUS_MODAL,
CLOSING_DOCUMENT_TYPE_STATUS_MODAL,
UPDATING_DOCUMENT_TYPE_STATUS
} from '../../actions/documentTypes/DocumentTypesListActions';

const INITIAL_STATE = {
    open: false,
    documentTypeId: null,
    isProcessing: false,
    isEnabled: null
}

export default function DocumentTypeStatusModal(state=INITIAL_STATE,action){
    switch(action.type){
        case OPENING_DOCUMENT_TYPE_STATUS_MODAL:
            return {...state, open: true, documentTypeId: action.payload.documentTypeId, isProcessing: false, isEnabled: action.payload.isEnabled};
        case CLOSING_DOCUMENT_TYPE_STATUS_MODAL:
            return {...state, open: false, documentTypeId: null, isProcessing: false, isEnabled: null};
        case `${UPDATING_DOCUMENT_TYPE_STATUS}_FULFILLED`:
            return {...state, open: false, documentTypeId: null, isProcessing: false, isEnabled: null};
            case `${UPDATING_DOCUMENT_TYPE_STATUS}_REQUESTED`:
            return {...state,  isProcessing: true};
        default:
            return state;
    }
}