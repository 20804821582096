import {OPEN_EDIT_LEGAL_CASE, CLOSE_EDIT_LEGAL_CASE, EDIT_LEGAL_CASE} from '../../actions/legalCases/legalCaseListAction';

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    legalCaseId: 0,
    legalCaseName: null,
    typeId: 0,
    assignedUserId: 0
}

export default function EditLegalCaseModal(state=INITIAL_STATE, action){
    switch(action.type){
        case OPEN_EDIT_LEGAL_CASE:
            return {...state, open: true, legalCaseId: action.payload.legalCaseId, legalCaseName: action.payload.legalCaseName, 
            typeId: action.payload.typeId, assignedUserId: action.payload.assignedUserId};
        case CLOSE_EDIT_LEGAL_CASE:
            return {...state, open: false, legalCaseId: 0, legalCaseName: null, typeId: 0, assignedUserId: 0};
        case `${EDIT_LEGAL_CASE}_PENDING`:
            return {...state, isProcessing: true};
        case `${EDIT_LEGAL_CASE}_SUCCESS`:
            return {...state, isProcessing: false, open: false, legalCaseId: 0, legalCaseName: null, typeId: 0, assignedUserId: 0};
        case `${EDIT_LEGAL_CASE}_REJECTED`:
            return {...state, isProcessing: false};
        default:
            return state;
    }
}