import {CHANGE_DOCUMENT_TYPE_LEGAL_CASE, OPEN_CHANGE_DOCUMENT_TYPE_LEGAL_CASE, CLOSE_CHANGE_DOCUMENT_TYPE_LEGAL_CASE} from '../../actions/legalCases/legalCaseListAction';

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    documentTypeId: null,
    legalCaseId: null
}

export default function ChangeDocumentTypeCaseModal(state = INITIAL_STATE, action){
    switch(action.type){
        case OPEN_CHANGE_DOCUMENT_TYPE_LEGAL_CASE:
            return {...state, open: true, legalCaseId: action.payload.legalCaseId, documentTypeId: action.payload.id};
        case CLOSE_CHANGE_DOCUMENT_TYPE_LEGAL_CASE:
            return {...state, open: false, legalCaseId: null, documentTypeId: null};
        case `${CHANGE_DOCUMENT_TYPE_LEGAL_CASE}_PENDING`:
            return {...state, isProcessing: true};
        case `${CHANGE_DOCUMENT_TYPE_LEGAL_CASE}_FULFILLED`:
            return {...state, isProcessing: false, open: false, legalCaseId: null, documentTypeId: null};
        case `${CHANGE_DOCUMENT_TYPE_LEGAL_CASE}_REJECTED`:
            return {...state, isProcessing: false, open: false, legalCaseId: null, documentTypeId: null};
        default:
            return state;
    }
}