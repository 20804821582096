import {OPENING_CLASSIFICATION_STATUS_MODAL, CLOSING_CLASSIFICATION_STATUS_MODAL, UPDATING_CLASSIFICATION_STATUS} from '../../actions/classifications/ClassificationListActions';

const INITIAL_STATE = {
    open: false,
    classificationId: null,
    isEnabled: null,
    isProcessing: false
}

export default function ClassificationStatusModal(state = INITIAL_STATE, action){
    switch(action.type){
        case OPENING_CLASSIFICATION_STATUS_MODAL:
            return {...state, open: true, classificationId: action.payload.classificationId, isProcessing: false, isEnabled: action.payload.isEnabled};
        case CLOSING_CLASSIFICATION_STATUS_MODAL:
            return {...state, open: false, classificationId: null, isProcessing: false, isEnabled: null};
        case `${UPDATING_CLASSIFICATION_STATUS}_FULFILLED`:
            return {...state, open: false, classificationId: null, isProcessing: false, isEnabled: null};
            case `${UPDATING_CLASSIFICATION_STATUS}_REQUESTED`:
            return {...state,  isProcessing: true};
        default:
            return state;
    }
}