import {FETCHING_REPORT, EXPORT_REPORT} from '../../actions/reports/reportActions';

const INITIAL_STATE = {
    data: null,
    columns: [],
    isFetching: false,
    pieData: {
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: ['lightgrey', 'lightgreen']
        }]
    },
    isExporting: false
};

export default function ReportReducer(state=INITIAL_STATE, action){
    switch(action.type){
        case `${FETCHING_REPORT}_PENDING`:
            return {...state, isFetching: true};
        case `${FETCHING_REPORT}_SUCCESS`:
            return {...state, isFetching: false, data: action.payload.data, columns: action.payload.newColumn,
                    pieData: {
                        ...state.pieData,
                        labels: action.payload.labels,
                        datasets: [{
                            data: action.payload.pieData,
                            backgroundColor: ['lightgrey', 'lightgreen']
                        }]
                    }};
        case `${FETCHING_REPORT}_REJECTED`:
            return {...state, isFetching: false, data: null, columns: []};
        case `${EXPORT_REPORT}_PENDING`:
            return {...state, isExporting: true};
        case `${EXPORT_REPORT}_SUCCESS`:
            return {...state, isExporting: false};
        case `${EXPORT_REPORT}_REJECTED`:
            return {...state, isExporting: false};
        default:
            return state;
    }
}