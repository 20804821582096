import {INVOICE_NOTIFICATION_BILL, OPEN_INVOICE_NOTIFICATION_MODAL, CLOSE_INVOICE_NOTIFICATION_MODAL} from '../../actions/bills/billActions';

const INITIAL_STATE = {
    open: false,
    billId: null,
    isProcessing: false
}

export default function InvoiceNotificationModal(state=INITIAL_STATE, action){
    switch(action.type){
        case OPEN_INVOICE_NOTIFICATION_MODAL:
            return {...state, open: true, billId: action.payload};
        case CLOSE_INVOICE_NOTIFICATION_MODAL:
            return {...state, open: false, billId: null};
        case `${INVOICE_NOTIFICATION_BILL}_PENDING`:
            return {...state, isProcessing: true};
        case `${INVOICE_NOTIFICATION_BILL}_FULFILLED`:
            return {...state, isProcessing: false, billId: null, open: false};
        case `${INVOICE_NOTIFICATION_BILL}_REJECTED`:
            return {...state, isProcessing: false, billId: null, open: false};
        default:
            return state;
    }
}