import React, { Component } from 'react'
import debounce from 'lodash/debounce';

import Container from '@material-ui/core/Container';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';

import { renderTextField, renderSelectField } from './../../constants/customElementsMUI';
import { Field, reduxForm } from 'redux-form';

import {ColorButtonBlue} from '../../constants/customElementsMUI';

import { getPagedLegalCases, handleChangePage, handleChangeRowsPerPage, initPagedLegalCases, handleSearch, openingLegalCaseModal, closingLegalCaseModal,
handleParentClient, handleChildClient, handleCaseType, handleLawyer, openingViewLegalCaseModal, closingViewLegalCaseModal, openingUploadCaseFileModal,
closingUploadCaseFileModal, openingReuploadCaseFileModal, closingReuploadCaseFIleModal, openingFinishCaseModal, closingFinishCaseModal,
finishingCase, openDeleteLegalCase, closeDeleteLegalCase, deleteLegalCase, openChangeOwnerLegalCase, closeChangeOwnerLegalCase, closeDeleteLegalCaseFile, deleteLegalCaseFile,
closeChangeDocumentTypeLegalCase, openChangeDocumentTypeLegalCase,
openEditLegalCase, closeEditLegalCase} from './../../actions/legalCases/legalCaseListAction';

import {getComments} from '../../actions/comments/commentActions';

import {getClients, getChildClients, getAllLawyers, getCaseTypes, clearChildClients} from '../../actions/catalog/catalogActions';

import VisibilityIcon from '@material-ui/icons/Visibility';
import PublishIcon from '@material-ui/icons/Publish';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import DeleteIcon from '@material-ui/icons/Delete';
import CommentIcon from '@material-ui/icons/Comment';
import EditIcon from '@material-ui/icons/Edit'

import IconButton from '@material-ui/core/IconButton';
import FormLegalCaseDialog from './formLegalCaseDialog';
import ViewLegalCaseDialog from './viewLegalCaseDialog';
import UploadCaseFileDialog from './uploadCaseFileDialog';
import ReuploadCaseFileDialog from './reuploadCaseFileDialog';
import FinishLegalCaseDialog from './finishLegalCaseDialog';
import DeleteLegalCaseDialog from './deleteLegalCaseDialog';
import DeleteLegalCaseFileDialog from './deleteLegalCaseFileDialog';
import ChangeOwnerCaseDialog from './changeOwnerCaseDialog';
import ChangeDocumentTypeCaseDialog from './changeDocumentTypeCaseDialog';
import EditLegalCaseDialog from './editLegalCaseDialog';


import Grid from '@material-ui/core/Grid';
import { green } from '@material-ui/core/colors';
import { isRootUser, isNonClient, hasModulePermission, modules } from './../../constants/api';
import Chip from '@material-ui/core/Chip';
import { ResponsiveTable } from 'material-ui-next-responsive-table';
import Tooltip from '@material-ui/core/Tooltip';
const columns = [
    { id: 'date', label: 'Fecha', minWidth: 120, align: 'center' },
    { id: 'caseName', label: 'Caso', minWidth: 120, align: 'center' },
    { id: 'clientName', label: 'Cliente', minWidth: 120, align: 'center' },
    { id: 'assignedUserName', label: 'Asignado a', minWidth: 120, align: 'center' },
    { id: 'typeName', label: 'Tipo', minWidth: 80, align: 'center' },
];

const styles = theme => ({
    root: {
      width: '100%',
      overflowX: 'auto',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    button: {
      margin: theme.spacing(3, 0, 2, 3),
      color: 'white'
    },
});

class LegalCasesLanding extends Component {

    debouncedOnSearchChange = debounce(this.props.handleSearch, 1000);

    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.props.getClients();
        this.props.getAllLawyers();
        this.props.getCaseTypes();
        this.props.initPagedLegalCases();
    }

    render() {
        const { classes } = this.props;

        let listItemsClasses = Object.assign({}, classes);

        const handleClickOpen = () => {
            this.props.openingLegalCaseModal()
        };
        const handleClose = () => {
            this.props.closingLegalCaseModal()
        };
        const handleViewClose = () => {
          this.props.closingViewLegalCaseModal();
        }
        const handleViewOpen = (id) => {
          this.props.openingViewLegalCaseModal(id);
        }
        const handleUploadClose = () => {
          this.props.closingUploadCaseFileModal();
        }
        const handleUploadOpen = (id) => {
          this.props.openingUploadCaseFileModal(id);
        }
        const handleReuploadClose = () =>{
          this.props.closingReuploadCaseFIleModal();
        }
        const handleReuploadOpen = (id) => {
          this.props.openingReuploadCaseFileModal(id);
        }
        const handleFinishClose = () => {
          this.props.closingFinishCaseModal();
        }
        const handleFinishOpen = (id) => {
          this.props.openingFinishCaseModal(id);
        }
        const handleFinishConfirmation = () => {
          this.props.finishingCase();
        }

        const handleNavigationToDocumentTYpe = () => {
          window.location='/documentTypes';
        }

        const handleNavigationToComment = (id) => {
          this.props.getComments(id);
          window.location=`legalCases/${id}/comments`;
        }

        const handleOpenDelete = (id) => {
          this.props.openDeleteLegalCase(id);
        }

        const handleCloseDelete = () => {
          this.props.closeDeleteLegalCase();
        }

        const handleCloseDeleteFile = () => {
          this.props.closeDeleteLegalCaseFile();
        }

        const handleDeleteConfirmation = () => {
          this.props.deleteLegalCase();
        }
        const handleDeleteFileConfirmation = () => {
          this.props.deleteLegalCaseFile();
        }

        const handleOpenChangeOwner = (id, parentClientId) => {
          this.props.openChangeOwnerLegalCase(id, parentClientId);
        }

        const handleCloseChangeOwner = () => {
          this.props.closeChangeOwnerLegalCase();
        }

        const handleOpenChangeDocumentType = (id, legalCaseId) => {
          this.props.openChangeDocumentTypeLegalCase(id, legalCaseId);
        }

        const handleCloseChangeDocumentType = () => {
          this.props.closeChangeDocumentTypeLegalCase();
        }

        const handleOpenEditLegalCase = (id, name, typeId, assignedUserId) => {
          this.props.openEditLegalCase(id, name, typeId, assignedUserId);
        }

        const handleCloseEditLegalCase = () => {
          this.props.closeEditLegalCase();
        }

        const newColumns = [
          { key: 'date', label: 'Fecha', primary: true },
          { key: 'caseName', label: 'Caso', primary: true },
          { key: 'clientName', label: 'Cliente', primary: true},
          { key: 'assignedUserName', label: 'Asignado a' },
          { key: 'typeName', label: 'Tipo'},
          { key: 'id', label: 'Acciones', render: (value, column) => {
            return (
              <div>
                <Tooltip title="Ver detalle">
                  
                <IconButton onClick={() => handleViewOpen(column.id)}>
                  <VisibilityIcon style={{ color: green[500] }}/>
                </IconButton>
                </Tooltip>

                
                          {column.isFinished === false && (
                            <Tooltip title="Subir nuevo">
                            <IconButton onClick={() => handleUploadOpen(column.id)}>
                              <PublishIcon style={{ color: green[500] }}/>
                            </IconButton>
                            </Tooltip>
                            
                          )}  
                          {this.props.isAdmin && column.canReupload === true && (
                            <Tooltip title="Resubir último">
                            <IconButton onClick={() => handleReuploadOpen(column.id)}>
                              <FileCopyIcon style={{ color: green[500] }}/>
                            </IconButton>
                            </Tooltip>
                          )}
                          
                          {this.props.isAdmin && column.isFinished === false && (
                            <Tooltip title="Finalizar caso">
                              
                            <IconButton onClick={() => handleFinishOpen(column.id)}>
                              <CheckCircleIcon style={{ color: green[500] }}/>
                            </IconButton>
                            </Tooltip>
                          )}
                          {this.props.isAdmin && column.isFinished === true && (
                            <Tooltip title="Reabrir caso">
                              
                            <IconButton onClick={() => handleFinishOpen(column.id)}>
                              <LockOpenIcon style={{ color: green[500] }}/>
                            </IconButton>
                            </Tooltip>
                          )}

                          {this.props.isAdmin && column.isDeleteable === true && (
                            <Tooltip title="Borrar caso">
                              
                            <IconButton onClick={() => handleOpenDelete(column.id)}>
                              <DeleteIcon style={{color: green[500]}} />
                            </IconButton>
                            </Tooltip>
                          )}
                          {this.props.isAdmin && (
                            <Tooltip title="Cambiar cliente dueño">
                              
                            <IconButton onClick={() => handleOpenChangeOwner(column.id, column.parentClientId)}>
                              <CompareArrowsIcon style={{color: green[500]}} />
                            </IconButton>
                            </Tooltip>
                          )}
                          {this.props.isAdmin && (
                            <Tooltip title="Editar caso">
                              <IconButton onClick={() => handleOpenEditLegalCase(column.id, column.caseName, column.typeId, column.assignedUserId)}>
                                <EditIcon style={{color: green[500]}} />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Chip 
                            label={`${column.commentsCount}`} 
                            clickable 
                            icon={<CommentIcon style={{color: green[500], marginTop: "20px", marginBottom: "20px"}} />}
                            style={{color: green[500], padding: '10px'}}
                            onClick={() => handleNavigationToComment(column.id)}
                            variant="outlined" /> 
              </div>
            );
          }}
        ];
        return (
            <React.Fragment>
            <h2>Casos</h2>
            <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Field
                              label="Buscar"
                              name="criteria"
                              type="text"
                              onChange={this.debouncedOnSearchChange}
                              component={renderTextField}
                          />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
              <div style={{height: "16px"}}></div>
                    <Field
                      label="Cliente"
                      name="parentClientId"
                      onChange={this.props.handleParentClient}
                      component={renderSelectField}
                    >
                      <option key={0} value={null}>Todos</option>
                                {this.props.clients !== undefined ? this.props.clients.map(client => {
                                    return(
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    )
                                }) : undefined}
                    </Field>
              </Grid>
              {this.props.isNonClient && (
                     <Grid item xs={12} sm={6} md={4} lg={3}>
                      <div style={{height: "16px"}}></div>
                    <Field
                      label="Subcliente"
                      name="subClientId"
                      onChange={this.props.handleChildClient}
                      component={renderSelectField}
                    >
                      <option key={0} value={null}>Todos</option>
                                {this.props.childClients !== undefined ? this.props.childClients.map(client => {
                                    return(
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    )
                                }) : undefined}
                    </Field>
                    </Grid>
                  )}
              <Grid item xs={12} sm={6} md={4} lg={3}>
              <div style={{height: "16px"}}></div>
                        <Field
                        label="Tipo de caso"
                        name="caseType"
                        onChange={this.props.handleCaseType}
                        component={renderSelectField}
                        >
                        <option key={0} value={null}>Todos</option>
                                    {this.props.caseTypes !== undefined ? this.props.caseTypes.map(type => {
                                        return(
                                            <option key={type.id} value={type.id}>{type.name}</option>
                                        )
                                    }) : undefined}
                        </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
              <div style={{height: "16px"}}></div>
                        <Field
                        label="Asignado a"
                        name="lawyer"
                        onChange={this.props.handleLawyer}
                        component={renderSelectField}
                        >
                        <option key={0} value={null}>Todos</option>
                                    {this.props.allLawyers !== undefined ? this.props.allLawyers.map(lawyer => {
                                        return(
                                            <option key={lawyer.id} value={lawyer.id}>{lawyer.name}</option>
                                        )
                                    }) : undefined}
                        </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
              
                <ColorButtonBlue variant="contained" className={classes.button} onClick={handleClickOpen}>Nuevo Caso</ColorButtonBlue>
              
                
                <FormLegalCaseDialog open={this.props.legalCaseModal.open} handleClose={handleClose}/>
                <ViewLegalCaseDialog open={this.props.viewLegalCaseModal.open} handleClose={handleViewClose}/>
                <UploadCaseFileDialog open={this.props.uploadCaseFileModal.open} handleClose={handleUploadClose}/>
                <ReuploadCaseFileDialog open={this.props.reuploadCaseFileModal.open} handleClose={handleReuploadClose} />
                <FinishLegalCaseDialog  open={this.props.finishCaseModal.open} handleClose={handleFinishClose} handleConfirmation={handleFinishConfirmation} />
                <DeleteLegalCaseDialog open={this.props.deleteCaseModal.open} handleClose={handleCloseDelete} handleConfirmation={handleDeleteConfirmation}
                isProcessing={this.props.deleteCaseModal.isProcessing} />
                <DeleteLegalCaseFileDialog open={this.props.deleteCaseFileModal.open} handleClose={handleCloseDeleteFile} handleConfirmation={handleDeleteFileConfirmation}
                isProcessing={this.props.deleteCaseFileModal.isProcessing} />
                <ChangeOwnerCaseDialog open={this.props.changeOwnerCaseModal.open} handleClose={handleCloseChangeOwner} />
                <ChangeDocumentTypeCaseDialog open={this.props.changeDocumentTypeLegalCaseModal.open} handleClose={handleCloseChangeDocumentType} />
                <EditLegalCaseDialog open={this.props.editLegalCaseModal.open} handleClose={handleCloseEditLegalCase} />
              </Grid>
              
              
              <Grid item xs={12} sm={6} md={3} lg={3}>
                                {this.props.canAccessDocumentTypes && (
                                  <ColorButtonBlue variant="contained" className={classes.button} onClick={handleNavigationToDocumentTYpe}>Nuevo Tipo de Documento</ColorButtonBlue>
                                )}
              </Grid>
            </Grid>
            
            </form>
            <Paper>
            <ResponsiveTable
              excludePrimaryFromDetails={true}
              columns={newColumns}
              data={this.props.data}
              showPagination={true}
              count={this.props.totalCount}
              rowsPerPage={this.props.pageSize}
              page={this.props.currentPage}
              noContentText={'No hay registros'}
              onChangePage={this.props.handleChangePage}
              TableProps={{dense: true, table: "true", size: "small"}}
              TableHeadCellProps= {{align: 'center', style: {"fontWeight": "bold", "backgroundColor": "#D3D3D3", color: "#60b044"}}}
              TableBodyCellProps={{align: 'center'}}
              TablePaginationProps={{
                labelRowsPerPage: "Cantidad de Filas",
                rowsPerPageOptions: [10, 25, 50, 100],
                component: "div",
                onChangeRowsPerPage: this.props.handleChangeRowsPerPage
              }}
              ExpansionPanelSummaryTypographyProps={{style: {"fontWeight": "bold"}}}
              ExpansionPanelDetailsTypographyProps={{ classes:listItemsClasses}}
            />
            </Paper>
            {/* <Paper  className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table stickyHeader aria-label="Clients table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      key='actions'
                      align='center'
                      style={{minWidth:80, fontWeight: 'bold'}}
                    >
                      Acciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.data.map(row => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                        <TableCell align='center'>
                          <IconButton onClick={() => handleViewOpen(row.id)}>
                            <VisibilityIcon style={{ color: green[500] }}/>
                          </IconButton>
                          {row.isFinished === false && (
                            <IconButton onClick={() => handleUploadOpen(row.id)}>
                              <PublishIcon style={{ color: green[500] }}/>
                            </IconButton>
                          )}  
                          {this.props.isAdmin && row.canReupload === true && (
                            <IconButton onClick={() => handleReuploadOpen(row.id)}>
                              <FileCopyIcon style={{ color: green[500] }}/>
                            </IconButton>
                          )}
                          
                          {this.props.isAdmin && row.canBeFinished === true && (
                            <IconButton onClick={() => handleFinishOpen(row.id)}>
                              <CheckCircleIcon style={{ color: green[500] }}/>
                            </IconButton>
                          )}
                          <Chip 
                            style={{padding: "10px"}}
                            label={`${row.commentsCount} comentario${row.commentsCount == 0 ? 's' : row.commentsCount > 1 ? 's' : ''}`} 
                            clickable 
                            icon={<CommentIcon style={{color: green[500], marginTop: "20px", marginBottom: "20px"}} />}
                            style={{color: green[500]}}
                            onClick={() => handleNavigationToComment(row.id)}
                            variant="outlined" />                                     
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={this.props.totalCount}
            rowsPerPage={this.props.pageSize}
            page={this.props.currentPage}
            onChangePage={this.props.handleChangePage}
            onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
            labelRowsPerPage={"Cantidad de Filas"}
            labelDisplayedRows={	({ from, to, count }) =>`${from}-${to === -1 ? count : to} de ${count}`}
          />
        </Paper> */}
          <br></br>
          <br></br>
      </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {pagedLegalCases, legalCaseModal, deleteCaseFileModal, viewLegalCaseModal, catalog, uploadCaseFileModal, reuploadCaseFileModal, finishCaseModal, deleteCaseModal,
      changeOwnerCaseModal, changeDocumentTypeLegalCaseModal, editLegalCaseModal} = state;
    const {clients, childClients, allLawyers, caseTypes} = catalog;
    const {user} = state.auth;
    let isAdmin = isRootUser(user);
    let isNotClient = isNonClient(user);
    let canAccessDocumentTypes = hasModulePermission(user, modules.documentTypes);
    return {...pagedLegalCases, legalCaseModal, viewLegalCaseModal, clients, childClients, allLawyers, caseTypes, 
        isAdmin, isNonClient: isNotClient, canAccessDocumentTypes, uploadCaseFileModal, reuploadCaseFileModal,
        finishCaseModal, deleteCaseModal, changeOwnerCaseModal, deleteCaseFileModal, changeDocumentTypeLegalCaseModal,
        editLegalCaseModal};
}

const mapDispatchToProps = {
    getPagedLegalCases,
    handleChangePage,
    handleChangeRowsPerPage,
    initPagedLegalCases,
    handleSearch,
    openingLegalCaseModal,
    closingLegalCaseModal,
    handleParentClient,
    handleChildClient,
    handleLawyer,
    handleCaseType,
    getClients, 
    getChildClients, 
    getAllLawyers,
    getCaseTypes, 
    clearChildClients,
    closeDeleteLegalCaseFile,
    deleteLegalCaseFile,
    openingViewLegalCaseModal,
    closingViewLegalCaseModal,
    openingUploadCaseFileModal,
    closingUploadCaseFileModal,
    openingReuploadCaseFileModal,
    closingReuploadCaseFIleModal,
    openingFinishCaseModal,
    closingFinishCaseModal,
    finishingCase,
    getComments,
    openDeleteLegalCase,
    closeDeleteLegalCase,
    deleteLegalCase,
    openChangeOwnerLegalCase,
    closeChangeOwnerLegalCase,
    closeChangeDocumentTypeLegalCase,
    openChangeDocumentTypeLegalCase,
    openEditLegalCase,
    closeEditLegalCase
};

export default withStyles(styles)(reduxForm({form:'LegalCaseListForm'})(connect(mapStateToProps, mapDispatchToProps)(LegalCasesLanding)));