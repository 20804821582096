import {OPENING_UPDATING_CLIENT_PASSWORD_MODAL, CLOSING_UPDATING_CLIENT_PASSWORD_MODAL, UPDATING_CLIENT_PASSWORD} from '../../actions/clients/ClientListActions';

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    clientId: null,
    userId: null
}

export default function UpdatingClientPasswordModal(state=INITIAL_STATE, action){
    switch(action.type){
        case OPENING_UPDATING_CLIENT_PASSWORD_MODAL:
            return {...state, open: true, isProcessing:false, clientId: action.payload.clientId, userId: action.payload.userId};
        case CLOSING_UPDATING_CLIENT_PASSWORD_MODAL:
            return {...state, open: false, isProcessing: false, clientId: null, userId: null};
        case `${UPDATING_CLIENT_PASSWORD}_PENDING`:
            return {...state, isProcessing: true};
        case `${UPDATING_CLIENT_PASSWORD}_FULFILLED`:
            return {...state, isProcessing: false, open: false, clientId: null, useId: null};
        case `${UPDATING_CLIENT_PASSWORD}_REJECTED`:
            return {...state, isProcessing: false, open: false, clientId: null, userId: null};
        default:
            return state;
    }
}