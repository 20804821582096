import {OPENING_CLIENT_USER_MODAL, CLOSING_CLIENT_USER_MODAL, EDITING_CLIENT_USER, 
    CREATING_NEW_CLIENT_USER} from '../../actions/clients/ClientListActions';

const INITIAL_STATE = {
    open: false,
    editing: false,
    clientId: 0,
    user: null,
    isProcessing: false
}

export default function ClientUserFormModal(state = INITIAL_STATE, action){
    switch(action.type){
        case OPENING_CLIENT_USER_MODAL:
            return {...state, open: true, editing: action.payload.isEditing, clientId: action.payload.clientId, user: action.payload.userData, isProcessing: false}
        case CLOSING_CLIENT_USER_MODAL:
            return {...state, open: false, editing: false, clientId: 0, user: null, isProcessing: false};
        case `${EDITING_CLIENT_USER}_PENDING`:
            return {...state, isProcessing: true};
        case `${EDITING_CLIENT_USER}_FULFILLED`:
            return {...state, isProcessing: false, open: false, editing: false, clientId: 0, user: null};
        case `${EDITING_CLIENT_USER}_REJECTED`:
            return {...state, isProcessing: false};
        case `${CREATING_NEW_CLIENT_USER}_PENDING`:
            return {...state, isProcessing: true};
        case `${CREATING_NEW_CLIENT_USER}_FULFILLED`:
            return {...state, isProcessing: false, open: false, editing: false, clientId: 0, user: null};
        case `${CREATING_NEW_CLIENT_USER}_REJECTED`:
            return {...state, isProcessing: false};
        default:
            return state;
    }
}