import {CREATING_NEW_BILLS, OPENING_BILLS_MODAL, CLOSING_BILLS_MODAL, EDIT_BILL, OPEN_EDIT_BILL, SET_PARENT_CLIENT_BILL} from '../../actions/bills/billActions';

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    bill: null,
    editing: false,
    isFetchingBill: false,
    parentClientId: null,
    readOnly: false,
    canPay: false
}

export default function BIllModalReducer(state=INITIAL_STATE, action){
    switch(action.type){
        case OPENING_BILLS_MODAL:
            return {...state, open: true, editing: false, parentClientId: null};
        case CLOSING_BILLS_MODAL:
            return {...state, open: false, editing: false, parentClientId: null, bill: null, readOnly: false};
        case `${CREATING_NEW_BILLS}_PENDING`:
            return {...state, isProcessing: true};
        case `${CREATING_NEW_BILLS}_FULFILLED`:
            return {...state, isProcessing: false, open: false, editing: false};
        case `${CREATING_NEW_BILLS}_REJECTED`:
            return {...state, isProcessing: false, open: false, editing: false};
        case `${OPEN_EDIT_BILL}_PENDING`:
            return {...state, isFetchingBill: true};
        case `${OPEN_EDIT_BILL}_FULFILLED`:
            return {...state, isFetchingBill: false, bill: action.payload.bill, editing: action.payload.editing, readOnly:action.payload.readOnly, 
                canPay: action.payload.canPay, open: true};
        case `${OPEN_EDIT_BILL}_REJECTED`:
            return {...state, isFetchingBill: false, bill: null, editing: false, open: false, readOnly: false};
        case `${EDIT_BILL}_PENDING`:
            return {...state, isProcessing: true};
        case `${EDIT_BILL}_FULFILLED`:
            return {...state, isProcessing: false, bill: null, editing: false, open: false};
        case `${EDIT_BILL}_REJECTED`:
            return {...state, isProcessing: false, bill: null, editing: false, open: false};
        case SET_PARENT_CLIENT_BILL:
            return {...state, parentClientId: action.payload};
        default:
            return state;
    }
}