import {CREATING_NEW_CLIENT, OPENING_CLIENT_MODAL, CLOSING_CLIENT_MODAL, OPENING_EDITING_MODAL, EDITING_CLIENT, OPENING_VIEW_MODAL} from './../../actions/clients/ClientListActions';

const INITIAL_STATE = {
    open: false,
    editing: false,
    client: null,
    readOnly: false,
    isProcessing: false
}

export default function ClientModal(state = INITIAL_STATE, action){
    switch(action.type){
        case `${CREATING_NEW_CLIENT}_REQUESTED`:
            return {...state, isProcessing: true};
        case `${CREATING_NEW_CLIENT}_FULFILLED`:
            return {...state, open: false, isProcessing: false};
        case `${CREATING_NEW_CLIENT}_REJECTED`:
            return {...state, open: false, isProcessing: false};
        case OPENING_CLIENT_MODAL:
            return {...state, open: true, editing: false, readOnly: false, isProcessing: false};
        case OPENING_EDITING_MODAL:
            return {...state, open: true, editing: true, client: action.payload.client, readOnly: false, isProcessing: false};
        case OPENING_VIEW_MODAL:
            return {...state, open: true, editing: false, client: action.payload.client, readOnly: true, isProcessing: false}
        case CLOSING_CLIENT_MODAL:
            return {...state, open: false, editing: false, client: null, readOnly: false, isProcessing: false};
        case `${EDITING_CLIENT}_REQUESTED`:
            return {...state, isProcessing: true};
        case `${EDITING_CLIENT}_FULFILLED`:
            return {...state, client: null, editing: false, open: false, readOnly: false, isProcessing: false};
        case `${EDITING_CLIENT}_REJECTED`:
            return {...state, client: null, editing: false, open: false, readOnly: false, isProcessing: false};
        default:
            return state;
    }
}