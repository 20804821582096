/* eslint-disable import/no-named-as-default */
import { NavLink, Route, Switch } from "react-router-dom";

import {
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom';

import PropTypes from "prop-types";
import React, {Component} from "react";
import { hot } from "react-hot-loader";
import Login from './Login';
import Home from './Home';
import PrivateRoute from './privateRoute';
import { connect } from 'react-redux';
import ClientLanding from './clients/ClientLanding';
import UserLanding from './users/UserLanding';
import DocumentLanding from './documents/documentsLanding';
import Notifier from './Notifier';
import PasswordReset from './passwordReset/PasswordReset';
import DocumentTypesLanding from './documentTypes/documentTypesLanding';
import CommentsLanding from './comments/commentsLanding';
import FilePreview from './FilePreview';
import { modules } from './../constants/api';
import { logoutUser } from './../actions/LoginActions';

import ClassificationLanding from './classifications/ClassificationLanding';
import LegalCaseLanding from './legalCases/legalCasesLanding';
import ReportsLanding from './reports/reportsLanding';

import BillsLanding from './bills/billsLanding';
// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends Component {
  render() {
    return (
      <div>
        
        <Switch>
            <Route exact path='/login' component={Login}/>
            <Route exact path='/passwordReset' component={PasswordReset}/>
            <PrivateRoute
            exact path='/'
            component={Home}
            userData={this.props.userData}
            moduleId={null}
            isAuthenticated={this.props.isAuthenticated}
            onExit = {() => {this.props.logoutUser();}}
            />          
            <PrivateRoute
            exact path='/clients'
            component={ClientLanding}
            userData={this.props.userData}
            moduleId={modules.clients}
            isAuthenticated={this.props.isAuthenticated}
            onExit = {() => {this.props.logoutUser();}}
            />          
            <PrivateRoute
            exact path='/documents'
            component={DocumentLanding}
            userData={this.props.userData}
            moduleId={modules.documents}
            isAuthenticated={this.props.isAuthenticated}
            onExit = {() => {this.props.logoutUser();}}
            />
            <PrivateRoute
            exact path='/classifications'
            moduleId={modules.classifications}
            userData={this.props.userData}
            component={ClassificationLanding}
            isAuthenticated={this.props.isAuthenticated}
            onExit = {() => {this.props.logoutUser();}}
            />
           <PrivateRoute
            exact path='/users'
            component={UserLanding}
            moduleId={modules.users}
            userData={this.props.userData}
            isAuthenticated={this.props.isAuthenticated}
            onExit = {() => {this.props.logoutUser();}}
            />
          <PrivateRoute 
            exact path='/documentTypes'
            component={DocumentTypesLanding}
            moduleId={modules.documentTypes}
            userData={this.props.userData}
            isAuthenticated={this.props.isAuthenticated}
            onExit={() => {this.props.logoutUser();}}
          />
          <PrivateRoute
            exact path='/legalCases'
            component={LegalCaseLanding}
            moduleId={modules.cases}
            userData={this.props.userData}
            isAuthenticated={this.props.isAuthenticated}
            onExit={() => {this.props.logoutUser();}}
          />
          <PrivateRoute
            exact path='/legalCases/:id/comments'
            component={CommentsLanding}
            moduleId={modules.cases}
            userData={this.props.userData}
            isAuthenticated={this.props.isAuthenticated}
            onExit={() => {this.props.logoutUser();}}
          />
          <PrivateRoute
            exact path='/bills'
            component={BillsLanding}
            moduleId={modules.bills}
            userData={this.props.userData}
            isAuthenticated={this.props.isAuthenticated}
            onExit={() => {this.props.logoutUser();}}
          />
          <PrivateRoute 
            exact path='/reports'
            component={ReportsLanding}
            moduleId={modules.reports}
            userData={this.props.userData}
            isAuthenticated={this.props.isAuthenticated}
            onExit={() => {this.props.logoutUser();}}
          />
          <Route
            exact path='/filePreview'>
              <FilePreview/>
            </Route>
          
        </Switch>
        <Notifier/>
      </div>
        
        
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

const mapStateToProps = state => {
  let { isAuthenticated, user } = state.auth;
  return { isAuthenticated, userData: user };
}

const mapDispatchToProps = {
  logoutUser
}
export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(App));
