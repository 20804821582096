import {
SET_IS_ACCOUNT_LOCKED,
DISABLE_MUST_CHANGE_PWD,
UPDATE_USER_INFO,
RESET_LOGIN,
LOGIN_REQUEST,
LOGOUT_SUCCESS,
REINITIALIZING_SESSION
} from '../actions/LoginActions';

const INITIAL_STATE = {
    isAuthenticated: false,
    token: null,
    loginError: false,
    user: null,
    headers: '',
    headersMultipart: '',
    isLocked: false
};

export default function Auth(state=INITIAL_STATE, action){
    let  isFetching, isAuthenticated, token, user,  headers, headersMultipart;
    switch(action.type){
        case SET_IS_ACCOUNT_LOCKED:
            return {...state, isLocked: action};
        case DISABLE_MUST_CHANGE_PWD:
            return {...state, user:{...state.user, ...action.payload}};
        case UPDATE_USER_INFO:
            return {...state, user:{...state.user, ...action.payload}};
        case RESET_LOGIN:
            return {...INITIAL_STATE};
        case LOGIN_REQUEST:
            ({isFetching, isAuthenticated} = action);
            return {...state, isFetching, isAuthenticated};
        case `${LOGIN_REQUEST}_FULFILLED`:
            ( { token, user, headers, headersMultipart } = action.payload );
            return {...state, isFetching:false, isAuthenticated: true, loginError: false, token, user, headers, headersMultipart}
        case `${LOGIN_REQUEST}_REJECTED`:
            return ({...state, isFetching: false, isAuthenticated: false, loginError: true});
        case `${LOGIN_REQUEST}_PENDING`:
            return state;
        case LOGOUT_SUCCESS:
            return {...state, isFetching, isAuthenticated, headers: '',  headersMultipart: '', token: null, user: null};
        case REINITIALIZING_SESSION:
            ({ user, token, headers, headersMultipart } = action);
            return {...state, user, token, isAuthenticated: true, headers, headersMultipart};
        default:
            return state;
    }
}