import {BAN_CLIENT_TO_USER, REMOVE_CLIENT_FROM_BAN_LIST, CREATING_NEW_USER, OPENING_USER_MODAL, CLOSING_USER_MODAL, OPENING_EDITING_MODAL, EDITING_USER, OPENING_VIEW_MODAL} from './../../actions/users/UserListActions';

const INITIAL_STATE = {
    open: false,
    editing: false,
    readOnly: false,
    user: {
        bannedClients : []
    }
}

export default function ClientModal(state = INITIAL_STATE, action){
    switch(action.type){
        case BAN_CLIENT_TO_USER:
            return {...state, user: {...state.user, bannedClients: [...state.user.bannedClients].concat([action.payload.client])}};
        case REMOVE_CLIENT_FROM_BAN_LIST: 
            return {...state, user: {...state.user, bannedClients: state.user.bannedClients.filter(c => c.id != action.payload.clientId )}};

        case `${CREATING_NEW_USER}_FULFILLED`:
            return {...state, open: false};
        case OPENING_USER_MODAL:
            return {...state, open: true, readOnly: false, editing: false};
        case OPENING_EDITING_MODAL:
            return {...state, open: true, editing: true, readOnly: false, user: action.payload.user};

        case OPENING_VIEW_MODAL:
        return {...state, open: true, editing: true, readOnly: true, user: action.payload.user};
        case CLOSING_USER_MODAL:
            return {...state, open: false, editing: false, readOnly: false, user: {
                bannedClients : []
            }};
        case `${EDITING_USER}_FULFILLED`:
            return {...state, user: {
                bannedClients : []
            }, editing: false, readOnly: false, open: false};
        default:
            return state;
    }
}