import authAxios, {documentTypes} from '../../constants/api';
import {reset} from 'redux-form';

export const FETCH_DOCUMENT_TYPES_START = 'FETCH_DOCUMENT_TYPES_START';
export const FETCH_DOCUMENT_TYPES_SUCCESS = 'FETCH_DOCUMENT_TYPES_SUCCESS';
export const FETCH_DOCUMENT_TYPES_FAILURE = 'FETCH_DOCUMENT_TYPES_FAILURE';
export const SET_DOCUMENT_TYPES_CURRENT_PAGE = 'SET_DOCUMENT_TYPES_CURRENT_PAGE';
export const SET_DOCUMENT_TYPES_CRITERIA = 'SET_DOCUMENT_TYPES_CRITERIA';
export const SET_DOCUMENT_TYPES_PAGE_SIZE = 'SET_DOCUMENT_TYPES_PAGE_SIZE';
export const CREATING_NEW_DOCUMENT_TYPE = 'CREATING_NEW_DOCUMENT_TYPE';
export const OPENING_DOCUMENT_TYPE_MODAL = 'OPENING_DOCUMENT_TYPE_MODAL';
export const CLOSING_DOCUMENT_TYPE_MODAL = 'CLOSING_DOCUMENT_TYPE_MODAL';
export const OPENING_EDITING_DOCUMENT_TYPE_MODAL = 'OPENING_EDITING_DOCUMENT_TYPE_MODAL';

export const EDITING_DOCUMENT_TYPE = 'EDITING_DOCUMENT_TYPE';

export const OPENING_DOCUMENT_TYPE_STATUS_MODAL = 'OPENING_DOCUMENT_TYPE_STATUS_MODAL';
export const CLOSING_DOCUMENT_TYPE_STATUS_MODAL = 'CLOSING_DOCUMENT_TYPE_STATUS_MODAL';
export const UPDATING_DOCUMENT_TYPE_STATUS = 'UPDATING_DOCUMENT_TYPE_STATUS';

export const DELETE_DOCUMENT_TYPE = 'DELETE_DOCUMENT_TYPE';
export const OPENING_DELETE_DOCUMENT_TYPE = 'OPENING_DELETE_DOCUMENT_TYPE';
export const CLOSING_DELETE_DOCUMENT_TYPE = 'CLOSING_DELETE_DOCUMENT_TYPE';

import {getDocumentTypes} from '../catalog/catalogActions';

export function handleSearch(event, newValue, previousValue, name) {
    return dispatch => {
      dispatch(setCriteria(newValue));
      return dispatch(getPagedDocumentTypes());
    }
  }
  
  export function handleChangePage(event, newPage){
    return dispatch => {
      dispatch(setPage(newPage));
      return dispatch(getPagedDocumentTypes());
    }
  }
  
  export function handleChangeRowsPerPage(event) {
    
    return dispatch => {
      dispatch(setPageSize(event.target.value));
      return dispatch(getPagedDocumentTypes());
    }
  }
  
  export function startFetchDocumentTypesList() {
    return {
      type: FETCH_DOCUMENT_TYPES_START
    };
  }
  
  export function setPage(page) {
    return {
      type: SET_DOCUMENT_TYPES_CURRENT_PAGE,
      payload: page
    };
  }
  
  
  export function setPageSize(pageSize) {
    return {
      type: SET_DOCUMENT_TYPES_PAGE_SIZE,
      payload: pageSize
    };
  }
  
  export function setCriteria(criteria) {
    return {
      type: SET_DOCUMENT_TYPES_CRITERIA,
      payload: criteria
    };
  }
  
  
  
  export function processDocumentTypesList(data) {
    return {
      type: FETCH_DOCUMENT_TYPES_SUCCESS,
      payload: data
    };
  }
  
  export function processDocumentTypesFailure() {
    return {
      type: FETCH_DOCUMENT_TYPES_FAILURE
    }
  }
  export function initPagedDocumentTypes() {
    return (dispatch) => {
      dispatch(setCriteria(''));
      dispatch(setPage(0));
      dispatch(setPageSize(25));
      return dispatch(getPagedDocumentTypes());
    };
  }
  export function getPagedDocumentTypes() {
    return (dispatch, getState) => {
      
      const { auth, pagedDocumentTypes } = getState();
      dispatch(startFetchDocumentTypesList());
      let pagedPayload = {
        page: pagedDocumentTypes.currentPage + 1,
        pageSize: pagedDocumentTypes.pageSize,
        criteria: pagedDocumentTypes.criteria
      }
      authAxios.get(documentTypes, {params: pagedPayload}, {headers: auth.headers})
        .then((response) => {
          return dispatch(processDocumentTypesList(response.data));
          
        })
        .catch(e => {
          return dispatch(processDocumentTypesListFailure());
        });
    }
  }
export function createDocumentType(info){
    return (dispatch, getState) => {
        const {auth} = getState();
        if(!info.canFinishCase){
            info.canFinishCase = false;
          }
        dispatch({type: `${CREATING_NEW_DOCUMENT_TYPE}_PENDING`});
        authAxios.post(documentTypes, JSON.stringify(info), {headers: auth.headers})
        .then(response => {
            dispatch({type: `${CREATING_NEW_DOCUMENT_TYPE}_FULFILLED`});
            dispatch(reset('DocumentTypeForm'));
            dispatch(getPagedDocumentTypes());
            dispatch(getDocumentTypes());
        })
        .catch(e => {
            dispatch(reset('DocumentTypeForm'));
            dispatch({type: `${CREATING_NEW_DOCUMENT_TYPE}_REJECTED`, payload: e});
        })
    }
}

export function openingModal(){
    return dispatch => {
        dispatch({type: OPENING_DOCUMENT_TYPE_MODAL});
    }
}

export function closingModal(){
    return dispatch => {
        dispatch({type: CLOSING_DOCUMENT_TYPE_MODAL});
    }
}

export function openingEditingModal(info){
    return(dispatch, getState) => {
        const {auth} = getState();
        authAxios.get(`${documentTypes}/${info}`, {headers: auth.headers})
        .then(response => {
            dispatch({
                type: OPENING_EDITING_DOCUMENT_TYPE_MODAL,
                payload: {
                    documentType: response.data
                }
            })
        })
    }
}

export function editingDocumentType(info){
    return(dispatch, getState) => {
        const {auth, documentTypeModal} = getState();
        const {id} = documentTypeModal.documentType;
        dispatch({type: `${EDITING_DOCUMENT_TYPE}_PENDING`});
        authAxios.put(`${documentTypes}/${id}`, JSON.stringify(info), {headers: auth.headers})
        .then(response => {
            dispatch({type: `${EDITING_DOCUMENT_TYPE}_FULFILLED`});
            dispatch(reset('DocumentTypeForm'));
            dispatch(getPagedDocumentTypes());
            dispatch(getDocumentTypes());
        })
        .catch(e => {
            dispatch({type: `${EDITING_DOCUMENT_TYPE}_REJECTED`, payload: e});
        })
    }
}

export function openingDocumentTypeStatusModal(id, isEnabled){
    return dispatch => {
        dispatch({
            type: OPENING_DOCUMENT_TYPE_STATUS_MODAL,
            payload: {
                documentTypeId: id,
                isEnabled
            }
        })
    }
}

export function closingDocumentTypeStatusModal(){
    return dispatch => {
        dispatch({type: CLOSING_DOCUMENT_TYPE_STATUS_MODAL});
    }
}

export function updateDocumentTypeStatus(){
    return (dispatch, getState) => {
        const {auth, documentTypeStatusModal} = getState();
        const {documentTypeId, isEnabled} = documentTypeStatusModal;
        let payload = {
            isEnabled: !isEnabled
        }
        dispatch({type: `${UPDATING_DOCUMENT_TYPE_STATUS}_PENDING`});
        authAxios.put(`${documentTypes}/${documentTypeId}/status`, JSON.stringify(payload), {headers: auth.headers})
        .then(response => {
            dispatch({
                type: `${UPDATING_DOCUMENT_TYPE_STATUS}_FULFILLED`,
            });
            dispatch(getPagedDocumentTypes());
            return dispatch(getDocumentTypes());
        })
        .catch(e => {
            dispatch({type: `${UPDATING_DOCUMENT_TYPE_STATUS}_REJECTED`, payload: e});
        });
    }
}

export function deleteDocumentType(){
  return (dispatch, getState) => {
    dispatch({type: `${DELETE_DOCUMENT_TYPE}_PENDING`});
    const {auth, deleteDocumentTypeModal} = getState();
    authAxios.delete(`${documentTypes}/${deleteDocumentTypeModal.documentTypeId}`, {headers: auth.headers})
    .then(() => {
      dispatch({type: `${DELETE_DOCUMENT_TYPE}_FULFILLED`});
      dispatch(getPagedDocumentTypes());
      return dispatch(getDocumentTypes());
    })
    .catch(e => {
      return dispatch({type: `${DELETE_DOCUMENT_TYPE}_REJECTED`, payload: e});
    })
  }
}

export function openDeleteDocumentType(id){
  return dispatch => {
    return dispatch({type: OPENING_DELETE_DOCUMENT_TYPE, payload: id});
  }
}

export function closeDeleteDocumentType(){
  return dispatch => {
    return dispatch({type: CLOSING_DELETE_DOCUMENT_TYPE});
  }
}