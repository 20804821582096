import {UPLOAD_NEW_INVOICE_BILL, OPEN_UPLOAD_NEW_INVOICE_BILL_MODAL, CLOSE_UPLOAD_NEW_INVOICE_BILL_MODAL} from '../../actions/bills/billActions';

const INITIAL_STATE = {
    open: false,
    billId: null,
    isProcessing: false
}

export default function UploadNewInvoiceModal(state=INITIAL_STATE, action){
    switch(action.type){
        case OPEN_UPLOAD_NEW_INVOICE_BILL_MODAL:
            return {...state, open: true, billId: action.payload};
        case CLOSE_UPLOAD_NEW_INVOICE_BILL_MODAL:
            return {...state, open: false, billId: null};
        case `${UPLOAD_NEW_INVOICE_BILL}_PENDING`:
            return {...state, isProcessing: true};
        case `${UPLOAD_NEW_INVOICE_BILL}_FULFILLED`:
            return {...state, isProcessing: false, open: false, billId: null};
        case `${UPLOAD_NEW_INVOICE_BILL}_REJECTED`:
            return {...state, isProcessing: false, open: false, billId: null};
        default:
            return state;
    }
}