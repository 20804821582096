import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import MyFile from '../fileUploader/fileUploader';

import {reuploadCaseFile} from '../../actions/legalCases/legalCaseListAction';

import { connect } from 'react-redux';

const validate = values => {
    const errors = {};
    let requiredFields = [
        'document',
    ];
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });
    /*if(values.user && emailRegex.test(values.email)){
    errors.email = 'Usuario inválido';
    }*/
    return errors;
}

class ReuploadCaseFileDialog extends Component {
    constructor(props){
        super(props);
    }

    handleFormSubmit = values => {
        const fd = new FormData();
        fd.append("document", values.document[0]);
        this.props.reuploadCaseFile(fd);
    }

    render() {
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                disableBackdropClick={this.props.isProcessing}
                disableEscapeKeyDown={this.props.isProcessing}
                >
                    <DialogTitle id="form-dialog-title">Resubir último archivo</DialogTitle>
                    <form onSubmit={handleSubmit(this.handleFormSubmit)} noValidate autoComplete='off'>
                    <DialogContent>
                        <DialogContentText>
                            Se procederá a sobreescribir el último archivo subido a este caso
                        </DialogContentText>
                        <div>                            
                            <Field label={"Documento a subir"} name="document" component={MyFile} type="file"
                            dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                        </div>                                               
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" disabled={submitting||this.props.isProcessing}>
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary" disabled={pristine||submitting||this.props.isProcessing}>
                            Guardar
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>       
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {reuploadCaseFileModal} = state;
    return {reuploadCaseFileModal, isProcessing: reuploadCaseFileModal.isProcessing};
}

const mapDispatchToProps = {
    reuploadCaseFile
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'ReUploadCaseFileForm', validate})(ReuploadCaseFileDialog));
