import React, {Component} from 'react';
import debounce from 'lodash/debounce';

import Container from '@material-ui/core/Container';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import { getPagedClients, handleChangePage, handleChangeRowsPerPage, initPagedClients, handleSearch, openingClientModal, closingClientModal, openingEditingModal,
openingDeletingClientModal, closingDeletingClientModal, deletingCLient, handleParentClient, openingChangingLoginStatusClientModal, closingChangingLoginStatusClientModal,
changeLoginStatusClient, openingUpdatingPasswordModal, closingUpdatingPasswordModal,
closingClientUserModal, closingDeletingClientUserModal, deletingClientUser} from './../../actions/clients/ClientListActions';
import { renderTextField, renderSelectField } from './../../constants/customElementsMUI';
import { Field, reduxForm } from 'redux-form';

import {ColorButtonBlue} from '../../constants/customElementsMUI';

import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DeleteIcon from '@material-ui/icons/Delete';

import Grid from '@material-ui/core/Grid';
import FormClientDialog from './formClientDialog';
import DeleteClientDialog from './deletingClientDialog';
import ChangeLoginStatusClientDialog from './changeLoginStatusClientDialog';
import UpdateClientPasswordDialog from './updateClientPasswordDialog';
import ClientUserFormDialog from './clientUserFormDialog';
import DeletingClientUserDialog from './deletingClientUserDialog';
import IconButton from '@material-ui/core/IconButton';
import { green } from '@material-ui/core/colors';
import { isRootUser, isNonClient } from './../../constants/api';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { ResponsiveTable } from 'material-ui-next-responsive-table';
import { Tooltip } from '@material-ui/core';

const columns = [
  { id: 'name', label: 'Nombre', minWidth: 120, align: 'center' },
  { id: 'parentClientName', label: 'Cliente', minWidth: 120, align: 'center' },
  { id: 'contactName', label: 'Nombre del contacto', minWidth: 120, align: 'center' },
  { id: 'contactPhoneNumber', label: 'Teléfono del contacto', minWidth: 80, align: 'center' },
  { id: 'contactEmail', label: 'Correo del contacto', minWidth: 80, align: 'center' },
];

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    margin: theme.spacing(3, 0, 2, 3),
    color: 'white'
  },
});

 class ClientLanding extends Component {

  componentDidMount() {
    this.props.initPagedClients();
  }

  debouncedOnSearchChange = debounce(this.props.handleSearch, 1000);

  constructor(props){
    super(props);
  }

  render() {
    const { classes } = this.props;
    let listItemsClasses = Object.assign({}, classes);

    const handleClickOpen = () => {
      this.props.openingClientModal()
    };
    const handleClose = () => {
      this.props.closingClientModal()
    };

    const handleEditingOpen = (row, readOnly) => {
      this.props.openingEditingModal(row, readOnly);
    }

    const handleDeleteClientOpen = (id) => {
      this.props.openingDeletingClientModal(id);
    }

    const handleDeletClientClose = () => {
      this.props.closingDeletingClientModal();
    }

    const handleDeleteConfirmation = () => {
      this.props.deletingCLient();
    }

    const handleLoginStatusOpen = (id, loginEnabled) => {
      this.props.openingChangingLoginStatusClientModal(id, loginEnabled);
    }
    const handleLoginStatusClose = () => {
      this.props.closingChangingLoginStatusClientModal();
    }
    const handleLoginStatusConfirmation = () => {
      this.props.changeLoginStatusClient();
    }
    const handleUpdatingPasswordOpen = (id) => {
      this.props.openingUpdatingPasswordModal(id);
    }
    const handleUpdatingPasswordClose = () => {
      this.props.closingUpdatingPasswordModal();
    }

    const handleClientUserChangePasswordClose = () => {
      this.props.closingUpdatingPasswordModal();
  }

  const handleClientUserDeleteClose = () => {
    this.props.closingDeletingClientUserModal();
}

const handleDeleteClientConfirmation = () => {
    this.props.deletingClientUser();
}

const handleClientUserModalClose = () => {
  this.props.closingClientUserModal();
}
    const newColumns = [
      { key: 'name', label: 'Nombre', primary: true},
      { key: 'parentClientName', label: 'Cliente', primary: true, render: (value) => value == null ? '' : `(${value})`},
      { key: 'contactName', label: 'Nombre del contacto' },
      { key: 'contactPhoneNumber', label: 'Teléfono del contacto' },
      { key: 'contactEmail', label: 'Correo del contacto' },
      { key: 'id', label: 'Acciones', render: (value, column) => {
        return (
          <div>
            <Tooltip title={"Ver detalle"}>
            <IconButton onClick={() => handleEditingOpen(column.id, true)}>
                          <VisibilityIcon style={{ color: green[500] }}/>
                        </IconButton> 
            </Tooltip>
            
                        {this.props.isNonClient && (
                          <Tooltip title="Editar">
                            <IconButton onClick={() => handleEditingOpen(column.id, false)}>
                            <EditIcon style={{ color: green[500] }}/>
                          </IconButton>
                          </Tooltip>
                          
                        )}
                        {/*this.props.isAdmin && column.parentClientName === null && column.hasUserAccount ? (
                          <Tooltip title={column.loginEnabled ? "Inhabilitar" : "Habilitar"}>
                            <IconButton onClick={() => handleLoginStatusOpen(column.id, column.loginEnabled)}>
                            {column.loginEnabled ? (<BlockIcon style={{ color: green[500] }}/>) : (<CheckCircleOutlineIcon style={{ color: green[500] }}/>)}
                          </IconButton>
                          </Tooltip>
                          
                        ): undefined*/}
                        {/*this.props.isAdmin && column.parentClientName === null && column.loginEnabled === true && column.hasUserAccount ? (
                          <Tooltip title="Cambiar clave">
                            <IconButton onClick={() => handleUpdatingPasswordOpen(column.id)}>
                              <VpnKeyIcon style={{ color: green[500] }}/>
                            </IconButton>
                          </Tooltip>
                          
                        ): undefined*/}                          
                          {this.props.isAdmin && column.isDeleteable === true ? (
                            <Tooltip title="Eliminar">
                              <IconButton onClick={() => handleDeleteClientOpen(column.id)}>
                                <DeleteIcon style={{color: green[500]}}/>
                              </IconButton> 
                            </Tooltip>
                              
                            ) : undefined
                          }    
          </div>
        );
      }}
    ];
    return (
      <React.Fragment>
            <h2>Clientes</h2>
            <form  noValidate autoComplete="off" >
            <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <div><Field
                                label="Buscar"
                                name="criteria"
                                type="text"
                                onChange={this.debouncedOnSearchChange}
                                component={renderTextField}
                               
                            />
                    </div>
                  </Grid>
                  {this.props.isNonClient && (
                  <Grid item  xs={12} sm={6} md={4} lg={3}>
                    
                      <div>
                        <div style={{height: "16px"}}></div>
                    <Field
                      label="Cliente"
                      name="parentClientId"
                      onChange={this.props.handleParentClient}
                      component={renderSelectField}
                    >
                      <option key={0} value={null}>Todos</option>
                                {this.props.clients !== undefined ? this.props.clients.map(client => {
                                    return(
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    )
                                }) : undefined}
                    </Field>
                      </div>
                    
                    
                  </Grid>
                  )}
              <Grid item  xs={12} sm={6} md={4} lg={3}>
                {this.props.isNonClient && (
                  <ColorButtonBlue variant="contained" className={classes.button} onClick={handleClickOpen}>Nuevo Cliente</ColorButtonBlue>
                )}
                
                <FormClientDialog open={this.props.clientModal.open} editing={this.props.clientModal.editing} handleClose={handleClose}/>
                <DeleteClientDialog open={this.props.deletingClientModal.open} handleClose={handleDeletClientClose} 
                isProcessing={this.props.deletingClientModal.isProcessing} handleConfirmation={handleDeleteConfirmation}/>
                <ChangeLoginStatusClientDialog open={this.props.changingLoginStatusClientModal.open} handleClose={handleLoginStatusClose}
                isProcessing={this.props.changingLoginStatusClientModal.isProcessing} handleConfirmation={handleLoginStatusConfirmation}
                loginEnabled={this.props.changingLoginStatusClientModal.loginEnabled}/>
                <ClientUserFormDialog open={this.props.clientUserFormModal.open} editing={this.props.clientUserFormModal.editing}
                  handleClose={handleClientUserModalClose} />
                <DeletingClientUserDialog open={this.props.deletingClientUserModal.open} handleClose={handleClientUserDeleteClose}
                  isProcessing={this.props.deletingClientUserModal.isProcessing} handleConfirmation={handleDeleteClientConfirmation} />
                <UpdateClientPasswordDialog open={this.props.updatingClientPasswordModal.open} handleClose={handleUpdatingPasswordClose}/>
              </Grid>
            </Grid>
            </form>
            <Paper>
            <ResponsiveTable
              excludePrimaryFromDetails={true}
              enableShouldComponentUpdate={true}
              columns={newColumns}
              data={this.props.data}
              showPagination={true}
              count={this.props.totalCount}
              rowsPerPage={this.props.pageSize}
              page={this.props.currentPage}
              noContentText={'No hay registros'}
              onChangePage={this.props.handleChangePage}
              TableProps={{dense: true, table: "true", size: "small"}}
              TableHeadCellProps= {{align: 'center', style: {"fontWeight": "bold", "background-color": "#D3D3D3", color: "#60b044"}}}
              TableBodyCellProps={{align: 'center'}}
              TablePaginationProps={{
                labelRowsPerPage: "Cantidad de Filas",
                rowsPerPageOptions: [10, 25, 50, 100],
                component: "div",
                onChangeRowsPerPage: this.props.handleChangeRowsPerPage
              }}
              ExpansionPanelSummaryTypographyProps={{style: {"fontWeight": "bold"}}}
              ExpansionPanelDetailsTypographyProps={{className: "test", classes:listItemsClasses}}
            />
            </Paper>
            {/* <Paper  className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table stickyHeader aria-label="Clients table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      key='actions'
                      align='center'
                      style={{minWidth:80, fontWeight: 'bold'}}
                    >
                      Acciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.data.map(row => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                        <TableCell align='center'>
                        <IconButton onClick={() => handleEditingOpen(row.id, true)}>
                          <VisibilityIcon style={{ color: green[500] }}/>
                        </IconButton> 
                        {this.props.isNonClient && (
                          <IconButton onClick={() => handleEditingOpen(row.id, false)}>
                            <EditIcon style={{ color: green[500] }}/>
                          </IconButton>
                        )}
                        {this.props.isAdmin && row.parentClientName === null ? (
                          <IconButton onClick={() => handleLoginStatusOpen(row.id, row.loginEnabled)}>
                            {row.loginEnabled ? (<BlockIcon style={{ color: green[500] }}/>) : (<CheckCircleOutlineIcon style={{ color: green[500] }}/>)}
                          </IconButton>
                        ): undefined}
                        {this.props.isAdmin && row.parentClientName === null && row.loginEnabled === true ? (
                          <IconButton onClick={() => handleUpdatingPasswordOpen(row.id)}>
                            <VpnKeyIcon style={{ color: green[500] }}/>
                          </IconButton>
                        ): undefined}                          
                          {this.props.isAdmin && row.isDeleteable === true ? (
                              <IconButton onClick={() => handleDeleteClientOpen(row.id)}>
                                <DeleteIcon style={{color: green[500]}}/>
                              </IconButton> 
                            ) : undefined
                          }                         
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={this.props.totalCount}
            rowsPerPage={this.props.pageSize}
            page={this.props.currentPage}
            onChangePage={this.props.handleChangePage}
            onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
            labelRowsPerPage={"Cantidad de Filas"}
            labelDisplayedRows={	({ from, to, count }) =>`${from}-${to === -1 ? count : to} de ${count}`}
          />
        </Paper> */}
        <br></br>
          <br></br>
      </React.Fragment>
    );
  }
  
  
  

 

}
const mapStateToProps = state => {
  const {pagedClients, clientModal, deletingClientModal, changingLoginStatusClientModal, updatingClientPasswordModal,
    clientUserFormModal, deletingClientUserModal} = state;
  const {clients} = state.catalog;
  const {user} = state.auth;
    let isAdmin = isRootUser(user);
    let isUserNonClient = isNonClient(user);
  return {...pagedClients, clientModal, deletingClientModal, changingLoginStatusClientModal, updatingClientPasswordModal, clients, isAdmin, isNonClient: isUserNonClient,
  clientUserFormModal, deletingClientUserModal};
}

const mapDispatchToProps = {
  getPagedClients,
  handleChangePage,
  handleChangeRowsPerPage,
  initPagedClients,
  handleSearch,
  openingClientModal,
  closingClientModal,
  openingEditingModal,
  openingDeletingClientModal,
  closingDeletingClientModal,
  deletingCLient,
  handleParentClient,
  openingChangingLoginStatusClientModal,
  closingChangingLoginStatusClientModal,
  changeLoginStatusClient,
  openingUpdatingPasswordModal,
  closingUpdatingPasswordModal,
  closingClientUserModal,
  closingDeletingClientUserModal,
  deletingClientUser
};

export default withStyles(styles)(reduxForm({form:'ClientsListForm'})(connect(mapStateToProps, mapDispatchToProps)(ClientLanding)));
//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientLanding));