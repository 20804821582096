import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import App from './App';
import { Container } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

// Material UI

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
const THEME = createMuiTheme({
  palette: {
    primary: {
      main: '#4caf50'
    }
  },
  typography: {
   "fontFamily": "\"Didact Gothic\", sans-serif",
   "fontSize": 18,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500,
   "fontWeightBold": 800
  }
});
moment.locale('es');

export default class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      <MuiThemeProvider theme={THEME}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Container maxWidth={"xl"}>
          <SnackbarProvider maxSnack={3} hideIconVariant={false}><App /></SnackbarProvider>
          </Container>
        </ConnectedRouter>
      </Provider>
      </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
