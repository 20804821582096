import {OPENING_DELETE_CASE_FILE, CLOSING_DELETE_CASE_FILE, DELETE_CASE_FILE} from '../../actions/legalCases/legalCaseListAction';

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    legalCaseId: null,
    id: null
}

export default function DeleteLegalCaseModal(state=INITIAL_STATE, action){
    switch(action.type){
        case OPENING_DELETE_CASE_FILE:
            return {...state, open: true, ...action.payload};
        case CLOSING_DELETE_CASE_FILE:
            return {...state, open: false, legalCaseId: null, id: null};
        case `${DELETE_CASE_FILE}_PENDING`:
            return {...state, isProcessing: true};
        case `${DELETE_CASE_FILE}_FULFILLED`:
            return {...state, isProcessing: false, open: false, legalCaseId: null};
        case `${DELETE_CASE_FILE}_REJECTED`:
            return {...state, isProcessing: false}
        default:
            return state;
    }
}