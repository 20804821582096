import {
    FETCH_LEGAL_CASES_START,
    FETCH_LEGAL_CASES_SUCCESS,
    FETCH_LEGAL_CASES_FAILURE,
    SET_LEGAL_CASES_CRITERIA,
    SET_LEGAL_CASES_CASE_TYPE,
    SET_LEGAL_CASES_CHILD_CLIENT,
    SET_LEGAL_CASES_CURRENT_PAGE,
    SET_LEGAL_CASES_LAWYER,
    SET_LEGAL_CASES_PARENT_CLIENT,
    SET_LEGAL_CASES_PAGE_SIZE
} from '../../actions/legalCases/legalCaseListAction';

const INITIAL_STATE = {
    fetchError: false,
    isFetchingList: false,
    criteria: '',
    parentClientId: null,
    childClientId: null,
    typeId: null,
    lawyerId: null,
    data: [],
    //0 for date
    orderBy: 0,
    ascending: false,
    totalCount: 0,
    pageSize: 25,
    totalPages: 0,
    currentPage: 0
}

export default function LegalCasesList(state = INITIAL_STATE, action){
    switch(action.type){
        case SET_LEGAL_CASES_CRITERIA:
            return {...state, criteria: action.payload, currentPage: 0};
        case SET_LEGAL_CASES_CURRENT_PAGE:
            return {...state, currentPage: action.payload};
        case SET_LEGAL_CASES_PARENT_CLIENT:
            return {...state, parentClientId: action.payload};
        case SET_LEGAL_CASES_CHILD_CLIENT:
            return {...state, childClientId: action.payload};
        case SET_LEGAL_CASES_CASE_TYPE:
            return {...state, typeId: action.payload};
        case SET_LEGAL_CASES_LAWYER:
            return {...state, lawyerId: action.payload};
        case SET_LEGAL_CASES_PAGE_SIZE:
            return { ...state, pageSize: action.payload, currentPage: 0 };
        case FETCH_LEGAL_CASES_START:
            return { ...state, isFetchingList: true, fetchError: false };
        case FETCH_LEGAL_CASES_SUCCESS:
            return {
                ...state,
                isFetchingList: false,
                fetchError: false,
                data: action.payload.records,
                totalCount: action.payload.totalCount,
                totalPages: action.payload.totalPages,
            };
        case FETCH_LEGAL_CASES_FAILURE:
            return { ...state, isFetchingList: false, fetchError: true, data: [], totalCount: 0, currentPage: 0, totalPages: 0 };
        default:
            return state;
    }
}