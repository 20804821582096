import {GET_COMMENTS_LIST} from '../../actions/comments/commentActions';

const INITIAL_STATE = {
    legalCaseId: null,
    comments: [],
    caseName: '',
    canComment: false,
    isFetching: false
}

export default function CommentsListReducer(state=INITIAL_STATE,action){
    switch(action.type){
        case `${GET_COMMENTS_LIST}_PENDING`:
            return {...state, isFetching: true};
        case `${GET_COMMENTS_LIST}_FULFILLED`:
            return {...state, isFetching: false, legalCaseId: action.payload.id, comments: action.payload.comments, caseName: action.payload.caseName, 
                canComment: action.payload.canComment};
        case `${GET_COMMENTS_LIST}_REJECTED`:
            return {...state, isFetching: false, legalCaseId: null, comments: [], caseName: '', canComment: false};
        default:
            return state;
    }
}