import {
CREATING_NEW_DOCUMENT_TYPE,
OPENING_DOCUMENT_TYPE_MODAL,
CLOSING_DOCUMENT_TYPE_MODAL,
OPENING_EDITING_DOCUMENT_TYPE_MODAL,
EDITING_DOCUMENT_TYPE
} from '../../actions/documentTypes/DocumentTypesListActions';

const INITIAL_STATE = {
    open: false,
    editing: false,
    documentType: null,
    isProcessing: false
}

export default function DocumentTypeModal(state=INITIAL_STATE,action){
    switch(action.type){
        case `${CREATING_NEW_DOCUMENT_TYPE}_PENDING`:
            return {...state, isProcessing: true};
        case `${CREATING_NEW_DOCUMENT_TYPE}_FULFILLED`:
            return {...state, open: false, isProcessing: false};
        case OPENING_DOCUMENT_TYPE_MODAL:
            return {...state, open: true};
        case OPENING_EDITING_DOCUMENT_TYPE_MODAL:
            return {...state, open: true, editing: true, documentType: action.payload.documentType};
        case CLOSING_DOCUMENT_TYPE_MODAL:
            return {...state, open: false, editing: false, documentType: null};
        case `${EDITING_DOCUMENT_TYPE}_PENDING`:
            return {...state, isProcessing: true};
        case `${EDITING_DOCUMENT_TYPE}_FULFILLED`:
            return {...state, DOCUMENT_TYPE: null, editing: false, open: false, isProcessing: false};
        case `${EDITING_DOCUMENT_TYPE}_REJECTED`:
            return {...state, DOCUMENT_TYPE: null, editing: false, open: false, isProcessing: false};
        default:
            return state;
    }
}