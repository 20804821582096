import React, {Component} from 'react';
import debounce from 'lodash/debounce';

import Container from '@material-ui/core/Container';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import { getPagedUsers, handleChangePage, handleChangeRowsPerPage, initPagedUsers, handleSearch, handleUserType, handleLoginStatus, openingUserModal, closingUserModal, openingEditingModal, openingUserLoginStatusModal, closingUserLoginStatusModal, updateLoginStatus,
 openingUserChangePasswordModal, closingUserChangePasswordModal, updateUserPassword} from './../../actions/users/UserListActions';
import { renderTextField, renderSelectField, ColorButtonBlue } from './../../constants/customElementsMUI';
import { Field, reduxForm } from 'redux-form';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Grid from '@material-ui/core/Grid';
import FormUserDialog from './formUserDialog';
import IconButton from '@material-ui/core/IconButton';
import UserLoginStatusDialog from './loginStatusUserDialog';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { getUserTypes } from './../../actions/catalog/catalogActions';
import { green } from '@material-ui/core/colors';
import FormUserChangePasswordDialog from './formUserChangePasswordDialog';
import { isRootUser } from './../../constants/api';
import { ResponsiveTable } from 'material-ui-next-responsive-table';
import { Tooltip } from '@material-ui/core';


const columns = [
  { id: 'name', label: 'Nombre', minWidth: 120, align: 'center', },
  { id: 'email', label: 'Correo', minWidth: 120, align: 'center', },
  { id: 'userType', label: 'Tipo de usuario', minWidth: 120, align: 'center',},
  { id: 'status', label: 'Estado', minWidth: 80, align: 'center',},
];

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    margin: theme.spacing(3, 0, 2, 3),
    color: 'white'
  },

});


 class UserLanding extends Component {

  componentDidMount() {
    this.props.getUserTypes();
    this.props.initPagedUsers();
  }

  debouncedOnSearchChange = debounce(this.props.handleSearch, 1000);

  constructor(props){
    super(props);
  }


  render() {
    const { classes } = this.props;
    let listItemsClasses = Object.assign({}, classes);

    const handleClickOpen = () => {
      this.props.openingUserModal()
    };
    const handleClose = () => {
      this.props.closingUserModal()
    };

    const handleUserChangePasswordClose = () => {
      this.props.closingUserChangePasswordModal()
    };
    const handleUserChangePasswordOpen = (id) => {
      this.props.openingUserChangePasswordModal(id)
    };

    const handleUserLoginStatusClose = () => {
      this.props.closingUserLoginStatusModal();
    }
    const handleUserLoginStatusOpen = (id, loginEnabled) => {
      this.props.openingUserLoginStatusModal(id, loginEnabled);
    }
    const handleUserLoginStatusConfirmation = () => {
      this.props.updateLoginStatus();
    }
    const handleEditingOpen = (row, readOnly) => {
      this.props.openingEditingModal(row, readOnly);
    }
    const newColumns = [
      { key: 'name', label: 'Nombre', primary: true},
      { key: 'email', label: 'Correo'},
      { key: 'userType', label: 'Tipo de usuario'},
      { key: 'status', label: 'Estado'},
      { key: 'id', label: 'Acciones', render: (value, column) => {
        return (
          <div>
            <Tooltip title="Ver detalle">
              <IconButton onClick={() => handleEditingOpen(column.id, true)}>
                <VisibilityIcon style={{ color: green[500] }}/>
              </IconButton >  
            </Tooltip>
            <Tooltip title="Editar">
              <IconButton onClick={() => handleEditingOpen(column.id, false)}>
                <EditIcon style={{ color: green[500] }}/>
              </IconButton>
            </Tooltip>
            
            {this.props.isAdmin && (
              <Tooltip title={column.loginEnabled ? "Inhabilitar" : "Habilitar"}>
                <IconButton onClick={() => handleUserLoginStatusOpen(column.id, column.loginEnabled)}>
                  {column.loginEnabled ? (<BlockIcon style={{ color: green[500] }}/>) : (<CheckCircleOutlineIcon style={{ color: green[500] }}/>)}
                </IconButton> 
              </Tooltip>
              
            )

            }
            
            {this.props.isAdmin && column.loginEnabled && (
              <Tooltip title={"Cambiar clave"}>
                <IconButton onClick={() => handleUserChangePasswordOpen(column.id)}>
                  <VpnKeyIcon style={{ color: green[500] }}/>
                </IconButton>
              </Tooltip>
              
            )}      
          </div>
        );
      }}
    ];

    
    return (
      <React.Fragment>
            <h2>Usuarios</h2>
            <form  noValidate autoComplete="off">
              
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>

                  <Field
                              label="Buscar"
                              name="criteria"
                              type="text"
                              onChange={this.debouncedOnSearchChange}
                              component={renderTextField}
                              className={classes.txtField}
                          />                  
                
              </Grid>
              <Grid item  xs={12} sm={6} md={4} lg={3}>
              <div style={{height: "16px"}}></div>
                  <Field
                                name="typeId"
                                component={renderSelectField}
                                label="Tipo de usuario"
                                onChange={this.props.handleUserType}
                            >
                                <option key={0} value={null}>Todos</option>
                                {this.props.userTypes !== undefined ? this.props.userTypes.map(user => {
                                    return(
                                        <option key={user.id} value={user.id}>{user.name}</option>
                                    )
                                }) : undefined}
                    </Field>
                  
                
              </Grid>
              <Grid item  xs={12} sm={6} md={4} lg={3}  >
              <div style={{height: "16px"}}></div>
                  <Field
                                name="loginEnabled"
                                component={renderSelectField}
                                label="Estado"
                                onChange={this.props.handleLoginStatus}
                            >
                                <option key={0} value={null}>Todos</option>
                                
                                <option key={1} value={true}>Activo</option>
                                
                                <option key={2} value={false}>Inactivo</option>
                                
                    </Field>
                  
                
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <ColorButtonBlue variant="contained" className={classes.button} onClick={handleClickOpen}>Nuevo Usuario</ColorButtonBlue>
                <FormUserDialog open={this.props.userModal.open} editing={this.props.userModal.editing} handleClose={handleClose}/>
                <FormUserChangePasswordDialog open={this.props.userChangePasswordModal.open} handleClose={handleUserChangePasswordClose} />
                <UserLoginStatusDialog open={this.props.userLoginStatusModal.open}
                  isProcessing={this.props.userLoginStatusModal.isProcessing}
                  loginEnabled={this.props.userLoginStatusModal.loginEnabled}
                  handleClose={handleUserLoginStatusClose}
                  handleConfirmation={handleUserLoginStatusConfirmation}
                />
              </Grid>
              
            </Grid>
            
            </form>
            <Paper>
            <ResponsiveTable
              excludePrimaryFromDetails={true}
              columns={newColumns}
              data={this.props.data}
              showPagination={true}
              count={this.props.totalCount}
              rowsPerPage={this.props.pageSize}
              page={this.props.currentPage}
              noContentText={'No hay registros'}
              onChangePage={this.props.handleChangePage}
              TableProps={{dense: true, table: "true", size: "small"}}
              TableHeadCellProps= {{align: 'center', style: {"fontWeight": "bold", "backgroundColor": "#D3D3D3", color: "#60b044"}}}
              TableBodyCellProps={{align: 'center'}}
              TablePaginationProps={{
                labelRowsPerPage: "Cantidad de Filas",
                rowsPerPageOptions: [10, 25, 50, 100],
                component: "div",
                onChangeRowsPerPage: this.props.handleChangeRowsPerPage
              }}
              ExpansionPanelSummaryTypographyProps={{style: {"fontWeight": "bold"}}}
              ExpansionPanelDetailsTypographyProps={{ classes:listItemsClasses}}
            />
            </Paper>
            {/* <Paper  className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table stickyHeader aria-label="Users table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      key='actions'
                      align='center'
                      style={{minWidth:80, fontWeight: "bold"}}
                    >
                      Acciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.data.map(row => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                        <TableCell align='center'>
                        <IconButton onClick={() => handleEditingOpen(row.id, true)}>
                            <VisibilityIcon style={{ color: green[500] }}/>
                          </IconButton >  
                          <IconButton onClick={() => handleEditingOpen(row.id, false)}>
                            <EditIcon style={{ color: green[500] }}/>
                          </IconButton>
                          {this.props.isAdmin && (
                            <IconButton onClick={() => handleUserLoginStatusOpen(row.id, row.loginEnabled)}>
                            {row.loginEnabled ? (<BlockIcon style={{ color: green[500] }}/>) : (<CheckCircleOutlineIcon style={{ color: green[500] }}/>)}
                            </IconButton>
                          )

                          }
                          
                          {this.props.isAdmin && row.loginEnabled && (
                            <IconButton onClick={() => handleUserChangePasswordOpen(row.id)}>
                            <VpnKeyIcon style={{ color: green[500] }}/>
                          </IconButton>
                          )}                          
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={this.props.totalCount}
            rowsPerPage={this.props.pageSize}
            page={this.props.currentPage}
            onChangePage={this.props.handleChangePage}
            onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
            labelRowsPerPage={"Cantidad de Filas"}
            labelDisplayedRows={	({ from, to, count }) =>`${from}-${to === -1 ? count : to} de ${count}`}
          />
        </Paper> */}
        <br></br>
          <br></br>
      </React.Fragment>
    );
  }
  
  
  

 

}
const mapStateToProps = state => {
  const {pagedUsers, userModal, userLoginStatusModal, userChangePasswordModal} = state;
  const {userTypes} = state.catalog;
  const {user} = state.auth;
  let isAdmin = isRootUser(user);
  return {...pagedUsers, userModal, userLoginStatusModal, userChangePasswordModal, userTypes, isAdmin};
}

const mapDispatchToProps = {
  getPagedUsers,
  handleChangePage,
  handleChangeRowsPerPage,
  initPagedUsers,
  handleSearch,
  handleUserType,
  handleLoginStatus,
  openingUserModal,
  closingUserModal,
  openingEditingModal,
  openingUserLoginStatusModal,
  closingUserLoginStatusModal,
  openingUserChangePasswordModal,
  closingUserChangePasswordModal,
  updateUserPassword,
  updateLoginStatus,
  getUserTypes
};

export default withStyles(styles)(reduxForm({form:'UsersListForm'})(connect(mapStateToProps, mapDispatchToProps)(UserLanding)));
//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserLanding));