import authAxios, {clients, classifications, userTypes, specialAccesses, documentTypes, users, caseTypes, legalCases, paymentTypes, paymentWays} from './../../constants/api';

export const GETTING_CLIENTS = 'GETTING_CLIENTS';
export const GETTING_CLASSIFICATIONS = 'GETTING_CLASSIFICATIONS';
export const GETTING_USER_TYPES = 'GETTING_USER_TYPES';
export const GETTING_SPECIAL_ACCESSES = 'GETTING_SPECIAL_ACCESSES';
export const GETTING_CHILD_CLIENTS_CATALOG = 'GETTING_CHILD_CLIENTS_CATALOG';
export const CLEAR_CHILD_CLIENTS_CATALOG = 'CLEAR_CHILD_CLIENTS';
export const GETTING_DOCUMENT_TYPES = 'GETTING_DOCUMENT_TYPES';
export const GETTING_ASSIGNABLE_LAWYERS = 'GETTING_ASSIGNABLE_LAWYERS';
export const GETTING_CASE_TYPES = 'GETTING_CASE_TYPES';
export const GETTING_ALL_LAWYERS = 'GETTING_ALL_LAWYERS';
export const GETTING_NOTIFIABLE_EMAILS = 'GETTING_NOTIFIABLE_EMAILS';
export const GETTING_BILLABLE_LEGAL_CASES = 'GETTING_BILLABLE_LEGAL_CASES';
export const GETTING_PAYMENT_TYPES = 'GETTING_PAYMENT_TYPES';
export const GETTING_PAYMENT_WAYS = 'GETTING_PAYMENT_WAYS';
export const CLEAR_BILLABLE_LEGAL_CASES_CATALOG = 'CLEAR_BILLABLE_LEGAL_CASES_CATALOG';

import {PAYMENT_TYPE_FEE} from '../../constants/paymentTypes';

export function getClients(){
    return (dispatch, getState) => {
        dispatch({type: `${GETTING_CLIENTS}_PENDING`})
        const {auth} = getState();
        authAxios.get(`${clients}/catalogue`, {headers: auth.headers})
        .then(response => {
            return dispatch({
                type: `${GETTING_CLIENTS}_FULFILLED`,
                payload: response.data
            });
        })
        .catch(e => {
            return dispatch({
                type: `${GETTING_CLIENTS}_REJECTED`,
                payload: e
            });
        })
    }
}

export function getChildClients(parentId){
    return (dispatch, getState) => {
        dispatch({type: `${GETTING_CHILD_CLIENTS_CATALOG}_PENDING`})
        const {auth} = getState();
        let pagedPayload = {
          parentClientId: parentId
        }
        authAxios.get(`${clients}/catalogue`, {params: pagedPayload}, {headers: auth.headers})
        .then(response => {
            return dispatch({
                type: `${GETTING_CHILD_CLIENTS_CATALOG}_FULFILLED`,
                payload: response.data
            });
        })
        .catch(e => {
            return dispatch({
                type: `${GETTING_CHILD_CLIENTS_CATALOG}_REJECTED`,
                payload: e
            });
        })
    }
}

export function clearChildClients(){
    return dispatch => {
        dispatch({type: CLEAR_CHILD_CLIENTS_CATALOG});
    }
}

export function getClassifications(){
    return (dispatch, getState) => {
        dispatch({type: `${GETTING_CLASSIFICATIONS}_PENDING`})
        const {auth} = getState();
        authAxios.get(`${classifications}/catalogue`, {headers: auth.headers})
        .then(response => {
            return dispatch({
                type: `${GETTING_CLASSIFICATIONS}_FULFILLED`,
                payload: response.data
            });
        })
        .catch(e => {
            return dispatch({
                type: `${GETTING_CLASSIFICATIONS}_REJECTED`,
                payload: e
            });
        })
    }
}

export function getUserTypes(){
    return (dispatch, getState) => {
        dispatch({type: `${GETTING_USER_TYPES}_PENDING`})
        const {auth} = getState();
        authAxios.get(userTypes, {headers: auth.headers})
        .then(response => {
            return dispatch({
                type: `${GETTING_USER_TYPES}_FULFILLED`,
                payload: response.data
            });
        })
        .catch(e => {
            return dispatch({
                type: `${GETTING_USER_TYPES}_REJECTED`,
                payload: e
            });
        })
    }
}

export function getSpecialAccesses(){
    return (dispatch, getState) => {
        dispatch({type: `${GETTING_SPECIAL_ACCESSES}_PENDING`})
        const {auth} = getState();
        authAxios.get(specialAccesses, {headers: auth.headers})
        .then(response => {
            return dispatch({
                type: `${GETTING_SPECIAL_ACCESSES}_FULFILLED`,
                payload: response.data
            });
        })
        .catch(e => {
            return dispatch({
                type: `${GETTING_SPECIAL_ACCESSES}_REJECTED`,
                payload: e
            });
        })
    }
}

export function getDocumentTypes() {
    return(dispatch, getState) => {
        dispatch({type: `${GETTING_DOCUMENT_TYPES}_PENDING`});
        const {auth} = getState();
        authAxios.get(documentTypes+"/catalogue", {headers:auth.headers})
        .then(response => {
            return dispatch({
                type: `${GETTING_DOCUMENT_TYPES}_FULFILLED`,
                payload: response.data
            });
        })
        .catch(e => {
            return dispatch({
                type: `${GETTING_DOCUMENT_TYPES}_REJECTED`,
                payload: e
            });
        });
    }
}

export function getAssignableLawyers(){
    return(dispatch, getState) => {
        dispatch({type: `${GETTING_ASSIGNABLE_LAWYERS}_PENDING`});
        const {auth} = getState();
        authAxios.get(`${users}/assignables`, {headers: auth.headers})
        .then(response => {
            return dispatch({
                type: `${GETTING_ASSIGNABLE_LAWYERS}_FULFILLED`,
                payload: response.data
            });
        })
        .catch(e => {
            return dispatch({
                type: `${GETTING_ASSIGNABLE_LAWYERS}_REJECTED`,
                payload: e
            });
        });
    }
}

export function getAllAssignableLawyers(){
    return(dispatch, getState) => {
        dispatch({type: `${GETTING_ASSIGNABLE_LAWYERS}_PENDING`});
        const {auth} = getState();
        authAxios.get(`${users}/assignables?all=true`, {headers: auth.headers})
        .then(response => {
            return dispatch({
                type: `${GETTING_ASSIGNABLE_LAWYERS}_FULFILLED`,
                payload: response.data
            });
        })
        .catch(e => {
            return dispatch({
                type: `${GETTING_ASSIGNABLE_LAWYERS}_REJECTED`,
                payload: e
            });
        });
    }
}

export function getCaseTypes(){
    return(dispatch, getState) => {
        dispatch({type: `${GETTING_CASE_TYPES}_PENDING`});
        const {auth} = getState();
        authAxios.get(caseTypes, {headers: auth.headers})
        .then(response => {
            return dispatch({
                type: `${GETTING_CASE_TYPES}_FULFILLED`,
                payload: response.data
            });
        })
        .catch(e => {
            return dispatch({
                type: `${GETTING_CASE_TYPES}_REJECTED`,
                payload: e
            });
        });
    }
}

export function getAllLawyers(){
    return(dispatch, getState) => {
        dispatch({type: `${GETTING_ALL_LAWYERS}_PENDING`});
        const {auth} = getState();
        authAxios.get(`${users}/assignables`, {params:{all:true}}, {headers: auth.headers})
        .then(response => {
            return dispatch({
                type: `${GETTING_ALL_LAWYERS}_FULFILLED`,
                payload: response.data
            });
        })
        .catch(e => {
            return dispatch({
                type: `${GETTING_ALL_LAWYERS}_REJECTED`,
                payload: e
            });
        });
    }
}

export function getNotifiableEmails(){
    return(dispatch, getState) => {
        dispatch({type: `${GETTING_NOTIFIABLE_EMAILS}_PENDING`});
        const {auth, uploadCaseFileModal} = getState();
        authAxios.get(`${legalCases}/${uploadCaseFileModal.legalCaseId}/notifiableEmails`, {headers: auth.headers})
        .then(response => {
            return dispatch({type: `${GETTING_NOTIFIABLE_EMAILS}_FULFILLED`, payload: response.data});
        })
        .catch(e => {
            return dispatch({type: `${GETTING_NOTIFIABLE_EMAILS}_REJECTED`, payload: e});
        });
    }
}

export function getBillableLegalCases(id){
    return (dispatch, getState) => {
        dispatch({type: `${GETTING_BILLABLE_LEGAL_CASES}_PENDING`});
        const {auth} = getState();
        const urlWithId = `${legalCases}/billables?clientId=${id}`;
        const url = `${legalCases}/billables`
        authAxios.get(id === null ? url: urlWithId, {headers: auth.headers})
        .then(response => {
            return dispatch({type: `${GETTING_BILLABLE_LEGAL_CASES}_FULFILLED`, payload: response.data});
        })
        .catch(e => {
            return dispatch({type: `${GETTING_BILLABLE_LEGAL_CASES}_REJECTED`, payload: e});
        });
    }
}

export function clearBillableCases(){
    return dispatch => {
        dispatch({type: CLEAR_BILLABLE_LEGAL_CASES_CATALOG});
    }
}

export function getPaymentTypes(){
    return (dispatch, getState) => {
        dispatch({type: `${GETTING_PAYMENT_TYPES}_PENDING`});
        const {auth, newPaymentModal} = getState();
        authAxios.get(paymentTypes, {headers: auth.headers})
        .then(response => {
            if(newPaymentModal.payments !== null && newPaymentModal.payments.isFree === true){
                let filteredTypes = response.data.filter(item => item.id !== Number(PAYMENT_TYPE_FEE));
                return dispatch({type: `${GETTING_PAYMENT_TYPES}_FULFILLED`, payload: filteredTypes});
            }
            return dispatch({type: `${GETTING_PAYMENT_TYPES}_FULFILLED`, payload: response.data});
        })
        .catch(e => {
            console.log(e);
            return dispatch({type: `${GETTING_PAYMENT_TYPES}_REJECTED`, payload: e});
        });
    }
}

export function getPaymentWays(){
    return(dispatch, getState) => {
        dispatch({type: `${GETTING_PAYMENT_WAYS}_PENDING`});
        const {auth} = getState();
        authAxios.get(paymentWays, {headers: auth.headers})
        .then(response => {
            return dispatch({type: `${GETTING_PAYMENT_WAYS}_FULFILLED`, payload: response.data});
        })
        .catch(e => {
            return dispatch({type: `${GETTING_PAYMENT_WAYS}_REJECTED`, payload: e});
        });
    }
}