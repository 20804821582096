import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {renderTextField} from '../../constants/customElementsMUI';
import { connect } from 'react-redux';
import {updateClientPassword} from '../../actions/clients/ClientListActions';
const validate = (values, props) => {
    const errors = {};
    let requiredFields = [
        'password',
        'confirmPassword'
    ];
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });

    if (values.password && values.confirmPassword && values.password != values.confirmPassword) {
        errors.confirmPassword = 'Las contraseñas no coinciden*';
    }
    return errors;
}

class UpdateClientPasswordDialog extends Component {

    constructor(props){
        super(props);
    }
    componentDidMount() {
    }
    
    render() {
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;
        
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}
                scroll={'paper'}
                    maxWidth={'sm'}>
                    <DialogTitle id="form-dialog-title">Cambiar clave</DialogTitle>
                    <form onSubmit={handleSubmit(this.props.updateClientPassword)} init noValidate autoComplete='off'>
                    <DialogContent>

                                <div>
                                    <Field
                                        label="Clave"
                                        name="password"
                                        type="password"
                                        component={renderTextField}

                                    />
                                </div>
                                <div>
                                    <Field
                                        label="Confirmar clave"
                                        name="confirmPassword"
                                        type="password"
                                        component={renderTextField}
                                    />
                                </div>                         
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" disabled={submitting || this.props.isProcessing}>
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary" disabled={pristine||submitting || this.props.isProcessing}>
                            Reestablecer
                            </Button>
                    </DialogActions>
                    </form>
                </Dialog>       
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {isProcessing} = state.updatingClientPasswordModal;
    return {isProcessing};
}

const mapDispatchToProps = {
    updateClientPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form:'ClientChangePasswordForm', validate, enableReinitialize: true})(UpdateClientPasswordDialog));