import {OPENING_DELETING_CLIENT_USER_MODAL, CLOSING_DELETING_CLIENT_USER_MODAL, 
    DELETING_CLIENT_USER} from '../../actions/clients/ClientListActions';

const INITIAL_STATE = {
    open: false,
    clientId: 0,
    userId: 0,
    isProcessing: false
}

export default function DeletingClientUserModalReducer(state=INITIAL_STATE, action){
    switch(action.type){
        case OPENING_DELETING_CLIENT_USER_MODAL:
            return {...state, open: true, clientId: action.payload.clientId, userId: action.payload.userId};
        case CLOSING_DELETING_CLIENT_USER_MODAL:
            return {...state, open: false, clientId: 0, userId: 0, isProcessing: false};
        case `${DELETING_CLIENT_USER}_PENDING`:
            return {...state, isProcessing: true};
        case `${DELETING_CLIENT_USER}_FULFILLED`:
            return {...state, isProcessing: false, open: false, clientId: 0, userId: 0};
        case `${DELETING_CLIENT_USER}_REJECTED`:
            return {...state, isProcessing: false};
        default:
            return state;
    }
}