import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';

import {renderTextField, renderFromHelper, renderCheckbox, renderSelectField, renderDropzoneInput, radioButton} from '../../constants/customElementsMUI';
import { connect } from 'react-redux';

import {createLegalCase} from '../../actions/legalCases/legalCaseListAction';

import {getChildClients, clearChildClients, getClients, getAssignableLawyers, getCaseTypes} from '../../actions/catalog/catalogActions';
import { isNonClient } from './../../constants/api';
import {FormControlLabel} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';

const validate = values => {
    const errors = {};
    let requiredFields = [
        'name',
        'typeId',
        'assignedUserId',
        'clientId'
    ];
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });
    /*if(values.user && emailRegex.test(values.email)){
    errors.email = 'Usuario inválido';
    }*/
    return errors;
}

class FormLegalCaseDialog extends Component {
    constructor(props){
        super(props);
    }

    state = { imageFile: [] };
    
    componentDidMount() {
        this.props.getClients();
        this.props.getAssignableLawyers();
        this.props.getCaseTypes();
    }

    handleParentClientChange = (value) => {
        const selected = value.currentTarget.value;
        if(selected===''){
            this.props.clearChildClients();
        }
        else if (selected !=='' && this.props.isNonClient){
            this.props.getChildClients(selected);
        }
    }

    render() {
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                    disableBackdropClick={this.props.isProcessing}
                    disableEscapeKeyDown={this.props.isProcessing}
                >
                    <DialogTitle id="form-dialog-title">Nuevo Caso</DialogTitle>
                    <form onSubmit={handleSubmit(this.props.createLegalCase)} noValidate autoComplete='off'>
                    <DialogContent>
                        <DialogContentText>
                            Para crear un nuevo documento, favor llenar los siguientes datos:
                        </DialogContentText>
                        <div>
                            <Field
                                label="Nombre del caso"
                                name="name"
                                type="text"
                                component={renderTextField}
                            />
                        </div>
                        <div>
                            <Field
                                name="clientId"
                                component={renderSelectField}
                                label="Cliente"
                                onChange={this.handleParentClientChange}
                            >
                                <option key={0} value={null}></option>
                                {this.props.clients !== undefined ? this.props.clients.map(client => {
                                    return(
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    )
                                }) : undefined}
                            </Field>    
                        </div>
                        {this.props.isNonClient && (
                            <div>
                            <div style={{height: "16px"}}></div>
                            <Field
                                name="childClientId"
                                component={renderSelectField}
                                label="Subcliente"
                            >
                                <option key={0} value={null}></option>
                                {this.props.childClients !== undefined ? this.props.childClients.map(client => {
                                    return(
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    )
                                }) : undefined}
                            </Field>    
                        </div>

                        )}
                        <div>
                            <div style={{height: "16px"}}></div>
                            <Field
                                name="assignedUserId"
                                component={renderSelectField}
                                label="Asignado a"
                            >
                                <option key={0} value={null}></option>
                                {this.props.assignableLawyers !== undefined ? this.props.assignableLawyers.map(lawyer => {
                                    return(
                                        <option key={lawyer.id} value={lawyer.id}>{lawyer.name}</option>
                                    )
                                }) : undefined}
                            </Field>    
                        </div>
                        <div>
                            <div style={{height: "16px"}}></div>
                            <Field
                                name="typeId"
                                component={renderSelectField}
                                label="Tipo de Caso"
                            >
                                <option key={0} value={null}></option>
                                {this.props.caseTypes !== undefined ? this.props.caseTypes.map(type => {
                                    return(
                                        <option key={type.id} value={type.id}>{type.name}</option>
                                    )
                                }) : undefined}
                            </Field>    
                        </div>                                               
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" disabled={submitting ||this.props.isProcessing}>
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary" disabled={pristine||submitting||this.props.isProcessing}>
                            Guardar
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>       
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const {clients, childClients, assignableLawyers, caseTypes} = state.catalog;
    const {isProcessing} = state.legalCaseModal;
    const {user} = state.auth;
    let isNotClient = isNonClient(user);
    return {clients, assignableLawyers, childClients, caseTypes, isNonClient: isNotClient, isProcessing};
}

const mapDispatchToProps = {
    createLegalCase,
    getClients,
    getChildClients,
    getAssignableLawyers,
    getCaseTypes,
    clearChildClients
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form:'LegalCaseForm', validate})(FormLegalCaseDialog));