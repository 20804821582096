import {OPENING_DELETING_CLIENT_MODAL, CLOSING_DELETING_CLIENT_MODAL, DELETING_CLIENT} from '../../actions/clients/ClientListActions'

const INITIAL_STATE = {
    open: false,
    clientId: null,
    isProcessing: false
}

export default function DeletingClientModal(state = INITIAL_STATE, action){
    switch(action.type){
        case OPENING_DELETING_CLIENT_MODAL:
            return {...state, open: true, clientId: action.payload, isProcessing: false};
        case CLOSING_DELETING_CLIENT_MODAL:
            return {...state, open: false, clientId: null, isProcessing: false};
        case `${DELETING_CLIENT}_PENDING`:
            return {...state, isProcessing: true};
        case `${DELETING_CLIENT}_FULFILLED`:
            return {...state, open: false, clientId: null};
        case `${DELETING_CLIENT}_REJECTED`:
            return {...state, open: false, clientId: null, isProcessing: false};
        default:
            return state;
    }
}