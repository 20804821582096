import {OPENING_VIEW_LEGAL_CASE_MODAL, CLOSING_VIEW_LEGAL_CASE_MODAL} from '../../actions/legalCases/legalCaseListAction';

const INITIAL_STATE = {
    open: false,
    isFetchingLegalCase: false,
    legalCase: null
}

export default function ViewLegalCaseModal(state = INITIAL_STATE, action){
    switch(action.type){
        case `${OPENING_VIEW_LEGAL_CASE_MODAL}_PENDING`:
            return {...state, isFetchingLegalCase: true};
        case `${OPENING_VIEW_LEGAL_CASE_MODAL}_FULFILLED`:
            return {...state, isFetchingLegalCase: false, legalCase: action.payload, open: true};
        case `${OPENING_VIEW_LEGAL_CASE_MODAL}_REJECTED`:
            return {...state, isFetchingLegalCase: false, legalCase: null};
        case CLOSING_VIEW_LEGAL_CASE_MODAL:
            return {...state, legalCase: null, open: false};
        default:
            return state;
    }
}