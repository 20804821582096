import authAxios, {CLASSIFICATIONS, classifications} from './../../constants/api';
import {reset} from 'redux-form';

export const FETCH_CLASSIFICATIONS_START = 'FETCH_CLASSIFICATIONS_START';
export const FETCH_CLASSIFICATIONS_SUCCESS = 'FETCH_CLASSIFICATIONS_SUCCESS';
export const FETCH_CLASSIFICATIONS_FAILURE = 'FETCH_CLASSIFICATIONS_FAILURE';
export const SET_CLASSIFICATIONS_CURRENT_PAGE = 'SET_CLASSIFICATIONS_CURRENT_PAGE';
export const SET_CLASSIFICATIONS_CRITERIA = 'SET_CLASSIFICATIONS_CRITERIA';
export const SET_CLASSIFICATIONS_PAGE_SIZE = 'SET_CLASSIFICATIONS_PAGE_SIZE';
export const CREATING_NEW_CLASSIFICATION = 'CREATING_NEW_CLASSIFICATION';
export const OPENING_CLASSIFICATION_MODAL = 'OPENING_CLASSIFICATION_MODAL';
export const CLOSING_CLASSIFICATION_MODAL = 'CLOSING_CLASSIFICATION_MODAL';
export const OPENING_EDITING_CLASSIFICATION_MODAL = 'OPENING_EDITING_CLASSIFICATION_MODAL';

export const EDITING_CLASSIFICATION = 'EDITING_CLASSIFICATION';

export const OPENING_CLASSIFICATION_STATUS_MODAL = 'OPENING_CLASSIFICATION_STATUS_MODAL';
export const CLOSING_CLASSIFICATION_STATUS_MODAL = 'CLOSING_CLASSIFICATION_STATUS_MODAL';
export const UPDATING_CLASSIFICATION_STATUS = 'UPDATING_CLASSIFICATION_STATUS';


export const OPENING_DELETING_CLASSIFICATION_MODAL = 'OPENING_DELETING_CLASSIFICATION_MODAL';
export const CLOSING_DELETING_CLASSIFICATION_MODAL = 'CLOSING_DELETING_CLASSIFICATION_MODAL';
export const DELETING_CLASSIFICATION = 'DELETING_CLASSIFICATION';

import {getClassifications} from '../catalog/catalogActions';

export function handleSearch(event, newValue, previousValue, name) {
    return dispatch => {
      dispatch(setCriteria(newValue));
      return dispatch(getPagedClassifications());
    }
  }
  
  export function handleChangePage(event, newPage){
    return dispatch => {
      dispatch(setPage(newPage));
      return dispatch(getPagedClassifications());
    }
  }
  
  export function handleChangeRowsPerPage(event) {
    
    return dispatch => {
      dispatch(setPageSize(event.target.value));
      return dispatch(getPagedClassifications());
    }
  }
  
  export function startFetchClassificationsList() {
    return {
      type: FETCH_CLASSIFICATIONS_START
    };
  }
  
  export function setPage(page) {
    return {
      type: SET_CLASSIFICATIONS_CURRENT_PAGE,
      payload: page
    };
  }
  
  
  export function setPageSize(pageSize) {
    return {
      type: SET_CLASSIFICATIONS_PAGE_SIZE,
      payload: pageSize
    };
  }
  
  export function setCriteria(criteria) {
    return {
      type: SET_CLASSIFICATIONS_CRITERIA,
      payload: criteria
    };
  }
  
  
  
  export function processClassificationList(data) {
    return {
      type: FETCH_CLASSIFICATIONS_SUCCESS,
      payload: data
    };
  }
  
  export function processClassificationListFailure() {
    return {
      type: FETCH_CLASSIFICATIONS_FAILURE
    }
  }
  export function initPagedClassifications() {
    return (dispatch) => {
      dispatch(setCriteria(''));
      dispatch(setPage(0));
      dispatch(setPageSize(25));
      return dispatch(getPagedClassifications());
    };
  }
  export function getPagedClassifications() {
    return (dispatch, getState) => {
      
      const { auth, pagedClassifications } = getState();
      dispatch(startFetchClassificationsList());
      let pagedPayload = {
        page: pagedClassifications.currentPage + 1,
        pageSize: pagedClassifications.pageSize,
        criteria: pagedClassifications.criteria
      }
      authAxios.get(classifications, {params: pagedPayload}, {headers: auth.headers})
        .then((response) => {
          return dispatch(processClassificationList(response.data));
          
        })
        .catch(e => {
          return dispatch(processClassificationListFailure());
        });
    }
  }

  export function openingDeletingClassificationModal(id){
    return dispatch => {
      dispatch({type: OPENING_DELETING_CLASSIFICATION_MODAL, payload: id});
    }
  }
  
  export function closingDeletingClassificationModal(){
    return dispatch => {
      dispatch({type: CLOSING_DELETING_CLASSIFICATION_MODAL});
    }
  }

  export function deletingClassification(){
    return (dispatch, getState) => {
      const {auth} = getState();
      const {deletingClassificationModal} = getState();
      dispatch({type: `${DELETING_CLASSIFICATION}_PENDING`});
      authAxios.delete(`${classifications}/${deletingClassificationModal.classificationId}`, {headers: auth.headers})
      .then(response => {
        dispatch({
          type: `${DELETING_CLASSIFICATION}_FULFILLED`
        });
        dispatch(getPagedClassifications());
      })
      .catch(e => {
        dispatch({
          type: `${DELETING_CLASSIFICATION}_REJECTED`,
          payload: e
        })
      });
    }
  }

  export function createClassification(info){
    return (dispatch, getState) => {
      const {auth} = getState();
      dispatch({
        type: `${CREATING_NEW_CLASSIFICATION}_REQUESTED`,
      });
      authAxios.post(classifications, JSON.stringify(info), {headers: auth.headers})
      .then((response) => {
        dispatch({
          type: `${CREATING_NEW_CLASSIFICATION}_FULFILLED`,
        });
        dispatch(reset('ClassificationForm'));
        dispatch(getPagedClassifications());
        dispatch(getClassifications());
      })
      .catch(e => {
        dispatch(reset('ClassificationForm'));
        return dispatch({
          type: `${CREATING_NEW_CLASSIFICATION}_REJECTED`,
          payload: e
        })
      })
    }
  }
  
  export function openingClassificationModal(){
    return dispatch => {
      dispatch({type: OPENING_CLASSIFICATION_MODAL});
    }
  }
  
  export function closingClassificationModal(){
    return dispatch => {
      dispatch({type: CLOSING_CLASSIFICATION_MODAL});
      dispatch(reset('ClassificationForm'));
    }
  }
  
  export function openingEditingModal(info){
    return (dispatch, getState) => {
      const {auth} = getState();
      authAxios.get(`${classifications}/${info}`, {headers: auth.headers})
      .then(response => {
        return dispatch({
          type: OPENING_EDITING_CLASSIFICATION_MODAL,
          payload: {
            classification: response.data
          }
        })
      })
    }
  }
  
  export function editingClassification(info){
    return (dispatch, getState) => {
      const {auth} = getState();
      const {classificationModal} = getState();
      const {id} = classificationModal.classification;
      dispatch({
        type: `${EDITING_CLASSIFICATION}_REQUESTED`
      });
      authAxios.put(`${classifications}/${id}`, JSON.stringify(info), {headers: auth.headers})
      .then(response => {
        dispatch({
          type: `${EDITING_CLASSIFICATION}_FULFILLED`
        });
        dispatch(reset('ClassificationForm'));
        dispatch(getPagedClassifications());
        dispatch(getClassifications());
      })
      .catch(e => {
        dispatch(reset('ClassificationForm'));
        dispatch({
          type: `${EDITING_CLASSIFICATION}_REJECTED`,
          payload: e
        });
      });
    }
  }

export function openingClassificationStatusModal(id, isEnabled){
    return dispatch => {
        dispatch({
            type: OPENING_CLASSIFICATION_STATUS_MODAL,
            payload: {
                classificationId: id,
                isEnabled
            }
        })
    }
}

export function closingClassificationStatusModal(){
    return dispatch => {
        dispatch({type: CLOSING_CLASSIFICATION_STATUS_MODAL});
    }
}

export function updateClassificationStatus(){
    return (dispatch, getState) => {
      const {auth} = getState();
      const {classificationStatusModal} = getState();
      const {classificationId, isEnabled} = classificationStatusModal;
      
      let payload = {
        isEnabled: !isEnabled
      }
      dispatch({
        type: `${UPDATING_CLASSIFICATION_STATUS}_REQUESTED`
      });
      authAxios.put(`${classifications}/${classificationId}/status`, JSON.stringify(payload), {headers: auth.headers})
      .then(response => {
        dispatch({
          type: `${UPDATING_CLASSIFICATION_STATUS}_FULFILLED`
        });
        dispatch(getPagedClassifications());
        return dispatch(getClassifications());
      })
      .catch(e => {
        dispatch({
          type: `${UPDATING_CLASSIFICATION_STATUS}_REJECTED`,
          payload: e
        });
      });
    }
  }