import {CREATING_NEW_CLASSIFICATION, OPENING_CLASSIFICATION_MODAL, CLOSING_CLASSIFICATION_MODAL, OPENING_EDITING_CLASSIFICATION_MODAL, EDITING_CLASSIFICATION} from './../../actions/classifications/ClassificationListActions';

const INITIAL_STATE = {
    open: false,
    editing: false,
    classification: null,
    isProcessing: false
}

export default function ClassificationModal(state = INITIAL_STATE, action){
    switch(action.type){
        case `${CREATING_NEW_CLASSIFICATION}_REQUESTED`:
            return {...state, isProcessing: true};
        case `${CREATING_NEW_CLASSIFICATION}_FULFILLED`:
            return {...state, open: false, isProcessing: false};
        case OPENING_CLASSIFICATION_MODAL:
            return {...state, open: true};
        case OPENING_EDITING_CLASSIFICATION_MODAL:
            return {...state, open: true, editing: true, classification: action.payload.classification};
        case CLOSING_CLASSIFICATION_MODAL:
            return {...state, open: false, editing: false, classification: null};
        case `${EDITING_CLASSIFICATION}_REQUESTED`:
            return {...state, isProcessing: true};
        case `${EDITING_CLASSIFICATION}_FULFILLED`:
            return {...state, classification: null, editing: false, open: false, isProcessing: false};
        case `${EDITING_CLASSIFICATION}_REJECTED`:
            return {...state, classification: null, editing: false, open: false, isProcessing: false};
        default:
            return state;
    }
}