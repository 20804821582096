import React, {Component} from 'react';
import debounce from 'lodash/debounce';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import { getPagedClassifications, handleChangePage, handleChangeRowsPerPage, initPagedClassifications, handleSearch, openingClassificationModal, 
  openingDeletingClassificationModal, closingDeletingClassificationModal, deletingClassification,
    closingClassificationModal, openingEditingModal, openingClassificationStatusModal, closingClassificationStatusModal, updateClassificationStatus} from './../../actions/classifications/ClassificationListActions';
import { renderTextField } from './../../constants/customElementsMUI';
import { Field, reduxForm } from 'redux-form';
import { isRootUser } from './../../constants/api';
import {ColorButtonBlue} from '../../constants/customElementsMUI';

import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';

import Grid from '@material-ui/core/Grid';
import FormClassificationDialog from './formClassificationDialog'
import ClassificationStatusDialog from './classificationStatusDialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteClassificationDialog from './deletingClassificationDialog';
import {green} from '@material-ui/core/colors';

import ResponsiveTable from 'material-ui-next-responsive-table'
import { Tooltip } from '@material-ui/core';
const columns = [
  { id: 'name', label: 'Nombre', minWidth: 120, align: 'center' },
  { id: 'description', label: 'Descripción', minWidth: 120, align: 'center' },
];

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  tableWrapper: {
    width: 'auto',
  },
  button: {
    margin: theme.spacing(3, 0, 2, 3),
    color: 'white'
  }
});

 class ClassificationLanding extends Component {

  componentDidMount() {
    this.props.initPagedClassifications();
  }

  debouncedOnSearchChange = debounce(this.props.handleSearch, 1000);

  constructor(props){
    super(props);
  }

  render() {
    const { classes } = this.props;
    let listItemsClasses = Object.assign({}, classes);
    //delete other properties except root
    delete listItemsClasses.tableWrapper;
    delete listItemsClasses.button;
    const handleClickOpen = () => {
      this.props.openingClassificationModal();
    };
    const handleClose = () => {
      this.props.closingClassificationModal();
    };

    const handleEditingOpen = (row) => {
      this.props.openingEditingModal(row);
    }

    const handleStatusChange = (id, isEnabled) => {
        this.props.openingClassificationStatusModal(id, isEnabled);
    }

    const handleClassificationStatusConfirmation = () => {
        this.props.updateClassificationStatus();
    }

    const handleStatusChangeClose = () => {
        this.props.closingClassificationStatusModal();
    }

    const handleDeleteClassificationOpen = (id) => {
      this.props.openingDeletingClassificationModal(id);
    }

    const handleDeletClassificationClose = () => {
      this.props.closingDeletingClassificationModal();
    }

    const handleDeleteConfirmation = () => {
      this.props.deletingClassification();
    }


    let newColumns = [
      {key: 'name', label: 'Nombre', primary: true},
      {key: 'description', label: 'Descripción'},
      {key: 'id', label: 'Acciones', render: (value, column, row, data) => {
        let isEnabled = row.filter((v) => v.id == value)[0].isEnabled;
        return (
          <div>
            <Tooltip title="Editar">
            <IconButton onClick={() => handleEditingOpen(value)}>
                            <EditIcon style={{color: green[500]}}/>
                          </IconButton>
            </Tooltip>
            <Tooltip title={isEnabled ?"Inhabilitar" : "Habilitar"}>
            <IconButton onClick={() => handleStatusChange(value, isEnabled)}>
                            {isEnabled ? (<BlockIcon style={{ color: green[500] }}/>) : (<CheckCircleOutlineIcon style={{ color: green[500] }}/>)}
                          </IconButton>  
            </Tooltip>
            
                           
                          {this.props.isAdmin && column.isDeleteable === true ? (
                            <Tooltip title="Eliminar">
                              <IconButton onClick={() => handleDeleteClassificationOpen(column.id)}>
                                <DeleteIcon style={{color: green[500]}}/>
                              </IconButton> 
                            </Tooltip>
                              
                            ) : undefined
                          }  
          </div>
        );
      }},
    ];

    return (
      <React.Fragment>
            <h2>Clasificaciones</h2>
            
            <form noValidate autoComplete="off">
            <Grid container>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                  <div><Field
                              label="Buscar"
                              name="criteria"
                              type="text"
                              onChange={this.debouncedOnSearchChange}
                              component={renderTextField}
                          />
                  </div>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <ColorButtonBlue variant="contained" className={classes.button} onClick={handleClickOpen}>Nueva Clasificación</ColorButtonBlue>
                <FormClassificationDialog open={this.props.classificationModal.open} editing={this.props.classificationModal.editing} handleClose={handleClose}/>
                <ClassificationStatusDialog open={this.props.classificationStatusModal.open}
                isProcessing={this.props.classificationStatusModal.isProcessing}
                isEnabled={this.props.classificationStatusModal.isEnabled}
                handleClose={handleStatusChangeClose}
                handleConfirmation={handleClassificationStatusConfirmation}

                />
                <DeleteClassificationDialog open={this.props.deletingClassificationModal.open} handleClose={handleDeletClassificationClose} 
                isProcessing={this.props.deletingClassificationModal.isProcessing} handleConfirmation={handleDeleteConfirmation}/>

              </Grid>
            </Grid>
            
            </form>
            <Paper>
            <ResponsiveTable
              excludePrimaryFromDetails={true}
              enableShouldComponentUpdate={true}
              columns={newColumns}
              data={this.props.data}
              showPagination={true}
              count={this.props.totalCount}
              rowsPerPage={this.props.pageSize}
              page={this.props.currentPage}
              noContentText={'No hay registros'}
              onChangePage={this.props.handleChangePage}
              TableProps={{dense: true, table: "true", size: "small"}}
              TableHeadCellProps= {{align: 'center', style: {"fontWeight": "bold", "background-color": "#D3D3D3", color: "#60b044"}}}
              TableBodyCellProps={{align: 'center'}}
              TablePaginationProps={{
                labelRowsPerPage: "Cantidad de Filas",
                rowsPerPageOptions: [10, 25, 50, 100],
                component: "div",
                onChangeRowsPerPage: this.props.handleChangeRowsPerPage
              }}
              ExpansionPanelSummaryTypographyProps={{style: {"fontWeight": "bold"}}}
              ExpansionPanelDetailsTypographyProps={{className: "test", classes:listItemsClasses}}
            />
            </Paper>
            
            {/* <br></br>
            <Paper  className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table stickyHeader aria-label="Classifications table" dense table size="small">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      key='actions'
                      align='center'
                      style={{minWidth:80}}
                    >
                      Acciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.data.map(row => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                        <TableCell align='center'>
                          <IconButton onClick={() => handleEditingOpen(row.id)}>
                            <EditIcon style={{color: green[500]}}/>
                          </IconButton>
                          <IconButton onClick={() => handleStatusChange(row.id, row.isEnabled)}>
                            {row.isEnabled ? (<BlockIcon style={{ color: green[500] }}/>) : (<CheckCircleOutlineIcon style={{ color: green[500] }}/>)}
                          </IconButton>                          
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={this.props.totalCount}
            rowsPerPage={this.props.pageSize}
            page={this.props.currentPage}
            onChangePage={this.props.handleChangePage}
            onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
            labelRowsPerPage={"Cantidad de Filas"}
            labelDisplayedRows={	({ from, to, count }) =>`${from}-${to === -1 ? count : to} de ${count}`}
          />
        </Paper> */}
        
        <br></br>
          <br></br>
      </React.Fragment>
    );
  }
  
  
  

 

}
const mapStateToProps = state => {
  const {pagedClassifications, classificationModal, classificationStatusModal, deletingClassificationModal} = state;
  const {user} = state.auth;
    let isAdmin = isRootUser(user);
  return {...pagedClassifications, classificationModal, classificationStatusModal, isAdmin, deletingClassificationModal};
}

const mapDispatchToProps = {
  getPagedClassifications,
  handleChangePage,
  handleChangeRowsPerPage,
  initPagedClassifications,
  handleSearch,
  openingClassificationModal,
  closingClassificationModal,
  openingEditingModal,
  openingClassificationStatusModal,
  closingClassificationStatusModal,
  updateClassificationStatus,
  openingDeletingClassificationModal,
  closingDeletingClassificationModal,
  deletingClassification,
};

export default withStyles(styles)(reduxForm({form:'ClassificationsListForm'})(connect(mapStateToProps, mapDispatchToProps)(ClassificationLanding)));