import authAxios, {bills, clients, isNonClient} from '../../constants/api';
import {reset} from 'redux-form';
import moment from 'moment';

export const FETCH_BILLS_START = 'FETCH_BILLS_START';
export const FETCH_BILLS_SUCCESS = 'FETCH_BILLS_SUCCESS';
export const FETCH_BILLS_FAILURE = 'FETCH_BILLS_FAILURE';
export const SET_BILLS_CURRENT_PAGE = 'SET_BILLS_CURRENT_PAGE';
export const SET_BILLS_CRITERIA = 'SET_BILLS_CRITERIA';
export const SET_BILLS_PAGE_SIZE = 'SET_BILLS_PAGE_SIZE';
export const SET_BILLS_PARENT_CLIENT = 'SET_BILLS_PARENT_CLIENT';
export const SET_BILLS_CHILD_CLIENT = 'SET_BILLS_CHILD_CLIENT';
export const SET_BILLS_MONTH = 'SET_BILLS_MONTH';
export const SET_BILLS_YEAR = 'SET_BILLS_YEAR';
export const CREATING_NEW_BILLS = 'CREATING_NEW_BILLS';
export const OPENING_BILLS_MODAL = 'OPENING_BILLS_MODAL';
export const CLOSING_BILLS_MODAL = 'CLOSING_BILLS_MODAL';

export const OPENING_BILL_DETAIL_MODAL = 'OPENING_BILL_DETAIL_MODAL';
export const CLOSING_BILL_DETAIL_MODAL = 'CLOSING_BILL_DETAIL_MODAL';

export const UPDATE_BILL_STATUS = 'UPDATE_BILL_STATUS';
export const OPEN_UPDATE_BILL_STATUS_MODAL = 'OPEN_UPDATE_BILL_STATUS_MODAL';
export const CLOSE_UPDATE_BILL_STATUS_MODAL = 'CLOSE_UPDATE_BILL_STATUS_MODAL';

export const UPLOAD_NEW_INVOICE_BILL = 'UPDATE_NEW_INVOICE_BILL';
export const OPEN_UPLOAD_NEW_INVOICE_BILL_MODAL = 'OPEN_UPLOAD_NEW_INVOICE_BILL_MODAL';
export const CLOSE_UPLOAD_NEW_INVOICE_BILL_MODAL = 'CLOSE_UPLOAD_NEW_INVOICE_BILL_MODAL';

export const INVOICE_NOTIFICATION_BILL = 'INVOICE_NOTIFICATION_BILL';
export const OPEN_INVOICE_NOTIFICATION_MODAL = 'OPEN_INVOICE_NOTIFICATION_MODAL';
export const CLOSE_INVOICE_NOTIFICATION_MODAL = 'CLOSE_INVOICE_NOTIFICATION_MODAL';

export const EDIT_BILL = 'EDIT_BILL';
export const OPEN_EDIT_BILL = 'OPEN_EDIT_BILL';

export const OPEN_PAYMENT_DETAIL_MODAL = 'OPEN_PAYMENT_DETAIL_MODAL';
export const CLOSE_PAYMENT_DETAIL_MODAL = 'CLOSE_PAYMENT_DETAIL_MODAL';

export const OPEN_NEW_PAYMENT_MODAL = 'OPEN_NEW_PAYMENT_MODAL';
export const CLOSE_NEW_PAYMENT_MODAL = 'CLOSE_NEW_PAYMENT_MODAL';
export const CREATE_NEW_PAYMENT = 'CREATE_NEW_PAYMENT';

export const SET_PARENT_CLIENT_BILL = 'SET_PARENT_CLIENT_BILL';

export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const OPEN_DELETE_PAYMENT = 'OPEN_DELETE_PAYMENT';
export const CLOSE_DELETE_PAYMENT = 'CLOSE_DELETE_PAYMENT';

import {getChildClients as getChildClientsCatalog, clearChildClients as clearChildClientsCatalog, getPaymentTypes} from '../catalog/catalogActions';

export function handleSearch(event, newValue, previousValue, name) {
    return dispatch => {
      dispatch(setCriteria(newValue));
      return dispatch(getPagedBills());
    }
}

export function handleParentClient(event, newValue, previousValue, name){
    return (dispatch, getState) => {
      let isNotClient = isNonClient(getState().auth.user);
        
      if(newValue === 'Todos'){
        newValue = null;
        dispatch(clearChildClientsCatalog());
        dispatch(setChildClient(newValue));
      }
      else if(isNotClient){
        dispatch(getChildClientsCatalog(newValue));
      }
      dispatch(setParentClient(newValue));
      if (!isNotClient) {
        dispatch(setChildClient(newValue))
      }
      return dispatch(getPagedBills());
    }
}

export function handleChildClient(event, newValue, previousValue, name){
    return dispatch => {
      if(newValue === 'Todos'){
        newValue = null;
      }
      dispatch(setChildClient(newValue));
      return dispatch(getPagedBills());
    }
}

export function handleMonth(event, newValue, previousValue, name){
    return dispatch => {
      if(newValue === 'Todos'){
        newValue = null;
      }
      dispatch(setMonth(newValue));
      return dispatch(getPagedBills());
    }
}

export function handleYear(event, newValue, previousValue, name){
    return dispatch => {
        if(newValue === 'Todos'){
            newValue = null;
        }
        dispatch(setYear(newValue));
        return dispatch(getPagedBills());
    }
}

export function handleChangePage(event, newPage){
    return dispatch => {
        dispatch(setPage(newPage));
        return dispatch(getPagedBills());
    }
}

export function handleChangeRowsPerPage(event) {
    return dispatch => {
      dispatch(setPageSize(event.target.value));
      return dispatch(getPagedBills());
    }
}

export function startFetchBillList() {
    return {
      type: FETCH_BILLS_START
    };
}

export function setPage(page) {
    return {
      type: SET_BILLS_CURRENT_PAGE,
      payload: page
    };
}

export function setPageSize(pageSize) {
    return {
      type: SET_BILLS_PAGE_SIZE,
      payload: pageSize
    };
}

export function setCriteria(criteria) {
    return {
      type: SET_BILLS_CRITERIA,
      payload: criteria
    };
}

export function setParentClient(parentClient){
    return{
      type: SET_BILLS_PARENT_CLIENT,
      payload: parentClient
    }
}

export function setChildClient(childClient){
    return{
      type: SET_BILLS_CHILD_CLIENT,
      payload: childClient
    }
}

export function setMonth(month){
    return{
        type: SET_BILLS_MONTH,
        payload: month
    }
}
export function setYear(year){
    return{
        type: SET_BILLS_YEAR,
        payload: year
    }
}

export function processBillList(data) {
    return {
      type: FETCH_BILLS_SUCCESS,
      payload: data
    };
}

export function processBillListFailure() {
    return {
      type: FETCH_BILLS_FAILURE
    }
}

export function initPagedBills() {
    return (dispatch) => {
      dispatch(setCriteria(''));
      dispatch(setPage(0));
      dispatch(setPageSize(25));
      return dispatch(getPagedBills());
    };
}

export function getPagedBills() {
    return (dispatch, getState) => {

      const { auth, pagedBills } = getState();
      

      dispatch(startFetchBillList());
      let pagedPayload = {
        page: pagedBills.currentPage + 1,
        pageSize: pagedBills.pageSize,
        criteria: pagedBills.criteria,
        ascending: pagedBills.ascending,
        orderBy: pagedBills.orderBy,
        parentClientId: pagedBills.parentClientId,
        specificClientId: pagedBills.childClientId,
        typeId: pagedBills.typeId,
        assignedUserId: pagedBills.lawyerId
      }
      
      authAxios.get(bills, {params: pagedPayload}, {headers: auth.headers})
        .then((response) => {
          response.data.records.map(c => c.referenceDate = moment(c.referenceDate).format('DD/MM/YYYY'));
          return dispatch(processBillList(response.data));
          
        })
        .catch(e => {
          return dispatch(processBillListFailure());
        });
    }
}

export function openingBillModal(){
    return dispatch => {
        return dispatch({type: OPENING_BILLS_MODAL});
    }
}

export function closingBillModal(){
    return dispatch => {
        return dispatch({type: CLOSING_BILLS_MODAL});
    }
}

export function creatingBill(info){
    return (dispatch, getState) => {
        dispatch({type: `${CREATING_NEW_BILLS}_PENDING`});
        const {auth} = getState();
        //info.referenceDate = moment(info.referenceDate).format('YYYY-MM-DD');
        authAxios.post(bills, info, {headers: auth.headersMultipart})
        .then(() => {
            dispatch({type: `${CREATING_NEW_BILLS}_FULFILLED`});
            dispatch(reset('BillForm'));
            return dispatch(getPagedBills());
        })
        .catch(e => {
            console.log(e)
            return dispatch({type: `${CREATING_NEW_BILLS}_REJECTED`, payload: e});
        });
    }
}

export function openDetailModal(id){
    return (dispatch, getState) => {
        dispatch({type: `${OPENING_BILL_DETAIL_MODAL}_PENDING`});
        const {auth} = getState();
        authAxios.get(`${bills}/${id}`, {headers: auth.headers})
        .then(response => {
            //response.data.referenceDate = moment.utc(response.data.referenceDate).local().format('DD/MM/YYYY');
            return dispatch({type: `${OPENING_BILL_DETAIL_MODAL}_FULFILLED`, payload: response.data});
        })
        .catch(e => {
            return dispatch({type: `${OPENING_BILL_DETAIL_MODAL}_REJECTED`, payload: e});
        });
    }
}

export function closeDetailModal(){
    return dispatch => {
        return dispatch({type: CLOSING_BILL_DETAIL_MODAL});
    }
}

export function updateBillStatus(){
    return (dispatch, getState) => {
        dispatch({type: `${UPDATE_BILL_STATUS}_PENDING`});
        const {auth, updateBillStatusModal} = getState();
        authAxios.put(`${bills}/${updateBillStatusModal.billId}/billStatus`, {headers: auth.headers})
        .then(() => {
            dispatch({type: `${UPDATE_BILL_STATUS}_FULFILLED`});
            return dispatch(getPagedBills());
        })
        .catch(e => {
            return dispatch({type: `${UPDATE_BILL_STATUS}_REJECTED`, payload: e});
        });
    }
}

export function openBillStatusModal(id){
    return dispatch => {
        return dispatch({type: OPEN_UPDATE_BILL_STATUS_MODAL, payload: id});
    }
}

export function closeBillStatusModal(){
    return dispatch => {
        return dispatch({type: CLOSE_UPDATE_BILL_STATUS_MODAL});
    }
}

export function uploadNewInvoice(info){
    return (dispatch, getState) => {
        dispatch({type: `${UPLOAD_NEW_INVOICE_BILL}_PENDING`});
        const {auth, uploadNewInvoiceModal} = getState();
        authAxios.post(`${bills}/${uploadNewInvoiceModal.billId}/invoices`, info, {headers: auth.headersMultipart})
        .then(() => {
            dispatch({type: `${UPLOAD_NEW_INVOICE_BILL}_FULFILLED`});
            dispatch(reset('UploadNewInvoiceBill'));
            return dispatch(getPagedBills());
        })
        .catch(e => {
            return dispatch({type: `${UPLOAD_NEW_INVOICE_BILL}_REJECTED`, payload: e});
        });
    }
}

export function openUploadNewInvoice(id){
    return dispatch => {
        return dispatch({type: OPEN_UPLOAD_NEW_INVOICE_BILL_MODAL, payload: id});
    }
}

export function closeUploadNewInvoice(){
    return dispatch => {
        return dispatch({type: CLOSE_UPLOAD_NEW_INVOICE_BILL_MODAL});
    }
}

export function invoiceNotification(){
    return (dispatch, getState) => {
        dispatch({type: `${INVOICE_NOTIFICATION_BILL}_PENDING`});
        const {auth, invoiceNotificationModal} = getState();
        authAxios.post(`${bills}/${invoiceNotificationModal.billId}/invoiceNotification`, {headers: auth.headers})
        .then(() => {
            return dispatch({type: `${INVOICE_NOTIFICATION_BILL}_FULFILLED`});
        })
        .catch(e => {
            return dispatch({type: `${INVOICE_NOTIFICATION_BILL}_REJECTED`, payload: e});
        });
    }
}

export function openInvoiceNotification(id){
    return dispatch => {
        return dispatch({type: OPEN_INVOICE_NOTIFICATION_MODAL, payload: id});
    }
}

export function closeInvoiceNotification(){
    return dispatch => {
        return dispatch({type: CLOSE_INVOICE_NOTIFICATION_MODAL});
    }
}

export function editBill(info){
    return (dispatch, getState) => {
        //info.referenceDate = moment.utc(info.referenceDate).format('yyyy-mm-dd');
        const {auth, billModal} = getState();
        dispatch({type: `${EDIT_BILL}_PENDING`});
        authAxios.put(`${bills}/${billModal.bill.id}`, info, {headers: auth.headersMultipart})
        .then(() => {
            dispatch({type: `${EDIT_BILL}_FULFILLED`});
            dispatch(reset('BillForm'));
            return dispatch(getPagedBills());
        })
        .catch(e => {
            return dispatch({type: `${EDIT_BILL}_REJECTED`, payload: e});
        });
    }
}

export function openEditBillModal(id, editing, canPay=false){
    return (dispatch, getState) => {
        dispatch({type: `${OPEN_EDIT_BILL}_PENDING`});
        const {auth} = getState();
        authAxios.get(`${bills}/${id}`, {headers: auth.headers})
        .then(response => {
            //response.data.referenceDate = moment.utc(response.data.referenceDate).local().format('YYYY-MM-DD');
            return dispatch({type: `${OPEN_EDIT_BILL}_FULFILLED`, payload: {
                bill: response.data,
                editing,
                readOnly: !editing,
                canPay
            }});
        })
        .catch(e => {
            return dispatch({type: `${OPEN_EDIT_BILL}_REJECTED`, payload: e});
        });
    }
}

export function openPaymentDetailModal(id, canPay){
    return (dispatch, getState) => {
        dispatch({type: `${OPEN_PAYMENT_DETAIL_MODAL}_PENDING`});
        const {auth} = getState();
        authAxios.get(`${bills}/${id}/paymentsDetail`, {headers: auth.headers})
        .then(response => {
            response.data.referenceDate = moment(response.data.referenceDate).format('DD/MM/YYYY');
            response.data.paymentsMade.map(payment => payment.paymentDate =  moment.utc(payment.paymentDate).local().format('DD/MM/YYYY'));
            return dispatch({type: `${OPEN_PAYMENT_DETAIL_MODAL}_FULFILLED`, payload:{
                data: response.data,
                canPay
            }});
        })
        .catch(e => {
            return dispatch({type: `${OPEN_PAYMENT_DETAIL_MODAL}_REJECTED`, payload: e});
        });
    }
}

export function closePaymentDetailModal(){
    return dispatch => {
        return dispatch({type: CLOSE_PAYMENT_DETAIL_MODAL});
    }
}

export function createNewPayment(info){
    return (dispatch, getState) => {
        dispatch({type: `${CREATE_NEW_PAYMENT}_PENDING`});
        const{auth, newPaymentModal} = getState();
        authAxios.post(`${bills}/${newPaymentModal.billId}/payments`, info, {headers: auth.headersMultipart})
        .then(() => {
            dispatch(reset('BillPaymentForm'));
            return dispatch({type: `${CREATE_NEW_PAYMENT}_FULFILLED`});
        })
        .catch(e => {
            return dispatch({type: `${CREATE_NEW_PAYMENT}_REJECTED`, payload: e});
        })
    }
}

export function openNewPaymentModal(id){
    return (dispatch, getState) => {
        const {auth} = getState();
        dispatch({type: `${OPEN_NEW_PAYMENT_MODAL}_PENDING`});
        authAxios.get(`${bills}/${id}/paymentsDetail`, {headers: auth.headers})
        .then(response => {
            response.data.referenceDate = moment(response.data.referenceDate).format('DD/MM/YYYY');
            response.data.paymentsMade.map(payment => payment.paymentDate =  moment.utc(payment.paymentDate).local().format('DD/MM/YYYY'));
            dispatch({type: `${OPEN_NEW_PAYMENT_MODAL}_FULFILLED`, payload:{
                id,
                data: response.data
            }});
            return dispatch(getPaymentTypes());
        })
        .catch(e => {
            return dispatch({type: `${OPEN_NEW_PAYMENT_MODAL}_REJECTED`, payload: e});
        });
        return dispatch({type: OPEN_NEW_PAYMENT_MODAL, payload:{
            id
        }});
    }
}

export function closeNewPaymentModal(){
    return dispatch => {
        return dispatch({type: CLOSE_NEW_PAYMENT_MODAL});
    }
}

export function setParentClientBill(id){
    return dispatch => {
        return dispatch({type: SET_PARENT_CLIENT_BILL, payload: id});
    }
}

export function deletePayment(){
    return (dispatch, getState) => {
        dispatch({type: `${DELETE_PAYMENT}_PENDING`});
        const {auth, deletePaymentModal} = getState();
        authAxios.delete(`${bills}/payments/${deletePaymentModal.paymentId}`, {headers: auth.headers})
        .then(() => {
            return dispatch({type: `${DELETE_PAYMENT}_FULFILLED`});
        })
        .catch(e => {
            return dispatch({type: `${DELETE_PAYMENT}_REJECTED`});
        });
    }
}

export function openDeletePaymentModal(id){
    return dispatch => {
        return dispatch({type: OPEN_DELETE_PAYMENT, payload: id});
    }
}

export function closeDeletePaymentModal(){
    return dispatch => {
        return dispatch({type: CLOSE_DELETE_PAYMENT});
    }
}