import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import MyFile from '../fileUploader/fileUploader';
import ChipInput from 'material-ui-chip-input'
import InputLabel from '@material-ui/core/InputLabel';

import {renderTextField, renderFromHelper, renderCheckbox, renderSelectField, renderDropzoneInput, renderChip, renderTextArea} from '../../constants/customElementsMUI';
import { connect } from 'react-redux';

import {willNotify, createNewComment} from '../../actions/comments/commentActions';

import {getNotifiableEmails} from '../../actions/catalog/catalogActions';
import { isNonClient } from './../../constants/api';

const validate = values => {
    const errors = {};
    let requiredFields = [
        'comment',
    ];
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });

    if(values.shouldNotify === true && (values.tos === undefined || values.tos === [])){
        errors.tos = 'Al seleccionar que debe notificar debe especificar al menos un email de envío.';
    }

    /*if(values.user && emailRegex.test(values.email)){
    errors.email = 'Usuario inválido';
    }*/
    return errors;
}

class NewCommentDialog extends Component {
    constructor(props){
        super(props);
    }
    
    componentDidMount() {
        this.props.getNotifiableEmails();
    }

    handleFormSubmit = values => {
        const fd = new FormData();
        if (values.document != null && values.document != undefined && values.document.length > 0) {
            
            fd.append("document", values.document[0]);
        }
        fd.append('comment', values.comment);
        if(values.shouldNotify === null || values.shouldNotify === undefined){
            fd.append('shouldNotify', false);
        }
        else{
            fd.append('shouldNotify', values.shouldNotify);
        }
        if(values.tos !== undefined && values.tos !== []){
            fd.append('tos', values.tos.join(','));
        }
        else{
            fd.append('tos', '');
        }
        if(values.ccs !== undefined && values.ccs != []){
            fd.append('CCs', values.ccs.join(','));
        }
        else{
            fd.append('CCs', '');
        }
        this.props.createNewComment(fd);
    }

    handleAddToChip(chip){
        this.props.addingNewToMail(chip);
    }
    handleDeleteToChip(chip, index){
        this.props.removingNewToMail(chip, index);
    }
    handleAddCCChip(chip){
        this.props.addingNewCCMail(chip);
    }
    handleDeleteCCChip(chip,index){
        this.props.removingNewCCMail(chip, index);
    }
    handleNotifyChange(){
        this.props.willNotify();
    }

    render() {
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                    disableBackdropClick={this.props.isProcessing}
                    disableEscapeKeyDown={this.props.isProcessing}
                >
                    <DialogTitle id="form-dialog-title">Nuevo comentario</DialogTitle>
                    <form onSubmit={handleSubmit(this.handleFormSubmit)} noValidate autoComplete='off'>
                    <DialogContent>
                        <DialogContentText>
                            Para crear un nuevo comentario, favor llenar los siguientes datos:
                        </DialogContentText>
                        <div>
                        <div>
                            <div style={{height: "16px"}}></div>
                            <Field
                                name="comment"
                                component={renderTextArea}
                                label="Comentario"
                            />  
                        </div> 
                        <div style={{height: "16px"}}></div>                            
                            <Field label={"Documento a subir"} name="document" component={MyFile} type="file"
                            dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                        </div>
                        
                        <div>
                            <Field name="shouldNotify" onChange={() => this.handleNotifyChange()} component={renderCheckbox} label="Notificar"/>
                        </div>
                        {this.props.shouldNotify === true ?(
                        <div>
                            <div style={{height: "16px"}}></div>
                            <InputLabel>Correos sugeridos:</InputLabel>
                        {this.props.notifiableEmails.map(email => <InputLabel>{email}</InputLabel>)}
                        </div> 
                        ): undefined} 
                        <div>
                        {this.props.shouldNotify === true ? (
                            <div>
                                <div style={{height: "16px"}}></div>
                                <Field name="tos" component={renderChip} floatingLabelText='Destinatarios'/>
                                <div style={{height: "8px"}}></div>
                            </div>
                            
                        ): undefined} 
                        </div>
                        <div>
                        {this.props.shouldNotify === true ? (
                            <div>
                                <div style={{height: "16px"}}></div>
                                <Field name="ccs" component={renderChip} floatingLabelText='CCs' />
                            </div>
                        ): undefined}
                        </div>                                    
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" disabled={submitting ||this.props.isProcessing}>
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary" disabled={pristine||submitting||this.props.isProcessing}>
                            Guardar
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>       
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const {notifiableEmails} = state.catalog;
    const {tos, ccs, shouldNotify, isProcessing} = state.newCommentModal;
    const {user} = state.auth;
    let isNotClient = isNonClient(user);
    return {notifiableEmails, isNonClient: isNotClient, isProcessing, tos, ccs, shouldNotify};
}

const mapDispatchToProps = {
    createNewComment,
    getNotifiableEmails,
    willNotify
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form:'NewCommentForm', validate})(NewCommentDialog));