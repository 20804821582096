import {OPENING_BILL_DETAIL_MODAL, CLOSING_BILL_DETAIL_MODAL} from '../../actions/bills/billActions';

const INITIAL_STATE = {
    open: false,
    isFetching: false,
    bill: null,
}

export default function BIllModalReducer(state=INITIAL_STATE, action){
    switch(action.type){
        case `${OPENING_BILL_DETAIL_MODAL}_PENDING`:
            return {...state, isFetching: true}
        case `${OPENING_BILL_DETAIL_MODAL}_FULFILLED`:
            return {...state, bill: action.payload, open: true, isFetching: false};
        case `${OPENING_BILL_DETAIL_MODAL}_REJECTED`:
            return {...state, bill:  null, open: false, isFetching: false};
        case CLOSING_BILL_DETAIL_MODAL:
            return {...state, bill: null, open: false};
        default:
            return state;
    }
}