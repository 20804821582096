import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';

import {renderTextField, renderSelectField} from '../../constants/customElementsMUI';
import {connect} from 'react-redux';

import {editLegalCase} from '../../actions/legalCases/legalCaseListAction';
import { CommentOutlined } from '@material-ui/icons';

const validate = values => {
    const errors = {};
    let requiredFields = [
        'name',
        'typeId',
        'assignedUserId'
    ];
    requiredFields.forEach(field => {
        if(!values[field]){
            errors[field] = 'Obligatorio*';
        }
    });

    return errors;
}

class EditLegalCaseDialog extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;

        return(
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                disableBackdropClick={this.props.isProcessing}
                disableEscapeKeyDown={this.props.isProcessing}>
                    <DialogTitle id="form-dialog-title">Editar Caso</DialogTitle>
                    <form onSubmit={handleSubmit(this.props.editLegalCase)} noValidate autoComplete='off'>
                        <DialogContent>
                            <DialogContentText>
                                Para editar un caso, favor llenar los siguientes datos:
                            </DialogContentText>
                            <div>
                                <Field 
                                    label="Nombre del caso"
                                    name="name"
                                    type="text"
                                    component={renderTextField}
                                />
                            </div>
                            <div>
                                <div style={{height: "16px"}}></div>
                                <Field
                                    name="assignedUserId"
                                    component={renderSelectField}
                                    label="Asignado a"
                                >
                                    <option key={0} value={null}></option>
                                    {this.props.assignableLawyers !== undefined ? this.props.assignableLawyers.map(lawyer => {
                                        return(
                                            <option key={lawyer.id} value={lawyer.id}>{lawyer.name}</option>
                                        )
                                    }) : undefined}
                                </Field>    
                            </div>
                            <div>
                            <div style={{height: "16px"}}></div>
                                <Field
                                    name="typeId"
                                    component={renderSelectField}
                                    label="Tipo de Caso"
                                >
                                    <option key={0} value={null}></option>
                                    {this.props.caseTypes !== undefined ? this.props.caseTypes.map(type => {
                                        return(
                                            <option key={type.id} value={type.id}>{type.name}</option>
                                        )
                                    }) : undefined}
                                </Field>    
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary" disabled={submitting || this.props.isProcessing}>
                                Cancelar
                            </Button>
                            <Button type="submit" color="primary" disabled={pristine || submitting || this.props.isProcessing}>
                                Guardar
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {assignableLawyers, caseTypes} = state.catalog;
    const {isProcessing} = state.editLegalCaseModal;
    const initialValues = {
        name: state.editLegalCaseModal.legalCaseName,
        typeId: state.editLegalCaseModal.typeId,
        assignedUserId: state.editLegalCaseModal.assignedUserId
    }
    return {assignableLawyers, caseTypes, isProcessing, initialValues}
}

const mapDispatchToProps = {
    editLegalCase
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'EditLegalCaseForm', validate, enableReinitialize: true})(EditLegalCaseDialog));