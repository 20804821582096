import {CHANGE_DOCUMENT_ONWER, OPENING_CHANGE_DOCUMENT_OWNER_MODAL, CLOSING_CHANGE_DOCUMENT_OWNER_MODAL,
GETTING_SIBLING_CLIENTS} from '../../actions/documents/DocumentListActions';

const INITIAL_STATE = {
    open: false,
    documentId: null,
    isProcessing: false,
    clients: []
}

export default function ChangeDocumentOwnerModal(state=INITIAL_STATE, action){
    switch(action.type){
        case `${CHANGE_DOCUMENT_ONWER}_REQUESTED`:
            return {...state, isProcessing: true};
        case `${CHANGE_DOCUMENT_ONWER}_REJECTED`:
            return {...state, open: false, documentId: null, clients: [], isProcessing: false};
        case `${CHANGE_DOCUMENT_ONWER}_FULFILLED`:
            return {...state, open: false, documentId: null, clients: [], isProcessing: false};
        case `${GETTING_SIBLING_CLIENTS}_FULFILLED`:
            return {...state, clients: action.payload};
        case OPENING_CHANGE_DOCUMENT_OWNER_MODAL:
            return {...state, open: true, documentId: action.payload};
        case CLOSING_CHANGE_DOCUMENT_OWNER_MODAL:
            return {...state, open: false, documentId: null, clients: []};
        default:
            return state;
    }
}