import { combineReducers } from 'redux';
import auth from './AuthReducer'
import { connectRouter } from 'connected-react-router'
import {reducer as formReducer}from 'redux-form';
import ClientsList from './clients/ClientListReducer';
import ClientModal from './clients/ClientModalReducer';
import Catalog from './catalog/catalogReducer';
import DocumentsList from './documents/DocumentListReducer';
import DocumentModal from './documents/DocumentModalReducer';
import ResubmitDocumentModal from './documents/ResubmitDocumentModalReducer';
import ChangeDocumentOwnerModal from './documents/ChangeDocumentOwnerReducer';
import PasswordReset from './passwordReset/PasswordResetReducer';
import notificationReducer from './notificationReducer';
import ClassificationListReducer from './classifications/ClassificationListReducer';
import ClassificationModalReducer from './classifications/ClassificationModalReducer';
import UsersList from './users/UserListReducer';
import UserModal from './users/UserModalReducer';
import UserLoginStatusModal from './users/UserLoginStatusModalReducer';
import ClassificationStatusModal from './classifications/ClassificationStatusModalReducer';
import DeletingClassificationModal from './classifications/DeletingClassificationModalReducer';
import UserChangePasswordModal from './users/UserChangePasswordModalReducer';
import DeletingClientModal from './clients/DeletingClientModalReducer';
import DeleteDocumentModal from './documents/DeleteDocumentModal';
import ChangeLoginStatusClientModal from './clients/ChangeLoginStatusClientModalReducer';
import UpdateClientPasswordModal from './clients/UpdatingClientPaswordModalReducer';
import DocumentTypeList from './documentTypes/DocumentTypeListReducer';
import DocumentTypeModal from './documentTypes/DocumentTypeModalReducer';
import DocumentTypeStatusModal from './documentTypes/DocumentTypeStatusModalReducer';
import LegalCaseList from './legalCases/LegalCaseListReducer';
import LegalCaseModal from './legalCases/LegalCaseModalReducer';
import ViewLegalCaseModal from './legalCases/ViewLegalCaseModalReducer';
import UploadLegalCaseModal from './legalCases/UploadLegalCaseModalReducer';
import ReuploadLegalCaseModal from './legalCases/ReuploadLegalCaseModalReducer';
import FinishLegalCaseModal from './legalCases/FinishLegalCaseReducer';
import CommentsList from './comments/commentsListReducer';
import NewCommentModal from './comments/newCommentModalReducer';
import BillList from './bills/billsListReducer';
import BillModal from './bills/billModalReducer';
import ViewBillModal from './bills/viewBillModalReducer';
import InvoiceNotificationModal from './bills/invoiceNotificationModalReducer';
import NewPaymentModal from './bills/newPaymentModalReducer';
import PaymentDetailModal from './bills/paymentsDetailModalReducer';
import UpdateBillStatusModal from './bills/updateBillStatusModalReducer';
import UploadNewInvoiceModal from './bills/uploadNewInvoiceModalreducer';
import DeleteLegalCaseModal from './legalCases/deleteLegalCaseReducer';
import DeleteLegalCaseFileModal from './legalCases/deleteLegalCaseFileReducer';
import DeleteDocumentTypeModal from './documentTypes/deleteDocumentTypeReducer';
import ChangeOwnerCaseModal from './legalCases/changeOwnerCaseModalReducer';
import ReportReducer from './reports/reportReducer';
import DeletePaymentModalReducer from './bills/deletePaymentModalReducer';
import ChangeDocumentTypeCaseModal from './legalCases/changeDocumentTypeCaseModalReducer';
import ClientUserFormModalReducer from './clients/ClientUserFormModalReducer';
import DeletingClientUserModalReducer from './clients/DeletingClientUserModalReducer';
import EditLegalCaseModalReducer from './legalCases/editLegalCaseModalReducer';

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  auth,
  pagedClients: ClientsList, 
  clientModal: ClientModal,
  pagedUsers: UsersList,
  userModal: UserModal,
  form: formReducer,
  catalog: Catalog,
  pagedDocuments: DocumentsList,
  documentModal: DocumentModal,
  resubmitDocumentModal: ResubmitDocumentModal,
  changeDocumentOwnerModal: ChangeDocumentOwnerModal,
  passwordReset: PasswordReset,
  notification: notificationReducer,
  pagedClassifications: ClassificationListReducer,
  classificationModal: ClassificationModalReducer,
  deletingClassificationModal: DeletingClassificationModal,
  userLoginStatusModal: UserLoginStatusModal,
  classificationStatusModal: ClassificationStatusModal,
  userChangePasswordModal: UserChangePasswordModal,
  deletingClientModal: DeletingClientModal,
  deleteDocumentModal: DeleteDocumentModal,
  changingLoginStatusClientModal: ChangeLoginStatusClientModal,
  updatingClientPasswordModal: UpdateClientPasswordModal,
  pagedDocumentTypes: DocumentTypeList,
  documentTypeModal: DocumentTypeModal,
  documentTypeStatusModal: DocumentTypeStatusModal,
  pagedLegalCases: LegalCaseList,
  legalCaseModal: LegalCaseModal,
  viewLegalCaseModal: ViewLegalCaseModal,
  uploadCaseFileModal: UploadLegalCaseModal,
  reuploadCaseFileModal: ReuploadLegalCaseModal,
  finishCaseModal: FinishLegalCaseModal,
  comments: CommentsList,
  newCommentModal: NewCommentModal,
  pagedBills: BillList,
  billModal: BillModal,
  viewBillModal: ViewBillModal,
  invoiceNotificationModal: InvoiceNotificationModal,
  newPaymentModal: NewPaymentModal,
  paymentDetailModal: PaymentDetailModal,
  updateBillStatusModal: UpdateBillStatusModal,
  uploadNewInvoiceModal: UploadNewInvoiceModal,
  deleteCaseModal: DeleteLegalCaseModal,
  deleteCaseFileModal: DeleteLegalCaseFileModal,
  deleteDocumentTypeModal: DeleteDocumentTypeModal,
  changeOwnerCaseModal: ChangeOwnerCaseModal,
  reports: ReportReducer,
  deletePaymentModal: DeletePaymentModalReducer,
  changeDocumentTypeLegalCaseModal: ChangeDocumentTypeCaseModal,
  clientUserFormModal: ClientUserFormModalReducer,
  deletingClientUserModal: DeletingClientUserModalReducer,
  editLegalCaseModal: EditLegalCaseModalReducer
});

export default rootReducer;
