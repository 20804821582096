import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TextAreaAutoSize from '@material-ui/core/TextareaAutosize';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles';
import {green, grey, indigo} from '@material-ui/core/colors';
import Link from '@material-ui/core/Link';
import Radio from '@material-ui/core/Radio';
import { Grid, Menu, FormControlLabel, FormControl, InputLabel, Select, FormHelperText } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { spacing } from '@material-ui/system';
import ChipInput from 'material-ui-chip-input';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment';

export const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'green',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'green',
        },
        '&:hover fieldset': {
          borderColor: 'green',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'green',
        },
      },
    },
})(TextField);

export const CssDatePicker = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'green',
      },
      '&:hover fieldset': {
        borderColor: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(DatePicker);

const useChipInputStyles = makeStyles((theme) => createStyles({
  chipInputRoot: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'green',
      },
      '&:hover fieldset': {
        borderColor: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
}));


export const CssTextArea = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'green',
      },
      '&:hover fieldset': {
        borderColor: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(TextAreaAutoSize);

export const CssFormControl = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'green',
      },
      '&:hover fieldset': {
        borderColor: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(FormControl);

export const ColorButton = withStyles(theme => ({
    root: {
        color: theme.palette.white,
        backgroundColor: green[500],
        '&:hover':{
            backgroundColor:green[700]
        },
    },
}))(Button);

export const ColorButtonBlue = withStyles(theme => ({
  root: {
      color: theme.palette.white,
      backgroundColor: green[500],
      '&:hover':{
          backgroundColor:green[700]
      },
  },
}))(Button);


export const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(Checkbox);

export const GreenLink = withStyles({
  root: {
    color: green[500],
    '&:hover':{
      color: green[800],
    },
  },
})(Link);

export const BorderedGrid = withStyles({
  root: {
    borderColor: grey[400],
    borderStyle: 'solid',
    padding: '15px',
  },
})(Grid);

export const HorizontalMenu = withStyles({
  root:{
    display: 'inlibe-block',
  }
})(Menu);

export const renderTextField = ({
  label,
  input,
  type,
  meta: {touched, invalid, error},
  ...custom
}) => (
  <CssTextField
  label={label}
  placeholder={label}
  type={type}
  variant="outlined"
  margin="normal"
  fullWidth
  error={touched && invalid}
  helperText={touched && error}
  {...input}
  {...custom}
  />
);

export const renderCheckbox = ({input, label, ...custom}) => (
  <div>
    <FormControlLabel
    control={
      <GreenCheckbox
      checked={input.value ? true : false}
      onChange={input.onChange}
      {...custom}
      />
    }
    label={label}
    />
  </div>
);

export const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <CssFormControl variant="outlined" fullWidth error={touched && !!error}>
    <InputLabel htmlFor="age-native-simple" variant={'outlined'} style={{backgroundColor: "#FFFFFF"}}>{label}</InputLabel>
    <Select
      native
      {...input}
      {...custom}
      inputProps={{
        name: 'age',
        id: 'age-native-simple'
      }}
    >
      {children}
    </Select>
    {touched && error && <div><FormHelperText>{touched && error}</FormHelperText></div>}
    
  </CssFormControl>
)

export const renderDropzoneInput = (field) => {
  const files = field.input.value;
  let dropzoneRef;
  return (
      <div className="text-center">
        <Dropzone
            style={{
              width: '200px',
              height: '200px',
              borderWidth: '2px',
              borderColor: 'rgb(102, 102, 102)',
              borderStyle: 'dashed',
              borderRadius: '5px',
              padding: '20px',
            }}
            name={field.name}
            onDrop={(filesToUpload, e) => field.input.onChange(filesToUpload)}
            ref={(node) => { dropzoneRef = node; }}
            maxSize={5242880}
            multiple={false}
            accept={'application/pdf'}
            className="drop-zone"
        >
          {({isDragActive, isDragReject, acceptedFiles, rejectedFiles}) => {
            if (isDragActive) {
              return 'Este archivo es válido';
            }
            if (isDragReject) {
              return 'Este archivo no es válido';
            }
            return acceptedFiles.length || rejectedFiles.length
                ? `Se aceptan ${acceptedFiles.length}, se rechazan ${rejectedFiles.length} estos archivos`
                : 'Intenta arrastrar algún archivo.';
          }}
        </Dropzone>
        {field.meta.touched &&
        field.meta.error &&
        <span className="error">{field.meta.error}</span>}
        {
          files && Array.isArray(files) && (
              <ul>
                {files.map((file, i) =>
                    <li key={i}>
                      <img key={i}
                           src={file.preview} alt="preview"/>
                      <p>{file.name}</p>
                    </li>,
                )}
              </ul>
          )}
            <Button type="button" style={{margin: '5px'}}
                    onClick={() => { dropzoneRef.open(); }}>Sube un archivo
            </Button>
      </div>
  );
};

export const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}

export const renderChip = ({input, hintText, floatingLabelText, meta: {touched, invalid, error},}) => {
  const classes = useChipInputStyles();
  return (
    <ChipInput
      input
      newChipKeyCodes={[13, 59, 188]}
      fullWidth={true}
      variant={'outlined'}
      classes={{
        root: classes.chipInputRoot,
      }}
      value = { input.value || []}
      onAdd={(addedChip) => {
        let values = input.value || [];
        values = values.slice();
        values.push(addedChip);
        input.onChange(values);
      }}
      onDelete={(deletedChip) => {
        let values = input.value || [];
        values = values.filter(v => v !== deletedChip);
        input.onChange(values);
      }}
      onBlur={() => input.onBlur()}
      hintText={hintText}
      label={floatingLabelText}
      error={touched && invalid}
      helperText={touched && error}
    />
  );
} 

export const renderTextArea = ({
  label,
  input,
  meta: {touched, invalid, error},
  ...custom
}) => (
  <CssTextArea
  label={label}
  placeholder={label}
  variant="outlined"
  margin="normal"
  fullWidth={true}
  rows={4}
  error={touched && invalid}
  helperText={touched && error}
  {...input}
  {...custom}
  style={{width: '100%', outlineColor: '#60b044'}}
  />
);

export const renderDatePicker = (props) => {
  const {
    label,
    disabled,
    meta: { submitting, error, touched },
    input: { onBlur, value, ...inputProps },
    ...others
  } = props;

  const onChange = date => {
    Date.parse(date) ? inputProps.onChange(date.toISOString()) : inputProps.onChange(null);
  };

  return(
    <CssDatePicker
      {...inputProps}
      {...others}
      format="DD/MM/YYYY"
      variant="inline"
      inputVariant='outlined'
      fullWidth={true}
      label={label}
      value={value ? new Date(value) : null}
      disabled={disabled}
      onBlur={() => onBlur(value ? new Date(value).toISOString() : null)}
      error={error && touched}
      onChange={onChange}
    />
  );
}