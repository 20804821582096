import {
 FETCH_DOCUMENTS_START,
 FETCH_DOCUMENTS_SUCCESS,
 FETCH_DOCUMENTS_FAILURE,
 SET_DOCUMENTS_CRITERIA,
 SET_DOCUMENTS_PARENT_CLIENT,
 SET_DOCUMENTS_CHILD_CLIENT,
 SET_DOCUMENTS_YEAR,
 SET_DOCUMENTS_CLASSIFICATION,
 SET_DOCUMENTS_PAGE_SIZE,
 SET_DOCUMENTS_CURRENT_PAGE
}

from '../../actions/documents/DocumentListActions';

const INITIAL_STATE = {
fetchError: false,
  isFetchingList: false,
  criteria: '',
  year: null,
  parentClientId: null,
  childClientId: null,
  classificationId: null,
  data: [],
  totalCount: 0,
  pageSize: 25,
  totalPages: 0,
  currentPage: 0
}

export default function DocumentsList(state=INITIAL_STATE, action){
    switch(action.type){
      case SET_DOCUMENTS_CRITERIA:
        return { ...state, criteria: action.payload, currentPage: 0 };
      case SET_DOCUMENTS_CURRENT_PAGE:
        return { ...state, currentPage: action.payload };
      case SET_DOCUMENTS_PARENT_CLIENT:
        return {...state, parentClientId: action.payload};
      case SET_DOCUMENTS_CHILD_CLIENT:
        return {...state, childClientId: action.payload};
      case SET_DOCUMENTS_YEAR:
        return {...state, year: action.payload};
      case SET_DOCUMENTS_CLASSIFICATION:
        return {...state, classificationId: action.payload};
      case SET_DOCUMENTS_PAGE_SIZE:
        return { ...state, pageSize: action.payload, currentPage: 0 };
      case FETCH_DOCUMENTS_START:
        return { ...state, isFetchingList: true, fetchError: false };
      case FETCH_DOCUMENTS_SUCCESS:
        return {
          ...state,
          isFetchingList: false,
          fetchError: false,
          data: action.payload.records,
          totalCount: action.payload.totalCount,
          totalPages: action.payload.totalPages,
        };
      case FETCH_DOCUMENTS_FAILURE:
        return { ...state, isFetchingList: false, fetchError: true, data: [], totalCount: 0, currentPage: 0, totalPages: 0 };

      default:
        return state;
    }
}