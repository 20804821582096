import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import MyFile from '../fileUploader/fileUploader';
import ChipInput from 'material-ui-chip-input'
import InputLabel from '@material-ui/core/InputLabel';

import {renderTextField, renderFromHelper, renderCheckbox, renderSelectField, renderDropzoneInput, renderChip} from '../../constants/customElementsMUI';
import { connect } from 'react-redux';

import {uploadCaseFile, addingNewToMail, removingNewToMail, addingNewCCMail, removingNewCCMail, willNotify} from '../../actions/legalCases/legalCaseListAction';

import {getDocumentTypes, getNotifiableEmails} from '../../actions/catalog/catalogActions';
import { isNonClient } from './../../constants/api';

const validate = values => {
    const errors = {};
    let requiredFields = [
        'typeId',
    ];
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });
    if(values.shouldNotify === true && (values.tos === undefined || values.tos === [])){
        errors.tos = 'Al seleccionar que debe notificar debe especificar al menos un email de envío.';
    }
    /*if(values.user && emailRegex.test(values.email)){
    errors.email = 'Usuario inválido';
    }*/
    return errors;
}

class UploadCaseFileDialog extends Component {
    constructor(props){
        super(props);
    }
    
    componentDidMount() {
        this.props.getDocumentTypes();
        this.props.getNotifiableEmails();
    }

    handleFormSubmit = values => {
        const fd = new FormData();
        if (values.document != undefined) {
            fd.append("document", values.document[0]);
        }
        fd.append('typeId', values.typeId);

        if (values.typeId2 && values.document2 != undefined) {
            fd.append("document2", values.document2[0]);
            fd.append("typeId2", values.typeId2);

            if (values.typeId3 && values.document3 != undefined) {
                fd.append("document3", values.document3[0]);
                fd.append("typeId3", values.typeId3);
                
                if (values.typeId4 && values.document4 != undefined) {
                    fd.append("document4", values.document4[0]);
                    fd.append("typeId4", values.typeId4);
                    
                    if (values.typeId5 && values.document5 != undefined) {
                        fd.append("document5", values.document5[0]);
                        fd.append("typeId5", values.typeId5);
                    }
                }
    
            }
        }

        if(values.shouldNotify === null || values.shouldNotify === undefined){
            fd.append('shouldNotify', false);
        }
        else{
            fd.append('shouldNotify', values.shouldNotify);
        }
        if(values.tos !== undefined && values.tos !== []){
            fd.append('tos', values.tos.join(','));
        }
        else{
            fd.append('tos', '');
        }
        if(values.ccs !== undefined && values.css !== []){
            fd.append('CCs', values.ccs.join(','));
        }
        else{
            fd.append('CCs', '');
        }
        this.props.uploadCaseFile(fd);
    }

    handleAddToChip(chip){
        this.props.addingNewToMail(chip);
    }
    handleDeleteToChip(chip, index){
        this.props.removingNewToMail(chip, index);
    }
    handleAddCCChip(chip){
        this.props.addingNewCCMail(chip);
    }
    handleDeleteCCChip(chip,index){
        this.props.removingNewCCMail(chip, index);
    }
    handleNotifyChange(){
        this.props.willNotify();
    }

    render() {
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                    disableBackdropClick={this.props.isProcessing}
                    disableEscapeKeyDown={this.props.isProcessing}
                >
                    <DialogTitle id="form-dialog-title">Subir archivo de caso</DialogTitle>
                    <form onSubmit={handleSubmit(this.handleFormSubmit)} noValidate autoComplete='off'>
                    <DialogContent>
                        <DialogContentText>
                            Para subir un archivo de caso, favor llenar los siguientes datos:
                        </DialogContentText>
                        <div>                 
                            <h4>Documento 1</h4>                           
                            <Field label={"Documento a subir"} name="document" component={MyFile} type="file"
                            dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                        </div>
                        <div>
                            <div style={{height: "16px"}}></div>
                            <Field
                                name="typeId"
                                component={renderSelectField}
                                label="Tipo de documento 1"
                            >
                                <option key={0} value={null}></option>
                                {this.props.documentTypes !== undefined ? this.props.documentTypes.map(type => {
                                    return(
                                        <option key={type.id} value={type.id}>{type.name}</option>
                                    )
                                }) : undefined}
                            </Field>    
                        </div> 
                        {this.props.formValues && !isNaN(this.props.formValues.typeId) && (
                            <div>
                                <div>                     
                                    <h4>Documento 2</h4>                           
                                    <Field label={"Documento a subir"} name="document2" component={MyFile} type="file"
                                    dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                                </div>
                                <div>
                                    <div style={{height: "16px"}}></div>
                                    <Field
                                        name="typeId2"
                                        component={renderSelectField}
                                        label="Tipo de documento 2"
                                    >
                                        <option key={0} value={null}></option>
                                        {this.props.documentTypes !== undefined ? this.props.documentTypes.map(type => {
                                            return(
                                                <option key={type.id} value={type.id}>{type.name}</option>
                                            )
                                        }) : undefined}
                                    </Field>    
                                </div> 
                            </div>
                        )}
                        
                        {this.props.formValues && !isNaN(this.props.formValues.typeId2) && (
                            <div>
                                <div>                     
                                    <h4>Documento 3</h4>                           
                                    <Field label={"Documento a subir"} name="document3" component={MyFile} type="file"
                                    dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                                </div>
                                <div>
                                    <div style={{height: "16px"}}></div>
                                    <Field
                                        name="typeId3"
                                        component={renderSelectField}
                                        label="Tipo de documento 3"
                                    >
                                        <option key={0} value={null}></option>
                                        {this.props.documentTypes !== undefined ? this.props.documentTypes.map(type => {
                                            return(
                                                <option key={type.id} value={type.id}>{type.name}</option>
                                            )
                                        }) : undefined}
                                    </Field>    
                                </div> 
                            </div>
                        )}

                        {this.props.formValues && !isNaN(this.props.formValues.typeId3) && (
                            <div>
                                <div>                     
                                    <h4>Documento 4</h4>                           
                                    <Field label={"Documento a subir"} name="document4" component={MyFile} type="file"
                                    dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                                </div>
                                <div>
                                    <div style={{height: "16px"}}></div>
                                    <Field
                                        name="typeId4"
                                        component={renderSelectField}
                                        label="Tipo de documento 4"
                                    >
                                        <option key={0} value={null}></option>
                                        {this.props.documentTypes !== undefined ? this.props.documentTypes.map(type => {
                                            return(
                                                <option key={type.id} value={type.id}>{type.name}</option>
                                            )
                                        }) : undefined}
                                    </Field>    
                                </div> 
                            </div>
                        )}
                        
                        {this.props.formValues && !isNaN(this.props.formValues.typeId4) && (
                            <div>
                                <div>                     
                                    <h4>Documento 5</h4>                           
                                    <Field label={"Documento a subir"} name="document5" component={MyFile} type="file"
                                    dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                                </div>
                                <div>
                                    <div style={{height: "16px"}}></div>
                                    <Field
                                        name="typeId5"
                                        component={renderSelectField}
                                        label="Tipo de documento 5"
                                    >
                                        <option key={0} value={null}></option>
                                        {this.props.documentTypes !== undefined ? this.props.documentTypes.map(type => {
                                            return(
                                                <option key={type.id} value={type.id}>{type.name}</option>
                                            )
                                        }) : undefined}
                                    </Field>    
                                </div> 
                            </div>
                        )}
                        
                        
                        <div>
                            <Field name="shouldNotify" onChange={() => this.handleNotifyChange()} component={renderCheckbox} label="Notificar"/>
                        </div>
                        {this.props.shouldNotify === true ?(
                        <div>
                            <div style={{height: "16px"}}></div>
                            <InputLabel>Correos sugeridos:</InputLabel>
                        {this.props.notifiableEmails.map(email => <InputLabel>{email}</InputLabel>)}
                        </div> 
                        ): undefined} 
                        <div>
                        {this.props.shouldNotify === true ? (<div>
                            <div style={{height: "16px"}}></div>
                            <Field name="tos" component={renderChip} floatingLabelText='Destinatarios'/>
                            <div style={{height: "8px"}}></div>
                        </div>
                            
                        ): undefined} 
                        </div>
                        <div>
                        {this.props.shouldNotify === true ? (
                            <div>
                                <div style={{height: "16px"}}></div>
                                <Field name="ccs" component={renderChip} floatingLabelText='CCs' />
                            </div>
                            
                        ): undefined}
                        </div> 
                                                             
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" disabled={submitting ||this.props.isProcessing}>
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary" disabled={pristine||submitting||this.props.isProcessing}>
                            Guardar
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>       
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const {documentTypes, notifiableEmails} = state.catalog;
    const {tos, ccs, shouldNotify, isProcessing} = state.uploadCaseFileModal;
    const {user} = state.auth;
    const selector = formValueSelector('UploadCaseFileForm');
    const formValues = selector(state, 'typeId', 'typeId2', 'typeId3', 'typeId4', 'typeId5');
    let isNotClient = isNonClient(user);
    return {documentTypes, notifiableEmails, isNonClient: isNotClient, isProcessing, tos, ccs, shouldNotify, formValues};
}

const mapDispatchToProps = {
    uploadCaseFile, 
    addingNewToMail, 
    removingNewToMail, 
    addingNewCCMail, 
    removingNewCCMail,
    getDocumentTypes, 
    getNotifiableEmails,
    willNotify
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form:'UploadCaseFileForm', validate})(UploadCaseFileDialog));