import {OPENING_LOGING_STATUS_CLIENT_MODAL, CLOSING_LOGING_STATUS_CLINET_MODAL, CHANGING_LOGING_STATUS_CLIENT} from '../../actions/clients/ClientListActions';

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    clientId: null,
    loginEnabled: null
}

export default function ChangeLoginStatusClientModal(state=INITIAL_STATE, action){
    switch(action.type){
        case OPENING_LOGING_STATUS_CLIENT_MODAL:
            return {...state, open: true, isProcessing: false, clientId: action.payload.clientId, loginEnabled: action.payload.loginEnabled};
        case CLOSING_LOGING_STATUS_CLINET_MODAL:
            return {...state, open: false, isProcessing: false, clientId: null, loginEnabled: null};
        case `${CHANGING_LOGING_STATUS_CLIENT}_PENDING`:
            return {...state, isProcessing: true};
        case `${CHANGING_LOGING_STATUS_CLIENT}_FULFILLED`:
            return {...state, isProcessing: false, open: false, clientId: null, loginEnabled: null};
        case `${CHANGING_LOGING_STATUS_CLIENT}_REJECTED`:
            return {...state, isProcessing: false, open: false, clientId: null, loginEnabled: null};
        default:
            return state;
    }
}