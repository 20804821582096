import {OPEN_UPDATE_BILL_STATUS_MODAL, CLOSE_UPDATE_BILL_STATUS_MODAL, UPDATE_BILL_STATUS} from '../../actions/bills/billActions';

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    billId: null
}

export default function UpdateBillStatusModal(state=INITIAL_STATE,action){
    switch(action.type){
        case OPEN_UPDATE_BILL_STATUS_MODAL:
            return {...state, open: true, billId: action.payload};
        case CLOSE_UPDATE_BILL_STATUS_MODAL:
            return {...state, open: false, billId: null};
        case `${UPDATE_BILL_STATUS}_PENDING`:
            return {...state, isProcessing: true};
        case `${UPDATE_BILL_STATUS}_FULFILLED`:
            return {...state, isProcessing: false, open: false, billId: null};
        case `${UPDATE_BILL_STATUS}_REJECTED`:
            return {...state, isProcessing: false, open: false, billId: null};
        default:
            return state;
    }
}