import {
    INIT_PASSWORD_RESET,
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAILURE
} from '../../actions/passwordReset/PasswordResetActions';

const INITIAL_STATE = {
    isFetching: false,
    wasProcessed: false,
    wasSuccessful: false,
};

export default function PasswordReset(state=INITIAL_STATE, action){
    switch(action.type){
        case INIT_PASSWORD_RESET: 
        return {...state, ...INITIAL_STATE};
        case PASSWORD_RESET_REQUEST:
        return {...state, isFetching: true};
        case PASSWORD_RESET_SUCCESS:
        return {...state, isFetching: false, wasSuccessful: true, wasProcessed: true};
        case PASSWORD_RESET_FAILURE:
        return {...state, isFetching: false, wasSuccessful: false, wasProcessed: true};
        default:
            return state;
    }
}