import axios from 'axios';
import {recoverPassword} from '../../constants/api';
import { enqueueSnackbar, closeSnackbar } from '../notificationActions';
import {reset} from 'redux-form';
export const INIT_PASSWORD_RESET = 'INIT_PASSWORD_RESET_';
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';

const headers = {
  headers:{
    'Content-Type':'application/json'
  }
};


export function initState(){
    return {type: INIT_PASSWORD_RESET}
}


export function resetPassword(info){
    
    return dispatch => {
      dispatch({
        type: PASSWORD_RESET_REQUEST
      });
      dispatch(closeSnackbar());
      axios.post(recoverPassword, JSON.stringify(info), headers)
        .then((response) => {
          //Refactor into a better place to where centralize the options returned and passed into the snack bar

          if(response.data.isSuccessful === true){
            dispatch(reset('PasswordResetForm'));
            dispatch(enqueueSnackbar({
              message: 'Se ha enviado a su correo su nueva contraseña reestablecida.',
              options: {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                autoHideDuration: 6000,
              }
            }));
            return dispatch({
              type: PASSWORD_RESET_SUCCESS
            });
          }
          else{
            dispatch(enqueueSnackbar({
              message: 'Para reestablecer su contraseña, por favor comuníquese con un administrador del sistema',
              options: {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                autoHideDuration: 8000,

              }
            }));
            return dispatch({
              type: PASSWORD_RESET_FAILURE,
          });
          }
        })
        .catch(e => {
            return dispatch({
                type: PASSWORD_RESET_FAILURE
            });
        });
    }
}

