import {
  FETCH_CLIENTS_START,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAILURE,
  SET_CLIENTS_CRITERIA,
  SET_CLIENTS_CURRENT_PAGE,
  SET_CLIENTS_PAGE_SIZE,
  SET_CLIENTS_PARENT_CLIENT
}

  from './../../actions/clients/ClientListActions';

const INITIAL_STATE = {
  fetchError: false,
  isFetchingList: false,
  criteria: '',
  parentClientId: null,
  data: [],
  totalCount: 0,
  pageSize: 25,
  totalPages: 0,
  currentPage: 0
};

export default function ClientsList(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CLIENTS_CRITERIA:
      return { ...state, criteria: action.payload, currentPage: 0 };
    case SET_CLIENTS_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case SET_CLIENTS_PAGE_SIZE:
      return { ...state, pageSize: action.payload, currentPage: 0 };
    case SET_CLIENTS_PARENT_CLIENT:
      return {...state, parentClientId: action.payload};
    case FETCH_CLIENTS_START:
      return { ...state, isFetchingList: true, fetchError: false };
    case FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
        fetchError: false,
        data: action.payload.records,
        totalCount: action.payload.totalCount,
        totalPages: action.payload.totalPages,
      };
    case FETCH_CLIENTS_FAILURE:
      return { ...state, isFetchingList: false, fetchError: true, data: [], totalCount: 0, currentPage: 0, totalPages: 0 };
    default:
      return state;
  }
}