import { GETTING_CLIENTS, GETTING_CLASSIFICATIONS, GETTING_USER_TYPES, GETTING_SPECIAL_ACCESSES, 
    GETTING_CHILD_CLIENTS_CATALOG, CLEAR_CHILD_CLIENTS_CATALOG, GETTING_DOCUMENT_TYPES,
    GETTING_ASSIGNABLE_LAWYERS, GETTING_CASE_TYPES, GETTING_ALL_LAWYERS, GETTING_NOTIFIABLE_EMAILS,
    GETTING_PAYMENT_TYPES, GETTING_PAYMENT_WAYS, GETTING_BILLABLE_LEGAL_CASES, CLEAR_BILLABLE_LEGAL_CASES_CATALOG } from '../../actions/catalog/catalogActions';

const INITIAL_STATE = {
    clients: [],
    childClients: [],
    classifications: [],
    userTypes: [],
    specialAccesses: [],
    documentTypes: [],
    assignableLawyers: [],
    caseTypes: [],
    allLawyers: [],
    notifiableEmails: [],
    billableLegalCases: [],
    paymentTypes: [],
    paymentWays: [],
    isFetchingClients: false,
    isFetchingClassifications: false,
    isFetchingUserTypes: false,
    isFetchingSpecialAccesses: false,
    isFetchingChildClients: false,
    isFetchingDocumentTypes: false,
    isFetchingAssignableLawyers: false,
    isFetchingCaseTypes: false,
    isFetchingAllLawyers: false,
    isFetchingNotifiableEmails: false,
    isFetchingBillableLegalCases: false,
    isFetchingPaymentTypes: false,
    isFetchingPaymentWays: false
};

export default function Catalog(state = INITIAL_STATE, action) {
    switch (action.type) {
        case `${GETTING_CLIENTS}_PENDING`:
            return { ...state, isFetchingClients: true };
        case `${GETTING_CLIENTS}_FULFILLED`:
            return { ...state, isFetchingClients: false, clients: action.payload };
        case `${GETTING_CLIENTS}_REJECTED`:
            return { ...state, isFetchingClients: false, clients: [] };
        case `${GETTING_CLASSIFICATIONS}_PENDING`:
            return { ...state, isFetchingClassifications: true };
        case `${GETTING_CLASSIFICATIONS}_FULFILLED`:
            return { ...state, isFetchingClassifications: false, classifications: action.payload };
        case `${GETTING_CLASSIFICATIONS}_REJECTED`:
            return { ...state, isFetchingClassifications: false, classifications: [] };
        case `${GETTING_USER_TYPES}_PENDING`:
            return { ...state, isFetchingUserTypes: true };
        case `${GETTING_USER_TYPES}_FULFILLED`:
            return { ...state, isFetchingUserTypes: false, userTypes: action.payload };
        case `${GETTING_USER_TYPES}_REJECTED`:
            return { ...state, isFetchingUserTypes: false, userTypes: [] };
        case `${GETTING_SPECIAL_ACCESSES}_PENDING`:
            return { ...state, isFetchingSpecialAccesses: true };
        case `${GETTING_SPECIAL_ACCESSES}_FULFILLED`:
            return { ...state, isFetchingSpecialAccesses: false, specialAccesses: action.payload };
        case `${GETTING_SPECIAL_ACCESSES}_REJECTED`:
            return { ...state, isFetchingSpecialAccesses: false, specialAccesses: [] };
        case `${GETTING_CHILD_CLIENTS_CATALOG}_PENDING`:
            return {...state, isFetchingChildClients: true};
        case `${GETTING_CHILD_CLIENTS_CATALOG}_FULFILLED`:
            return {...state, isFetchingChildClients: false, childClients: action.payload};
        case `${GETTING_CHILD_CLIENTS_CATALOG}_REJECTED`:
            return {...state, isFetchingChildClients: false, childClients: []};
        case CLEAR_CHILD_CLIENTS_CATALOG:
            return {...state, childClients: []};
        case `${GETTING_DOCUMENT_TYPES}_PENDING`:
            return {...state, isFetchingDocumentTypes: true};
        case `${GETTING_DOCUMENT_TYPES}_FULFILLED`:
            return {...state, isFetchingDocumentTypes: false, documentTypes: action.payload};
        case `${GETTING_DOCUMENT_TYPES}_REJECTED`:
            return {...state, isFetchingDocumentTypes: false, documentTypes: []};
        case `${GETTING_ASSIGNABLE_LAWYERS}_PENDING`:
            return {...state, isFetchingAssignableLawyers: true};
        case `${GETTING_ASSIGNABLE_LAWYERS}_FULFILLED`:
            return {...state, isFetchingAssignableLawyers: false, assignableLawyers: action.payload};
        case `${GETTING_ASSIGNABLE_LAWYERS}_REJECTED`:
            return {...state, isFetchingAssignableLawyers: false, assignableLawyers: []};
        case `${GETTING_CASE_TYPES}_PENDING`:
            return {...state, isFetchingCaseTypes: true};
        case `${GETTING_CASE_TYPES}_FULFILLED`:
            return {...state, isFetchingCaseTypes: false, caseTypes: action.payload};
        case `${GETTING_CASE_TYPES}_REJECTED`:
            return {...state, isFetchingCaseTypes: false, caseTypes: []};
        case `${GETTING_ALL_LAWYERS}_PENDING`:
            return {...state, isFetchingAllLawyers: true};
        case `${GETTING_ALL_LAWYERS}_FULFILLED`:
            return {...state, isFetchingAllLawyers: false, allLawyers: action.payload};
        case `${GETTING_ALL_LAWYERS}_REJECTED`:
            return {...state, isFetchingAllLawyers: false, allLawyers: []};
        case `${GETTING_NOTIFIABLE_EMAILS}_PENDING`:
            return {...state, isFetchingNotifiableEmails: true};
        case `${GETTING_NOTIFIABLE_EMAILS}_FULFILLED`:
            return {...state, isFetchingNotifiableEmails: false, notifiableEmails: action.payload};
        case `${GETTING_NOTIFIABLE_EMAILS}_REJECTED`:
            return {...state, isFetchingNotifiableEmails: false, notifiableEmails: []};
        case `${GETTING_BILLABLE_LEGAL_CASES}_PENDING`:
            return {...state, isFetchingBillableLegalCases: true};
        case `${GETTING_BILLABLE_LEGAL_CASES}_FULFILLED`:
            return {...state, isFetchingBillableLegalCases: false, billableLegalCases: action.payload};
        case `${GETTING_BILLABLE_LEGAL_CASES}_REJECTED`:
            return {...state, isFetchingBillableLegalCases: false, billableLegalCases: []};
        case `${GETTING_PAYMENT_TYPES}_PENDING`:
            return {...state, isFetchingPaymentTypes: true};
        case `${GETTING_PAYMENT_TYPES}_FULFILLED`:
            return {...state, isFetchingPaymentTypes: false, paymentTypes: action.payload};
        case `${GETTING_PAYMENT_TYPES}_REJECTED`:
            return {...state, isFetchingPaymentTypes: false, paymentTypes: []};
        case `${GETTING_PAYMENT_WAYS}_PENDING`:
            return {...state, isFetchingPaymentWays: true};
        case `${GETTING_PAYMENT_WAYS}_FULFILLED`:
            return {...state, isFetchingPaymentWays: false, paymentWays: action.payload};
        case `${GETTING_PAYMENT_WAYS}_REJECTED`:
            return {...state, isFetchingPaymentWays: false, paymentWays: []};
        case CLEAR_BILLABLE_LEGAL_CASES_CATALOG:
            return {...state, billableLegalCases: []};
        default:
            return state;
    }
}