import  {
    FETCH_DOCUMENT_TYPES_START,
    FETCH_DOCUMENT_TYPES_SUCCESS,
    FETCH_DOCUMENT_TYPES_FAILURE,
    SET_DOCUMENT_TYPES_CRITERIA,
    SET_DOCUMENT_TYPES_CURRENT_PAGE,
    SET_DOCUMENT_TYPES_PAGE_SIZE
} from '../../actions/documentTypes/DocumentTypesListActions';

const INITIAL_STATE = {
    fetchError: false,
    isFetchingList: false,
    criteria: '',
    data: [],
    totalCount: 0,
    pageSize: 25,
    totalPages: 0,
    currentPage: 0
}

export default function DocumentTypesList(state=INITIAL_STATE, action){
    switch(action.type){
      case SET_DOCUMENT_TYPES_CRITERIA:
        return { ...state, criteria: action.payload, currentPage: 0 };
      case SET_DOCUMENT_TYPES_CURRENT_PAGE:
        return { ...state, currentPage: action.payload };
      case SET_DOCUMENT_TYPES_PAGE_SIZE:
        return { ...state, pageSize: action.payload, currentPage: 0 };
      case FETCH_DOCUMENT_TYPES_START:
        return { ...state, isFetchingList: true, fetchError: false };
      case FETCH_DOCUMENT_TYPES_SUCCESS:
        return {
          ...state,
          isFetchingList: false,
          fetchError: false,
          data: action.payload.records,
          totalCount: action.payload.totalCount,
          totalPages: action.payload.totalPages,
        };
      case FETCH_DOCUMENT_TYPES_FAILURE:
        return { ...state, isFetchingList: false, fetchError: true, data: [], totalCount: 0, currentPage: 0, totalPages: 0 };
      default:
        return state;
    }
}