import {OPENING_USER_LOGIN_STATUS_MODAL, CLOSING_USER_LOGIN_STATUS_MODAL,
    UPDATING_USER_LOGIN_STATUS} from './../../actions/users/UserListActions';

const INITIAL_STATE = {
    open: false,
    userId: null,
    loginEnabled: null,
    isProcessing: false
}

export default function UserLoginStatusModal(state = INITIAL_STATE, action){
    switch(action.type){
        case OPENING_USER_LOGIN_STATUS_MODAL:
            return {...state, open: true, userId: action.payload.userId, isProcessing: false, loginEnabled: action.payload.loginEnabled};
        case CLOSING_USER_LOGIN_STATUS_MODAL:
            return {...state, open: false, userId: null, isProcessing: false, loginEnabled: null};
        case `${UPDATING_USER_LOGIN_STATUS}_FULFILLED`:
            return {...state, open: false, userId: null, isProcessing: false, loginEnabled: null};
            case `${UPDATING_USER_LOGIN_STATUS}_REQUESTED`:
            return {...state,  isProcessing: true};
        case `${UPDATING_USER_LOGIN_STATUS}_REJECTED`:
            return {...state, open: false, userId: null, isProcessing: false, loginEnabled: null};
        default:
            return state;
    }
}