import React, {Component} from 'react';
import debounce from 'lodash/debounce';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import {openCommentModal, closeCommentModal, getComments} from './../../actions/comments/commentActions';
import { renderTextField } from './../../constants/customElementsMUI';
import { Field, reduxForm } from 'redux-form';

import {ColorButtonBlue} from '../../constants/customElementsMUI';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import NewCommentDialog from './newCommentDialog';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import {green} from '@material-ui/core/colors';
import { ResponsiveTable } from 'material-ui-next-responsive-table';
import { Tooltip } from '@material-ui/core';

const columns = [
  { id: 'createdAt', label: 'Fecha', minWidth: 120, align: 'center' },
  { id: 'createdByName', label: 'Creado por', minWidth: 120, align: 'center' },
  { id: 'comment', label: 'Mensaje', minWidth: 120, align: 'center' },
];

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    margin: theme.spacing(3, 0, 2, 3),
    color: 'white'
  },
});

 class CommentsLanding extends Component {

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getComments(id);
  }

  render() {
    const { classes } = this.props;
    let listItemsClasses = Object.assign({}, classes);

    const handleClickOpen = () => {
      this.props.openCommentModal();
    };
    const handleClose = () => {
      this.props.closeCommentModal();
    };
    const handleDownload = (url) => {
        window.open(url);
    }

    const handleReturnToTop = () => {
      window.location = '/legalCases';
    }

    const newColumns = [
      { key: 'createdAt', label: 'Fecha', primary: true},
      { key: 'createdByName', label: 'Creado por', primary: true },
      { key: 'comment', label: 'Mensaje' },
      { key: 'fileUrl', label: 'Acciones', render: (value, column) => {
        return value == null || value == undefined ? '-' : (
          <div>
            <Tooltip title="Descargar">
              <IconButton onClick={() => handleDownload(value)}>
                <CloudDownloadIcon style={{color: green[500]}}/>
              </IconButton>
            </Tooltip>
            
            
            <a target='_blank' className={'link'} href={`/filePreview?name=${column.displayFileName}&fileType=${column.documentExtension}&url=${btoa(column.fileUrl)}`}>Ver preview</a>
             
          </div>
        );
      }}
    ];

    return (
      <React.Fragment>
            <div style={{height: '16px'}}></div>
            <Grid container>
              <Grid item xs={2} sm={1} md={1} lg={1}>
                <br></br>    
                <IconButton onClick={() => handleReturnToTop()}>
                  <ArrowBackIcon style={{color: green[500]}}/>
                </IconButton>
              </Grid>
              <Grid item xs={10} sm={11} md={11} lg={11}>
                <h2>Comentarios para caso: {this.props.comments.caseName}</h2>
              </Grid>
            </Grid>
            
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                  {this.props.comments.canComment === true && (
                        <ColorButtonBlue variant="contained" className={classes.button} onClick={handleClickOpen}>Nuevo Comentario</ColorButtonBlue>
                    )
                  }
                  <NewCommentDialog open={this.props.newCommentModal.open} handleClose={handleClose} />
              </Grid>
            </Grid>
            <Paper>
                            <ResponsiveTable
                                excludePrimaryFromDetails={true}
                                columns={newColumns}
                                data={(this.props.comments && this.props.comments.comments) || []}
                                noContentText={'No hay archivos subidos'}
                                TableProps={{dense: true, table: "true", size: "small"}}
                                TableHeadCellProps= {{align: 'center', style: {"fontWeight": "bold", "backgroundColor": "#D3D3D3", color: "#60b044"}}}
                                TableBodyCellProps={{align: 'center'}}
                                
                                ExpansionPanelSummaryTypographyProps={{style: {"fontWeight": "bold"}}}
                                ExpansionPanelDetailsTypographyProps={{ classes:listItemsClasses}}
                            />
            </Paper>
            {/* <Paper  className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table stickyHeader aria-label="Clients table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      key='actions'
                      align='center'
                      style={{minWidth:80, fontWeight: 'bold'}}
                    >
                      Acciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.comments.comments.map(row => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                        <TableCell align='center'>
                            {row.fileUrl !== null && (
                                <IconButton onClick={() => handleDownload(row.fileUrl)}>
                                    <CloudDownloadIcon style={{color: green[500]}}/>
                                </IconButton>
                            )}                         
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
        </Paper> */}
        
        <br></br>
          <br></br>
      </React.Fragment>
    );
  }
  
  
  

 

}
const mapStateToProps = state => {
  const {comments, newCommentModal} = state;
  return {comments, newCommentModal};
}

const mapDispatchToProps = {
  openCommentModal,
  closeCommentModal,
  getComments
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CommentsLanding));