import React, {Component} from 'react';
import debounce from 'lodash/debounce';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import { getPagedDocumentTypes, handleChangePage, handleChangeRowsPerPage, initPagedDocumentTypes, handleSearch, openingModal,
    closingModal, openingEditingModal, openingDocumentTypeStatusModal, closingDocumentTypeStatusModal, updateDocumentTypeStatus,
    openDeleteDocumentType, closeDeleteDocumentType, deleteDocumentType} from './../../actions/documentTypes/DocumentTypesListActions';
import { renderTextField } from './../../constants/customElementsMUI';
import { Field, reduxForm } from 'redux-form';

import {ColorButtonBlue} from '../../constants/customElementsMUI';

import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';

import Grid from '@material-ui/core/Grid';
import FormDocumentTypeDialog from './formDocumentTypeDialog'
import DocumentTypeStatusDialog from './documentTypeStatusDialog';
import DeleteDocumentTypeDialog from './deleteDocumentTypeDialog';
import IconButton from '@material-ui/core/IconButton';

import {green} from '@material-ui/core/colors';
import ResponsiveTable from 'material-ui-next-responsive-table'
import { Tooltip } from '@material-ui/core';

const columns = [
  { id: 'name', label: 'Nombre', minWidth: 120, align: 'center' },
  { id: 'description', label: 'Descripción', minWidth: 120, align: 'center' },
  { id: 'canFinishCase', label: 'Permite finalizar caso', minWidth: 80, align: 'center', isBoolean: true },

];

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    margin: theme.spacing(3, 0, 2, 3),
    color: 'white'
  },
});

 class DocumentTypeLanding extends Component {

  componentDidMount() {
    this.props.initPagedDocumentTypes();
  }

  debouncedOnSearchChange = debounce(this.props.handleSearch, 1000);

  constructor(props){
    super(props);
  }

  render() {
    const { classes } = this.props;
    let listItemsClasses = Object.assign({}, classes);


    const handleClickOpen = () => {
      this.props.openingModal();
    };
    const handleClose = () => {
      this.props.closingModal();
    };

    const handleEditingOpen = (row) => {
      this.props.openingEditingModal(row);
    }

    const handleStatusChange = (id, isEnabled) => {
        this.props.openingDocumentTypeStatusModal(id, isEnabled);
    }

    const handleClassificationStatusConfirmation = () => {
        this.props.updateDocumentTypeStatus();
    }

    const handleStatusChangeClose = () => {
        this.props.closingDocumentTypeStatusModal();
    }

    const handleDeleteOpen = (id) => {
      this.props.openDeleteDocumentType(id);
    }

    const handleDeleteClose = () => {
      this.props.closeDeleteDocumentType();
    }

    const handleDeleteConfirmation = () => {
      this.props.deleteDocumentType();
    }

    let newColumns = [
      {key: 'name', label: 'Nombre', primary: true},
      {key: 'description', label: 'Descripción'},
      {key: 'canFinishCase', label: 'Puede finalizar caso', render: (value) => {return value ? 'Sí' : 'No';}},
      {key: 'id', label: 'Acciones', render: (value, column, row, data) => {
        return (
          <div>
            <Tooltip title="Editar">
            <IconButton onClick={() => handleEditingOpen(value)}>
                            <EditIcon style={{color: green[500]}}/>
                          </IconButton>
            </Tooltip>
            <Tooltip title={column.isEnabled ? "Inhabilitar" : "Habilitar"}>
                <IconButton onClick={() => handleStatusChange(column.id, column.isEnabled)}>
                            {column.isEnabled ? (<BlockIcon style={{ color: green[500] }}/>) : (<CheckCircleOutlineIcon style={{ color: green[500] }}/>)}
                          </IconButton>
            </Tooltip>
            
                          
                          {column.isDeleteable === true && (
                            <Tooltip title="Eliminar">
                              <IconButton onClick={() => handleDeleteOpen(column.id)}>
                              <DeleteIcon style={{color: green[500]}}/>
                            </IconButton>  
                            </Tooltip>
                            
                          )} 
          </div>
        );
      }},
    ];

    return (
      <React.Fragment>
            <h2>Tipos de documentos</h2>
            <form noValidate autoComplete="off">

            <Grid container>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                  <div><Field
                              label="Buscar"
                              name="criteria"
                              type="text"
                              onChange={this.debouncedOnSearchChange}
                              component={renderTextField}
                          />
                  </div>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <ColorButtonBlue variant="contained" className={classes.button} onClick={handleClickOpen}>Nuevo tipo de documento</ColorButtonBlue>
                <FormDocumentTypeDialog open={this.props.documentTypeModal.open} editing={this.props.documentTypeModal.editing} handleClose={handleClose}/>
                <DocumentTypeStatusDialog open={this.props.documentTypeStatusModal.open}
                isProcessing={this.props.documentTypeStatusModal.isProcessing}
                isEnabled={this.props.documentTypeStatusModal.isEnabled}
                handleClose={handleStatusChangeClose}
                handleConfirmation={handleClassificationStatusConfirmation}
                />
                <DeleteDocumentTypeDialog open={this.props.deleteDocumentTypeModal.open} isProcessing={this.props.deleteDocumentTypeModal.isProcessing}
                handleClose={handleDeleteClose} handleConfirmation={handleDeleteConfirmation} />
              </Grid>
            </Grid>

            </form>
            <Paper>
            <ResponsiveTable
              excludePrimaryFromDetails={true}
              columns={newColumns}
              data={this.props.data}
              showPagination={true}
              count={this.props.totalCount}
              rowsPerPage={this.props.pageSize}
              page={this.props.currentPage}
              noContentText={'No hay registros'}
              onChangePage={this.props.handleChangePage}
              TableProps={{dense: true, table: "true", size: "small"}}
              TableHeadCellProps= {{align: 'center', style: {"fontWeight": "bold", "backgroundColor": "#D3D3D3", color: "#60b044"}}}
              TableBodyCellProps={{align: 'center'}}
              TablePaginationProps={{
                labelRowsPerPage: "Cantidad de Filas",
                rowsPerPageOptions: [10, 25, 50, 100],
                component: "div",
                onChangeRowsPerPage: this.props.handleChangeRowsPerPage
              }}
              ExpansionPanelSummaryTypographyProps={{style: {"fontWeight": "bold"}}}
              ExpansionPanelDetailsTypographyProps={{ classes:listItemsClasses}}
            />
            </Paper>
            {/* <Paper  className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table stickyHeader aria-label="Clients table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      key='actions'
                      align='center'
                      style={{minWidth:80,fontWeight: 'bold'}}
                    >
                      Acciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.data.map(row => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.isBoolean ? (value == true ? "Sí" : "No") : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align='center'>
                          <IconButton onClick={() => handleEditingOpen(row.id)}>
                            <EditIcon style={{color: green[500]}}/>
                          </IconButton>
                          <IconButton onClick={() => handleStatusChange(row.id, row.isEnabled)}>
                            {row.isEnabled ? (<BlockIcon style={{ color: green[500] }}/>) : (<CheckCircleOutlineIcon style={{ color: green[500] }}/>)}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={this.props.totalCount}
            rowsPerPage={this.props.pageSize}
            page={this.props.currentPage}
            onChangePage={this.props.handleChangePage}
            onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
            labelRowsPerPage={"Cantidad de Filas"}
            labelDisplayedRows={	({ from, to, count }) =>`${from}-${to === -1 ? count : to} de ${count}`}
          />
        </Paper> */}
        <br></br>
          <br></br>
      </React.Fragment>
    );
  }






}
const mapStateToProps = state => {
  const {pagedDocumentTypes, documentTypeModal, documentTypeStatusModal, deleteDocumentTypeModal} = state;
  return {...pagedDocumentTypes, documentTypeModal, documentTypeStatusModal, deleteDocumentTypeModal};
}

const mapDispatchToProps = {
  getPagedDocumentTypes,
  handleChangePage,
  handleChangeRowsPerPage,
  initPagedDocumentTypes,
  handleSearch,
  openingModal,
  closingModal,
  openingEditingModal,
  openingDocumentTypeStatusModal,
  closingDocumentTypeStatusModal,
  updateDocumentTypeStatus,
  openDeleteDocumentType,
  closeDeleteDocumentType,
  deleteDocumentType
};

export default withStyles(styles)(reduxForm({form:'DocumentTypesListForm'})(connect(mapStateToProps, mapDispatchToProps)(DocumentTypeLanding)));