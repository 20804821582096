import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';

import {renderSelectField} from '../../constants/customElementsMUI';

import {changeDocumentTypeLegalCase} from '../../actions/legalCases/legalCaseListAction';

import { connect } from 'react-redux';

const validate = values => {
    const errors = {};
    let requiredFields = [
        'documentTypeId',
    ];
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });
    /*if(values.user && emailRegex.test(values.email)){
    errors.email = 'Usuario inválido';
    }*/
    return errors;
}

class ChangeDocumentTypeDialog extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;
        return (
            <div>
                <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                    disableBackdropClick={this.props.isProcessing}
                    disableEscapeKeyDown={this.props.isProcessing}
                >
                    <DialogTitle id="form-dialog-title">Cambiar tipo de documento</DialogTitle>
                    <form onSubmit={handleSubmit(this.props.changeDocumentTypeLegalCase)} noValidate autoComplete='off'>
                    <DialogContent>
                        <DialogContentText>
                            Se procederá a cambiar el tipo de documento
                        </DialogContentText>
                        <Field
                                name="documentTypeId"
                                component={renderSelectField}
                                label="Tipo de documento"
                                onChange={this.handleParentClientChange}
                            >
                                <option key={0} value={null}></option>
                                {this.props.documentTypes !== undefined ? this.props.documentTypes.map(dt => {
                                    return(
                                        <option key={dt.id} value={dt.id}>{dt.name}</option>
                                    )
                                }) : undefined}
                        </Field>                                                  
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" disabled={this.props.isProcessing}>
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary" disabled={pristine||submitting||this.props.isProcessing}>
                            Guardar
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>       
            </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {changeDocumentTypeLegalCaseModal, catalog} = state;
    const {documentTypes} = catalog;
    return {changeDocumentTypeLegalCaseModal, isProcessing: changeDocumentTypeLegalCaseModal.isProcessing, documentTypes};
}

const mapDispatchToProps = {
    changeDocumentTypeLegalCase
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'ChangeDocumentTypeLegalCaseForm', validate})(ChangeDocumentTypeDialog));