import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import {renderTextField, renderFromHelper, renderCheckbox, renderSelectField, renderDropzoneInput, radioButton} from '../../constants/customElementsMUI';
import { connect } from 'react-redux';

import {createLegalCase, openDeleteLegalCaseFile, openChangeDocumentTypeLegalCase} from '../../actions/legalCases/legalCaseListAction';

import {getChildClients, clearChildClients, getClients, getAssignableLawyers, getCaseTypes} from '../../actions/catalog/catalogActions';
import { isNonClient, isRootUser } from './../../constants/api';
import {FormControlLabel} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import { green } from '@material-ui/core/colors';
import { ResponsiveTable } from 'material-ui-next-responsive-table';
import {Link} from 'react-router';
const columns = [
    { id: 'documentType', label: 'Tipo de doc', minWidth: 120, align: 'center' },
    { id: 'displayFileName', label: 'Archivo', minWidth: 120, align: 'center' },
    { id: 'uploadedBy', label: 'Subido por', minWidth: 120, align: 'center' },
    { id: 'uploadedAt', label: 'Fecha de subida', minWidth: 120, align: 'center' },
];

const styles = theme => ({
    root: {
      width: '100%',
      overflowX: 'auto',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    tableWrapper: {
      width: 'auto',
    },
    button: {
      margin: theme.spacing(3, 0, 2, 3),
      color: 'white'
    },
    horizontalForm: {
      width: '100%',
      overflowY: 'auto',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row'
    },
    gridInput: {
      margin: theme.spacing(1)
    }
});

const validate = values => {
    const errors = {};
    let requiredFields = [
        'name',
        'typeId',
        'assignedUserId',
        'clientId'
    ];
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });
    /*if(values.user && emailRegex.test(values.email)){
    errors.email = 'Usuario inválido';
    }*/
    return errors;
}

class ViewLegalCaseDialog extends Component {
    constructor(props){
        super(props);
    }

    state = { imageFile: [] };

    render() {
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;
        const { classes } = this.props;
        let listItemsClasses = Object.assign({}, classes);
        const handleDownload = (url) => {
          window.open(url);
        }
        const handleErase = (id, legalCaseId) => {
            this.props.openDeleteLegalCaseFile(id, legalCaseId);
        }
        const handleOpenChangeDocumentType = (id, legalCaseId) => {
          this.props.openChangeDocumentTypeLegalCase(id, legalCaseId);
        }
        const newColumns = [
            { key: 'documentType', label: 'Tipo de doc'},
            { key: 'displayFileName', label: 'Archivo'},
            { key: 'uploadedBy', label: 'Subido por' },
            { key: 'uploadedAt', label: 'Fecha de subida'},
            { key: 'downloadUrl', label: 'Acciones', render: (value, column) => {
                return value == null ? '-' : (
                    <div>
                        {this.props.isAdmin && (
                          <IconButton onClick={() => handleOpenChangeDocumentType(column.id, this.props.legalCase.id)}>
                            <EditIcon style={{ color: green[500] }}/>
                          </IconButton>
                        )}
                        {this.props.isAdmin && this.props.legalCase.isFinished === false ?
                            (
                                <IconButton onClick={() => handleErase(column.id, this.props.legalCase.id)}>
                                    <DeleteIcon style={{ color: green[500] }}/>
                                </IconButton>

                            )
                            : undefined}
                        <IconButton onClick={() => handleDownload(value)}>
                            <CloudDownloadIcon style={{ color: green[500] }}/>
                        </IconButton>
                          <a target='_blank' className={'link'} href={`/filePreview?name=${column.displayFileName}&fileType=${column.documentExtension}&url=${btoa(column.downloadUrl)}`}>Ver preview</a>
                        
                    </div>
                )
            }}
        ];
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                    scroll={'paper'}
                    disableBackdropClick={this.props.isProcessing}
                    disableEscapeKeyDown={this.props.isProcessing}
                    fullWidth={true}
                    maxWidth={'xl'}
                >
                    <DialogTitle id="form-dialog-title">Visualizar caso</DialogTitle>
                    <form onSubmit={handleSubmit(this.props.createLegalCase)} noValidate autoComplete='off'>
                    <DialogContent dividers={scroll === 'paper'}>
                        <DialogContentText>
                            Datos del caso {this.props.legalCase !== null ? this.props.legalCase.caseName: undefined}
                        </DialogContentText>
                        <div>
                            <Field
                                label="Nombre del caso"
                                name="caseName"
                                type="text"
                                component={renderTextField}
                                disabled={true}
                            />
                        </div>
                        <div>
                            <Field
                                name="parentClientName"
                                component={renderTextField}
                                label="Cliente"
                                disabled={true}
                            />   
                        </div>
                        {this.props.legalCase !== null && this.props.legalCase.subclientName !== null ?
                        (<div>
                            <div style={{height: "16px"}}></div>
                            <Field
                                name="subclientName"
                                component={renderTextField}
                                label="Subcliente"
                                disabled={true}
                            />  
                        </div>) : undefined}
                        <div>
                            <div style={{height: "16px"}}></div>
                            <Field
                                name="assignedUserName"
                                component={renderTextField}
                                label="Asignado a"
                                disabled={true}
                            />  
                        </div>
                        <div>
                            <div style={{height: "16px"}}></div>
                            <Field
                                name="typeName"
                                component={renderTextField}
                                label="Tipo de Caso"
                                disabled={true}
                            />    
                        </div>
                        <Paper>
                            <ResponsiveTable
                                excludePrimaryFromDetails={true}
                                columns={newColumns}
                                data={(this.props.legalCase && this.props.legalCase.uploadedFiles) || []}
                                noContentText={'No hay archivos subidos'}
                                TableProps={{dense: true, table: "true", size: "small"}}
                                TableHeadCellProps= {{align: 'center', style: {"fontWeight": "bold", "backgroundColor": "#D3D3D3", color: "#60b044"}}}
                                TableBodyCellProps={{align: 'center'}}
                                
                                ExpansionPanelSummaryTypographyProps={{style: {"fontWeight": "bold"}}}
                                ExpansionPanelDetailsTypographyProps={{ classes:listItemsClasses}}
                            />
                         </Paper>
                        {/* <Paper  className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table stickyHeader aria-label="Clients table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell 
                        style={{fontWeight: 'bold'}}
                    >
                      Acciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.legalCase !== null ? this.props.legalCase.uploadedFiles.map(row => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                        <TableCell align='center'>
                          <IconButton onClick={() => handleDownload(row.downloadUrl)}>
                            <CloudDownloadIcon style={{ color: green[500] }}/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  }):undefined}
                </TableBody>
              </Table>
            </div>
                </Paper>                                              */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" disabled={submitting ||this.props.isProcessing}>
                            Cancelar
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>       
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const {legalCase} = state.viewLegalCaseModal;
    const initialValues = legalCase;
    const {user} = state.auth;
    
    let isAdmin = isRootUser(user);
    let isNotClient = isNonClient(user);
    return {isNonClient: isNotClient, isAdmin, initialValues, legalCase};
}

const mapDispatchToProps = {
    createLegalCase,
    openDeleteLegalCaseFile,
    getClients,
    getChildClients,
    getAssignableLawyers,
    getCaseTypes,
    clearChildClients,
    openChangeDocumentTypeLegalCase
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(reduxForm({form:'ViewLegalCaseForm', validate, enableReinitialize: true})(ViewLegalCaseDialog)));