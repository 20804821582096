import {OPENING_DELETE_DOCUMENT_MODAL, CLOSING_DELETE_DOCUMENT_MODAL, DELETING_DOCUMENT} from '../../actions/documents/DocumentListActions';

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    documentId: null
}

export default function DeleteDocumentModal(state = INITIAL_STATE, action){
    switch(action.type){
        case OPENING_DELETE_DOCUMENT_MODAL:
            return {...state, open: true, documentId: action.payload, isProcessing: false}
        case CLOSING_DELETE_DOCUMENT_MODAL:
            return {...state, open: false, isProcessing: false, documentId: null}
        case `${DELETING_DOCUMENT}_PENDING`:
            return {...state, isProcessing: true}
        case `${DELETING_DOCUMENT}_FULFILLED`:
            return {...state, isProcessing: false, open: false, documentId: null}
        case `${DELETING_DOCUMENT}_REJECTED`:
            return {...state, isProcessing: false, open: false, documentId: null}
        default:
            return state;
    }
}