import authAxios, {clients} from './../../constants/api';
import {reset} from 'redux-form';
export const FETCH_CLIENTS_START = 'FETCH_CLIENTS_START';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE';
export const SET_CLIENTS_CURRENT_PAGE = 'SET_CLIENTS_CURRENT_PAGE';
export const SET_CLIENTS_CRITERIA = 'SET_CLIENTS_CRITERIA';
export const SET_CLIENTS_PARENT_CLIENT = 'SET_CLIENTS_PARENT_CLIENT';
export const SET_CLIENTS_PAGE_SIZE = 'SET_CLIENTS_PAGE_SIZE';
export const CREATING_NEW_CLIENT = 'CREATING_NEW_CLIENT';
export const OPENING_CLIENT_MODAL = 'OPENING_CLIENT_MODAL';
export const CLOSING_CLIENT_MODAL = 'CLOSING_CLIENT_MODAL';
export const OPENING_EDITING_MODAL = 'OPENING_EDITING_MODAL';
export const OPENING_VIEW_MODAL = 'OPENING_VIEW_MODAL';
export const DELETING_CLIENT = 'DELETING_CLIENT';

export const EDITING_CLIENT = 'EDITING_CLIENT';

export const OPENING_DELETING_CLIENT_MODAL = 'OPENING_DELETING_CLIENT_MODAL';
export const CLOSING_DELETING_CLIENT_MODAL = 'CLOSING_DELETING_CLIENT_MODAL';

export const CHANGING_LOGING_STATUS_CLIENT = 'CHANGING_LOGING_STATUS_CLIENT';
export const OPENING_LOGING_STATUS_CLIENT_MODAL = 'OPENING_LOGING_STATUS_CLIENT';
export const CLOSING_LOGING_STATUS_CLINET_MODAL = 'CLOSING_LOGING_STATUS_CLIENT';

export const UPDATING_CLIENT_PASSWORD = 'UPDATING_CLIENT_PASSWORD';
export const OPENING_UPDATING_CLIENT_PASSWORD_MODAL = 'OPENING_UPDATING_CLIENT_PASSWORD_MODAL';
export const CLOSING_UPDATING_CLIENT_PASSWORD_MODAL = 'CLOSING_UPDATING_CLIENT_PASSWORD_MODAL';

export const OPENING_CLIENT_USER_MODAL='OPENING_CLIENT_USER_MODAL';
export const CLOSING_CLIENT_USER_MODAL='CLOSING_CLIENT_USER_MODAL';

export const EDITING_CLIENT_USER = 'EDITING_CLIENT_USER';
export const CREATING_NEW_CLIENT_USER = 'CREATING_NEW_CLIENT_USER';
export const CHANGING_PASSWORD_CLIENT_USER = 'CHANGING_PASSWORD_CLIENT_USER';
export const DELETING_CLIENT_USER = 'DELETING_CLIENT_USER';
export const OPENING_DELETING_CLIENT_USER_MODAL = 'OPENING_DELETING_CLIENT_USER_MODAL';
export const CLOSING_DELETING_CLIENT_USER_MODAL = 'CLOSING_DELETING_CLIENT_USER_MODAL';

import {getClients} from '../catalog/catalogActions';
 
export function handleSearch(event, newValue, previousValue, name) {
  return dispatch => {
    dispatch(setCriteria(newValue));
    return dispatch(getPagedClients());
  }
}

export function handleParentClient(event, newValue, previousValue, name){
  return dispatch => {
    if(newValue === 'Todos'){
      newValue = null;
    }
    dispatch(setParentClient(newValue));
    return dispatch(getPagedClients());
  }
}

export function handleChangePage(event, newPage){
  return dispatch => {
    dispatch(setPage(newPage));
    return dispatch(getPagedClients());
  }
}

export function handleChangeRowsPerPage(event) {
  
  return dispatch => {
    dispatch(setPageSize(event.target.value));
    return dispatch(getPagedClients());
  }
}

export function startFetchClientList() {
  return {
    type: FETCH_CLIENTS_START
  };
}

export function setPage(page) {
  return {
    type: SET_CLIENTS_CURRENT_PAGE,
    payload: page
  };
}


export function setPageSize(pageSize) {
  return {
    type: SET_CLIENTS_PAGE_SIZE,
    payload: pageSize
  };
}

export function setCriteria(criteria) {
  return {
    type: SET_CLIENTS_CRITERIA,
    payload: criteria
  };
}

export function setParentClient(parentId){
  return {
    type: SET_CLIENTS_PARENT_CLIENT,
    payload: parentId
  };
}

export function processClientList(data) {
  return {
    type: FETCH_CLIENTS_SUCCESS,
    payload: data
  };
}

export function processClientListFailure() {
  return {
    type: FETCH_CLIENTS_FAILURE
  }
}
export function initPagedClients() {
  return (dispatch) => {
    dispatch(setCriteria(''));
    dispatch(setPage(0));
    dispatch(setPageSize(25));
    return dispatch(getPagedClients());
  };
}
export function getPagedClients() {
  return (dispatch, getState) => {
    
    const { auth, pagedClients } = getState();
    dispatch(startFetchClientList());
    let pagedPayload = {
      page: pagedClients.currentPage + 1,
      pageSize: pagedClients.pageSize,
      parentClientId: pagedClients.parentClientId,
      criteria: pagedClients.criteria
    }
    authAxios.get(clients, {params: pagedPayload}, {headers: auth.headers})
      .then((response) => {
        return dispatch(processClientList(response.data));
        
      })
      .catch(e => {
        return dispatch(processClientListFailure());
      });
  }
}

export function createClient(info){
  return (dispatch, getState) => {
    if(!info.canAccessBills){
      info.canAccessBills = false;
    }
    if(!info.canAccessDocuments){
      info.canAccessDocuments = false;
    }
    dispatch({
      type: `${CREATING_NEW_CLIENT}_REQUESTED`
    })
    const {auth} = getState();
    authAxios.post(clients, JSON.stringify(info), {headers: auth.headers})
    .then((response) => {
      dispatch(reset('ClientForm'));
      dispatch({
        type: `${CREATING_NEW_CLIENT}_FULFILLED`,
      });

      dispatch(getPagedClients());
      return dispatch(getClients());
    })
    .catch(e => {
      dispatch(reset('ClientForm'));
      return dispatch({
        type: `${CREATING_NEW_CLIENT}_REJECTED`,
        payload: e
      })
    })
  }
}

export function openingClientModal(){
  return dispatch => {
    dispatch({type: OPENING_CLIENT_MODAL});
  }
}

export function closingClientModal(){
  return dispatch => {
    dispatch({type: CLOSING_CLIENT_MODAL});
    return dispatch(reset('ClientForm'));
  }
}

export function openingEditingModal(info, readOnly){
  return (dispatch, getState) => {
    const {auth} = getState();
    authAxios.get(`${clients}/${info}`, {headers: auth.headers})
    .then(response => {
      delete response.data.parentClientName;
      delete response.data.loginEnabled;
      return dispatch({
        type: readOnly ? OPENING_VIEW_MODAL : OPENING_EDITING_MODAL,
        payload: {
          client: response.data
        }
      })
    })
  }
}

export function openingDeletingClientModal(id){
  return dispatch => {
    dispatch({type: OPENING_DELETING_CLIENT_MODAL, payload: id});
  }
}

export function closingDeletingClientModal(){
  return dispatch => {
    dispatch({type: CLOSING_DELETING_CLIENT_MODAL});
  }
}

export function openingChangingLoginStatusClientModal(id, loginEnabled){
  return dispatch => {
    dispatch({type: OPENING_LOGING_STATUS_CLIENT_MODAL,
      payload: {
      clientId: id,
      loginEnabled
    }});
  }
}

export function closingChangingLoginStatusClientModal(){
  return dispatch => {
    dispatch({type: CLOSING_LOGING_STATUS_CLINET_MODAL});
  }
}

export function openingUpdatingPasswordModal(clientId, userId){
  return dispatch => {
    dispatch(closingClientModal());
    return dispatch({
      type: OPENING_UPDATING_CLIENT_PASSWORD_MODAL,
      payload: {
        clientId,
        userId
      }
    });
  }
}

export function closingUpdatingPasswordModal(){
  return dispatch => {
    dispatch({type: CLOSING_UPDATING_CLIENT_PASSWORD_MODAL});
  }
}

export function editingClient(info){
  return (dispatch, getState) => {
    if(!info.canAccessBills){
      info.canAccessBills = false;
    }
    if(!info.canAccessDocuments){
      info.canAccessDocuments = false;
    }
    const {auth} = getState();
    const {clientModal} = getState();
    const {id} = clientModal.client;
    dispatch({
      type: `${EDITING_CLIENT}_REQUESTED`
    });
    authAxios.put(`${clients}/${id}`, JSON.stringify(info), {headers: auth.headers})
    .then(response => {
      dispatch({
        type: `${EDITING_CLIENT}_FULFILLED`
      });
      dispatch(reset('ClientForm'));
      dispatch(getPagedClients());
      dispatch(getClients());
    })
    .catch(e => {
      dispatch(reset('ClientForm'));
      dispatch({
        type: `${EDITING_CLIENT}_REJECTED`,
        payload: e
      });
    });
  }
}

export function deletingCLient(){
  return (dispatch, getState) => {
    const {auth} = getState();
    const {deletingClientModal} = getState();
    dispatch({type: `${DELETING_CLIENT}_PENDING`});
    authAxios.delete(`${clients}/${deletingClientModal.clientId}`, {headers: auth.headers})
    .then(response => {
      dispatch({
        type: `${DELETING_CLIENT}_FULFILLED`
      });
      dispatch(getPagedClients());
      dispatch(getClients());
    })
    .catch(e => {
      dispatch({
        type: `${EDITING_CLIENT}_REJECTED`,
        payload: e
      })
    });
  }
}

export function changeLoginStatusClient(){
  return (dispatch, getState) => {
    const {auth, changingLoginStatusClientModal} = getState();
    const {loginEnabled} = changingLoginStatusClientModal;
    let payload = {
      loginEnabled: !loginEnabled
    }
    dispatch({type:`${CHANGING_LOGING_STATUS_CLIENT}_PENDING`});
    authAxios.put(`${clients}/${changingLoginStatusClientModal.clientId}/loginStatus`, JSON.stringify(payload), {headers: auth.headers})
    .then(response => {
      dispatch({
        type: `${CHANGING_LOGING_STATUS_CLIENT}_FULFILLED`
      })
      dispatch(getPagedClients());
    })
    .catch(e => {
      dispatch({type: `${CHANGING_LOGING_STATUS_CLIENT}_REJECTED`, payload: e});
    })
  }
}

export function updateClientPassword(info){
  return (dispatch, getState) => {
    const {auth, updatingClientPasswordModal} = getState();
    dispatch({type: `${UPDATING_CLIENT_PASSWORD}_PENDING`});
    authAxios.put(`${clients}/${updatingClientPasswordModal.clientId}/user/${updatingClientPasswordModal.userId}/password`, 
    JSON.stringify(info), {headers:auth.headers})
    .then(response => {
      dispatch({
        type: `${UPDATING_CLIENT_PASSWORD}_FULFILLED`
      })
      dispatch(openingEditingModal(updatingClientPasswordModal.clientId, true));
    })
    .catch(e => {
      dispatch({type: `${UPDATING_CLIENT_PASSWORD}_REJECTED`, payload: e});
    });
  }
}

export function openingClientUserModal(clientId, info, isEditing){
  return(dispatch) => {
    dispatch(closingClientModal());
    return dispatch({type: OPENING_CLIENT_USER_MODAL, payload: {
      clientId,
      userData: info,
      isEditing
    }});
  }
}

export function closingClientUserModal(){
  return dispatch => {
    dispatch({type: CLOSING_CLIENT_USER_MODAL});
  }
}

export function editingClientUser(info){
  return (dispatch, getState) => {
    dispatch({type: `${EDITING_CLIENT_USER}_PENDING`});
    const {auth, clientUserFormModal} = getState();
    info.clientId = clientUserFormModal.clientId;
    authAxios.put(`${clients}/${clientUserFormModal.clientId}/user/${clientUserFormModal.user.id}`,
    JSON.stringify(info), {headers: auth.headers})
    .then(response => {
      dispatch({type: `${EDITING_CLIENT_USER}_FULFILLED`});
      dispatch(openingEditingModal(clientUserFormModal.clientId, true));
      return dispatch(reset('ClientUserForm'));
    })
    .catch(e => {
      return dispatch({type: `${EDITING_CLIENT_USER}_REJECTED`, payload: e});
    })
  }
}

export function creatingNewClientUser(info){
  return (dispatch, getState) => {
    dispatch({type: `${CREATING_NEW_CLIENT_USER}_PENDING`});
    const {auth, clientUserFormModal} = getState();
    info.clientId = clientUserFormModal.clientId;
    authAxios.post(`${clients}/${clientUserFormModal.clientId}/user`, JSON.stringify(info), {headers: auth.headers})
    .then(response => {
      dispatch({type: `${CREATING_NEW_CLIENT_USER}_FULFILLED`});
      dispatch(openingEditingModal(clientUserFormModal.clientId, true));
      return dispatch(reset('ClientUserForm'));
    })
    .catch(e => {
      return dispatch({type: `${CREATING_NEW_CLIENT_USER}_REJECTED`, payload: e});
    })
  }
}

export function deletingClientUser(id){
  return (dispatch, getState) => {
    dispatch({type: `${DELETING_CLIENT_USER}_PENDING`});
    const {auth, deletingClientUserModal} = getState();
    authAxios.put(`${clients}/${deletingClientUserModal.clientId}/user/${deletingClientUserModal.userId}/loginStatus`,
    JSON.stringify({
      loginEnabled: false
    }), {headers: auth.headers})
    .then(response => {
      dispatch({type: `${DELETING_CLIENT_USER}_FULFILLED`});
      return dispatch(openingEditingModal(deletingClientUserModal.clientId, true));
    })
    .catch(e => {
      return dispatch({type: `${DELETING_CLIENT_USER}_REJECTED`, payload: e});
    })
  }
}

export function openingDeletingClientUserModal(clientId, userId){
  return dispatch => {
    dispatch({type: OPENING_DELETING_CLIENT_USER_MODAL, payload: {clientId, userId}});
    return dispatch(closingClientModal());
  }
}

export function closingDeletingClientUserModal(){
  return dispatch => {
    dispatch({type: CLOSING_DELETING_CLIENT_USER_MODAL});
  }
}