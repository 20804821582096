import {OPEN_COMMENT_MODAL, CLOSE_COMMENT_MODAL, CREATE_NEW_COMMENT, CHANGE_WILL_NOTIFY} from '../../actions/comments/commentActions';

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    shouldNotify: false
}

export default function NewCommentModalReducer(state=INITIAL_STATE, action){
    switch(action.type){
        case OPEN_COMMENT_MODAL:
            return {...state, open: true};
        case CLOSE_COMMENT_MODAL:
            return {...state, open: false, isProcessing: false};
        case `${CREATE_NEW_COMMENT}_PENDING`:
            return {...state, isProcessing: true};
        case `${CREATE_NEW_COMMENT}_FULFILLED`:
            return {...state, isProcessing: false, open: false};
        case `${CREATE_NEW_COMMENT}_REJECTED`:
            return {...state, isProcessing: false, open: false};
        case CHANGE_WILL_NOTIFY:
            return {...state, shouldNotify: action.payload};
        default:
            return state;
    }
}