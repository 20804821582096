import React, { Component } from 'react'

import { Grid } from '@material-ui/core';
import logo from '../assets/logo.png';
import Hidden from '@material-ui/core/Hidden';
export default class Home extends Component {
    render() {
        return (
            <div>
                <div
                    style={{
                        position: 'absolute', 
                        left: '50%', 
                        top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                <Hidden mdUp>
                    
                <img src={logo} height='100vh' style={{textAlign: "center"}} />
                
                <h1  style={{textAlign: "center"}}>Bienvenido</h1>
                </Hidden>
                <Hidden smDown>
                    
                <img src={logo} height='250vh' style={{textAlign: "center"}}/>
                
                <h1  style={{textAlign: "center"}}>Bienvenido</h1>
                </Hidden>
                </div>
            </div>
        )
    }
}
