import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';

import {renderTextField, renderFromHelper, renderCheckbox, renderSelectField} from '../../constants/customElementsMUI';
import { connect } from 'react-redux';

import {createDocumentType, editingDocumentType} from '../../actions/documentTypes/DocumentTypesListActions';

const validate = values => {
    const errors = {};
    let requiredFields = [
        'name'
    ];
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });
    return errors;
}

class FormDocumentTypeDialog extends Component {

    constructor(props){
        super(props);
    }
    
    render() {
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;
        
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                disableBackdropClick={this.props.isProcessing}
                disableEscapeKeyDown={this.props.isProcessing}
                >
                    <DialogTitle id="form-dialog-title">Nuevo Tipo de Documento</DialogTitle>
                    <form onSubmit={handleSubmit(this.props.editing === false ? this.props.createDocumentType : this.props.editingDocumentType)} init noValidate autoComplete='off'>
                    <DialogContent>
                        <DialogContentText>
                            Para crear un nuevo tipo de documento, favor llenar los siguientes datos:
                        </DialogContentText>
                        <div>
                            <Field
                                label="Nombre"
                                name="name"
                                type="text"
                                component={renderTextField}
                            />
                        </div>
                        <div>
                            <Field
                                label="Descripción"
                                name="description"
                                type="text"
                                component={renderTextField}
                            />
                        </div>
                        <div>
                            <Field name="canFinishCase" component={renderCheckbox} label="Permite finalizar un trámite"/>
                        </div>                                      
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" disabled={this.props.isProcessing}>
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary" disabled={pristine||submitting || this.props.isProcessing}>
                            Guardar
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>       
            </div>
        )
    }
}


const mapStateToProps = (state, props) => {
    const initialValues = state.documentTypeModal.documentType;
    const { isProcessing } = state.documentTypeModal;
    return {initialValues, isProcessing};
}

const mapDispatchToProps = {
    createDocumentType,
    editingDocumentType
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form:'DocumentTypeForm', validate, enableReinitialize: true})(FormDocumentTypeDialog));