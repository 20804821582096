import authAxios, {legalCases, clients, isNonClient} from './../../constants/api';
import {reset} from 'redux-form';
import moment from 'moment';

export const FETCH_LEGAL_CASES_START = 'FETCH_LEGAL_CASES_START';
export const FETCH_LEGAL_CASES_SUCCESS = 'FETCH_LEGAL_CASES_SUCCESS';
export const FETCH_LEGAL_CASES_FAILURE = 'FETCH_LEGAL_CASES_FAILURE';
export const SET_LEGAL_CASES_CURRENT_PAGE = 'SET_LEGAL_CASES_CURRENT_PAGE';
export const SET_LEGAL_CASES_CRITERIA = 'SET_LEGAL_CASES_CRITERIA';
export const SET_LEGAL_CASES_PAGE_SIZE = 'SET_LEGAL_CASES_PAGE_SIZE';
export const SET_LEGAL_CASES_PARENT_CLIENT = 'SET_LEGAL_CASES_PARENT_CLIENT';
export const SET_LEGAL_CASES_CHILD_CLIENT = 'SET_LEGAL_CASES_CHILD_CLIENT';
export const SET_LEGAL_CASES_CASE_TYPE = 'SET_LEGAL_CASES_CASE_TYPE';
export const SET_LEGAL_CASES_LAWYER = 'SET_LEGAL_CASES_LAWYER';
export const CREATING_NEW_LEGAL_CASE = 'CREATING_NEW_LEGAL_CASE';
export const OPENING_LEGAL_CASE_MODAL = 'OPENING_LEGAL_CASE_MODAL';
export const CLOSING_LEGAL_CASE_MODAL = 'CLOSING_LEGAL_CASE_MODAL';

export const GETTING_CHILD_CLIENTS = 'GETTING_CHILD_CLIENTS';
export const RESETTING_CHILD_CLIENTS = 'RESETTING_CHILD_CLIENTS';

export const OPENING_VIEW_LEGAL_CASE_MODAL = 'OPENING_VIEW_LEGAL_CASE_MODAL';
export const CLOSING_VIEW_LEGAL_CASE_MODAL = 'CLOSING_VIEW_LEGAL_CASE_MODAL';

export const UPLOADING_CASE_FILE = 'UPLOADING_CASE_FILE';
export const OPENING_UPLOAD_CASE_FILE_MODAL = 'OPENING_UPLOAD_CASE_FILE_MODAL';
export const CLOSING_UPLOAD_CASE_FILE_MODAL = 'CLOSING_UPLOAD_CASE_FILE_MODAL';
export const ADDING_NEW_TO_MAIL = 'ADDING_NEW_TO_MAIL';
export const REMOVING_NEW_TO_MAIL = 'REMOVING_NEW_TO_MAIL';
export const ADDING_NEW_CC_MAIL = 'ADDING_NEW_CC_MAIL';
export const REMOVING_NEW_CC_MAIL = 'REMOVING_NEW_CC_MAIL';
export const CHANGE_WILL_NOTIFY = 'CHANGE_WILL_NOTIFY';

export const REUPLOADING_CASE_FILE = 'REUPLOADING_CASE_FILE';
export const OPENING_REUPLOAD_CASE_FILE_MODAL = 'OPENING_REUPLOAD_CASE_FILE_MODAL';
export const CLOSING_REUPLOAD_CASE_FILE_MODAL = 'CLOSING_REUPLOAD_CASE_FILE_MODAL';

export const FINISHING_CASE = 'FINISHING_CASE';
export const OPENING_FINISHING_CASE_MODAL = 'OPENING_FINISHING_CASE_MODAL';
export const CLOSING_FINISHING_CASE_MODAL = 'CLOSING_FINISHING_CASE_MODAL';

export const DELETE_CASE = 'DELETE_CASE';
export const OPENING_DELETE_CASE = 'OPENING_DELETE_CASE';
export const CLOSING_DELETE_CASE = 'CLOSING_DELETE_CASE';


export const DELETE_CASE_FILE = 'DELETE_CASE_FILE';
export const OPENING_DELETE_CASE_FILE = 'OPENING_DELETE_CASE_FILE';
export const CLOSING_DELETE_CASE_FILE = 'CLOSING_DELETE_CASE_FILE';

export const CHANGE_OWNER_LEGAL_CASE = 'CHANGE_OWNER_LEGAL_CASE';
export const OPEN_CHANGE_OWNER_CASE = 'OPEN_CHANGE_OWNER_CASE';
export const CLOSE_CHANGE_OWNER_CASE = 'CLOSE_CHANGE_OWNER_CASE';

export const CHANGE_DOCUMENT_TYPE_LEGAL_CASE = 'CHANGE_DOCUMENT_TYPE_LEGAL_CASE';
export const OPEN_CHANGE_DOCUMENT_TYPE_LEGAL_CASE = 'OPEN_CHANGE_DOCUMENT_TYPE_LEGAL_CASE';
export const CLOSE_CHANGE_DOCUMENT_TYPE_LEGAL_CASE = 'CLOSE_CHANGE_DOCUMENT_TYPE_LEGAL_CASE';

export const EDIT_LEGAL_CASE = 'EDIT_LEGAL_CASE';
export const OPEN_EDIT_LEGAL_CASE = 'OPEN_EDIT_LEGAL_CASE';
export const CLOSE_EDIT_LEGAL_CASE = 'CLOSE_EDIT_LEGAL_CASE';

import {getChildClients as getChildClientsCatalog, clearChildClients as clearChildClientsCatalog, getDocumentTypes, getCaseTypes, getAssignableLawyers} from '../catalog/catalogActions';

export function handleSearch(event, newValue, previousValue, name) {
    return dispatch => {
      dispatch(setCriteria(newValue));
      return dispatch(getPagedLegalCases());
    }
}

export function handleParentClient(event, newValue, previousValue, name){
    return (dispatch, getState) => {
      let isNotClient = isNonClient(getState().auth.user);
        
      if(newValue === 'Todos'){
        newValue = null;
        dispatch(clearChildClientsCatalog());
        dispatch(setChildClient(newValue));
      }
      else if(isNotClient){
        dispatch(getChildClientsCatalog(newValue));
      }
      dispatch(setParentClient(newValue));
      if (!isNotClient) {
        dispatch(setChildClient(newValue))
      }
      return dispatch(getPagedLegalCases());
    }
}

export function handleChildClient(event, newValue, previousValue, name){
    return dispatch => {
      if(newValue === 'Todos'){
        newValue = null;
      }
      dispatch(setChildClient(newValue));
      return dispatch(getPagedLegalCases());
    }
}

export function handleCaseType(event, newValue, previousValue, name){
    return dispatch => {
        if(newValue === 'Todos'){
            newValue = null;
        }
        dispatch(setCaseType(newValue));
        return dispatch(getPagedLegalCases());
    }
}

export function handleLawyer(event, newValue, previousValue, name){
    return dispatch => {
        if(newValue === 'Todos'){
            newValue = null;
        }
        dispatch(setLawyer(newValue));
        return dispatch(getPagedLegalCases());
    }
}

export function handleChangePage(event, newPage){
    return dispatch => {
        dispatch(setPage(newPage));
        return dispatch(getPagedLegalCases());
    }
}

export function handleChangeRowsPerPage(event) {
    return dispatch => {
      dispatch(setPageSize(event.target.value));
      return dispatch(getPagedLegalCases());
    }
}

export function startFetchLegalCaseList() {
    return {
      type: FETCH_LEGAL_CASES_START
    };
}

export function setPage(page) {
    return {
      type: SET_LEGAL_CASES_CURRENT_PAGE,
      payload: page
    };
}

export function setPageSize(pageSize) {
    return {
      type: SET_LEGAL_CASES_PAGE_SIZE,
      payload: pageSize
    };
}

export function setCriteria(criteria) {
    return {
      type: SET_LEGAL_CASES_CRITERIA,
      payload: criteria
    };
}

export function setParentClient(parentClient){
    return{
      type: SET_LEGAL_CASES_PARENT_CLIENT,
      payload: parentClient
    }
}

export function setChildClient(childClient){
    return{
      type: SET_LEGAL_CASES_CHILD_CLIENT,
      payload: childClient
    }
}

export function setCaseType(caseType){
    return{
        type: SET_LEGAL_CASES_CASE_TYPE,
        payload: caseType
    }
}

export function setLawyer(lawyer){
    return{
        type: SET_LEGAL_CASES_LAWYER,
        payload: lawyer
    }
}

export function processLegalCaseList(data) {
    return {
      type: FETCH_LEGAL_CASES_SUCCESS,
      payload: data
    };
}

export function processLegalCaseListFailure() {
    return {
      type: FETCH_LEGAL_CASES_FAILURE
    }
}

export function initPagedLegalCases() {
    return (dispatch) => {
      dispatch(setCriteria(''));
      dispatch(setPage(0));
      dispatch(setPageSize(25));
      return dispatch(getPagedLegalCases());
    };
}

export function getPagedLegalCases() {
    return (dispatch, getState) => {

      const { auth, pagedLegalCases } = getState();
      

      dispatch(startFetchLegalCaseList());
      let pagedPayload = {
        page: pagedLegalCases.currentPage + 1,
        pageSize: pagedLegalCases.pageSize,
        criteria: pagedLegalCases.criteria,
        ascending: pagedLegalCases.ascending,
        orderBy: pagedLegalCases.orderBy,
        parentClientId: pagedLegalCases.parentClientId,
        specificClientId: pagedLegalCases.childClientId,
        typeId: pagedLegalCases.typeId,
        assignedUserId: pagedLegalCases.lawyerId
      }
      
      authAxios.get(legalCases, {params: pagedPayload}, {headers: auth.headers})
        .then((response) => {
          response.data.records.map(c => c.date = moment.utc(c.date).local().format('DD/MM/YYYY'));
          return dispatch(processLegalCaseList(response.data));
          
        })
        .catch(e => {
          return dispatch(processLegalCaseListFailure());
        });
    }
}

export function openingLegalCaseModal(){
    return dispatch => {
        dispatch({type: OPENING_LEGAL_CASE_MODAL});
    }
}

export function closingLegalCaseModal(){
    return dispatch => {
        dispatch({type: CLOSING_LEGAL_CASE_MODAL});
    }
}

export function createLegalCase(info){
    return(dispatch, getState) => {
        if(info.childClientId !== undefined && info.childClientId !== null){
          info.clientId = info.childClientId;
        }
        const {auth} = getState();
        dispatch({type: `${CREATING_NEW_LEGAL_CASE}_REQUESTED`});
        authAxios.post(legalCases, JSON.stringify(info), {headers: auth.headers})
        .then(response => {
            dispatch({type: `${CREATING_NEW_LEGAL_CASE}_FULFILLED`});
            dispatch(reset('LegalCaseForm'));
            return dispatch(getPagedLegalCases());
        })
        .catch(e => {
            dispatch(reset('LegalCaseForm'));
            dispatch({type: `${CREATING_NEW_LEGAL_CASE}_REJECTED`, payload: e});
        })
    }
}

export function getChildClients(parentId){
    return (dispatch, getState) => {
        dispatch({type: `${GETTING_CHILD_CLIENTS}_PENDING`})
        const {auth} = getState();
        let pagedPayload = {
          parentClientId: parentId
        }
        authAxios.get(`${clients}/catalogue`, {params: pagedPayload}, {headers: auth.headers})
        .then(response => {
            return dispatch({
                type: `${GETTING_CHILD_CLIENTS}_FULFILLED`,
                payload: response.data
            });
        })
        .catch(e => {
            return dispatch({
                type: `${GETTING_CHILD_CLIENTS}_REJECTED`,
                payload: e
            });
        })
    }
}

export function clearChildClients(){
  return dispatch => {
    return dispatch({type: `${RESETTING_CHILD_CLIENTS}`});
  }
}

export function openingViewLegalCaseModal(id){
  return (dispatch, getState) => {
    dispatch({type: `${OPENING_VIEW_LEGAL_CASE_MODAL}_PENDING`});
    const {auth} = getState();
    authAxios.get(`${legalCases}/${id}`, {headers: auth.headers})
    .then(response => {
      response.data.uploadedFiles.map(file => file.uploadedAt = moment.utc(file.uploadedAt).local().format('DD/MM/YYYY'));
      return dispatch({
        type: `${OPENING_VIEW_LEGAL_CASE_MODAL}_FULFILLED`,
        payload: response.data
      });
    })
    .catch(e => {
      return dispatch({
        type: `${OPENING_VIEW_LEGAL_CASE_MODAL}_REJECTED`,
        payload: e
      });
    });
  }
}

export function closingViewLegalCaseModal(){
  return dispatch => {
    return dispatch({type: CLOSING_VIEW_LEGAL_CASE_MODAL});
  }
}

export function uploadCaseFile(info){
  return (dispatch, getState) => {
    dispatch({type: `${UPLOADING_CASE_FILE}_PENDING`});
    const {auth, uploadCaseFileModal} = getState();
    authAxios.post(`${legalCases}/${uploadCaseFileModal.legalCaseId}/caseFiles`, info, {headers: auth.headersMultipart})
    .then(response => {
      dispatch({type: `${UPLOADING_CASE_FILE}_FULFILLED`});
      dispatch(reset('UploadCaseFileForm'));
      return dispatch(getPagedLegalCases());
    })
    .catch(e => {
      return dispatch({type: `${UPLOADING_CASE_FILE}_REJECTED`, payload: e});
    });
  }
}

export function openingUploadCaseFileModal(id){
  return (dispatch) => {
    dispatch(reset('UploadCaseFileForm'));
    dispatch(getDocumentTypes());
    return dispatch({type: OPENING_UPLOAD_CASE_FILE_MODAL, payload: id});
  }
}

export function closingUploadCaseFileModal(){
  return dispatch => {
    return dispatch({type: CLOSING_UPLOAD_CASE_FILE_MODAL});
  }
}

export function addingNewToMail(chip){
  return (dispatch, getState) => {
    const {uploadCaseFileModal} = getState();
    uploadCaseFileModal.tos.push(chip);
    return dispatch({type: ADDING_NEW_TO_MAIL, payload: uploadCaseFileModal.tos});
  }
}

export function removingNewToMail(chip, index){
  return (dispatch, getState) => {
    const {uploadCaseFileModal} = getState();
    uploadCaseFileModal.tos.splice(uploadCaseFileModal.tos.indexOf(chip), index);
    return dispatch({type: REMOVING_NEW_TO_MAIL, payload: uploadCaseFileModal.tos});
  }
}

export function addingNewCCMail(chip){
  return (dispatch, getState) => {
    const {uploadCaseFileModal} = getState();
    uploadCaseFileModal.ccs.push(chip);
    return dispatch({type: ADDING_NEW_CC_MAIL, payload: uploadCaseFileModal.ccs});
  }
}

export function removingNewCCMail(chip, index){
  return (dispatch, getState) => {
    const {uploadCaseFileModal} = getState();
    uploadCaseFileModal.ccs.splice(uploadCaseFileModal.ccs.indexOf(chip), index);
    return dispatch({type: REMOVING_NEW_CC_MAIL, payload: uploadCaseFileModal.ccs});
  }
}

export function willNotify(){
  return(dispatch, getState) => {
    const {uploadCaseFileModal} = getState();
    return dispatch({type: CHANGE_WILL_NOTIFY, payload: !uploadCaseFileModal.shouldNotify});
  }
}

export function reuploadCaseFile(info){
  return(dispatch, getState) => {
    dispatch({type: `${REUPLOADING_CASE_FILE}_PENDING`});
    const {auth, reuploadCaseFileModal} = getState();
    authAxios.post(`${legalCases}/${reuploadCaseFileModal.legalCaseId}/caseFileReupload`, info, {headers: auth.headersMultipart})
    .then(response =>{
      dispatch({type: `${REUPLOADING_CASE_FILE}_FULFILLED`});
      dispatch(reset('ReUploadCaseFileForm'));
      dispatch(getPagedLegalCases());
    })
    .catch(e => {
      return dispatch({type: `${REUPLOADING_CASE_FILE}_REJECTED`, payload: e});
    });
  }
}

export function openingReuploadCaseFileModal(id){
  return dispatch => {
    return dispatch({type: OPENING_REUPLOAD_CASE_FILE_MODAL, payload: id});
  }
}

export function closingReuploadCaseFIleModal(){
  return dispatch => {
    return dispatch({type: CLOSING_REUPLOAD_CASE_FILE_MODAL});
  }
}

export function finishingCase(){
  return(dispatch, getState) => {
    dispatch({type: `${FINISHING_CASE}_PENDING`});
    const {auth, finishCaseModal} = getState();
    authAxios.put(`${legalCases}/${finishCaseModal.legalCaseId}/caseStatus`, {headers: auth.headers})
    .then(response => {
      dispatch({type: `${FINISHING_CASE}_FULFILLED`});
      return dispatch(getPagedLegalCases());
    })
    .catch(e => {
      return dispatch({type: `${FINISHING_CASE}_REJECTED`, payload: e});
    });
  }
}

export function openingFinishCaseModal(id){
  return dispatch => {
    return dispatch({type: OPENING_FINISHING_CASE_MODAL, payload: id});
  }
}

export function closingFinishCaseModal(){
  return dispatch => {
    return dispatch({type: CLOSING_FINISHING_CASE_MODAL});
  }
}

export function deleteLegalCase(){
  return (dispatch, getState) => {
    dispatch({type: `${DELETE_CASE}_PENDING`});
    const {auth, deleteCaseModal} = getState();
    authAxios.delete(`${legalCases}/${deleteCaseModal.legalCaseId}`, {headers: auth.headers})
    .then(() => {
      dispatch({type: `${DELETE_CASE}_FULFILLED`});
      return dispatch(getPagedLegalCases());
    })
    .catch(e => {
      return dispatch({type: `${DELETE_CASE}_REJECTED`, payload: e});
    });
  }
}

export function openDeleteLegalCase(id){
  return dispatch => {
    return dispatch({type: OPENING_DELETE_CASE, payload: id});
  }
}

export function closeDeleteLegalCase(){
  return dispatch => {
    return dispatch({type: CLOSING_DELETE_CASE});
  }
}

export function deleteLegalCaseFile(){
  return (dispatch, getState) => {
    dispatch({type: `${DELETE_CASE_FILE}_PENDING`});
    const {auth, deleteCaseFileModal} = getState();
    let legalCaseId = deleteCaseFileModal.legalCaseId;
    authAxios.delete(`${legalCases}/caseFiles/${deleteCaseFileModal.id}`, {headers: auth.headers})
    .then(() => {
      dispatch({type: `${DELETE_CASE_FILE}_FULFILLED`});
      return dispatch(openingViewLegalCaseModal(legalCaseId));
    })
    .catch(e => {
      dispatch({type: `${DELETE_CASE}_REJECTED`, payload: e});
      
      return dispatch(openingViewLegalCaseModal(legalCaseId));
    });
  }
}

export function openDeleteLegalCaseFile(id, legalCaseId){
  return dispatch => {
    dispatch(closingViewLegalCaseModal());
    return dispatch({type: OPENING_DELETE_CASE_FILE, payload: {id, legalCaseId}});
  }
}

export function closeDeleteLegalCaseFile(){
  return (dispatch, getState) => {
    const {deleteCaseFileModal} = getState();
    let legalCaseId = deleteCaseFileModal.legalCaseId;
    
    dispatch({type: CLOSING_DELETE_CASE_FILE});
    
    return dispatch(openingViewLegalCaseModal(legalCaseId));
  }
}

export function changeOwnerLegalCase(info){
  return (dispatch, getState) => {
    dispatch({type: `${CHANGE_OWNER_LEGAL_CASE}_PENDING`});
    const {auth, changeOwnerCaseModal} = getState();
    authAxios.put(`${legalCases}/${changeOwnerCaseModal.legalCaseId}/owner`, JSON.stringify(info), {headers: auth.headers})
    .then(() => {
      dispatch({type: `${CHANGE_OWNER_LEGAL_CASE}_FULFILLED`});
      dispatch(reset('ChangeOwnerLegalCaseForm'));
      return dispatch(getPagedLegalCases());
    })
  }
}

export function openChangeOwnerLegalCase(id, parentClientId){
  return dispatch => {
    dispatch({type: OPEN_CHANGE_OWNER_CASE, payload:{
      legalCaseId: id,
      parentClientId
    }});
    return dispatch(getChildClientsCatalog(parentClientId));
  }
}

export function closeChangeOwnerLegalCase(){
  return dispatch => {
    return dispatch({type: CLOSE_CHANGE_OWNER_CASE});
  }
}

export function changeDocumentTypeLegalCase(info){
  return (dispatch, getState) => {
    dispatch({type: `${CHANGE_DOCUMENT_TYPE_LEGAL_CASE}_PENDING`});
    const {auth, changeDocumentTypeLegalCaseModal} = getState();
    let legalCaseId = changeDocumentTypeLegalCaseModal.legalCaseId;
    authAxios.put(`${legalCases}/caseFiles/${changeDocumentTypeLegalCaseModal.documentTypeId}`, JSON.stringify(info), {headers: auth.headers})
    .then(() => {
      dispatch({type: `${CHANGE_DOCUMENT_TYPE_LEGAL_CASE}_FULFILLED`});
      dispatch(reset('ChangeDocumentTypeLegalCaseForm'));
      return dispatch(openingViewLegalCaseModal(legalCaseId));
    })
    .catch(e => {
      dispatch({type: `${CHANGE_DOCUMENT_TYPE_LEGAL_CASE}_REJECTED`, payload: e});
      
      return dispatch(openingViewLegalCaseModal(legalCaseId));
    });
  }
}

export function openChangeDocumentTypeLegalCase(id, legalCaseId){
  return dispatch => {
    dispatch(getDocumentTypes());
    dispatch({type: OPEN_CHANGE_DOCUMENT_TYPE_LEGAL_CASE, payload:{id, legalCaseId}});
    return dispatch(closingViewLegalCaseModal());
  }
}

export function closeChangeDocumentTypeLegalCase(){
  return (dispatch, getState) => {
    const {changeDocumentTypeLegalCaseModal} = getState();
    let legalCaseId = changeDocumentTypeLegalCaseModal.legalCaseId;
    dispatch({type: CLOSE_CHANGE_DOCUMENT_TYPE_LEGAL_CASE});
    return dispatch(openingViewLegalCaseModal(legalCaseId));
  }
}

export function editLegalCase(info){
  return(dispatch, getState) => {
    dispatch({type: `${EDIT_LEGAL_CASE}_PENDING`});
    const {editLegalCaseModal, auth} = getState();
    authAxios.post(`${legalCases}/${editLegalCaseModal.legalCaseId}`, JSON.stringify(info), {headers: auth.headers})
    .then(() => {
      dispatch({type: `${EDIT_LEGAL_CASE}_SUCCESS`});
      dispatch(reset('EditLegalCaseForm'));
      return dispatch(getPagedLegalCases());
    })
    .catch(e => {
      return dispatch({type: `${EDIT_LEGAL_CASE}_REJECTED`, payload: e});
    })
  }
}

export function openEditLegalCase(legalCaseId, legalCaseName, typeId, assignedUserId){
  return dispatch => {
    dispatch(getCaseTypes());
    dispatch(getAssignableLawyers());
    return dispatch({type: OPEN_EDIT_LEGAL_CASE, payload: {
      legalCaseId,
      legalCaseName,
      typeId,
      assignedUserId
    }})
  }
}

export function closeEditLegalCase(){
  return dispatch => {
    return dispatch({type: CLOSE_EDIT_LEGAL_CASE});
  }
}