import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


class FinishLegalCaseDialog extends Component {

    constructor(props){
        super(props);
    }
    
    render() {
        const {open, handleClose, handleConfirmation, isProcessing, loginEnabled} = this.props;
        
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}
                disableBackdropClick={isProcessing}
                disableEscapeKeyDown={isProcessing}
                scroll={'paper'}
                    maxWidth={'xs'}>
                    <DialogTitle id="form-dialog-title">Finalizar / Reabrir Caso</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            ¿Está seguro que desea cambiar el estado de este caso? Cuando el caso se encuentra cerrado ya no se podrán cargar más archivos ni comentar, aunque se podrá reabrir de ser necesario.
                        </DialogContentText>                                                            
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary"  disabled={isProcessing}>
                            Cancelar
                        </Button>
                        <Button  color="primary" onClick={handleConfirmation} disabled={isProcessing}>
                            Confirmar
                            </Button>
                    </DialogActions>

                </Dialog>       
            </div>
        )
    }
}



export default FinishLegalCaseDialog;