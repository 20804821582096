import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {green} from '@material-ui/core/colors';
import {Field, reduxForm} from 'redux-form';

import Logo from '../../assets/logo.png'
import {ColorButton, GreenLink, BorderedGrid, renderTextField} from '../../constants/customElementsMUI';
import { connect } from 'react-redux';

import {initState, resetPassword} from '../../actions/passwordReset/PasswordResetActions';


const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  typography: {
      color: green[500],
  },
});

const validate = values => {
  const errors = {};
  const requiredFields = [
    'username',
    'email',
  ];
  requiredFields.forEach(field => {
    if(!values[field]){
      errors[field] = 'Obligatorio*';
    }
  });

  return errors;
}

class PasswordReset extends Component {
  constructor(props){
    super(props);
    this.state = {
      username: '',
      email: '',
      formValid: false
    };
  }

  componentDidMount() {
    this.props.initState();
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isAuthenticated){
      let routeTo;
      if(this.props.location.state && this.props.location.state.from){
        routeTo = this.props.location.state.from.pathname;
      }
      else{
        routeTo = '/';
      }
      return this.props.history.push(routeTo);
    }
  }

  setFormInvalid = () => {
    this.setState({
      formValid: false,
    });
  }

  setFormValid = () => {
    this.setState({
      formValid: true,
    });
  }

  handleChange = (e, {name, value}) => {
    let field = {};
    field[name] = value;
    this.setState(field);
  }

  requestPasswordReset = (formValues) => {
    return this.props.resetPassword(formValues)
  }
  


  render() {
    const {handleSubmit, pristine, reset, submitting, classes} = this.props;
    return(
      <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh'}}
      >
          <BorderedGrid item xs={12} sm={12} md={6} lg={6}>
            <div className={classes.paper}>
                <img src={Logo}/>
                <Typography component="h1" variant="h5" className={classes.typography}>
                Recuperar contraseña
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit(this.props.resetPassword)} noValidate autoComplete="off">
                <div>
                  <Field
                      label="Usuario"
                      name="username"
                      type="text"
                      component={renderTextField}
                  />
                </div>
                <div>
                  <Field
                    label="Correo electrónico"
                    name="email"
                    type="text"
                    component={renderTextField}
                  />
                </div>
                
                <div>
                  <ColorButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={pristine || submitting || this.props.isFetching}
                  >
                      Recuperar
                  </ColorButton>
                </div>
                <Grid container >
                    <Grid item xs style={{textAlign: "center"}}>
                        <GreenLink href="/login" variant="body2">
                            Regresar a Login
                        </GreenLink>
                    </Grid>
                </Grid>
                
                </form>
            </div>
          </BorderedGrid>
      </Grid>
    )     
  }
}
const mapStateToProps = state => {
  const {isAuthenticated} = state.auth;
  const {passwordReset} = state;
  return {...passwordReset, isAuthenticated};
}

const mapDispatchToProps = {
  initState,
  resetPassword
};
export default withStyles(styles)(reduxForm({form:'PasswordResetForm', validate, resetPassword})(connect(mapStateToProps, mapDispatchToProps)(PasswordReset)));