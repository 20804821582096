import React, { Component } from 'react'
import debounce from 'lodash/debounce';

import Container from '@material-ui/core/Container';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';

import { renderTextField, renderSelectField } from './../../constants/customElementsMUI';
import { Field, reduxForm } from 'redux-form';

import {ColorButtonBlue} from '../../constants/customElementsMUI';

import { getPagedDocuments, handleChangePage, handleChangeRowsPerPage, initPagedDocuments, handleSearch, openingDocumentModal, closingDocumentModal,
openingResubmitDocumentModal, closingResubmitDocumentModal, openingChangeOwnerModal, closingChangeOwnerModal,
handleParentClient, handleChildClient, handleYear, handleClassification, openingDeletingDocumentModal, closdingDeletingDocumentModal,
deleteDocument} from './../../actions/documents/DocumentListActions';

import {getClients, getChildClients, getClassifications, clearChildClients} from '../../actions/catalog/catalogActions';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PublishIcon from '@material-ui/icons/Publish';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import DeleteIcon from '@material-ui/icons/Delete';

import IconButton from '@material-ui/core/IconButton';
import FormDocumentDialog from './formDocumentDialog';

import ResubmitDocumentDialog from './resubmitDocumentDialog';
import ChangeOwnerDialog from './changeOwnerDialog';
import DeleteDocumentDialog from './deleteDocumentDialog';

import Grid from '@material-ui/core/Grid';
import { green } from '@material-ui/core/colors';
import { isRootUser, isNonClient, hasModulePermission, modules } from './../../constants/api';
import { ResponsiveTable } from 'material-ui-next-responsive-table';
import Tooltip from '@material-ui/core/Tooltip';
const columns = [
    { id: 'year', label: 'Año', minWidth: 120, align: 'center' },
    { id: 'name', label: 'Nombre del documento', minWidth: 120, align: 'center' },
    { id: 'ownerName', label: 'Cliente', minWidth: 120, align: 'center' },
    { id: 'classificationName', label: 'Clasificación', minWidth: 80, align: 'center' },
];

const styles = theme => ({
    root: {
      width: '100%',
      overflowX: 'auto',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    button: {
      margin: theme.spacing(3, 0, 2, 3),
      color: 'white'
    },
});

class DocumentsLanding extends Component {

    debouncedOnSearchChange = debounce(this.props.handleSearch, 1000);

    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.props.getClients();
        this.props.getClassifications();
        this.props.initPagedDocuments();
    }

    render() {
        const { classes } = this.props;
        let listItemsClasses = Object.assign({}, classes);

        const handleClickOpen = () => {
            this.props.openingDocumentModal()
        };
        const handleClose = () => {
            this.props.closingDocumentModal()
        };
      
        const handleResubmitOpen = (id) => {
            this.props.openingResubmitDocumentModal(id);
        }

        const handleResubmitClose = () => {
          this.props.closingResubmitDocumentModal();
        }
        
        const handleChangeOwnerOpen = (id, parentId) => {
          this.props.openingChangeOwnerModal(id, parentId);
        }

        const handleChangeOwnerClose = () => {
          this.props.closingChangeOwnerModal();
        }

        const handleNavigationToClassifications = () => {
          window.location='/classifications';
        }

        const handleDownload = (url) => {
          window.open(url);
        }

        const handleDeleteDocumentOpen = (id) => {
          this.props.openingDeletingDocumentModal(id);
        }

        const handleDeleteDocumentClose = () => {
          this.props.closdingDeletingDocumentModal();
        }

        const handleDeleteConfirmation = () => {
          this.props.deleteDocument();
        }

        const newColumns = [
          { key: 'year', label: 'Año' },
          { key: 'name', label: 'Nombre del documento', primary: true},
          { key: 'ownerName', label: 'Cliente', primary: true },
          { key: 'classificationName', label: 'Clasificación' },
          { key: 'id', label: 'Acciones', render: (value, column) => {
            return (
              <div>
                          <Tooltip title="Resubir" aria-label="resubir">
                          <IconButton onClick={() => handleResubmitOpen(column.id)}>
                                                      <PublishIcon style={{ color: green[500] }}/>
                                                    </IconButton>
                          </Tooltip>
                          <Tooltip title="Cambiar dueño" aria-label="cambiar dueño">
                            <IconButton onClick={() => handleChangeOwnerOpen(column.id, column.parentClientId)}>
                              <CompareArrowsIcon style={{ color: green[500] }}/>
                            </IconButton>
                          </Tooltip>
                          
                          {this.props.isAdmin ? (
                            <Tooltip title="Borrar" aria-label="borrar">
                              <IconButton onClick={() => handleDeleteDocumentOpen(column.id)}>
                                <DeleteIcon style={{color: green[500]}}/>
                              </IconButton>  
                            </Tooltip>
                            
                          ): undefined}  
                          <Tooltip title="Descargar" aria-label="descargar">
                            <IconButton onClick={() => handleDownload(column.downloadUrl)}>
                              <CloudDownloadIcon style={{ color: green[500] }}/>
                            </IconButton>
                          </Tooltip>
                          
                          <a target='_blank' className={'link'} href={`/filePreview?name=${column.displayFileName}&fileType=${column.documentExtension}&url=${btoa(column.downloadUrl)}`}>Ver preview</a>
                          
              </div>
            );
          }}
        ];

        return (
            <React.Fragment>
            <h2>Documentos</h2>
            
            <form noValidate autoComplete="off">
            <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                  <div><Field
                              label="Buscar"
                              name="criteria"
                              type="text"
                              onChange={this.debouncedOnSearchChange}
                              component={renderTextField}
                          />
                  </div>
                  </Grid>
                  <Grid item  item xs={12} sm={6} md={4} lg={3}>
                  <div style={{height: "16px"}}></div>
                    <Field
                      label="Cliente"
                      name="parentClientId"
                      onChange={this.props.handleParentClient}
                      component={renderSelectField}
                    >
                      <option key={0} value={null}>Todos</option>
                                {this.props.clients !== undefined ? this.props.clients.map(client => {
                                    return(
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    )
                                }) : undefined}
                    </Field>
                  </Grid>
                 
                  {this.props.isNonClient && (
                     <Grid item  item xs={12} sm={6} md={4} lg={3}>
                    <div>
                      <div style={{height: "16px"}}></div>
                    <Field
                      label="Subcliente"
                      name="subClientId"
                      onChange={this.props.handleChildClient}
                      component={renderSelectField}
                    >
                      <option key={0} value={null}>Todos</option>
                                {this.props.childClients !== undefined ? this.props.childClients.map(client => {
                                    return(
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    )
                                }) : undefined}
                    </Field>
                    </div>
                    </Grid>
                  )}
                  
                 
                  <Grid item  item xs={12} sm={6} md={4} lg={3}>
                        <Field
                              label="Año"
                              name="year"
                              type="number"
                              onChange={this.props.handleYear}
                              component={renderTextField}
                          />
                  </Grid>
                  <Grid item  item xs={12} sm={6} md={4} lg={3}>
                  <div style={{height: "16px"}}></div>
                    <Field
                      label="Clasificación"
                      name="classification"
                      onChange={this.props.handleClassification}
                      component={renderSelectField}
                    >
                      <option key={0} value={null}>Todos</option>
                                {this.props.classifications !== undefined ? this.props.classifications.map(classification => {
                                    return(
                                        <option key={classification.id} value={classification.id}>{classification.name}</option>
                                    )
                                }) : undefined}
                    </Field>
                  </Grid>

              <Grid item  item xs={12} sm={6} md={2} lg={2}>
                <ColorButtonBlue variant="contained" className={classes.button} onClick={handleClickOpen}>Nuevo Documento</ColorButtonBlue>
                <FormDocumentDialog open={this.props.documentModal.open} handleClose={handleClose}/>
                <ResubmitDocumentDialog open={this.props.resubmitDocumentModal.open} handleClose={handleResubmitClose}/>
                <ChangeOwnerDialog open={this.props.changeDocumentOwnerModal.open} handleClose={handleChangeOwnerClose}/>
                <DeleteDocumentDialog open={this.props.deleteDocumentModal.open} handleClose={handleDeleteDocumentClose}
                isProcessing={this.props.deleteDocumentModal.isProcessing} handleConfirmation={handleDeleteConfirmation}/>
              </Grid>
              <Grid item  item xs={12} sm={6} md={2} lg={2}>
                                {this.props.canAccessClassifications && (
                                  <ColorButtonBlue variant="contained" className={classes.button} onClick={handleNavigationToClassifications}>Nueva clasificación</ColorButtonBlue>
                                )}
              </Grid>
            </Grid>
            
            </form>
            <Paper>
              <ResponsiveTable
                excludePrimaryFromDetails={true}
                enableShouldComponentUpdate={true}
                columns={newColumns}
                data={this.props.data}
                showPagination={true}
                count={this.props.totalCount}
                rowsPerPage={this.props.pageSize}
                page={this.props.currentPage}
                noContentText={'No hay registros'}
                onChangePage={this.props.handleChangePage}
                TableProps={{dense: true, table: "true", size: "small"}}
                TableHeadCellProps= {{align: 'center', style: {"fontWeight": "bold", "background-color": "#D3D3D3", color: "#60b044"}}}
                TableBodyCellProps={{align: 'center'}}
                TablePaginationProps={{
                  labelRowsPerPage: "Cantidad de Filas",
                  rowsPerPageOptions: [10, 25, 50, 100],
                  component: "div",
                  onChangeRowsPerPage: this.props.handleChangeRowsPerPage
                }}
                ExpansionPanelSummaryTypographyProps={{style: {"fontWeight": "bold"}}}
                ExpansionPanelDetailsTypographyProps={{className: "test", classes:listItemsClasses}}
              />
            </Paper>
            {/* <Paper  className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table stickyHeader aria-label="Clients table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      key='actions'
                      align='center'
                      style={{minWidth:80, fontWeight: 'bold'}}
                    >
                      Acciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.data.map(row => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                        <TableCell align='center'>
                          <IconButton onClick={() => handleDownload(row.downloadUrl)}>
                            <CloudDownloadIcon style={{ color: green[500] }}/>
                          </IconButton>
                          <IconButton onClick={() => handleResubmitOpen(row.id)}>
                            <PublishIcon style={{ color: green[500] }}/>
                          </IconButton>
                          <IconButton onClick={() => handleChangeOwnerOpen(row.id, row.parentClientId)}>
                            <CompareArrowsIcon style={{ color: green[500] }}/>
                          </IconButton>
                          {this.props.isAdmin ? (
                            <IconButton onClick={() => handleDeleteDocumentOpen(row.id)}>
                              <DeleteIcon style={{color: green[500]}}/>
                            </IconButton>  
                          ): undefined}                                          
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={this.props.totalCount}
            rowsPerPage={this.props.pageSize}
            page={this.props.currentPage}
            onChangePage={this.props.handleChangePage}
            onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
            labelRowsPerPage={"Cantidad de Filas"}
            labelDisplayedRows={	({ from, to, count }) =>`${from}-${to === -1 ? count : to} de ${count}`}
          />
        </Paper> */}
        <br></br>
          <br></br>
      </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {pagedDocuments, documentModal, resubmitDocumentModal, changeDocumentOwnerModal, catalog, deleteDocumentModal} = state;
    const {clients, childClients, classifications} = catalog;
    const {user} = state.auth;
    let isAdmin = isRootUser(user);
    let isNotClient = isNonClient(user);
    let canAccessClassifications = hasModulePermission(user, modules.classifications);
    return {...pagedDocuments, documentModal, resubmitDocumentModal, changeDocumentOwnerModal, clients, 
      childClients, classifications, isAdmin, isNonClient: isNotClient, canAccessClassifications, deleteDocumentModal};
}

const mapDispatchToProps = {
    getPagedDocuments,
    handleChangePage,
    handleChangeRowsPerPage,
    initPagedDocuments,
    handleSearch,
    openingDocumentModal,
    closingDocumentModal,
    openingResubmitDocumentModal,
    closingResubmitDocumentModal,
    openingChangeOwnerModal,
    closingChangeOwnerModal,
    handleParentClient,
    handleChildClient,
    handleYear,
    handleClassification,
    getClients, 
    getChildClients, 
    getClassifications, 
    clearChildClients,
    openingDeletingDocumentModal,
    closdingDeletingDocumentModal,
    deleteDocument
};

export default withStyles(styles)(reduxForm({form:'DocumentsListForm'})(connect(mapStateToProps, mapDispatchToProps)(DocumentsLanding)));