import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';

import {renderTextField, renderFromHelper, renderCheckbox, renderSelectField, renderDropzoneInput} from '../../constants/customElementsMUI';
import { connect } from 'react-redux';

import {creatingNewClientUser, editingClientUser} from '../../actions/clients/ClientListActions';

const validate = values => {
    const errors = {};

    let requiredFields = [
        'username'
    ]

    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });

    if(values.password && !values.confirmPassword){
        errors.confirmPassword = 'Obligatorio*'
    }

    if (values.password && values.confirmPassword && values.password != values.confirmPassword) {
        errors.confirmPassword = 'Las contraseñas no coinciden*';
    }

    return errors;
}

class ClientUserFormDialog extends Component{
    constructor(props){
        super(props);
    }

    handleFormSubmit = values => {
        const info = {
            username: values.username,
            clientId: 0,
            canAccessBills: values.canAccessBills,
            canAccessDocuments: values.canAccessDocuments,
            loginEnabled: true
        }

        if(this.props.editing === false){
            info.confirmPassword = values.confirmPassword;
            info.password = values.password;
        }

        this.props.editing === true ? this.props.editingClientUser(info) : this.props.creatingNewClientUser(info);
    }

    render(){
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;

        return(
            <div>
                <Dialog open={open} onClose={handleClose} aria-labeledby='form-dialog-title'
                    disableBackdropClick={this.props.isProcessing}
                    disableEscapeKeyDown={this.props.isProcessing}
                >
                    <DialogTitle id="form-dialog-title">{this.props.editing === true ? 'Editar usuario' : 'Nuevo Usuario'}</DialogTitle>
                    <form onSubmit={handleSubmit(this.handleFormSubmit)} noValidate autoComplete='off'>
                        <DialogContent>
                            <DialogContentText>
                                Ingrese los datos solicitados
                            </DialogContentText>
                            <div>
                                <Field 
                                    label="Usuario"
                                    name="username"
                                    component={renderTextField}
                                    type="text"
                                />                               
                            </div>
                            {this.props.editing === false && (
                                    <div>
                                        <div>
                                            <Field
                                                label="Contraseña"
                                                name="password"
                                                component={renderTextField}
                                                type="password"
                                            />
                                        </div>
                                        <div>
                                            <Field 
                                                label="Confirmar contraseña"
                                                name="confirmPassword"
                                                component={renderTextField}
                                                type="password"
                                            />
                                        </div>
                                    </div>
                            )}
                            <div>
                                <Field name="canAccessBills" component={renderCheckbox} label="Acceso a sus Bills"/>
                            </div>
                            <div>
                                <Field name="canAccessDocuments" component={renderCheckbox} label="Acceso a sus documentos"/>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary" disabled={submitting || this.props.isProcessing}>
                                Cancelar
                            </Button>
                            <Button type="submit" color="primary" disabled={pristine||submitting||this.props.isProcessing}>
                                Guardar
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const {isProcessing, editing} = state.clientUserFormModal
    const initialValues = state.clientUserFormModal.user;
    return {isProcessing, editing, initialValues};
}

const mapDispatchToProps = {
    creatingNewClientUser,
    editingClientUser
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'ClientUserForm', validate, enableReinitialize: true})(ClientUserFormDialog));