import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import MyFile from '../fileUploader/fileUploader';

import {renderTextField, renderFromHelper, renderCheckbox, renderSelectField, renderDropzoneInput, renderDatePicker} from '../../constants/customElementsMUI';
import { connect } from 'react-redux';

import {createNewPayment} from '../../actions/bills/billActions';

import {getPaymentTypes, getPaymentWays} from '../../actions/catalog/catalogActions';
import { isNonClient } from './../../constants/api';
import { PAYMENT_TYPE_FEE, PAYMENT_TYPE_DUTY_RECEIVED, PAYMENT_TYPE_THIRD_PARTY, PAYMENT_TYPE_DUTY_THIRD_PARTY } from '../../constants/paymentTypes';
import moment from 'moment';

const validate = values => {
    const errors = {};
    let requiredFields = [
        'paymentDate',
        'paymentWayId',
        'paymentTypeId',
        'amount'
    ];
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });
    /*if(values.user && emailRegex.test(values.email)){
    errors.email = 'Usuario inválido';
    }*/
    return errors;
}

class FormBillDialog extends Component {
    constructor(props){
        super(props);
    }

    state = { imageFile: [] };
    
    componentDidMount() {
        this.props.getPaymentTypes();
        this.props.getPaymentWays();
    }

    handleFormSubmit = values => {
        const fd = new FormData();
        if(values.document !== undefined){
            fd.append("document", values.document[0]);
        }
        if(values.paymentDate !== undefined && values.paymentDate !== null){
            fd.append('paymentDate', moment(values.paymentDate).format('YYYY-MM-DD'));
        }
        fd.append('paymentWayId', values.paymentWayId);
        fd.append('paymentTypeId', values.paymentTypeId);
        fd.append('amount', values.amount);
        fd.append('paymentReference', values.paymentReference);
        fd.append('observation', values.observation);        
        this.props.createNewPayment(fd);
    }
    render() {
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;

        const validateAmount = (value, values) => {
            if(this.props.newPaymentModal.payments !== null){
                if(values.paymentTypeId !== undefined){
                    // if(values.paymentTypeId === PAYMENT_TYPE_FEE){
                    //     if((Number(value) + this.props.newPaymentModal.payments.receivedFeeUSD)>this.props.newPaymentModal.payments.feeUSD){
                    //         return 'El monto proporcionado supera al total a pagar.'
                    //     }
                    // }
                    // if(values.paymentTypeId === PAYMENT_TYPE_DUTY_RECEIVED){
                    //     if((Number(value) + this.props.newPaymentModal.payments.receivedDutyNIO) > this.props.newPaymentModal.payments.dutyNIO){
                    //         return 'El monto proporcionado supera al total a pagar.'
                    //     }
                    // }
                    // if(values.paymentTypeId === PAYMENT_TYPE_THIRD_PARTY){
                    //     console.log(Number(value) + this.props.newPaymentModal.payments.paidThirdPartyUSD);
                    //     if((Number(value) + this.props.newPaymentModal.payments.paidThirdPartyUSD) > this.props.newPaymentModal.payments.thirdPartyUSD){
                    //         return 'El monto proporcionado supera al total a pagar.'
                    //     }
                    // }
                    // if(values.paymentTypeId === PAYMENT_TYPE_DUTY_THIRD_PARTY){
                    //     if((Number(value)+ this.props.newPaymentModal.payments.thirdPartyDutyNIO) > this.props.newPaymentModal.payments.receivedDutyNIO){
                    //         return 'El monto proporcionado supera al total a pagar.'
                    //     }
                    // }                
                }
            }
        }

        const validateAmountGreaterThan0 = value => value <= 0 ? 'El valor no puede ser cero.' : undefined;
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                    disableBackdropClick={this.props.isProcessing}
                    disableEscapeKeyDown={this.props.isProcessing}
                >
                    <DialogTitle id="form-dialog-title">Nuevo Pago</DialogTitle>
                    <form onSubmit={handleSubmit(this.handleFormSubmit)} noValidate autoComplete='off'>
                    <DialogContent>
                        <DialogContentText>
                            Favor llenar los siguientes datos:
                        </DialogContentText>
                        <div>
                            <Field
                                label="Fecha de pago"
                                name="paymentDate"
                                component={renderDatePicker}
                            />
                            <div style={{height:'16px'}}></div>
                        </div>
                            <div>
                                <div>
                            <Field
                                name="paymentWayId"
                                component={renderSelectField}
                                label="Forma de pago"
                            >
                                <option key={0} value={null}></option>
                                {this.props.paymentWays !== undefined ? this.props.paymentWays.map(way => {
                                    return(
                                        <option key={way.id} value={way.id}>{way.name}</option>
                                    )
                                }) : undefined}
                            </Field>    
                        </div>
                            <div>
                            <div style={{height: "16px"}}></div>
                            <Field
                                name="paymentTypeId"
                                component={renderSelectField}
                                label="Tipo de pago"
                                onChange={this.handleChildClientChange}
                            >
                                <option key={0} value={null}></option>
                                {this.props.paymentTypes !== undefined ? this.props.paymentTypes.map(type => {
                                    return(
                                        <option key={type.id} value={type.id}>{type.name}</option>
                                    )
                                }) : undefined}
                            </Field>    
                        </div>
                            </div>
                        <div>
                            <Field
                                label="Monto abonado"
                                name="amount"
                                type="number"
                                component={renderTextField}
                                validate={[validateAmount, validateAmountGreaterThan0]}
                            />
                        </div>
                        <div>
                            <Field
                                label="Referencia de pago"
                                name="paymentReference"
                                type="text"
                                component={renderTextField}
                            />
                        </div>
                        <div>
                            <Field
                                label="Observación"
                                name="observation"
                                type="text"
                                component={renderTextField}
                            />
                        </div>
                            <div>
                                <div style={{height: "16px"}}></div>                            
                                    <Field label={"Documento a subir"} name="document" component={MyFile} type="file"
                                    dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                            </div>                                 
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" disabled={submitting ||this.props.isProcessing}>
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary" disabled={pristine||submitting||this.props.isProcessing}>
                            Guardar
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>       
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const {paymentTypes, paymentWays} = state.catalog;
    const {newPaymentModal} = state;
    const {isProcessing} = newPaymentModal;
    const {user} = state.auth;
    let isNotClient = isNonClient(user);
    return {paymentTypes, paymentWays, isNonClient: isNotClient, isProcessing, newPaymentModal};
}

const mapDispatchToProps = {
    createNewPayment,
    getPaymentTypes,
    getPaymentWays
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form:'BillPaymentForm', validate})(FormBillDialog));