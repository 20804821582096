import {RESUBMITTING_DOCUMENT, OPENING_RESUBMIT_DOCUMENT_MODAL, CLOSING_RESUBMIT_DOCUMENT_MODAL} from './../../actions/documents/DocumentListActions'

const INITIAL_STATE = {
    open: false,
    documentId: null,
    isProcessing: false
}

export default function ResubmitDocumentModal(state = INITIAL_STATE, action){
    switch(action.type){
        case `${RESUBMITTING_DOCUMENT}_REQUESTED`:
            return {...state, isProcessing: true};
        case `${RESUBMITTING_DOCUMENT}_REJECTED`:
            return {...state, open: false, documentId: null, isProcessing: false};
        case `${RESUBMITTING_DOCUMENT}_FULFILLED`:
            return {...state, open: false, documentId: null, isProcessing: false};
        case OPENING_RESUBMIT_DOCUMENT_MODAL:
            return {...state, open: true, documentId: action.payload};
        case CLOSING_RESUBMIT_DOCUMENT_MODAL:
            return {...state, open: false, documentId: null};
        default:
            return state;
    }
}