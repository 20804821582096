import {
    OPENING_UPLOAD_CASE_FILE_MODAL,
    CLOSING_UPLOAD_CASE_FILE_MODAL,
    UPLOADING_CASE_FILE,
    ADDING_NEW_TO_MAIL,
    ADDING_NEW_CC_MAIL,
    REMOVING_NEW_TO_MAIL,
    REMOVING_NEW_CC_MAIL,
    CHANGE_WILL_NOTIFY
} from '../../actions/legalCases/legalCaseListAction';

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    tos: [],
    ccs: [],
    legalCaseId: null,
    shouldNotify: false
}

export default function LegalCaseModalReducer(state=INITIAL_STATE, action){
    switch(action.type){
        case OPENING_UPLOAD_CASE_FILE_MODAL:
            return {...state, open: true, legalCaseId: action.payload, shouldNotify: false};
        case CLOSING_UPLOAD_CASE_FILE_MODAL:
            return {...state, open: false, legalCaseId: null, isProcessing: false, tos: [], ccs: [], shouldNotify: false};
        case `${UPLOADING_CASE_FILE}_PENDING`:
            return {...state, isProcessing: true};
        case `${UPLOADING_CASE_FILE}_FULFILLED`:
            return {...state, isProcessing: false, open: false, legalCaseId: null, tos: [], ccs: [], shouldNotify: false};
        case `${UPLOADING_CASE_FILE}_REJECTED`:
            return {...state, isProcessing: false, open: false, legalCaseId: null, tos: [], ccs: [], shouldNotify: false};
        case ADDING_NEW_TO_MAIL:
            return {...state, tos: action.payload};
        case REMOVING_NEW_TO_MAIL:
            return {...state, tos: action.payload};
        case ADDING_NEW_CC_MAIL:
            return {...state, ccs: action.payload};
        case REMOVING_NEW_CC_MAIL:
            return {...state, ccs: action.payload};
        case CHANGE_WILL_NOTIFY:
            return {...state, shouldNotify: action.payload};
        default: 
            return state;
    }
}