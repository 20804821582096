import {
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  SET_USERS_CRITERIA,
  SET_USERS_CURRENT_PAGE,
  SET_USERS_PAGE_SIZE,
  SET_USERS_TYPE_ID,
  SET_USERS_LOGIN_STATUS_FILTER
}

  from './../../actions/users/UserListActions';

const INITIAL_STATE = {
  fetchError: false,
  isFetchingList: false,
  criteria: '',
  typeId: null,
  loginEnabled: null,
  data: [],
  totalCount: 0,
  pageSize: 25,
  totalPages: 0,
  currentPage: 0
};

export default function ClientsList(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_USERS_CRITERIA:
      return { ...state, criteria: action.payload, currentPage: 0 };
    case SET_USERS_LOGIN_STATUS_FILTER:
      return {...state, loginEnabled: action.payload, currentPage: 0};
    case SET_USERS_TYPE_ID:
      return { ...state, typeId: action.payload, currentPage: 0 };
    case SET_USERS_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case SET_USERS_PAGE_SIZE:
      return { ...state, pageSize: action.payload, currentPage: 0 };

    case FETCH_USERS_START:
      return { ...state, isFetchingList: true, fetchError: false };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
        fetchError: false,
        data: action.payload.records,
        totalCount: action.payload.totalCount,
        totalPages: action.payload.totalPages,
      };
    case FETCH_USERS_FAILURE:
      return { ...state, isFetchingList: false, fetchError: true, data: [], totalCount: 0, currentPage: 0, totalPages: 0 };

    default:
      return state;
  }
}