import {FETCH_BILLS_START, FETCH_BILLS_SUCCESS, FETCH_BILLS_FAILURE, SET_BILLS_CRITERIA, SET_BILLS_PARENT_CLIENT, SET_BILLS_CHILD_CLIENT,
SET_BILLS_YEAR, SET_BILLS_MONTH, SET_BILLS_PAGE_SIZE, SET_BILLS_CURRENT_PAGE, } from '../../actions/bills/billActions';

const INITIAL_STATE = {
    fetchError: false,
    isFetchingList: false,
    criteria: '',
    parentClientId: null,
    childClientId: null,
    year: null,
    month: null,
    data: [],
    orderBy: 0,
    ascending: false,
    totalCount: 0,
    pageSize: 25,
    totalPages: 0,
    currentPage: 0
}

export default function BillsListReducer(state=INITIAL_STATE, action){
    switch(action.type){
        case SET_BILLS_CRITERIA:
            return {...state, criteria: action.payload, currentPage: 0};
        case SET_BILLS_CURRENT_PAGE:
            return {...state, currentPage: action.payload};
        case SET_BILLS_PARENT_CLIENT:
            return {...state, parentClientId: action.payload};
        case SET_BILLS_CHILD_CLIENT:
            return {...state, childClientId: action.payload};
        case SET_BILLS_MONTH:
            return {...state, month: action.payload};
        case SET_BILLS_YEAR:
            return {...state, year: action.payload};
        case SET_BILLS_PAGE_SIZE:
            return { ...state, pageSize: action.payload, currentPage: 0 };
        case FETCH_BILLS_START:
            return { ...state, isFetchingList: true, fetchError: false };
        case FETCH_BILLS_SUCCESS:
            return {
                ...state,
                isFetchingList: false,
                fetchError: false,
                data: action.payload.records,
                totalCount: action.payload.totalCount,
                totalPages: action.payload.totalPages,
            };
        case FETCH_BILLS_FAILURE:
            return { ...state, isFetchingList: false, fetchError: true, data: [], totalCount: 0, currentPage: 0, totalPages: 0 };
        default:
            return state;
    }
}