import {REUPLOADING_CASE_FILE, OPENING_REUPLOAD_CASE_FILE_MODAL, CLOSING_REUPLOAD_CASE_FILE_MODAL} from '../../actions/legalCases/legalCaseListAction';

const INITIAL_STATE = {
    open: false,
    legalCaseId: null,
    isProcessing: false
}

export default function ReuploadLegalCaseModalReducer(state = INITIAL_STATE, action){
    switch(action.type){
        case OPENING_REUPLOAD_CASE_FILE_MODAL:
            return {...state, open: true, legalCaseId: action.payload};
        case CLOSING_REUPLOAD_CASE_FILE_MODAL:
            return {...state, open: false, legalCaseId: null, isProcessing: false};
        case `${REUPLOADING_CASE_FILE}_PENDING`:
            return {...state, isProcessing: true};
        case `${REUPLOADING_CASE_FILE}_FULFILLED`:
            return {...state, isProcessing: false, legalCaseId: null, open: false};
        case `${REUPLOADING_CASE_FILE}_REJECTED`:
            return {...state, isProcessing: false, legalCaseId: null, open: false};
        default:
            return state;
    }
}