import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import MyFile from '../fileUploader/fileUploader';

import {renderTextField, renderFromHelper, renderCheckbox, renderSelectField, renderDropzoneInput} from '../../constants/customElementsMUI';
import { connect } from 'react-redux';

import {createDocument, getChildClients, clearChildClients} from '../../actions/documents/DocumentListActions';

import {getClients, getClassifications} from '../../actions/catalog/catalogActions';
import { isNonClient } from './../../constants/api';

const validate = values => {
    const errors = {};
    let requiredFields = [
        'name',
        'classificationId',
        'clientId',
        'year'
    ];
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });
    /*if(values.user && emailRegex.test(values.email)){
    errors.email = 'Usuario inválido';
    }*/
    return errors;
}

class FormDocumentDialog extends Component {
    constructor(props){
        super(props);
    }

    state = { imageFile: [] };
    
    componentDidMount() {
        this.props.getClients();
        this.props.getClassifications();
    }

    handleFormSubmit = values => {
        const fd = new FormData();
        fd.append("document", values.document[0]);
        fd.append('name', values.name);
        fd.append('classificationId', values.classificationId);
        fd.append('year', values.year);
        
        if (values.name2 && values.classificationId2 && values.year2 && values.document2 != undefined) {
            fd.append("document2", values.document2[0]);
            fd.append('name2', values.name2);
            fd.append('classificationId2', values.classificationId2);
            fd.append('year2', values.year2);
            
            if (values.name3 && values.classificationId3 && values.year3 && values.document3 != undefined) {
                fd.append("document3", values.document3[0]);
                fd.append('name3', values.name3);
                fd.append('classificationId3', values.classificationId3);
                fd.append('year3', values.year3);
                
                if (values.name4 && values.classificationId4 && values.year4 && values.document4 != undefined) {
                    fd.append("document4", values.document4[0]);
                    fd.append('name4', values.name4);
                    fd.append('classificationId4', values.classificationId4);
                    fd.append('year4', values.year4);
                
                    if (values.name5 && values.classificationId5 && values.year5 && values.document5 != undefined) {
                        fd.append("document5", values.document5[0]);
                        fd.append('name5', values.name5);
                        fd.append('classificationId5', values.classificationId5);
                        fd.append('year5', values.year5);
                    
                    }
                }
    
            }
        }


        if(values.childClientId !== null && values.childClientId !== undefined){
            fd.append('clientId', values.childClientId);
        }
        else{
            fd.append('clientId', values.clientId);
        }
        this.props.createDocument(fd);
    }

    handleParentClientChange = (value) => {
        const selected = value.currentTarget.value;
        if(selected===''){
            this.props.clearChildClients();
        }
        else if (selected !=='' && this.props.isNonClient){
            this.props.getChildClients(selected);
        }
    }

    render() {
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                    disableBackdropClick={this.props.isProcessing}
                    disableEscapeKeyDown={this.props.isProcessing}
                >
                    <DialogTitle id="form-dialog-title">Nuevo Documento</DialogTitle>
                    <form onSubmit={handleSubmit(this.handleFormSubmit)} noValidate autoComplete='off'>
                    <DialogContent>
                        <DialogContentText>
                            Para crear un nuevo documento, favor llenar los siguientes datos:
                        </DialogContentText>
                        <div>
                            <Field
                                name="clientId"
                                component={renderSelectField}
                                label="Cliente"
                                onChange={this.handleParentClientChange}
                            >
                                <option key={0} value={null}></option>
                                {this.props.clients !== undefined ? this.props.clients.map(client => {
                                    return(
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    )
                                }) : undefined}
                            </Field>    
                        </div>
                        {this.props.isNonClient && (
                            <div>
                            <div style={{height: "16px"}}></div>
                            <Field
                                name="childClientId"
                                component={renderSelectField}
                                label="Subcliente"
                            >
                                <option key={0} value={null}></option>
                                {this.props.childClients !== undefined ? this.props.childClients.map(client => {
                                    return(
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    )
                                }) : undefined}
                            </Field>    
                        </div>

                        )}

                        <div>
                            <h4>Documento 1</h4>
                            <Field label={"Documento a subir"} name="document" component={MyFile} type="file"
                            dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                        </div>
                        <div>
                            <Field
                                label="Nombre 1"
                                name="name"
                                type="text"
                                component={renderTextField}
                            />
                        </div>
                        <div>
                            <Field
                                name="classificationId"
                                component={renderSelectField}
                                label="Clasificación 1"
                            >
                                <option key={0} value={null}></option>
                                {this.props.classifications !== undefined ? this.props.classifications.map(classification => {
                                    return(
                                        <option key={classification.id} value={classification.id}>{classification.name}</option>
                                    )
                                }) : undefined}
                            </Field>    
                        </div>
                        <div>
                            <Field
                                label="Año 1"
                                name="year"
                                type="number"
                                component={renderTextField}
                            />
                        </div>
                        {this.props.formValues && this.props.formValues.name 
                            && !isNaN(this.props.formValues.classificationId) && !isNaN(this.props.formValues.year)
                            && (
                                <div>
                                    <div>
                                        <h4>Documento 2</h4>
                                        <Field label={"Documento a subir"} name="document2" component={MyFile} type="file"
                                        dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                                    </div>
                                    <div>
                                        <Field
                                            label="Nombre 2"
                                            name="name2"
                                            type="text"
                                            component={renderTextField}
                                        />
                                    </div>
                                    <div>
                                        <Field
                                            name="classificationId2"
                                            component={renderSelectField}
                                            label="Clasificación 2"
                                        >
                                            <option key={0} value={null}></option>
                                            {this.props.classifications !== undefined ? this.props.classifications.map(classification => {
                                                return(
                                                    <option key={classification.id} value={classification.id}>{classification.name}</option>
                                                )
                                            }) : undefined}
                                        </Field>    
                                    </div>
                                    <div>
                                        <Field
                                            label="Año 2"
                                            name="year2"
                                            type="number"
                                            component={renderTextField}
                                        />
                                    </div>

                                </div>

                            )}
                        
                        {this.props.formValues && this.props.formValues.name2 
                            && !isNaN(this.props.formValues.classificationId2) && !isNaN(this.props.formValues.year2)
                            && (
                                <div>
                                    <div>
                                        <h4>Documento 3</h4>
                                        <Field label={"Documento a subir"} name="document3" component={MyFile} type="file"
                                        dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                                    </div>
                                    <div>
                                        <Field
                                            label="Nombre 3"
                                            name="name3"
                                            type="text"
                                            component={renderTextField}
                                        />
                                    </div>
                                    <div>
                                        <Field
                                            name="classificationId3"
                                            component={renderSelectField}
                                            label="Clasificación 3"
                                        >
                                            <option key={0} value={null}></option>
                                            {this.props.classifications !== undefined ? this.props.classifications.map(classification => {
                                                return(
                                                    <option key={classification.id} value={classification.id}>{classification.name}</option>
                                                )
                                            }) : undefined}
                                        </Field>    
                                    </div>
                                    <div>
                                        <Field
                                            label="Año 3"
                                            name="year3"
                                            type="number"
                                            component={renderTextField}
                                        />
                                    </div>

                                </div>

                            )}

                        {this.props.formValues && this.props.formValues.name3 
                            && !isNaN(this.props.formValues.classificationId3) && !isNaN(this.props.formValues.year3)
                            && (
                                <div>
                                    <div>
                                        <h4>Documento 4</h4>
                                        <Field label={"Documento a subir"} name="document4" component={MyFile} type="file"
                                        dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                                    </div>
                                    <div>
                                        <Field
                                            label="Nombre 4"
                                            name="name4"
                                            type="text"
                                            component={renderTextField}
                                        />
                                    </div>
                                    <div>
                                        <Field
                                            name="classificationId4"
                                            component={renderSelectField}
                                            label="Clasificación 4"
                                        >
                                            <option key={0} value={null}></option>
                                            {this.props.classifications !== undefined ? this.props.classifications.map(classification => {
                                                return(
                                                    <option key={classification.id} value={classification.id}>{classification.name}</option>
                                                )
                                            }) : undefined}
                                        </Field>    
                                    </div>
                                    <div>
                                        <Field
                                            label="Año 4"
                                            name="year4"
                                            type="number"
                                            component={renderTextField}
                                        />
                                    </div>

                                </div>

                            )}

                        {this.props.formValues && this.props.formValues.name4 
                            && !isNaN(this.props.formValues.classificationId4) && !isNaN(this.props.formValues.year4)
                            && (
                                <div>
                                    <div>
                                        <h4>Documento 5</h4>
                                        <Field label={"Documento a subir"} name="document5" component={MyFile} type="file"
                                        dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                                    </div>
                                    <div>
                                        <Field
                                            label="Nombre 5"
                                            name="name5"
                                            type="text"
                                            component={renderTextField}
                                        />
                                    </div>
                                    <div>
                                        <Field
                                            name="classificationId5"
                                            component={renderSelectField}
                                            label="Clasificación 5"
                                        >
                                            <option key={0} value={null}></option>
                                            {this.props.classifications !== undefined ? this.props.classifications.map(classification => {
                                                return(
                                                    <option key={classification.id} value={classification.id}>{classification.name}</option>
                                                )
                                            }) : undefined}
                                        </Field>    
                                    </div>
                                    <div>
                                        <Field
                                            label="Año 5"
                                            name="year5"
                                            type="number"
                                            component={renderTextField}
                                        />
                                    </div>

                                </div>

                            )}

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" disabled={submitting ||this.props.isProcessing}>
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary" disabled={pristine||submitting||this.props.isProcessing}>
                            Guardar
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>       
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const {clients, classifications} = state.catalog;
    const {childClients, isProcessing} = state.documentModal;
    const {user} = state.auth;
    let isNotClient = isNonClient(user);
    const selector = formValueSelector('CreateDocumentForm');
    const formValues = selector(state, 'name', 'name2', 'name3', 'name4', 'name5',
        'year', 'year2', 'year3', 'year4', 'year5',
        'classificationId', 'classificationId2', 'classificationId3', 'classificationId4', 'classificationId5'
    );
    return {clients, classifications, childClients, isNonClient: isNotClient, isProcessing, formValues};
}

const mapDispatchToProps = {
    createDocument,
    getClients,
    getClassifications,
    getChildClients,
    clearChildClients
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form:'CreateDocumentForm', validate})(FormDocumentDialog));