import {OPENING_USER_CHANGE_PASSWORD, CLOSING_USER_CHANGE_PASSWORD,
    UPDATING_USER_CHANGE_PASSWORD} from './../../actions/users/UserListActions';

const INITIAL_STATE = {
    open: false,
    userId: null,
    isProcessing: false
}

export default function UserChangePasswordModal(state = INITIAL_STATE, action){
    switch(action.type){
        case OPENING_USER_CHANGE_PASSWORD:
            return {...state, open: true, userId: action.payload.userId, isProcessing: false, loginEnabled: action.payload.loginEnabled};
        case CLOSING_USER_CHANGE_PASSWORD:
            return {...state, open: false, userId: null, isProcessing: false};
        case `${UPDATING_USER_CHANGE_PASSWORD}_FULFILLED`:
            return {...state, open: false, userId: null, isProcessing: false};
        case `${UPDATING_USER_CHANGE_PASSWORD}_REQUESTED`:
            return {...state,  isProcessing: true};
        case `${UPDATING_USER_CHANGE_PASSWORD}_REJECTED`:
            return {...state, open: false,  userId: null, isProcessing: false};
        default:
            return state;
    }
}