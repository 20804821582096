import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import MyFile from '../fileUploader/fileUploader';
import moment from 'moment';
import {renderTextField, renderFromHelper, renderCheckbox, renderSelectField, renderDropzoneInput, ColorButtonBlue, renderDatePicker} from '../../constants/customElementsMUI';
import { connect } from 'react-redux';

import {creatingBill, editBill, setParentClientBill} from '../../actions/bills/billActions';

import {getBillableLegalCases, getClients, getChildClients, clearChildClients, clearBillableCases} from '../../actions/catalog/catalogActions';
import { isNonClient } from './../../constants/api';

const validate = values => {
    const errors = {};
    let requiredFields = [
        'legalCaseId',
        'referenceDate',
    ];
    requiredFields.forEach(field => {
        if(!values[field]){
          errors[field] = 'Obligatorio*';
        }
    });
    /*if(values.user && emailRegex.test(values.email)){
    errors.email = 'Usuario inválido';
    }*/
    return errors;
}

class FormBillDialog extends Component {
    constructor(props){
        super(props);
    }

    state = { imageFile: [] };
    
    componentDidMount() {
        this.props.getClients();
    }

    handleFormSubmit = (values, props) => {
        const fd = new FormData();
        if(values.document !== undefined){
            fd.append("document", values.document[0]);
        }
        if(values.dutyDocument !== undefined){
            fd.append("documentDuty", values.dutyDocument[0]);
        }
        fd.append('legalCaseId', values.legalCaseId);
        if(values.referenceDate !== undefined && values.referenceDate !== null){
            fd.append('referenceDate', moment(values.referenceDate).format('YYYY-MM-DD'));
        }
        if (values.feeUSD !== undefined && values.feeUSD !== null){
            fd.append('feeUSD', values.feeUSD);
        }
        if (values.dutyNIO !== undefined && values.dutyNIO !== null){
            fd.append('dutyNIO', values.dutyNIO);    
        }
        // fd.append('thirdPartyFeeUSD', values.thirdPartyFeeUSD);
        if(values.isFree !== undefined && values.isFre !== null){
            fd.append('isFree', values.isFree);
        }
        else{
            fd.append('isFree', false);
        }
        if(values.shouldNotify !== undefined && values.shouldNotify !== null){
            fd.append('shouldNotify', values.shouldNotify);
        }
        else{
            fd.append('shouldNotify', false);
        }
        
        if(this.props.editing === true){
            this.props.editBill(fd);
        }
        else{
            this.props.creatingBill(fd);
        }
    }

    handleParentClientChange = (value) => {
        const selected = value.currentTarget.value;
        this.props.setParentClientBill(selected);
        if(selected===''){
            this.props.clearChildClients();
            this.props.clearBillableCases();
        }
        else if (selected !=='' && this.props.isNonClient){
            this.props.getChildClients(selected);
            this.props.getBillableLegalCases(selected);
        }
    }

    handleChildClientChange = (value) => {
        const selected = value.currentTarget.value;
        if(selected ===''){
            this.props.getBillableLegalCases(this.props.parentClientId);
        }
        else if(selected !==''){
            this.props.getBillableLegalCases(selected);
        }
    }

    render() {
        const {handleSubmit, pristine, reset, submitting} = this.props;
        const {open, handleClose} = this.props;
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                    disableBackdropClick={this.props.isProcessing}
                    disableEscapeKeyDown={this.props.isProcessing}
                >
                    <DialogTitle id="form-dialog-title">{this.props.editing === true ? `Editando bill para caso: ${this.props.bill.caseName}` : 
                    this.props.readOnly === true ? `Información para bill de caso: ${this.props.bill.caseName}` : 'Nueva bill'}</DialogTitle>
                    <form onSubmit={handleSubmit(this.handleFormSubmit)} noValidate autoComplete='off'>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.readOnly === false && 'Favor llenar los siguientes datos:'}
                        </DialogContentText>
                        {(this.props.readOnly === true && this.props.canPay === true) ? (
                            <div>
                                <ColorButtonBlue variant="contained" style={{color: 'white',}} onClick={this.props.handleNewPaymentFromView}>Nuevo pago</ColorButtonBlue>
                                <div style={{height: "16px"}}></div>
                            </div>
                        ):undefined}
                        <div>
                            <Field
                                label="Fecha de referencia"
                                name="referenceDate"
                                component={renderDatePicker}
                                disabled={this.props.readOnly}
                            />
                        </div>
                        {this.props.editing === false && this.props.readOnly === false ? (
                            <div>
                                <div>
                                <div style={{height: "16px"}}></div>
                            <Field
                                name="clientId"
                                component={renderSelectField}
                                label="Cliente"
                                onChange={this.handleParentClientChange}
                            >
                                <option key={0} value={null}></option>
                                {this.props.clients !== undefined ? this.props.clients.map(client => {
                                    return(
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    )
                                }) : undefined}
                            </Field>    
                        </div>
                        {this.props.isNonClient && (
                            <div>
                            <div style={{height: "16px"}}></div>
                            <Field
                                name="childClientId"
                                component={renderSelectField}
                                label="Subcliente"
                                onChange={this.handleChildClientChange}
                            >
                                <option key={0} value={null}></option>
                                {this.props.childClients !== undefined ? this.props.childClients.map(client => {
                                    return(
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    )
                                }) : undefined}
                            </Field>    
                        </div>

                        )}
                        <div>
                            <div style={{height: "16px"}}></div>
                            <Field
                                name="legalCaseId"
                                component={renderSelectField}
                                label="Caso"
                            >
                                <option key={0} value={null}></option>
                                {this.props.billableLegalCases !== undefined ? this.props.billableLegalCases.map(legalCase => {
                                    return(
                                        <option key={legalCase.id} value={legalCase.id}>{legalCase.name}</option>
                                    )
                                }) : undefined}
                            </Field>    
                        </div>
                            </div>
                        ): undefined}
                        <div>
                            <Field
                                label="Honorarios (USD)"
                                name="feeUSD"
                                type="number"
                                component={renderTextField}
                                disabled={this.props.readOnly}
                            />
                        </div>
                        <div>
                            <Field
                                label="Aranceles (C$)"
                                name="dutyNIO"
                                type="number"
                                component={renderTextField}
                                disabled={this.props.readOnly}
                            />
                        </div>

                        {this.props.readOnly === false && (
                            <div>

                                <div style={{height: "16px"}}></div>                            
                                
                            <h4>Documento de honorarios a subir</h4>
                                    <Field label={"Documento de factura a subir"} name="document" component={MyFile} type="file"
                                    dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                            </div>
                        )}
                        {this.props.readOnly === false && (
                            <div>
                                <div style={{height: "16px"}}></div>  
                                
                            <h4>Documento de aranceles a subir</h4>                          
                                    <Field label={"Documento de aranceles a subir"} name="dutyDocument" component={MyFile} type="file"
                                    dataAllowedFileExtensions="jpg png bmp pdf docx xlsx xls" />
                            </div>
                        )}
                        <div>
                            <Field name="isFree" component={renderCheckbox} label="¿Es gratis?" disabled={this.props.readOnly}/>
                        </div>
                        {this.props.readOnly === false && (
                            <div>
                                <Field name="shouldNotify" component={renderCheckbox} label="¿Notificar al cliente y al contacto?"/>
                            </div>   
                        )}                                          
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" disabled={submitting ||this.props.isProcessing}>
                            Cancelar
                        </Button>
                        {this.props.readOnly === false && (
                            <Button type="submit" color="primary" disabled={pristine||submitting||this.props.isProcessing}>
                                Guardar
                            </Button>
                        )}
                    </DialogActions>
                    </form>
                </Dialog>       
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const {billableLegalCases, clients, childClients} = state.catalog;
    const {isProcessing, editing, bill, parentClientId, readOnly, canPay} = state.billModal;
    const {user} = state.auth;
    const initialValues = state.billModal.bill;
    let isNotClient = isNonClient(user);
    return {billableLegalCases, initialValues, isNonClient: isNotClient, isProcessing, editing, bill, clients, childClients, parentClientId,
    readOnly, canPay};
}

const mapDispatchToProps = {
    creatingBill,
    editBill,
    getBillableLegalCases,
    getClients, 
    getChildClients,
    clearChildClients,
    clearBillableCases,
    setParentClientBill
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form:'BillForm', validate, enableReinitialize: true})(FormBillDialog));