import React, {Component} from 'react';
import debounce from 'lodash/debounce';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { renderTextField } from './../../constants/customElementsMUI';
import { Field, reduxForm } from 'redux-form';

import {ColorButtonBlue} from '../../constants/customElementsMUI';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';


import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import {green} from '@material-ui/core/colors';
import { ResponsiveTable } from 'material-ui-next-responsive-table';

import { isRootUser, isNonClient, hasModulePermission, modules } from './../../constants/api';

import {openDeletePaymentModal, closeDeletePaymentModal, deletePayment} from '../../actions/bills/billActions';
import DeletePaymentDialog from './deletePaymentDialog';
import { Tooltip } from '@material-ui/core';
const columns = [
  { id: 'createdAt', label: 'Fecha', minWidth: 120, align: 'center' },
  { id: 'createdByName', label: 'Creado por', minWidth: 120, align: 'center' },
  { id: 'comment', label: 'Mensaje', minWidth: 120, align: 'center' },
];

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    margin: theme.spacing(3, 0, 2, 3),
    color: 'white'
  },
});

 class CommentsLanding extends Component {

  componentDidMount() {

  }

  render() {
    const { classes } = this.props;
    const {open, handleClose} = this.props;
    let listItemsClasses = Object.assign({}, classes);

    const handleDelete = (id) => {
      this.props.openDeletePaymentModal(id);
      this.props.handleClose();
    }

    const handleDeleteClose = () => {
      this.props.closeDeletePaymentModal();
    }

    const handleDeleteConfirmation = () => {
      this.props.deletePayment();
    }


    const newColumns = [
      { key: 'paymentDate', label: 'Fecha de pago', primary: true},
      { key: 'paymentWay', label: 'Forma de pago', primary: true },
      { key: 'paymentReference', label: 'Referencia de pago', primary: true },
      { key: 'amount', label: 'Monto abonado' },
      { key: 'registeredBy', label: 'Registrado por' },
      { key: 'paymentType', label: 'Tipo' },
      { key: 'observation', label: 'Observación' },
      { key: 'downloadUrl', label: 'Acciones', render: (value, column) => {
        return (
          <div>
            {value === null || value === undefined ? '-' : 
            (<div>
              <Tooltip title="Descargar">
                <IconButton onClick={() => handleDownload(value)}>
                  <CloudDownloadIcon style={{color: green[500]}}/>
                </IconButton>
              </Tooltip>
                          
            
            <a target='_blank' className={'link'} href={`/filePreview?name=${column.displayFileName}&fileType=${column.documentExtension}&url=${btoa(column.downloadUrl)}`}>Ver preview</a>
            </div>)}
            {this.props.isAdmin === true && (
          <div>
            <Tooltip title="Eliminar">
            <IconButton onClick={() => handleDelete(column.id)}>
              <DeleteIcon style={{color: green[500]}}/>
            </IconButton>     
            </Tooltip>
                    
          </div>
        )}
             
          </div>
        );
        return 
      }}
    ];

    return (
      <div>
          <DeletePaymentDialog open={this.props.deletePaymentModal.open} isProcessing={this.props.deletePaymentModal.isProcessing} handleClose={handleDeleteClose}
          handleConfirmation={handleDeleteConfirmation} />
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}
                scroll={'paper'}
                    maxWidth={'xl'}>
                    <DialogTitle id="form-dialog-title">{`Pagos realizados para el caso: ${this.props.paymentDetailModal.payments !== null && this.props.paymentDetailModal.payments.caseName}`}</DialogTitle>
                    <DialogContent>

                    <div>
                                <ColorButtonBlue variant="contained" style={{color: 'white',}} onClick={this.props.handleNewPaymentFromDetail}>Nuevo pago</ColorButtonBlue>
                                <div style={{height: "16px"}}></div>
                            </div>           
            <Paper>
                            <ResponsiveTable
                                excludePrimaryFromDetails={true}
                                columns={newColumns}
                                data={(this.props.paymentDetailModal.payments && this.props.paymentDetailModal.payments.paymentsMade) || []}
                                noContentText={'No hay pagos realizados'}
                                TableProps={{dense: true, table: "true", size: "small"}}
                                TableHeadCellProps= {{align: 'center', style: {"fontWeight": "bold", "backgroundColor": "#D3D3D3", color: "#60b044"}}}
                                TableBodyCellProps={{align: 'center'}}
                                
                                ExpansionPanelSummaryTypographyProps={{style: {"fontWeight": "bold"}}}
                                ExpansionPanelDetailsTypographyProps={{ classes:listItemsClasses}}
                            />
            </Paper>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>

            </Dialog>       
            <div style={{height: '16px'}}></div>
        
        <br></br>
          <br></br>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const {paymentDetailModal, deletePaymentModal} = state;
  const {user} = state.auth;
  let isAdmin = isRootUser(user);
  return {paymentDetailModal, isAdmin, deletePaymentModal};
}

const mapDispatchToProps = {
  openDeletePaymentModal,
  closeDeletePaymentModal,
  deletePayment
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CommentsLanding));